import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { T, sum } from 'ramda'
import formatter from 'services/formatter'
import { precisionRound, defaultToZero } from 'services/utils'
import { MultiSelectionTable as Table } from 'components/MultiSelectionTable'
import {
  RenderTableRow as TableRow,
  StyledCheckBox as TableCheckBox,
} from 'components/Table'
import {
  GroupContainer,
  GroupHeader,
  GroupBody,
} from 'components/Table/TableGroup'
import { Button } from 'components/index'
import {
  Container,
  Controls,
  TableContainer,
  StyledDialog,
  StyledCheckBox,
  WeightList,
} from './styles'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function CollectSchedules({
  schedules,
  scheduleParams,
  onClose,
  onConfirm,
  ...props
}) {
  const tablesRef = useRef([])
  const [scheduleWeight, setScheduleWeight] = useState(0)

  const collectWeight = defaultToZero(scheduleParams.collectWeight)
  const capacity = defaultToZero(scheduleParams.capacity)
  const tare = defaultToZero(scheduleParams.tare)

  const calcAvailableWeight = () => {
    const calc = capacity - tare - collectWeight - scheduleWeight
    return calc
  }

  const availableWeight = calcAvailableWeight()
  const overweight = availableWeight < 0

  const createColumns = () => {
    let columns = [
      {
        field: 'cod_pedido',
        title: 'PEDIDO',
      },
      {
        field: 'item.dsc_abreviado',
        title: 'PRODUTO',
      },
      {
        field: 'iord_qtdade',
        title: 'QUANTIDADE',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        field: 'iord_peso',
        title: 'PESO (kg)',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        field: 'iord_bitola',
        title: 'BIT',
        align: 'right',
      },
      {
        field: 'iord_tonalidade',
        title: 'TON',
        align: 'right',
      },
      {
        field: 'iord_lote',
        title: 'LOTE',
        align: 'right',
      },
    ]

    return columns
  }

  const createComponents = (schedule) => (provided) => {
    let components = {}
    const { onSelectTable, getTableChecked, getShowGroupSelector } = provided

    const Header = () => {
      return (
        <GroupContainer>
          {getShowGroupSelector() && (
            <TableCheckBox
              checked={getTableChecked()}
              onChange={onSelectTable}
            />
          )}
          <div>
            <GroupHeader>
              <h3 className="group__title">{schedule.ford_senha}</h3>
              <span className="group__info">
                {[formatter(schedule.ford_data).toSimpleDate()]}
              </span>
              <span className="group__info">{schedule.unidade}</span>
            </GroupHeader>
            <GroupBody>
              <span>{schedule.ford_obs}</span>
              <span>{schedule.ford_nome_motorista}</span>
              <span>{schedule.ford_placa_numero}</span>
            </GroupBody>
          </div>
        </GroupContainer>
      )
    }

    const Group = ({ rowData: scheduleItem, ...props }) => {
      return (
        <TableRow
          {...props}
          columns={[{ field: 'cliente', colSpan: 6 }]}
          rowData={{
            cliente: `${scheduleItem.company.emp_razao_social} (${formatter(
              scheduleItem.cod_cliente
            ).toCNPJorCPF()})`,
          }}
        />
      )
    }

    const Footer = (props) => {
      return (
        <TableRow
          {...props}
          rowData={{
            iord_qtdade: schedule.total_quantity,
            iord_peso: schedule.total_weight,
          }}
        />
      )
    }

    components.Header = Header
    components.Group = Group
    components.Footer = Footer
    return components
  }

  const selectAll = (_, checked) => {
    tablesRef.current.forEach((methods) => {
      methods.selectAll(checked)
    })
  }

  const handleSelectSchedule = (updatedItems) => {
    const sumOfWeight = (arr) => sum(arr.map((i) => i.iord_peso))
    const removedWeight = sumOfWeight(updatedItems.filter((i) => !i.selected))
    const addedWeight = sumOfWeight(updatedItems.filter((i) => i.selected))

    setScheduleWeight((previousWeight) => {
      const newWeight = previousWeight + addedWeight - removedWeight
      return precisionRound(newWeight, 1)
    })
  }

  const handleConfirm = () => {
    const tables = tablesRef.current

    const selectedSchedules = tables.reduce((acc, table) => {
      const selectedItemsOnTable = table.getSelectedItems()
      const shippingOrderItem = selectedItemsOnTable[0]

      if (shippingOrderItem) {
        acc.push(shippingOrderItem)
      }

      return acc
    }, [])

    let values = { ...scheduleParams, schedules: selectedSchedules }
    onConfirm(values)
  }

  return (
    <StyledDialog
      title="Agendamentos disponíveis para carregamento"
      fullWidth
      maxWidth="md"
      actions={
        <>
          <Button
            className="success light"
            label="Confimar"
            variant="contained"
            onClick={handleConfirm}
          />
          <Button
            className="error light"
            label="Cancelar"
            variant="contained"
            onClick={onClose}
          />
        </>
      }
      {...props}
    >
      <Container>
        <WeightList>
          <section className="weights">
            <div>
              <span>Capacidade Veículo</span>
              <b>{formatter(capacity).toDecimal(2)}</b>
            </div>
            <div>
              <span>Tara Estimada</span>
              <b>{formatter(tare).toDecimal(2)}</b>
            </div>
            <div>
              <span>Peso Coleta</span>
              <b>{formatter(collectWeight).toDecimal(2)}</b>
            </div>
            <div>
              <span onClick={() => setScheduleWeight(0)}>Peso Agendamento</span>
              <b>{formatter(scheduleWeight).toDecimal(2)}</b>
            </div>
            <div className={clsx({ overweight })}>
              <span>
                {overweight
                  ? 'Capacidade do veículo excedida'
                  : 'Peso Disponível'}
              </span>
              <b>{formatter(availableWeight).toDecimal(2)}</b>
            </div>
          </section>
        </WeightList>

        <Controls>
          <section className="select-all">
            <StyledCheckBox onChange={selectAll} label="Selecionar Todos" />
          </section>
        </Controls>

        {[...schedules].map((schedule, index) => (
          <TableContainer key={index}>
            <Table
              onUpdate={handleSelectSchedule}
              data={schedule.items}
              columns={createColumns}
              components={createComponents(schedule)}
              getRef={(provided) => (tablesRef.current[index] = provided)}
              isAvailable={T}
              TableProps={{
                size: 'medium',
                cellStyle: () => ({ whiteSpace: 'noWrap' }),
              }}
              groupBy="cod_cliente"
              {...props}
            />
          </TableContainer>
        ))}
      </Container>
    </StyledDialog>
  )
}

CollectSchedules.propTypes = {
  schedules: PropTypes.array.isRequired,
  scheduleParams: PropTypes.object,
  onConfirm: PropTypes.func,
}

export default CollectSchedules
