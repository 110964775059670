import React, { useMemo, useEffect, useState } from 'react'
import { useMediaQuery, ThemeProvider, Grid } from '@material-ui/core'
import { sum } from 'ramda'
import { toast } from 'react-toastify'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import { themeForReport } from 'theme'

import * as medias from 'consts/medias'
import formatter from 'services/formatter'
import handleErrors from 'services/handleErrors'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ChevronLeft, Email, Print } from '@material-ui/icons'

import Mail from '../Mail'
import Table from '../../../../../components/Table'
import BaseDialog from 'components/Dialog'
import { TableRow, TableCell } from '../../../../../components/Table'

import { Buttons, Container, DescriptionContainer } from './styles'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const TABLE_SM_PADDING = '8px 2px'

const inititalOrderData = {
  items: [],
  cod_pedido: '...',
}

export function IssuedOrderReport({ current, onClose, ...props }) {
  // ╦ ╦╔═╗╔═╗╦╔═╔═╗
  // ╠═╣║ ║║ ║╠╩╗╚═╗
  // ╩ ╩╚═╝╚═╝╩ ╩╚═╝
  const sm = useMediaQuery(medias.sm)

  const [order, setOrder] = useState({ ...inititalOrderData })
  const [openMail, setOpenMail] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const initializeOrder = async () => {
      try {
        const response = await api.get(current._links.self)
        const response2 = await api.get(
          apiEndPoints.user.orders.print(current.cod_pedido)
        )

        const orderTaxes = response2.data
        const order = { ...response.data }

        order.items = order.items.map((i) => {
          const taxes = orderTaxes.find((t) => t.id_item === i.id_itens)

          return {
            ...i,
            taxes,
          }
        })

        setOrder(order)
      } catch (error) {
        handleErrors(error, 'Erro ao buscar o pedido')
      }

      setLoading(false)
    }

    initializeOrder()
  }, [current])

  // ╔═╗╔═╗╔╗╔╔═╗╔╦╗╔═╗
  // ║  ║ ║║║║╚═╗ ║ ╚═╗
  // ╚═╝╚═╝╝╚╝╚═╝ ╩ ╚═╝

  const representative = order.representative
  const customer = order.customer
  const company = order.customer?.company
  const emails = company?.emp_e_mail.split(';') || []

  // ╦ ╦╔═╗╔╗╔╔╦╗╦  ╔═╗╦═╗╔═╗
  // ╠═╣╠═╣║║║ ║║║  ║╣ ╠╦╝╚═╗
  // ╩ ╩╩ ╩╝╚╝═╩╝╩═╝╚═╝╩╚═╚═╝

  const compactText = (...values) =>
    values.filter((v) => v !== undefined).join(' / ')

  const handlePrint = () => {
    window.print()
  }

  const handleOpenMail = () => {
    setOpenMail(true)
  }

  const handleCloseMail = () => {
    setOpenMail(false)
  }

  const handleSend = (mailer) => {
    setLoading(true)

    api
      .post(apiEndPoints.user.orders.sendIssuedOrderEmail(order.id), {
        mailer,
      })
      .then(() => {
        toast.success('E-mail enviado com sucesso')
        handleCloseMail()
      })
      .catch(() => {
        toast.error('Não foi possível enviar o e-mail')
      })
      .finally(() => setLoading(false))
  }

  return (
    <BaseDialog
      fullWidth
      fullScreen={sm}
      maxWidth="lg"
      title={`Impressão Orçamento ${order.cod_pedido}`}
      actions={
        <>
          <Buttons>
            <Button
              size="medium"
              variant="text"
              color="primary"
              startIcon={<ChevronLeft />}
              onClick={onClose}
            >
              Voltar
            </Button>
            <Box display="flex" gridGap="1rem">
              <Button
                size="medium"
                variant="outlined"
                color="primary"
                endIcon={<Email />}
                onClick={handleOpenMail}
              >
                Enviar para o cliente
              </Button>
              <Button
                size="medium"
                variant="contained"
                color="primary"
                endIcon={<Print />}
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            </Box>
          </Buttons>
        </>
      }
      {...props}
    >
      <ThemeProvider theme={themeForReport}>
        <Container className="printable">
          {/* ==== HEADER ==== */}

          <header>
            <Typography component="h5" variant="h5">
              Pedido de Orçamento {order.cod_pedido}
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Description
                size={4}
                label="Data Emissão"
                value={formatter(order.ped_data_emissao).toSimpleDate()}
              />
              <Description
                size={6}
                label="Pedido Cliente Nº"
                value={order.ped_seupedido}
              />
              <Description
                size={2}
                label="Faturado por"
                value={order.billing_company?.descr}
              />

              <Description
                size={12}
                label="Representante"
                value={representative?.emp_razao_social}
              />
              <Description
                size={12}
                label="E-mail"
                value={representative?.emp_e_mail?.toLowerCase()}
              />
              <Description
                size={12}
                label="Telefone(s)"
                value={compactText(
                  representative?.rep_fone,
                  representative?.rep_celular
                )}
              />
            </Grid>
          </header>

          {/* ==== DADOS DO CLIENTE ==== */}

          <section>
            <Typography component="h5" variant="h5">
              Dados do Cliente
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Description
                size={6}
                label="Razão Social"
                value={customer?.emp_razao_social.toUpperCase()}
              />
              <Description
                size={3}
                label="CNPJ"
                value={formatter(customer?.id).toCNPJorCPF()}
              />
              <Description
                size={3}
                label="I.E."
                value={company?.emp_estadual_rg}
              />

              <Description
                size={12}
                label="Endereço"
                value={company?.zip_code.descr}
              />

              <Description
                size={12}
                label="Telefone(s)"
                value={compactText(
                  company?.emp_telefone,
                  company?.emp_telefone2
                )}
              />

              <Description
                size={12}
                label="E-mail(s)"
                value={compactText([...emails])}
              />
            </Grid>
          </section>

          {/* ==== DADOS DA ENTREGA ==== */}

          <section>
            <Typography component="h5" variant="h5">
              Dados da Entrega
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Description
                label="Endereço"
                value={order.delivery_address?.descr}
              />
            </Grid>
          </section>

          {/* ==== DADOS DA TRANSPORTADORA ==== */}

          <section>
            <Typography component="h5" variant="h5">
              Dados da Transportadora
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Description
                size={6}
                label="Transportadora"
                value={order.carrier?.descr}
              />
              <Description
                size={6}
                label="Tipo de Frete"
                value={order.ped_tipo_frete}
              />
              <Description
                size={12}
                label="Observações do Transportador"
                value={order.ped_obs_transp}
              />
            </Grid>
          </section>

          {/* ==== INFORMAÇÔES PEDIDO ==== */}

          <section>
            <Typography component="h5" variant="h5">
              Outras informações
            </Typography>

            <Grid container spacing={1} alignItems="center">
              <Description
                label="Condição de Pagamento"
                value={order.payment_condition?.descr}
              />
              <Description label="Moeda" value={order.currency?.descr} />
              <Description
                label="Exportação Indireta"
                value={order.ped_export_indireta ? 'Sim' : 'Não'}
              />
              <Description
                label="Observações do Orçamento"
                value={order.ped_obs}
              />
            </Grid>
          </section>

          {/* ==== ITEMS DO PEDIDO ==== */}

          <section>
            <TableItems order={order} data={order.items} loading={loading} />
          </section>
        </Container>
      </ThemeProvider>

      {/* ==== MAILER MODAL CONFIRMATION === */}

      {order.id && (
        <Mail
          title="Enviar Orçamento"
          open={openMail}
          onClose={handleCloseMail}
          onSend={handleSend}
          inAction={loading}
          initialData={{
            email: emails[0],
            self_copy: true,
            copy_to: [...emails.slice(1)],
          }}
        />
      )}
    </BaseDialog>
  )
}

function TableItems({ order, ...props }) {
  const displayTableFooter = useMemo(() => {
    const sumOf = (field) =>
      sum(order.items.map((item) => (item.taxes ? item.taxes[field] : 0)))

    const sumDifal = sumOf('vr_encargo')
    const sumIPI = sumOf('vr_ipi')
    const totalWithTaxes = sum([order.total, sumDifal, sumIPI])

    return (
      <>
        <TableRow>
          <TableCell
            colSpan={7}
            align="left"
            className="min-cell-text-overflow"
          >
            Total Mercadorias
          </TableCell>
          <TableCell align="right" style={{ padding: TABLE_SM_PADDING }}>
            {formatter(order.total_quantity).toDecimal(2)}
          </TableCell>
          <TableCell colSpan={3} />
          <TableCell align="right" style={{ padding: TABLE_SM_PADDING }}>
            {formatter(order.total).toDecimal(2)}
          </TableCell>
          <TableCell align="right" style={{ padding: TABLE_SM_PADDING }}>
            {formatter(String(sumDifal)).toDecimal(2)}
          </TableCell>
          <TableCell />
          <TableCell align="right" style={{ padding: TABLE_SM_PADDING }}>
            {formatter(String(sumIPI)).toDecimal(2)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={11}
            align="left"
            className="min-cell-text-overflow"
          >
            Total c/ Imposto
          </TableCell>
          <TableCell align="right" style={{ padding: TABLE_SM_PADDING }}>
            {formatter(totalWithTaxes).toDecimal(2)}
          </TableCell>
          <TableCell colSpan={3} />
        </TableRow>
      </>
    )
  }, [order])

  const cellStyle = ({ columnName }) => {
    const styles = {
      padding: TABLE_SM_PADDING,
      maxWidth: '50px',
    }

    switch (columnName) {
      case 'product.dsc_item_ingles':
        styles.whiteSpace = 'nowrap'
        styles.maxWidth = '110px'
        break
      default:
        break
    }

    return styles
  }

  return (
    <Table
      {...props}
      size="medium"
      cellStyle={cellStyle}
      components={{
        Footer: () => {
          return displayTableFooter
        },
      }}
      columns={[
        {
          field: 'id_itens',
          title: '#',
          align: 'center',
        },
        {
          field: 'product.cod_produto',
          title: 'Código',
        },
        {
          field: 'product.dsc_item_ingles',
          title: 'Produto',
        },
        {
          field: 'product.dsc_tamanho_produtos',
          title: 'Tamanho',
          align: 'center',
        },
        {
          field: 'taxes.itped_seu_item',
          title: 'S. Item',
          align: 'center',
        },
        {
          field: 'taxes.id_reserva',
          title: 'Reserva',
          align: 'center',
        },
        {
          field: 'product.prd_referencia',
          title: 'Ref.',
          align: 'center',
        },
        {
          field: 'itped_qtd',
          title: 'Qtdade',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'itped_qtd_cancelada',
          title: 'Qt.Canc.',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'peso_bru',
          title: 'P.Bruto',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'itped_prunit',
          title: 'Vl.Unit.',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'total',
          title: 'Vl.Total',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'taxes.vr_encargo',
          title: 'ST/DIFAL',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'taxes.itped_percipi',
          title: '%IPI',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          field: 'taxes.vr_ipi',
          title: 'Valor IPI',
          align: 'right',
          cellFormat: 'decimal',
        },
      ]}
    />
  )
}

function Description({ value = '', size = 12, label }) {
  return (
    <Grid className="description-grid-item" item md={size} xs={12}>
      <DescriptionContainer>
        <Typography className="label" variant="body2">
          {label}
          {label ? ':' : ''}
        </Typography>
        <Typography variant="body2">{value}</Typography>
      </DescriptionContainer>
    </Grid>
  )
}

export default IssuedOrderReport
