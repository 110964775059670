import React from 'react'
import DateFnsUtils from '@date-io/date-fns'

import { useLocale } from '../../hooks'
import { LOCALE_DATE_FNS } from '../../_i18n'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

export default function MaterialUiPickersUtilsProvider({ children }) {
  const { idLang } = useLocale()
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={LOCALE_DATE_FNS[idLang]}
    >
      {children}
    </MuiPickersUtilsProvider>
  )
}
