import { all, put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { confirmationDialog } from 'components/ConfirmationDialog'

import {
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  getOrderItemsFailure,
  getOrderItemsRequest,
  getOrderItemsSuccess,
  createOrderItemRequest,
  createOrderItemSuccess,
  createOrderItemFailure,
  removeOrderItemRequest,
  removeOrderItemFailure,
  updateOrderItemFailure,
  updateOrderItemRequest,
  updateOrderItemSuccess,
} from './reducer'

import { updateProduct } from '../products/reducer'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const PALLET_OCCUPATION_PERCENT = 75

function* getOrders({ payload }) {
  try {
    const queryParameters = {
      params: payload,
    }

    const response = yield call(
      api.get,
      apiEndPoints.user.orders.root,
      queryParameters
    )

    const orders = response.data
    const count = Number(response.headers.total)

    yield put(getOrdersSuccess({ orders, count }))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os pedidos')
    yield put(getOrdersFailure())
  }
}

function* createOrder({ payload: order }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Enviar Orçamento',
      'Confirma o envio do Orçamento ?'
    )

    confirmationDialog.close()

    if (confirm) {
      const response = yield call(api.post, apiEndPoints.user.orders.root, {
        order,
      })
      const createOrder = response.data
      yield put(createOrderSuccess(createOrder))
      toast.success('Orçamento enviado com sucesso')
    } else {
      yield put(createOrderFailure())
    }
  } catch (error) {
    handleErrors(error, 'Não foi possível criar o pedido')
    yield put(createOrderFailure())
  }
}

// 𝕆𝕣𝕕𝕖𝕣 𝕚𝕥𝕖𝕞
function* getOrderItems() {
  try {
    const response = yield call(api.get, apiEndPoints.user.orders.items.root)

    const orderItems = response.data

    yield put(getOrderItemsSuccess(orderItems))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os itens')
    yield put(getOrderItemsFailure())
  }
}

function* createOrderItem({ payload }) {
  try {
    const boxesPerPallet = payload.product.ctp_caixa_pallet
    const boxes = payload.boxes

    const parts = boxes % boxesPerPallet
    const isOpenedPallet = parts !== 0

    const isOccuped =
      isOpenedPallet &&
      Math.ceil((parts * 100) / boxesPerPallet) >= PALLET_OCCUPATION_PERCENT

    if (isOccuped) {
      yield call(
        confirmationDialog.open,
        'Atenção',
        'Você está próximo de fechar um pallet, nós temos preços especiais para pallet fechado. Consulte um Representante para maiores informações',
        { onlyMessage: true }
      )

      confirmationDialog.close()
    }

    const product = payload.product

    const requetBody = {
      web_order_item: {
        cod_produto: product.cod_produto,
        reference: product.prd_referencia,
        unit_price: payload.unit_price,
        quantity: payload.quantity,
        cod_representante: product.cod_representante,
        rep_nome: product.rep_nome,
        rep_sigla: product.rep_sigla,
        gross_weight: product.it_peso_bru,
        net_weight: product.it_peso_liq,
        amount_boxes: boxes,
      },
    }

    yield call(api.post, apiEndPoints.user.orders.items.root, requetBody)
    yield put(createOrderItemSuccess())
    yield put(updateProduct(requetBody.web_order_item))

    toast.success('Item adicionado com sucesso')
  } catch (error) {
    handleErrors(error, 'Não foi possível criar o item')
    yield put(createOrderItemFailure())
  }
}

function* updateOrderItem({ payload }) {
  try {
    const requetBody = {
      web_order_item: {
        quantity: payload.quantity,
      },
    }

    const response = yield call(
      api.put,
      apiEndPoints.user.orders.items.onMember(payload.id),
      requetBody
    )

    yield put(updateOrderItemSuccess(response.data))
    toast.success('Item atualizado com sucesso')
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o item')
    yield put(updateOrderItemFailure())
  }
}

function* removeOrderItem({ payload: ids }) {
  try {
    const isMultiple = ids.length > 1

    const confirm = yield call(
      confirmationDialog.open,
      'Tem certeza ?',
      'Essa ação não pode ser revertida.'
    )

    confirmationDialog.close()

    if (confirm) {
      // Removing multiple order items...
      for (let i = 0; i < ids.length; i++) {
        yield call(api.delete, apiEndPoints.user.orders.items.onMember(ids[i]))
      }

      toast.success(
        (isMultiple ? 'Itens removidos' : 'Item removido') + ' com sucesso'
      )
      yield put(getOrderItemsRequest())
    } else {
      yield put(removeOrderItemFailure())
    }
  } catch (error) {
    handleErrors(error, 'Não foi possível remover o item')
    yield put(removeOrderItemFailure())
  }
}

export default all([
  takeLatest(getOrdersRequest.type, getOrders),
  takeLatest(createOrderRequest.type, createOrder),
  takeLatest(getOrderItemsRequest.type, getOrderItems),
  takeLatest(createOrderItemRequest.type, createOrderItem),
  takeLatest(updateOrderItemRequest.type, updateOrderItem),
  takeLatest(removeOrderItemRequest.type, removeOrderItem),
])
