import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getUnitOfMeasuresRequest } from 'store/modules/users/orders/products/reducer'

import { FormCombobox } from '../../CoForm'

export function ComboboxUnitOfMeasures(props) {
  const dispatch = useDispatch()
  const unitOfMeasures = useSelector(
    (state) => state.users.orders.products.unitOfMeasures
  )

  useEffect(() => {
    dispatch(getUnitOfMeasuresRequest())
  }, [dispatch])

  const options = unitOfMeasures.map((u) => ({
    value: u.id_un_medidas,
    label: u.dsc_un_medidas,
  }))

  return <FormCombobox options={options} {...props} />
}

export default ComboboxUnitOfMeasures
