import styled from 'styled-components'
import { sm } from 'consts/medias'

export const Container = styled.div`
  padding: 0 16px 27px 16px;
  overflow: hidden;

  @media ${sm} {
    padding: 0;
  }
`

export const ContentContainer = styled.div`
  margin-top: 46px;
  @media ${sm} {
    min-height: calc(100vh - 66px);
    margin-top: 58px;
    padding: 0;
  }
`
