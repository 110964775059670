import { all } from 'redux-saga/effects'

import customers from './customers/sagas'
import carriers from './carriers/sagas'
import bulletins from './bulletins/sagas'
import truckTypes from './truckTypes/sagas'
import possibleDates from './possibleDates/sagas'
import lastVehicles from './lastVehicles/sagas'
import lastDrivers from './lastDrivers/sagas'
import shipmentUnities from './shipmentUnities/sagas'

export default all([
  customers,
  carriers,
  bulletins,
  truckTypes,
  possibleDates,
  lastVehicles,
  lastDrivers,
  shipmentUnities,
])
