import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import styled from 'styled-components'

import { translate } from '_i18n'
import { filterProps, toRankingOptions, formatFilters } from 'services/utils'
import formatter from 'services/formatter'

import {
  DATE_FIELDS,
  selectFilter,
  removeFilter,
  removeAllFilters,
} from 'store/modules/users/ordersForCollect/reducer'

import { Tooltip } from '@material-ui/core'

import Trash from 'components/Icons/Trash'
import Chip from 'components/Chip'
import Ranking from 'components/Ranking'
import SideBar from 'components/SideBar'
import SearchFilter from 'components/SearchFilter'

import { FormDatePicker } from 'components/CoForm'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const formatDates = (filter) =>
  DATE_FIELDS.includes(filter[filterProps.BY])
    ? {
        ...filter,
        value: formatter(filter[filterProps.VALUE]).toSimpleDate(),
      }
    : filter

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

const StyledTrashIcon = styled(Trash)`
  margin-right: 20px;

  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function Filters({ selectedFilters }) {
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.users.ordersForCollect.loading)

  const ufs = useSelector(
    (state) => state.users.ordersForCollect.filters.dsc_uf
  )
  const cities = useSelector(
    (state) => state.users.ordersForCollect.filters.cli_cidade
  )
  const customers = useSelector(
    (state) => state.users.ordersForCollect.filters.cli_nome
  )

  const handleRemoveAllFilters = () => dispatch(removeAllFilters())
  const handleSelectFilter = (filter) => dispatch(selectFilter(filter))

  const handleSubmit = (values, { reset }) => {
    formatFilters(values).forEach((filter) => handleSelectFilter(filter))
    reset()
  }

  return (
    <SideBar
      loading={loading}
      HeaderComponent={
        !isEmpty(selectedFilters) ? (
          <div className="ChipList ChipListSpacing">
            {selectedFilters.map(formatDates).map((filter, index) => (
              <Chip
                onDelete={() => dispatch(removeFilter(filter))}
                label={filter.value}
                key={index}
              />
            ))}
            <Tooltip title="Excluir filtros">
              <div>
                <StyledTrashIcon onClick={handleRemoveAllFilters} />
              </div>
            </Tooltip>
          </div>
        ) : null
      }
      ContentComponent={
        <div className="Ranking-List">
          <Ranking
            title="Pedidos por UF"
            filterBy="dsc_uf"
            rows={toRankingOptions(ufs)}
            onFiltering={handleSelectFilter}
          />
          <Ranking
            title="Pedidos por Cidade"
            filterBy="cli_cidade"
            rows={toRankingOptions(cities)}
            onFiltering={handleSelectFilter}
          />
          <Ranking
            title="Pedidos por Cliente"
            filterBy="cli_nome"
            rows={toRankingOptions(customers)}
            onFiltering={handleSelectFilter}
          />
        </div>
      }
      FooterComponent={
        <SearchFilter
          onSubmit={handleSubmit}
          title={translate('actions.searchBy')}
          buttonLabel={translate('actions.send')}
          fields={[
            { name: 'cod_pedido', label: 'Pedido' },
            {
              name: 'ped_data_emissao',
              label: 'Data de Emissão',
              Component: FormDatePicker,
            },
          ]}
        />
      }
    />
  )
}
