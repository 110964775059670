import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import handleErrors from 'services/handleErrors'

import {
  getTruckTypesRequest,
  getTruckTypesFailure,
  getTruckTypesSuccess,
} from './reducer'

function* getTruckTypes() {
  try {
    const response = yield call(api.get, apiEndPoints.miscellaneous.truckTypes)

    yield put(getTruckTypesSuccess(response.data))
  } catch (error) {
    yield put(getTruckTypesFailure())
    handleErrors(error, 'Não foi possível buscar os tipos do Caminhão')
  }
}

export default all([takeLatest(getTruckTypesRequest.type, getTruckTypes)])
