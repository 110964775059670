import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import styled from 'styled-components'

import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import { FormTextField, useCurrentForm } from '../CoForm'
import PasswordStrengthBar from 'react-password-strength-bar'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const inputTypes = {
  TEXT: 'text',
  PASSWORD: 'password',
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

const StyledBox = styled(Box)`
  cursor: pointer;
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormPasswordField({ showPasswordForce, ...props }) {
  const [inputType, setInputType] = useState(inputTypes.PASSWORD)
  const currentForm = useCurrentForm()

  const isPassword = equals(inputTypes.PASSWORD)
  const title = isPassword(inputType) ? 'Exibir senha' : 'Ocultar senha'

  const password = currentForm ? currentForm.getFieldValue(props.name) : null

  const handleInputType = () =>
    setInputType((type) => (isPassword(type) ? 'text' : 'password'))

  return (
    <>
      <FormTextField
        type={inputType}
        InputProps={{
          endAdornment: (
            <StyledBox title={title} onClick={handleInputType}>
              {isPassword(inputType) ? (
                <VisibilityOff fontSize="small" color="action" />
              ) : (
                <Visibility fontSize="small" color="action" />
              )}
            </StyledBox>
          ),
        }}
        {...props}
      />
      {showPasswordForce && (
        <PasswordStrengthBar
          password={password}
          scoreWords={['Muito fraca', 'Fraca', 'Okay', 'Boa', 'Forte']}
          shortScoreWord={['Muito curta']}
        />
      )}
    </>
  )
}

FormPasswordField.propTypes = {
  showPasswordForce: PropTypes.bool,
}

FormPasswordField.defaultProps = {
  showPasswordForce: false,
}

export default FormPasswordField
