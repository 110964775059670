import styled from 'styled-components'
import theme from 'theme'

export const Container = styled.div`
  padding: 1rem 0;

  header {
    img {
      width: 100%;
      max-width: 120px;
    }
  }

  section {
    margin: 1rem 0;
    padding-top: 0.5rem;
  }

  h5 {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    border-bottom: 1px solid ${theme.palette.divider};
  }

  .description-grid-item {
    padding-bottom: 2px;
    padding-top: 2px;
  }

  // TABLE

  table {
    width: 100%;

    .min-cell-text-overflow {
      max-width: 60px;
      white-space: nowrap;
    }

    thead {
      display: table-row-group !important;
    }

    tbody tr td,
    thead tr th,
    tfoot tr td {
      font-size: 10px;
      border-bottom: 1px solid ${theme.palette.divider};
    }
  }
`

export const Buttons = styled.div`
  margin: 1rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 0.25rem;

  .label {
    font-weight: bold;
    white-space: nowrap;
  }
`
