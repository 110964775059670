import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormCombobox, useCurrentForm } from 'components/CoForm'
import { getShipmentUnitiesRequest } from 'store/modules/comuns/shipmentUnities/reducer'

import TextField from 'components/TextField'
import ComboboxBase from '../Base'

export function ComboboxShipmentUnities(props) {
  const { showAll, ...rest } = props

  const currentForm = useCurrentForm()
  const dispatch = useDispatch()

  const shipmentUnities = useSelector(
    (state) => state.comuns.shipmentUnities.options
  )
  const loading = useSelector((state) => state.comuns.shipmentUnities.loading)

  const shipmentUnitiesOptions = useMemo(() => {
    const options = shipmentUnities.map((item) => ({
      value: item.id,
      label: item.cia_descricao_carreg,
    }))

    if (showAll) {
      options.splice(0, 0, { label: 'TODAS', value: 0 })
    }

    return options
  }, [shipmentUnities, showAll])

  useEffect(() => {
    dispatch(getShipmentUnitiesRequest())
  }, [dispatch])

  const componentInsideForm = currentForm.valid

  if (componentInsideForm) {
    return (
      <FormCombobox
        fullWidth
        disableClearable
        margin="normal"
        options={shipmentUnitiesOptions}
        loading={loading}
        {...rest}
      />
    )
  }

  return (
    <ComboboxBase
      options={shipmentUnitiesOptions}
      loading={loading}
      renderInput={(provided) => (
        <TextField
          style={{ minWidth: '200px' }}
          fullWidth
          label="Unidade"
          variant="outlined"
          {...provided}
        />
      )}
      fullWidth
      disableClearable
      {...rest}
    />
  )
}

ComboboxShipmentUnities.propTypes = {
  showAll: PropTypes.bool,
}

ComboboxShipmentUnities.defaultProps = {
  showAll: false,
}

export default ComboboxShipmentUnities
