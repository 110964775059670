import styled from 'styled-components'
import { colors } from 'theme'

export const Container = styled.div`
  .custom-row {
    max-height: calc(23px * 8.5);
  }
`

export const HeaderContainer = styled.div`
  margin-bottom: 10px;
`

export const FooterContainer = styled.div`
  padding: 15px 0;

  font-size: 12px;
  color: ${colors.color500};
`
