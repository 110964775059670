import styled from 'styled-components'
import Button from 'components/Button'
import { colors } from 'theme'
import * as medias from 'consts/medias'
import Paper from 'components/Paper'

import MuiList from '@material-ui/core/List'
import MuiListItem from '@material-ui/core/ListItem'
import { darken } from 'polished'

export const Container = styled(Paper)`
  display: flex;
  flex-direction: row;
  border: none;
  margin-top: 5px;
  padding: 1rem;
`

export const List = styled(MuiList)`
  margin: 5px 0;
`

export const ListItem = styled(MuiListItem)`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  // product banner
  img {
    flex: 1;
    max-width: 80px;
    max-height: 40px;
  }

  border-bottom: 1px solid ${colors.main};

  &.Product {
    padding-left: 2rem;
  }

  &.Total {
    font-weight: bold;
  }

  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: ${colors.background};
  }

  @media ${medias.sm} {
    img {
      max-width: 60px;
    }
    .MuiTypography-body1 {
      font-size: 11px;
    }
    .MuiTypography-body2 {
      font-size: 10px;
    }
    .ListItem-productInfo {
      flex-direction: column;
    }
  }
`

export const SizeInfo = styled.div`
  min-height: 40px;
  text-align: right;
  border-radius: 10px;
  background-color: ${colors.main};

  max-width: 30%;
  min-width: 300px;
  padding: 2.5px;
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid ${darken(0.05, colors.main)};

  p.MuiTypography-root {
    flex: 1;
    text-align: center;
  }

  @media ${medias.md} {
    margin-top: 10px;
    max-width: 100%;
  }
`

export const Buttons = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`

export const StyledButton = styled(Button)`
  min-width: 200px;

  @media ${medias.md} {
    min-width: auto;
    .MuiButton-label {
      font-size: 12px;
    }
  }
`
