import { LOCALES } from '../locales'

export const es = {
  [LOCALES.SPANISH]: {
    carriers: 'Portadoras',
    carrier: 'Portadora',
    toolbar: {
      title: '{companyType}',
      changeLanguage: 'Cambiar el Idioma',
      menus: {
        orders: 'Pedidos',
        collect: 'Coleta',
        issued: 'Emitidas',
        queries: 'Consultas',
      },
    },
    orders: {
      filter: {
        requestsFor: 'Pedidos por {typeFilter}',
      },
      table: {
        order: 'PEDIDO',
        company: 'CIA',
        product: 'PRODUTO',
        yourOrder: 'SEU PEDIDO',
        pallets: 'PAL',
        available: 'DISPONÍVEL',
        balance: 'SALDO',
        quantity: 'QUANT',
        boxes: 'CXS',
        weight: 'PESO (kg)',
        delivery: 'ENTREGA',
        freight: 'FRETE',
        representative: 'REPR',
        scheduling: 'AGENDAMENTO',
      },
    },

    collect: {
      includeButtonText: 'Incluir en la colección',
      collect: 'Coleta:',
      collectNum: 'COLETA {collect}',
      weightText: 'Peso {typeWeight} {weight}',
      selected: 'seleccionado:',
      total: 'Total:',
      collectBox: {
        truckType: 'Tipo de camión',
        datePlanned: 'Fecha prevista de recogida',
        vehicleCapacity: 'Capacidad total del vehículo',
        collectionWeight: 'Peso total de la colección',
        avaialble: 'Disponible para descargar',
      },
    },
    issued: {
      emissionFilter: {
        issuedBy: 'emitido por {typeFilter}',
      },
      table: {
        collect: 'COLECCIÓN:',
        collectionDate: 'FECHA DE RECOGIDA',
        plate: 'PLACA',
        driver: 'CONDUCTOR',
        O_C: 'o.c.',
      },
    },
    queries: {
      uploads: {
        inputDate: 'FECHA DE ENTRADA',
        attending: 'ASISTENCIA',
        plate: 'PLACA',
        driver: 'CONDUCTOR',
        password: 'CONTRASEÑA',
        cargo: 'CARGO',
        entry: 'ENTRADA',
        exit: 'SALIDA',
      },
      invoices: {
        invoice: 'NOTA DE VENTA',
        issue: 'EMICIÓN',
        customer: 'CLIENTE',
        city: 'CIUDAD',
        M2: 'UF',
        heigth: 'ALTURA',
        value: 'VALOR',
        periodFilter: {
          period: 'Informar del periodo de tiempo',
          initialDate: 'Fecha inicial',
          finalDate: 'Fecha final',
        },
      },
      comumns: {
        filter: {
          loadingText: 'Configurar los filtros',
        },
        stateInitials: 'UF',
        city: 'Ciudad',
        driver: 'Conductor',
        plate: 'Placa',
        yourOrder: 'Su pedido',
        issueDate: 'Fecha de emisión',
        approved: 'Aprobado',
        canceled: 'Cancelado',
        loading: 'Cargando',
        finished: 'Terminado',
        waiting: 'Esperando',
        released: 'Liberada',
      },
      actions: {
        signout: 'Salir',
        searchBy: 'Buscar por',
        includeButtonText: 'incluir en la colección',
        deleteButton: 'Eliminar lo seleccionado',
        selectAll: 'Seleccionar todo',
        send: 'Enviar',
        check: 'Ver las facturas emitidas',
        consultShipments: 'Consultar los envíos',
        search: 'Buscar',
      },
    },
  },
}
