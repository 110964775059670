import styled, { css } from 'styled-components'
import theme from '../../theme'

import { Chip } from '@material-ui/core'

export const StyledChip = styled(Chip)`
  background-color: ${(props) => props.background};
  color: ${() => theme.palette.common.white};

  &.outlined {
    background-color: unset;
    font-weight: normal;
    color: ${(props) => props.background};
    border: 1px solid ${(props) => props.background};
  }

  &.m-auto {
    margin: auto;
  }

  &.large {
    min-width: 200px;
    height: 30px;

    .MuiChip-label {
      font-weight: 700;
    }
  }

  ${(props) =>
    props.clickable
      ? css`
          &:hover,
          &:active,
          &:focus {
            background-color: ${(props) => props.background};
            opacity: 0.7;
          }
        `
      : css`
          pointer-events: none;
        `}
`

export const StyledChipOld = styled(Chip)`
  width: 80px;
  height: 20px;

  padding: 2px 11px 5px 12px;

  .MuiChip-label {
    overflow: unset;
    white-space: normal;
    padding-left: 12px;
    padding-right: 12px;
    text-overflow: unset;
  }

  background-color: ${(props) => props.background};
  color: ${() => theme.palette.common.white};
  font-weight: bold;
  font-size: 9px;

  line-height: 11px;
  letter-spacing: 0.6px;

  &.outlined {
    background-color: unset;
    font-weight: normal;
    color: ${(props) => props.background};
    border: 1px solid ${(props) => props.background};
  }

  &.m-auto {
    margin: auto;
  }

  ${(props) =>
    props.clickable
      ? css`
          &:hover,
          &:active,
          &:focus {
            background-color: ${(props) => props.background};
            opacity: 0.7;
          }
        `
      : css`
          pointer-events: none;
        `}

  // LARGE

  &.large {
    width: 205px;
    height: 30px;

    .MuiChip-label {
      font-size: 13px;
      font-weight: bolder;
    }
  }
`
