import React from 'react'
import PropTypes from 'prop-types'

import { downloadInvoicePdf, downloadInvoiceXml } from '..'

import { Container, Content, Row, Downloads } from './styles'
import formatter from 'services/formatter'

function InvoiceCard({ current, ...props }) {
  return (
    <Container variant="outlined" {...props}>
      <Content>
        <Row className="invoice-header">
          <span>{current.nf_numero}</span>
          <span>{formatter(current.nf_data_emissao).toSimpleDate()}</span>
        </Row>

        <small>Cliente</small>
        <Row>
          <span>{current.customer.emp_razao_social}</span>
          <span>
            {[current.customer.emp_cidade, current.customer.dsc_uf].join('/')}
          </span>
        </Row>

        <small>Transporte</small>
        <span>{current.shipping_order.ford_nome_motorista}</span>
        <span>{current.nf_placa_numero}</span>

        <small>Detalhes</small>
        <span>Quantidade: {formatter(current.total_quantity).toDecimal()}</span>
        <span>Peso: {formatter(current.nf_peso_bruto).toDecimal()}</span>
        <span>Representante: {current.representative}</span>

        <small>Downloads</small>
        <Downloads>
          <span onClick={() => downloadInvoiceXml(current._links.xml)}>
            XML
          </span>
          <span
            onClick={() => downloadInvoicePdf(current._links.pdf)}
            className="pdf"
          >
            PDF
          </span>
        </Downloads>
      </Content>
    </Container>
  )
}

InvoiceCard.propTypes = {
  current: PropTypes.object,
}

InvoiceCard.defaultProps = {
  current: {},
}

export default InvoiceCard
