import styled from 'styled-components'

export const StyledLink = styled.a`
  display: block;
  margin: 0 auto;
  min-height: 200px;
  max-width: 200px;
  transition: opacity 100ms ease;

  img {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
`
