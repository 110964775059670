import React from 'react'
import { equals } from 'ramda'
import { colors } from 'theme'

import formatter from 'services/formatter'

import {
  CancelOutlined as CancelCollect,
  MenuOpen as OpenCollect,
  Edit as EditCollect,
} from '@material-ui/icons'

import { TableRow, TableCell } from 'components/Table/styles'
import { Button } from 'components/index'
import {
  StyledChipStatus,
  StyledGroupContainer,
  StyledGroupHeader,
  StyledGroupBody,
  Actions,
  Divider,
} from '../styles'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const scheduletStatus = {
  canceled: 1,
  awaitValidation: 2,
  validated: 3,
  validatedBlocked: 4,
  loaded: 5,
  scheduled: 6,
}

const scheduleStatusColors = {
  [scheduletStatus.canceled]: colors.notification,
  [scheduletStatus.awaitValidation]: '#EEAD2D',
  [scheduletStatus.validated]: '#4caf50',
  [scheduletStatus.validatedBlocked]: '#FF7F00',
  [scheduletStatus.loaded]: '#3f51b5',
  [scheduletStatus.scheduled]: '#00bcd4',
}

const getColorById = (id = scheduletStatus.validated) =>
  scheduleStatusColors[id]

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ScheduleHeader({
  rowData: schedule,
  columns,
  onEdit,
  onReopen,
  onCancel,
}) {
  const status = schedule.status || {}
  const statusIs = equals(status.id)

  const enableStatus = [
    scheduletStatus.awaitValidation,
    scheduletStatus.validated,
  ]

  const editable = statusIs(scheduletStatus.scheduled)

  const reopenable = enableStatus.some(statusIs)

  const cancellable = enableStatus
    .concat(scheduletStatus.validatedBlocked)
    .some(statusIs)

  return (
    <TableRow className="groupRow groupRowBorderered">
      <TableCell colSpan={columns.length}>
        <StyledGroupContainer>
          <div>
            <StyledGroupHeader>
              <h3 className="group__title">{schedule.id_agendamento}</h3>
              <div>
                <span className="group__info">
                  <b>EMISSÃO:</b>
                  {formatter(schedule.vpa_data_emissao).toSimpleDate()}
                </span>
                <span className="group__info">
                  <b>AGENDAMENTO:</b>
                  {formatter(schedule.vpa_dagenda).toSimpleDate()}
                </span>
              </div>
            </StyledGroupHeader>
            <StyledGroupBody>
              <span>{schedule.vpa_motorista}</span>
              <span>{formatter(schedule.vpa_cod_motorista).toCPF()}</span>
              <span>{[schedule.vpa_placa].join(' - ')}</span>
              <Divider />
              <div>
                <Actions>
                  {editable ? (
                    <UserRight id={RIGHTS.alteraAgendamento.id}>
                      <Button
                        disabled={!editable}
                        label="Editar"
                        className="edit"
                        startIcon={<EditCollect />}
                        onClick={() => onEdit({ ...schedule })}
                      />
                    </UserRight>
                  ) : (
                    <UserRight id={RIGHTS.alteraAgendamento.id}>
                      <Button
                        disabled={!reopenable}
                        label="Reabrir"
                        startIcon={<OpenCollect />}
                        onClick={() => onReopen({ ...schedule })}
                      />
                    </UserRight>
                  )}
                  <Button
                    disabled={!cancellable}
                    label="Cancelar"
                    startIcon={<CancelCollect />}
                    className="cancel"
                    onClick={() => onCancel({ ...schedule })}
                  />
                </Actions>
                {status.id && (
                  <StyledChipStatus
                    label={status.descr}
                    background={getColorById(status.id)}
                  />
                )}
              </div>
            </StyledGroupBody>
          </div>
        </StyledGroupContainer>
      </TableCell>
    </TableRow>
  )
}

export default ScheduleHeader
