import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { merge } from 'ramda'
import formatter from 'services/formatter'

import SearchInput from '../../SearchInput'
import { FormCombobox } from '../../CoForm'
import {
  getCustomersSuccess,
  getCustomersRequest,
} from 'store/modules/comuns/customers/reducer'

export function ComboboxCustomers({ defaultValue, ...props }) {
  const dispatch = useDispatch()
  const { customers, loading } = useSelector((state) => state.comuns.customers)

  const handleSearch = useCallback(
    (searchTerm) => {
      if (searchTerm) dispatch(getCustomersRequest({ search: searchTerm }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (defaultValue) handleSearch(defaultValue)
    else dispatch(getCustomersSuccess([]))
  }, [handleSearch, dispatch, defaultValue])

  const options = customers.map((customer) =>
    merge(customer, {
      value: customer.id,
      label: `${customer.emp_razao_social} (${formatter(
        customer.id
      ).toCNPJorCPF()})`,
    })
  )

  return (
    <FormCombobox
      options={options}
      loading={loading}
      loadingText="Buscando clientes..."
      renderInput={(inputProps) => (
        <SearchInput
          {...inputProps}
          variant="standard"
          label="Escolha o cliente"
          onSearch={handleSearch}
          showSearchIcon={false}
        />
      )}
      {...props}
    />
  )
}

export default ComboboxCustomers
