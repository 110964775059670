import React from 'react'

import { useCurrentForm } from '..'

import TextField from 'components/TextField'
import ComboBoxBase from 'components/Combobox/Base'
import ImageInput from 'components/ImageInput'
import RadioGroup from 'components/RadioGroup'
import MultipleAutoComplete from 'components/MultipleAutoComplete'

import { FormCheckBox as CheckBox } from 'components/Checkbox'
import { FormDatePicker as DatePicker } from 'components/DatePicker'

const withCurrentForm = (Component) => {
  const WithCurrentForm = ({ name, separator, onChange, ...props }) => {
    const { errors, getFieldValue, handleChange, readOnly } = useCurrentForm()
    if (!name) return null

    const fieldValue = getFieldValue(name, separator) ?? ''
    const error = errors[name]

    const handleInputChange = (e) => {
      // 1. ChangeForm
      handleChange(separator)(e)

      // 2. ChangeInput
      if (onChange) {
        onChange(e)
      }
    }

    return (
      <Component
        name={name}
        value={fieldValue}
        onChange={handleInputChange}
        disabled={Boolean(readOnly || props.disabled)}
        error={Boolean(error)}
        helperText={error}
        {...props}
      />
    )
  }
  WithCurrentForm.WrappedComponent = Component

  return WithCurrentForm
}

export const FormTextField = withCurrentForm(TextField)
export const FormCombobox = withCurrentForm(ComboBoxBase)
export const FormCheckbox = withCurrentForm(CheckBox)
export const FormRadioGroup = withCurrentForm(RadioGroup)
export const FormDatePicker = withCurrentForm(DatePicker)
export const FormImageInput = withCurrentForm(ImageInput)
export const FormMultipleAutoComplete = withCurrentForm(MultipleAutoComplete)
