import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'

import { cnpj } from 'consts/regexp'
import { formatFilters } from 'services/utils'

import { Tooltip } from '@material-ui/core'
import { Chip, SideBar, SearchFilter } from 'components/index'
import { Trash } from 'components/Icons'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const removeElem = (elem, previousState) =>
  [...previousState].filter((e) => JSON.stringify(e) !== JSON.stringify(elem))

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

const StyledTrashIcon = styled(Trash)`
  margin-right: 20px;

  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function FilterCompany({
  data,
  onFiltering,
  loading,
  selectedFilters,
  ...props
}) {
  function handleDelete(filter) {
    onFiltering(removeElem(filter, [...selectedFilters]))
  }

  function handleSubmit(values) {
    onFiltering(
      formatFilters({
        ...values,
        cod_empresa: values.cod_empresa?.replace(/\D/g, ''),
      })
    )
  }

  return (
    <SideBar
      {...props}
      loading={loading}
      HeaderComponent={
        !isEmpty(selectedFilters) ? (
          <div className="ChipList ChipListSpacing">
            {selectedFilters.map((filter, index) => (
              <Chip
                onDelete={() => handleDelete(filter)}
                label={filter.value}
                key={index}
              />
            ))}
            <Tooltip title="Excluir filtros">
              <div>
                <StyledTrashIcon onClick={() => onFiltering([])} />
              </div>
            </Tooltip>
          </div>
        ) : null
      }
      ContentComponent={
        <SearchFilter
          onSubmit={handleSubmit}
          title="Pesquisar por"
          buttonLabel="Buscar"
          fields={[
            {
              name: 'company.emp_razao_social',
              label: 'Empresa',
            },
            {
              name: 'cod_empresa',
              label: 'CNPJ',
              textMaskProps: { mask: cnpj.array },
            },
            {
              name: 'wee_email',
              label: 'Email',
            },
          ]}
        />
      }
    />
  )
}
