import styled from 'styled-components'
import { colors } from 'theme'

import Paper from 'components/Paper'
import Dialog from 'components/Dialog'
import CheckBox from 'components/Checkbox'

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 750px;
  }

  .MuiDialogContent-root {
    /* padding: 8px; */
  }

  #draggable-dialog-title .MuiTypography-root {
    /* text-align: center; */
  }
`

export const Container = styled.div``

export const WeightList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 16px 0;

  .weights {
    display: flex;
    flex-direction: column;
    flex: 1;

    > div {
      color: ${colors.color700};
      display: flex;
      justify-content: space-between;
      text-align: right;

      span {
        display: block;
        text-align: left;

        font-size: 11px;
        font-weight: 600;
      }

      b {
        font-size: 15px;
        font-weight: bold;
      }

      &:nth-child(even) {
        color: ${colors.color500};
      }

      &:last-child {
        margin-top: 1px;
        border-top: 1px solid ${(props) => props.theme.palette.divider};
      }

      &.overweight {
        color: ${colors.error};
      }
    }
  }
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  .select-all {
    margin-left: 8px;
  }
`

export const StyledCheckBox = styled(CheckBox)`
  ~ span.MuiTypography-root {
    font-size: 12.5px;
    color: ${colors.color500};
  }
`

export const TableContainer = styled(Paper)`
  padding: 0;
`
