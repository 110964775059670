import { darken } from 'polished'
// import { company } from "consts/types";

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const themeKey = '@DeltaPortal:theme'

// --------------- 𝔻𝕖𝕧𝕖𝕝𝕠𝕡𝕖𝕣 ---------------

const developerPaletteMain = '#ECEFF1'

export const developerColors = {
  main: developerPaletteMain,
  get color100() {
    return darken(0.1, this.main)
  },
  get color200() {
    return darken(0.2, this.main)
  },
  get color300() {
    return darken(0.3, this.main)
  },
  get color400() {
    return darken(0.4, this.main)
  },
  get color500() {
    return darken(0.45, this.main)
  },
  get color600() {
    return darken(0.6, this.main)
  },
  get color700() {
    return darken(0.6, this.main)
  },
  get color800() {
    return darken(0.8, this.main)
  },
  get color900() {
    return darken(0.9, this.main)
  },
}

// --------------- 𝔸𝕕𝕞𝕚𝕟 ---------------

const adminPaletteMain = '#ECEFF1'

export const adminColors = {
  main: adminPaletteMain,
  get color100() {
    return darken(0.1, this.main)
  },
  get color200() {
    return darken(0.2, this.main)
  },
  get color300() {
    return darken(0.3, this.main)
  },
  get color400() {
    return darken(0.4, this.main)
  },
  get color500() {
    return darken(0.45, this.main)
  },
  get color600() {
    return darken(0.6, this.main)
  },
  get color700() {
    return darken(0.6, this.main)
  },
  get color800() {
    return darken(0.8, this.main)
  },
  get color900() {
    return darken(0.9, this.main)
  },
}

// --------------- ℂ𝕦𝕤𝕥𝕠𝕞𝕖𝕣𝕤 ---------------

const customerPaletteMain = '#e8ecf0'

export const customerColors = {
  main: customerPaletteMain,
  error: '#f7776e',
  success: '#4eb151',
  get color100() {
    return darken(0.1, this.main)
  },
  get color200() {
    return darken(0.2, this.main)
  },
  get color300() {
    return darken(0.3, this.main)
  },
  get color400() {
    return darken(0.4, this.main)
  },
  get color500() {
    return darken(0.45, this.main)
  },
  get color600() {
    return darken(0.6, this.main)
  },
  get color700() {
    return darken(0.6, this.main)
  },
  get color800() {
    return darken(0.8, this.main)
  },
  get color900() {
    return darken(0.9, this.main)
  },
}

// --------------- ℂ𝕒𝕣𝕣𝕚𝕖𝕣𝕤 ---------------

export const carrierColors = {
  main: '#ECEFF1',
  color100: '#CFD8DC',
  color200: '#B0BEC5',
  color300: '#90A4AE',
  color400: '#78909C',
  color500: '#607D8B',
  color600: '#546E7A',
  color700: '#455A64',
  color800: '#37474F',
  color900: '#263238',
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

export function saveProfileTheme(profileCompanyType) {
  localStorage.setItem(themeKey, profileCompanyType)
}

export function getProfileTheme() {
  // const profileTheme = localStorage.getItem(themeKey);
  return carrierColors

  // switch (profileTheme) {
  //   case company.CLIENTE:
  //     return customerColors;
  //   case company.ECOSIS:
  //     return developerColors;
  //   case company.ADMIN:
  //     return adminColors;
  //   case company.TRANSPORTADORA:
  //   default:
  //     return carrierColors;
  // }
}
