import styled from 'styled-components'
import { Form } from 'components/CoForm'
import { Title } from 'components/Ranking/styles'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  ${Title} {
    padding-top: 7px;
    padding-bottom: 7px;
  }
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const DateFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
`

export const ButtonsContainer = styled.div`
  margin-top: 0.5rem;
  gap: 0.5rem;
  display: flex;
  justify-content: flex-end;
`
