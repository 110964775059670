import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'ramda'
import {
  getOrderItemsRequest,
  removeOrderItemRequest,
  updateOrderItemRequest,
} from 'store/modules/users/orders/orders/reducer'
import formatter from 'services/formatter'
import { sumOf } from 'services/utils'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Checkbox from 'components/Checkbox'

import { Container, List, ListItem, StyledButton, SizeInfo } from './styles'

import OrderItemFormEdit from './FormEdit'
import OrderForm from './Form'
import OrderItem from './Item'

export function OrderPage() {
  const dispatch = useDispatch()
  const orderItems = useSelector(
    (state) => state.users.orders.orders.order.items
  )

  const [selectedItems, setSelectedItems] = useState([])
  const [currentItem, setCurrentItem] = useState(null)

  const total = useMemo(() => {
    const fractionalOrderItems = orderItems.filter(
      (i) => i.no_boxes % i.product.ctp_caixa_pallet > 0
    )

    return {
      pallets: sumOf(orderItems, 'no_pallets'),
      quantity: sumOf(orderItems, 'itped_qtd'),
      weight: sumOf(orderItems, 'peso_bru'),
      boxes: sumOf(fractionalOrderItems, 'no_boxes'),
    }
  }, [orderItems])

  useEffect(() => {
    dispatch(getOrderItemsRequest())
  }, [dispatch])

  const getCheckboxValue = (id) => {
    const isSeletedAll =
      orderItems.length !== 0 && selectedItems.length === orderItems.length
    const isSelected = selectedItems.indexOf(id) !== -1
    return isSelected || isSeletedAll
  }

  const handleChangeAll = (event) => {
    const checked = event.target.checked
    if (checked) setSelectedItems(orderItems.map((item) => String(item.id)))
    else setSelectedItems([])
  }

  const handleChangeGroup = (event, representativeId) => {
    const checked = event.target.checked

    const ids = orderItems
      .filter((item) => item.cod_representante === representativeId)
      .map((item) => String(item.id))

    if (checked) {
      setSelectedItems((previousItems) => previousItems.concat(ids))
    } else {
      setSelectedItems((previousItems) =>
        previousItems.filter((id) => !ids.includes(id))
      )
    }
  }

  const handleChange = (event, id) => {
    const checked = event.target.checked

    if (checked) setSelectedItems((previousItems) => previousItems.concat(id))
    else
      setSelectedItems((previousItems) => previousItems.filter((i) => i !== id))
  }

  const handleEditItem = (orderItem) => {
    setCurrentItem({ ...orderItem })
  }

  const handleClose = () => {
    setCurrentItem(null)
  }

  const handleUpdateItem = (orderItem) => {
    dispatch(updateOrderItemRequest({ ...orderItem }))
    handleClose()
  }

  const handleRemoveSelectedItems = () => {
    dispatch(removeOrderItemRequest(selectedItems))
    setSelectedItems([])
  }

  const renderOrderItems = () => {
    let representativeId = null

    return orderItems.map((orderItem, index) => {
      const id = String(orderItem.id)
      const checked = getCheckboxValue(id)

      const representativeIsChanged =
        representativeId !== orderItem.cod_representante

      // Update representative
      representativeId = orderItem.cod_representante

      return (
        <OrderItem
          key={index}
          orderItem={orderItem}
          showRepresentative={representativeIsChanged}
          checked={checked}
          onChangeGroup={handleChangeGroup}
          onChangeItem={handleChange}
          onEditItem={handleEditItem}
        />
      )
    })
  }

  if (isEmpty(orderItems))
    return (
      <Container>
        <span className="empty-text">O Orçamento não possui nenhum item.</span>
      </Container>
    )

  return (
    <Container>
      <Box flex={2}>
        <List>
          <ListItem>
            <Box flex={1} display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={getCheckboxValue()}
                  value={getCheckboxValue()}
                  onChange={handleChangeAll}
                />
                <Typography color="primary" variant="body2">
                  Selecionar Todos
                </Typography>
              </Box>
              <StyledButton
                onClick={handleRemoveSelectedItems}
                className="error light"
                label="Excluir Selecionados"
              />
            </Box>
          </ListItem>
          {renderOrderItems()}

          {/* ==== TOTAL ==== */}
          <ListItem selected className="Total">
            <Box
              flex={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>Total</Box>
              <SizeInfo className="bordered">
                <Typography variant="body2">{total.pallets}</Typography>
                <Typography variant="body2">{total.boxes}</Typography>
                <Typography variant="body2">
                  {formatter(total.quantity).toDecimal()}
                </Typography>
                <Typography variant="body2">
                  {formatter(total.weight).toDecimal()}
                </Typography>
              </SizeInfo>
            </Box>
          </ListItem>
        </List>
      </Box>

      <Box flex={1}>
        <OrderForm />
      </Box>

      <OrderItemFormEdit
        open={!!currentItem}
        current={currentItem}
        onClose={handleClose}
        onSave={handleUpdateItem}
      />
    </Container>
  )
}

export default OrderPage
