import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatISO, startOfMonth } from 'date-fns'
import { any, equals } from 'ramda'

import theme from 'theme'
import * as medias from 'consts/medias'
import {
  createQueryParameters,
  downloadFileByUrl,
  formatDateFields,
} from 'services/utils'

import { usePagination, useSortData } from 'hooks'
import { invoicesRequest } from 'store/modules/users/queries/invoices/reducer'

import { useMediaQuery } from '@material-ui/core'
import { PictureAsPdf } from 'components/Icons'
import { Content, ChipStatus, Table, TablePagination, Paper } from 'components'

import { StyledChip, Links } from './styles'
import Filter from './Filter'
import InvoiceCard from './Card'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const DATE_FIELDS = ['nf_data_emissao.initial', 'nf_data_emissao.final']
const DEFAULT_SORT = 'nf_data_emissao'

const INVOICE_STATUS = {
  denied: 'denied',
  canceled: 'canceled',
}

const INVOICE_STATUS_LABEL = {
  denied: 'Denegada',
  canceled: 'Cancelada',
}

const initialDate = formatISO(startOfMonth(new Date()))
const finalDate = formatISO(new Date())

const initialFilterValues = {
  shipment_unity: 0,
  nf_numero: '',
  nf_placa_numero: '',
  shipping_order: { ford_nome_motorista: '' },
  nf_data_emissao: {
    initial: initialDate,
    final: finalDate,
  },
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const isBlockedForDownload = (status) =>
  any(equals(status), [INVOICE_STATUS.denied, INVOICE_STATUS.canceled])

export const downloadInvoiceXml = async (downloadUrl) => {
  await downloadFileByUrl(downloadUrl, 'xml', 'nfe_xml_b64', 'nf_numero')
}

export const downloadInvoicePdf = async (downloadUrl) => {
  await downloadFileByUrl(downloadUrl, 'pdf', 'nfe_pdf_b64', 'nf_numero')
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function DownloadLinks({ rowData: invoice }) {
  const status = invoice.eletronic_invoice?.nfe_status
  const links = invoice._links

  if (isBlockedForDownload(status)) {
    return (
      <ChipStatus
        label={INVOICE_STATUS_LABEL[status]}
        background={theme.palette.error.main}
      />
    )
  }

  return (
    <Links>
      <StyledChip
        label="XML"
        clickable
        onClick={() => downloadInvoiceXml(links.xml)}
      />
      <PictureAsPdf onClick={() => downloadInvoicePdf(links.pdf)} />
    </Links>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function InvoicesPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const count = useSelector((state) => state.users.queries.invoices.count)
  const loading = useSelector((state) => state.users.queries.invoices.loading)
  const invoices = useSelector((state) => state.users.queries.invoices.invoices)
  const [selectedFilters, setSelectedFilters] = useState(initialFilterValues)

  const { currentSort, onSortChange } = useSortData({
    initialField: DEFAULT_SORT,
  })

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, reset } =
    usePagination()

  useEffect(() => {
    const filters = { ...selectedFilters }
    const shipmentUnity = filters.shipment_unity

    delete filters.shipment_unity

    // Effect responsible for controlling data rendering
    const queryParameters = createQueryParameters({
      currentSort,
      page,
      rowsPerPage: sm ? 999 : rowsPerPage,
      filters: formatDateFields(filters, DATE_FIELDS),
      shipment_unity: shipmentUnity,
    })
    dispatch(invoicesRequest(queryParameters))
  }, [dispatch, currentSort, page, rowsPerPage, selectedFilters, sm])

  useEffect(() => {
    // Effect responsible for reset paging state
    reset()
  }, [reset, currentSort, selectedFilters])

  return (
    <Content
      loading={loading}
      title="Consultar Notas Fiscais emitidas"
      showReturn
      SideComponent={
        <Filter
          initialValues={initialFilterValues}
          onFilter={setSelectedFilters}
        />
      }
    >
      {sm ? (
        <div>
          {invoices.map((invoice, index) => (
            <InvoiceCard current={invoice} key={index} />
          ))}
        </div>
      ) : (
        <Paper className="border-none">
          <Table
            size="medium"
            data={invoices}
            columns={[
              {
                title: 'UNID',
                field: 'id_cia',
                renderCell: ({ rowData }) =>
                  rowData.billing_company?.cia_descricao_carreg,
              },
              { title: 'NOTA FISCAL', field: 'nf_numero' },
              {
                title: 'EMISSÃO',
                field: 'nf_data_emissao',
                cellFormat: 'date',
              },
              {
                title: 'CLIENTE',
                field: 'customer.emp_razao_social',
                sortable: false,
              },
              {
                title: 'CIDADE',
                field: 'customer.emp_cidade',
                sortable: false,
              },
              {
                title: 'UF',
                field: 'customer.dsc_uf',
                sortable: false,
              },
              {
                title: 'M2',
                field: 'total_quantity',
                sortable: false,
                cellFormat: 'decimal',
                align: 'right',
              },
              {
                title: 'PESO',
                field: 'nf_peso_bruto',
                cellFormat: 'decimal',
                align: 'right',
              },
              { title: 'PLACA', field: 'nf_placa_numero' },
              {
                title: 'MOTORISTA',
                field: 'shipping_order.ford_nome_motorista',
                sortable: false,
              },
              {
                title: 'REPRES',
                field: 'representative',
                sortable: false,
              },
              {
                role: 'button',
                renderCell: DownloadLinks,
              },
            ]}
            groupHeader={[{ start: 12, expand: 1, label: 'DOWNLOADS' }]}
            currentSort={currentSort}
            onSortChange={onSortChange}
          />
          <TablePagination
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Paper>
      )}
    </Content>
  )
}
