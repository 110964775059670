import React from 'react'
import { Box, Typography } from '@material-ui/core'

import Paper from 'components/Paper'

const LEGEND_CONFIG = [
  { id: 'A', descr: 'Crédito' },
  { id: 'B', descr: 'Cadastro' },
  { id: 'C', descr: 'Cobrança' },
  { id: 'E', descr: 'Comercial' },
  { id: 'F', descr: 'Financeiro' },
  { id: 'G', descr: 'Faturamento' },
  { id: 'M', descr: 'Marketing' },
  { id: 'R', descr: 'Rejeitado' },
  { id: 'T', descr: 'Bloqueado' },
  { id: 'V', descr: 'Vendas' },

  { id: '', descr: 'Data emissão + 45 dias', color: 'red' },
  { id: '', descr: 'Pedidos Agendados', color: 'green' },
  { id: '', descr: 'Aguardar Liberação', color: 'blue' },
]

export default function Legend() {
  return (
    <Paper
      className="border-none"
      style={{
        maxWidth: '200px',
      }}
    >
      <Typography
        gutterBottom
        variant="subtitle2"
        color="primary"
        style={{
          fontWeight: 'bold',
        }}
      >
        LEGENDA
      </Typography>

      <Box>
        {LEGEND_CONFIG.map((legend, index) => (
          <Box key={index} display="flex" justifyContent="space-between">
            <Typography variant="caption">{legend.id}</Typography>
            <Typography
              variant="caption"
              style={legend.color ? { color: legend.color } : {}}
            >
              {legend.descr}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  )
}
