import { useLocation } from 'react-router-dom'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function useQuery() {
  const { search } = useLocation()
  return new URLSearchParams(search)
}

export default useQuery
