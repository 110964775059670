import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  order: {
    items: [],
    params: {},
  },
  count: 0,
  issuedOrders: [],
  loading: false,
  filters: null,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    getOrdersRequest(state) {
      state.loading = true
    },
    getOrdersSuccess(state, { payload }) {
      state.issuedOrders = payload.orders
      state.count = payload.count
      state.loading = false
    },
    getOrdersFailure(state) {
      state.loading = false
    },
    createOrderRequest(state) {
      state.loading = true
    },
    createOrderSuccess(state) {
      state.loading = false
      state.order.items = []
    },
    createOrderFailure(state) {
      state.loading = false
    },
    initializeOrder(state, { payload }) {
      state.order.params = payload
    },
    setFilters(state, { payload }) {
      state.filters = payload
    },
    // 𝕆𝕣𝕕𝕖𝕣 𝕚𝕥𝕖𝕞

    // Get order item
    getOrderItemsRequest(state) {
      state.loading = true
    },
    getOrderItemsSuccess(state, { payload }) {
      state.loading = false
      state.order.items = payload
    },
    getOrderItemsFailure(state) {
      state.loading = false
    },

    // Create order item
    createOrderItemRequest(state) {
      state.loading = true
    },
    createOrderItemSuccess(state) {
      state.loading = false
    },
    createOrderItemFailure(state) {
      state.loading = false
    },

    // Update order item
    updateOrderItemRequest(state) {
      state.loading = true
    },
    updateOrderItemSuccess(state, { payload: updatedOrderItem }) {
      state.order.items = state.order.items.map((orderItem) =>
        String(orderItem.id) === String(updatedOrderItem.id)
          ? { ...updatedOrderItem }
          : orderItem
      )
      state.loading = false
    },
    updateOrderItemFailure(state) {
      state.loading = false
    },

    // Remove order item
    removeOrderItemRequest(state) {
      state.loading = true
    },
    removeOrderItemSuccess(state) {
      state.loading = false
    },
    removeOrderItemFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  getOrderItemsFailure,
  getOrderItemsRequest,
  getOrderItemsSuccess,
  createOrderItemRequest,
  createOrderItemSuccess,
  createOrderItemFailure,
  removeOrderItemRequest,
  removeOrderItemFailure,
  removeOrderItemSuccess,
  updateOrderItemFailure,
  updateOrderItemRequest,
  updateOrderItemSuccess,
  initializeOrder,
  setFilters,
} = ordersSlice.actions

export default ordersSlice.reducer
