import React, { useCallback } from 'react'
import clsx from 'clsx'
import { sum } from 'ramda'

import formatter from 'services/formatter'

import { Table, TableCell, DescriptionText } from 'components/index'

import * as SC from '../../styles'

export function IssuedOrdersTable({ data }) {
  // ╦ ╦╔╦╗╦╦  ╔═╗
  // ║ ║ ║ ║║  ╚═╗
  // ╚═╝ ╩ ╩╩═╝╚═╝
  const getDetailPanel = ({ row: order, show }) => {
    if (!show) return null

    return (
      <>
        <SC.TableRow>
          <TableCell colSpan={2} />
          <TableCell colSpan={4}>
            <DescriptionText
              label="TRANSPORTADORA:"
              value={order.transportadora}
            />
          </TableCell>
          <TableCell colSpan={2} />
          <TableCell colSpan={4}>
            <DescriptionText label="FATURAMENTO:" value={order.dsc_tipo} />
          </TableCell>
          <TableCell colSpan={3}>
            <DescriptionText
              label="COND. PGTO.:"
              value={order.desc_condpagto}
            />
          </TableCell>
        </SC.TableRow>
      </>
    )
  }

  const displayTotal = useCallback(({ data, reportTitle }) => {
    const sumOf = (field) =>
      String(sum(data.map((order) => (order[field] ? order[field] : 0))))

    return (
      <SC.TableRow className="highlightText">
        <TableCell role="button" />
        <TableCell colSpan={5} />
        <TableCell colSpan={2} align="right">
          {reportTitle}
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {formatter(sumOf('qtdade')).toDecimal(2)} m²
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {formatter(sumOf('prd_peso_bruto')).toDecimal(2)} kg
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {formatter(sumOf('itped_qtd_reservada')).toDecimal(2)} m²
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          {formatter(sumOf('peso_bru_reserva')).toDecimal(2)} kg
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          R$ {formatter(sumOf('itped_prunit')).toDecimal(2)}
        </TableCell>
        <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
          R$ {formatter(sumOf('valor')).toDecimal(2)}
        </TableCell>
        <TableCell />
      </SC.TableRow>
    )
  }, [])

  const renderProductDescription = ({ rowData: order }) => {
    return <span style={{ whiteSpace: 'nowrap' }}>{order.dsc_abreviado}</span>
  }

  // ╔╦╗╔═╗╦╔╗╔
  // ║║║╠═╣║║║║
  // ╩ ╩╩ ╩╩╝╚╝
  return (
    <Table
      groupBy="cod_pedido"
      size="medium"
      data={data}
      components={{
        Footer: displayTotal,
        GroupFooter: displayTotal,
      }}
      columns={[
        { title: 'PEDIDO', field: 'cod_pedido' },
        { title: 'EMISSÃO', field: 'emissao', align: 'center' },
        { title: 'REP.', field: 'dsc_empresa', align: 'center' },
        { title: 'PRODUTO', field: 'cod_produto' },
        {
          title: '',
          field: 'dsc_abreviado',
          renderCell: renderProductDescription,
        },
        { title: 'REF', field: 'prd_referencia', align: 'center' },
        { title: 'UN', field: 'dsc_un_medidas', align: 'center' },
        {
          title: 'QTDE',
          field: 'qtdade',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'P. BRUTO',
          field: 'prd_peso_bruto',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'QTDE.RES.',
          field: 'itped_qtd_reservada',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'P. BRUTO RES.',
          field: 'peso_bru_reserva',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'VL. UN.',
          field: 'itped_prunit',
          align: 'center',
          cellFormat: 'decimal',
        },
        {
          title: 'VALOR',
          field: 'valor',
          align: 'right',
          cellFormat: 'decimal',
        },
        { title: 'SEU PEDIDO', field: 'ped_seupedido', align: 'right' },
      ]}
      detailPanel={getDetailPanel}
      detailPanelIcon={({ open, ...props }) => (
        <SC.DetailIcon className={clsx({ open })} {...props} />
      )}
    />
  )
}

export default React.memo(IssuedOrdersTable)
