import React, { useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { number } from 'services/utils'
import { useLocation, NavLink as Link } from 'react-router-dom'
import { StyledMenuItem, StyledBadge } from './styles'

import { DrawerContext } from '.'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const ANIMATION_NAME = 'MuiBadge-pulse'
export const ANIMATION_DURATION = 2000

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function MenuItem({
  label,
  Icon,
  path,
  badgeContent,
  badgePosition,
  ...props
}) {
  const { pathname } = useLocation()
  const drawer = useContext(DrawerContext)

  const active = !!pathname.match(path)
  const id = useMemo(() => `badge-notification-${number(1000)}`, [])

  useEffect(() => {
    if (!badgeContent) return

    const badge = document.querySelector(`#${id}`).children[1]
    badge.classList.add(ANIMATION_NAME)

    setTimeout(() => {
      badge.classList.remove(ANIMATION_NAME)
    }, ANIMATION_DURATION)

    return () => {
      clearTimeout()
    }
  }, [id, badgeContent])

  return (
    <StyledMenuItem {...props} className={clsx('AppBar-MenuItem', { active })}>
      <Link
        to={path}
        activeStyle={{ pointerEvents: 'none' }}
        onClick={() => (drawer ? drawer.close() : undefined)}
      >
        <span className="MenuItem-label">{label}</span>
        {badgeContent ? (
          <StyledBadge
            id={id}
            badgeContent={badgeContent}
            overlap="rectangular"
            className={clsx({ active })}
            color="primary"
            {...badgePosition}
          >
            <Icon />
          </StyledBadge>
        ) : (
          <Icon />
        )}
      </Link>
    </StyledMenuItem>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

MenuItem.propTypes = {
  label: TranslationStringType,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  path: PropTypes.string.isRequired,

  badgeContent: PropTypes.number,
  badgePosition: PropTypes.exact({
    top: PropTypes.number,
    right: PropTypes.number,
    topactive: PropTypes.number,
    rightactive: PropTypes.number,
  }),
}

MenuItem.defaultProps = {
  badgePosition: {
    top: 3,
    right: 7,
    topactive: 5,
    rightactive: 8,
  },
}

export default MenuItem
