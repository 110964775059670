// --> LIBRARIES
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
// --> SERVICES
import { changeLocale } from '../../store/modules/user/reducer'
import { FLAGS } from '../../_i18n'
import { useLocale } from '../../hooks'
// --> COMPONENTS
import {
  Container,
  LanguageIcon,
  ActiveLanguage,
  Menu,
  MenuItem,
} from './styles'

function ChangeLanguage() {
  const { id } = useLocale()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  function handleClose() {
    setAnchorEl(null)
  }

  function handleOpen({ currentTarget }) {
    setAnchorEl(currentTarget)
  }

  function changeLanguage(newLocale) {
    return () => {
      handleClose()
      dispatch(changeLocale(newLocale))
    }
  }

  return (
    <Container>
      <LanguageIcon onClick={handleOpen} />
      <ActiveLanguage>
        <span>{FLAGS[id]}</span>
      </ActiveLanguage>
      <Menu
        id="change-language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {Object.entries(FLAGS).map(([flagId, label]) => (
          <MenuItem
            selected={id === flagId}
            key={flagId}
            onClick={changeLanguage(flagId)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  )
}

ChangeLanguage.propTypes = {}

export { ChangeLanguage }
export default ChangeLanguage
