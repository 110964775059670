import React, { useMemo, useState, useEffect } from 'react'
import BarCode from 'react-barcode'
import { sum } from 'ramda'

import logoColorido from 'assets/images/logo-colorido.png'
import { colors } from 'theme'
import * as medias from 'consts/medias'
import { useProfile } from 'hooks'
import { groupWithCompany, padZero } from 'services/utils'
import { unmask } from 'services/masks'
import formatter from 'services/formatter'
import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import handleErrors from 'services/handleErrors'

import { Box, Typography, useMediaQuery } from '@material-ui/core'
import {
  Print,
  LocalShipping,
  AccountCircle,
  Description,
} from '@material-ui/icons'

import { Dialog, Table } from 'components/index'
import { TableRow, TableCell } from 'components/Table/styles'
import RenderTableRow from 'components/Table/TableRow'
import {
  Container,
  Header,
  Cards,
  GroupContainer,
  StyledButton,
  StyledCard,
  StyledCardIcon,
  StyleCardBody,
  TableSContainer,
  TableContainer,
  SummaryContainer,
  MultipleUnityDisclaimer,
} from './styles'

import { CollectSchedules } from './Schedules'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Card({ Icon, title, content, ...props }) {
  return (
    <StyledCard {...props}>
      <StyledCardIcon>
        <Icon fontSize="large" />
      </StyledCardIcon>
      <StyleCardBody>
        <Typography component="div" variant="body1">
          {title}
        </Typography>
        <Typography component="div" variant="body2">
          {content}
        </Typography>
      </StyleCardBody>
    </StyledCard>
  )
}

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const collectStatus = {
  canceled: 1,
  awaitValidation: 2,
  validated: 3,
  validatedBlocked: 4,
  loaded: 5,
  scheduled: 6,
  blockedByLinkedCollect: 7,
}

export const collectStatusColors = {
  canceled: colors.notification,
  validated: '#4caf50',
  awaitValidation: '#EEAD2D',
  validatedBlocked: '#FF7F00',
  loaded: '#3f51b5',
  scheduled: '#00bcd4',
  blockedByLinkedCollect: '#FF7F00',
}

const columns = [
  { title: 'PEDIDO', field: 'cod_pedido' },
  { title: 'SEU PEDIDO', field: 'ped_seupedido' },
  { title: 'PRODUTO', field: 'dsc_produto' },
  {
    title: 'ENTREGA',
    field: 'vpt_data_prev_entrega',
    cellFormat: 'date',
  },
  { title: 'FRETE', field: 'ped_tipo_frete' },
  { title: 'REPRES', field: 'ped_representante' },
  {
    title: 'QUANTIDADE',
    field: 'vpt_qtd',
    cellFormat: 'decimal',
    align: 'right',
  },
  {
    title: 'PESO',
    field: 'vpt_peso_bruto',
    cellFormat: 'decimal',
    align: 'right',
  },
]

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

export const getColorById = (id) => {
  const color = Object.entries(collectStatus).find(
    ([_, statusId]) => statusId === id
  )
  return color ? collectStatusColors[color[0]] : collectStatusColors.validated
}

export function CollectPrint({ collect, ...props }) {
  const [collectSchedules, setCollectSchedules] = useState([])

  const sm = useMediaQuery(medias.sm)
  const carrier = useProfile().company
  const encryptedId = padZero(10, collect.id)

  const summary = useMemo(() => {
    const result = collect.items.reduce(
      (acc, item) => {
        acc.weight += Number(item.vpt_peso_bruto)
        acc.amount += Number(item.vpt_qtd)

        return acc
      },
      {
        weight: 0,
        amount: 0,
      }
    )

    // INCLUIR PESO DOS AGENDAMENTOS
    if (collectSchedules.length > 0) {
      const weights = collectSchedules.map((schedule) => schedule.peso)
      collect.status.descr === 'Carregada'
        ? (result.weight = sum(weights))
        : (result.weight += sum(weights)) 
    }

    return result
  }, [collect, collectSchedules])

  useEffect(() => {
    async function fetchCollectSchedules() {
      try {
        const url = apiEndPoints.user.orderCollects.root
          .concat('/')
          .concat(encodeURIComponent(collect.id))
          .concat('/schedules')

        const response = await api.get(url)

        const schedules = response.data
        setCollectSchedules(schedules)
      } catch (error) {
        handleErrors(error, 'Não foi possível buscar os agendamentos da Coleta')
      }
    }

    fetchCollectSchedules()
  }, [collect])

  function handlePrint() {
    window.print()
  }

  const cellStyle = ({ columnName }) =>
    columnName === 'dsc_produto' ? { whiteSpace: 'nowrap' } : {}

  const companies = groupWithCompany(collect.items)

  return (
    <Dialog
      {...props}
      fullWidth
      fullScreen={sm}
      maxWidth="md"
      title={`Impressão Coleta ${collect.id}`}
      className="printable"
      actions={
        <>
          <StyledButton
            startIcon={<Print />}
            className="info light"
            label="Imprimir"
            variant="contained"
            onClick={handlePrint}
          />
          <StyledButton
            className="error light"
            label="Fechar"
            variant="contained"
            onClick={props.onClose}
          />
        </>
      }
    >
      <Container>
        {/* === BARCODE HEADER ===  */}
        <Header>
          <div className="Header-barcode">
            <BarCode
              height={sm ? 30 : 100}
              value={encryptedId}
              displayValue={false}
            />
          </div>
          <div className="Header-content">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              DELTA - Pedido de Coleta
            </Typography>
            <Typography gutterBottom component="div" variant="body1">
              {collect.status.descr}
            </Typography>
            <Typography component="div" variant="body1">
              <b>Transportadora:</b>
              <span>{carrier.emp_razao_social}</span>
            </Typography>
            <Typography gutterBottom component="div" variant="body1">
              <b>Nº Coleta:</b>
              <span>{collect.id}</span>
            </Typography>
            <Box display="flex">
              <Typography component="div" variant="body1">
                <b>Emissão:</b>
                <span>{collect.date}</span>
              </Typography>
              {collect.lastUpdateDate && (
                <Typography
                  component="div"
                  variant="body1"
                  style={{ marginLeft: '12px' }}
                >
                  <b>Ultima Alteração:</b>
                  <span>{collect.lastUpdateDate}</span>
                </Typography>
              )}
            </Box>
            <Typography component="div" variant="body1">
              <b>Impressão:</b>
              <span>{collect.printDate}</span>
            </Typography>
          </div>
          <div className="Header-logo">
            <img src={logoColorido} alt="logo" />
          </div>
        </Header>

        {/* === CARDS ===  */}
        <Cards>
          <Card
            Icon={AccountCircle}
            title={collect.driver.name}
            content={
              <p>{formatter(unmask(collect.driver.id)).toCNPJorCPF()}</p>
            }
          />
          <Card
            Icon={LocalShipping}
            title={collect.truck.plate}
            content={<p>{collect.truck.dsc_caminhao}</p>}
          />
          <Card
            Icon={Description}
            title={`Data da Coleta: ${collect.collectDate}`}
            content={<p>{collect.obs}</p>}
          />
        </Cards>

        {/* === TABLES ===  */}
        <TableSContainer>
          {companies.map((collectItems) => {
            return (
              <TableContainer
                key={`collect-print-table-${collectItems[0].id_cia}`}
              >
                <Typography component="span" className="unity-name">
                  CARREGAMENTO NA UNIDADE:{' '}
                  {collectItems[0].cia_descricao_carreg}
                </Typography>

                <Table
                  groupBy="cod_cliente"
                  size="medium"
                  data={collectItems}
                  columns={columns}
                  stripped={false}
                  cellStyle={cellStyle}
                  components={{
                    Group: ({ rowData }) => {
                      const customer = rowData.customer
                      const customerStr = `${
                        customer.emp_razao_social
                      } (${formatter(rowData.cod_cliente).toCNPJorCPF()})`
                      return (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            style={{
                              borderBottom: `1px solid ${colors.color300}`,
                            }}
                          >
                            <GroupContainer>
                              <b>{customerStr}</b>
                              <span>{customer.emp_endereco}</span>
                            </GroupContainer>
                          </TableCell>
                        </TableRow>
                      )
                    },
                    Footer: (props) => {
                      const totalQuantity = sum(
                        collectItems.map((ci) => ci.vpt_qtd)
                      )

                      const totalWeight = sum(
                        collectItems.map((ci) => ci.vpt_peso_bruto)
                      )

                      return (
                        <RenderTableRow
                          {...props}
                          rowData={{
                            cod_pedido: 'TOTAL COLETA',
                            vpt_qtd: totalQuantity,
                            vpt_peso_bruto: totalWeight,
                          }}
                        />
                      )
                    },
                  }}
                />
              </TableContainer>
            )
          })}

          {/* === SCHEDULES === */}
          <CollectSchedules data={collectSchedules} />

          <SummaryContainer>
            <div>
              <Typography variant="body2">Quantidade</Typography>
              <Typography variant="h6">
                {formatter(summary.amount).toDecimal()}
              </Typography>
            </div>

            <div>
              <Typography variant="body2">Peso</Typography>
              <Typography variant="h6">
                {formatter(summary.weight).toDecimal()}
              </Typography>
            </div>
          </SummaryContainer>
        </TableSContainer>

        {collect.isGrouped && (
          <MultipleUnityDisclaimer>
            ORDEM COM CARREGAMENTO NAS DUAS UNIDADES: HORÁRIO LIMITE PARA
            CHEGADA DO VEICULO NA PRIMEIRA UNIDADE DE SEGUNDA ÁS SEXTAS-FEIRAS
            ATE AS 14:00 HORAS E AOS SÁBADOS ATÉ AS 08:00 HORAS. CASO EXCEDA O
            HORÁRIO DE CHEGADA, SERÁ CARREGADO SOMENTE EM UMA UNIDADE
          </MultipleUnityDisclaimer>
        )}
      </Container>
    </Dialog>
  )
}

export default CollectPrint
