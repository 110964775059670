import React from 'react'
import { equals } from 'ramda'
import StyledKeyboardDatePicker from './styles'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const variantProps = (variant) => {
  const variantIs = equals(variant)
  if (variantIs('dialog'))
    return {
      showTodayButton: true,
      todayLabel: 'Hoje',
      cancelLabel: 'Cancelar',
    }
  else return {}
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function DatePickerBase(props) {
  return (
    <StyledKeyboardDatePicker
      autoOk
      format="dd/MM/yyyy"
      margin="none"
      invalidDateMessage="Data inválida"
      minDateMessage="A data não poder ser anterior à data mínima"
      maxDateMessage="A data não poder ser posterior à data máxima"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...variantProps(props.variant)}
      {...props}
    />
  )
}

DatePickerBase.defaultProps = {
  variant: 'dialog',
}

export { DatePickerBase }
export default DatePickerBase
