import styled from 'styled-components'
import { Chip } from '../../../../components'
import { colors } from '../../../../theme'

export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div,
  > svg {
    margin: 0 10px;
  }
`

export const StyledChip = styled(Chip)`
  height: 18px;

  background-color: ${colors.color500};
  border-radius: 2px;

  > span.MuiChip-label {
    font-size: 9px;

    padding: 0 3px;
    color: ${colors.white};
  }
`
