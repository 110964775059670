import { createSlice } from '@reduxjs/toolkit'
import { inviteStatus } from 'consts/types'

const initialState = {
  webCompanies: [],
  loading: false,
  formEditShow: false,
}

const updateInviteStatus = (webCompany) => ({
  ...webCompany,
  admin_web_user: {
    ...webCompany.admin_web_user,
    invite_status: inviteStatus.PENDING,
  },
})

const companies = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    getCompaniesRequest(state) {
      state.loading = true
    },
    getCompaniesSuccess(state, { payload }) {
      state.webCompanies = payload
      state.loading = false
    },
    getCompaniesFailure(state) {
      state.loading = false
    },

    handleSaveCompany(state) {
      state.loading = true
    },
    errorOnSave(state) {
      state.loading = false
    },

    showFormEdit(state) {
      state.formEditShow = true
    },
    closeFormEdit(state) {
      state.formEditShow = false
    },

    // create
    createCompanyRequest() {},

    // update
    updateCompanyRequest() {},

    // delete
    removeCompanyRequest(state) {
      state.loading = true
    },
    removeCompanySuccess(state, { payload }) {
      state.loading = false
      state.webCompanies = state.webCompanies.filter(
        (webCompany) => webCompany.id !== payload.id
      )
    },
    removeCompanyCancel(state) {
      state.loading = false
    },

    // send invite
    sendInviteRequest(state) {
      state.loading = true
    },
    sendInviteSuccess(state, { payload: recipient }) {
      state.loading = false
      state.webCompanies = state.webCompanies.map((webCompany) =>
        webCompany.admin_web_user?.id === recipient
          ? updateInviteStatus(webCompany)
          : webCompany
      )
    },
    sendInviteCancel(state) {
      state.loading = false
    },

    logIsAsCompanyRequest() {},
    logIsAsCompanyFailure() {},
  },
})

export const {
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesFailure,
  handleSaveCompany,
  errorOnSave,
  showFormEdit,
  closeFormEdit,
  createCompanyRequest,
  updateCompanyRequest,
  removeCompanyRequest,
  removeCompanySuccess,
  sendInviteRequest,
  sendInviteSuccess,
  sendInviteCancel,
  logIsAsCompanyRequest,
  logIsAsCompanyFailure,
} = companies.actions
export default companies.reducer
