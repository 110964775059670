import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  token: null,
  signed: false,
  loading: false,
  rights: [],
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInRequest(state) {
      state.loading = true
    },
    signInSuccess(state, { payload }) {
      state.token = payload.token
      state.rights = payload.rights
      state.signed = true
      state.loading = false
    },
    signOutRequest(state) {
      state.token = null
      state.signed = false
      state.loading = false
    },

    // recovery password
    recoveryRequest(state) {
      state.loading = true
    },
    recoverySuccess(state) {
      state.loading = false
    },
    recoveryFailure(state) {
      state.loading = false
    },
  },
})

export const {
  signInRequest,
  signInSuccess,
  signOutRequest,
  recoveryRequest,
  recoverySuccess,
  recoveryFailure,
} = authSlice.actions

export const authSelector = (state) => state.auth

export default authSlice.reducer
