import { createTheme } from '@material-ui/core/styles'
import { getProfileTheme } from 'services/colors'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const colors = {
  success: '#5ace81',
  error: '#D18989',
  notification: '#FF6767',
  background: '#F7F7F7',
  backgroundTable: '#F6F6F6',
  white: '#FFF',
  ...getProfileTheme(),
}

export const THEME_DEFAULTS = {
  palette: {
    primary: {
      main: colors.color500,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.main,
    },
    error: {
      main: colors.error,
      contrastText: colors.white,
    },
    success: {
      main: colors.success,
    },
  },
  typography: {
    fontFamily: [
      'Roboto Slab',
      'Roboto',
      'Arial',
      'Helvetica',
      'sans-serif',
    ].join(','),
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    button: {
      textTransform: 'unset',
    },
  },
}

export const theme = createTheme({ ...THEME_DEFAULTS })

export const themeForReport = createTheme({
  ...THEME_DEFAULTS,
  typography: {
    ...THEME_DEFAULTS.typography,
    fontFamily: ['Calibri', 'Arial', 'Helvetica', 'sans-serif'].join(','),
  },
})

export default theme
