import { combineReducers } from 'redux'

import shipments from './shipments/reducer'
import invoices from './invoices/reducer'
import tradeReceivables from './tradeReceivables/reducer'

export default combineReducers({
  shipments,
  invoices,
  tradeReceivables,
})
