import React from 'react'
import PropTypes from 'prop-types'
import ChipStatus from 'components/ChipStatus'
import { TableRow, TableCell } from 'components/Table/styles'
import { Container, Header, Body, Actions } from './styles'

export default function GroupHeader(props) {
  return (
    <TableRow className="groupRow groupRowBorderered">
      <TableCell colSpan={props.size}>
        <Container>
          <Header>
            <h3 className="group__title">{props.title}</h3>
            <div>
              <span className="group__info">
                <span className="label">{props.primary.label}</span>
                {props.primary.text}
              </span>
              {props.secondary && (
                <span className="group__info">
                  <span className="label">{props.secondary.label}</span>
                  {props.secondary.text}
                </span>
              )}
            </div>
          </Header>

          <Body>
            <div>
              {props.specs.map((spec, index) => (
                <span className="group__spec" key={index}>
                  {spec}
                </span>
              ))}
              <Actions>{props.children}</Actions>
            </div>
            <div>
              {props.status && (
                <ChipStatus
                  className="large"
                  label={props.status.label}
                  background={props.status.color}
                />
              )}
            </div>
          </Body>
        </Container>
      </TableCell>
    </TableRow>
  )
}

GroupHeader.propTypes = {
  size: PropTypes.number,
  title: PropTypes.string,
  primary: PropTypes.shape({
    label: PropTypes.string,
    text: PropTypes.string,
  }),
  secondary: PropTypes.shape({
    label: PropTypes.string,
    text: PropTypes.string,
  }),
  status: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
  }),
  specs: PropTypes.arrayOf(PropTypes.string),
}

GroupHeader.defaultProps = {
  specs: [],
}
