import React, { useState } from 'react'

import handleErrors from 'services/handleErrors'
import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'

import { Print } from '@material-ui/icons'
import { Content, Paper } from 'components/index'

import * as SC from '../styles'

import SideBar from './Filter'
import WalletOrdersTable from './Table'
import Legend from './Legend'

export function WalletOrdersPage() {
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)

  const initializeReport = async (filters) => {
    setLoading(true)

    try {
      const response = await api.post(apiEndPoints.queries.walletOrders, {
        filtros: { ...filters },
      })

      const orders = response.data
      setOrders(orders)
    } catch (error) {
      handleErrors(error, 'Não foi possível gerar o relatório.')
    }

    setLoading(false)
  }

  const clearReport = () => {
    setOrders([])
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <Content
        title="Consultar Pedidos em Carteira"
        showReturn
        sideBarStart
        className="printable"
        loading={loading}
        SideComponent={
          <SideBar onSubmit={initializeReport} onClear={clearReport} />
        }
        Controls={
          <SC.Controls>
            <SC.Button
              disableElevation
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={handlePrint}
            >
              Imprimir
            </SC.Button>
          </SC.Controls>
        }
      >
        <Paper className="border-none">
          <WalletOrdersTable data={orders} />
        </Paper>

        <Legend />
      </Content>
    </>
  )
}

export default WalletOrdersPage
