import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  vehicles: [],
}

const lastVehiclesSlicer = createSlice({
  name: 'lastVehicles',
  initialState,
  reducers: {
    getLastVehiclesRequest(state) {
      state.loading = true
    },
    getLastVehiclesSuccess(state, { payload }) {
      state.loading = false
      state.vehicles = payload
    },
    getLastVehiclesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getLastVehiclesFailure,
  getLastVehiclesRequest,
  getLastVehiclesSuccess,
} = lastVehiclesSlicer.actions

export default lastVehiclesSlicer.reducer
