import { createGlobalStyle } from 'styled-components'
import { lighten } from 'polished'
import theme from '../theme'
import * as medias from '../consts/medias'

import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-toastify/dist/ReactToastify.css'

const GlobalStyle = createGlobalStyle`  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }
  
  body {
    background: #FFF;
    font-family: 'Roboto Slab', 'Roboto', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  .printable {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
  }

  *:focus,
  *:active {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
  }

  *, li {
    list-style: none;
    text-decoration: none;
  }

  a {
    color: ${theme.palette.primary.main};
    &:hover {
      text-decoration: underline;
    }
  }
  
  button {
    cursor: pointer;
  }

  /** MUI */
  .MuiAutocomplete-popper {
    > div > div {
      font-size: 12px;
    }
  }

  .hoverable:hover {
    cursor: pointer;
  }

  @media print {
    :not(.printable) {
      visibility: hidden;
    }

    .printable :not(.MuiBackdrop-root) {
      * {
        overflow: hidden;
      }

      .MuiDialogActions-root {
        display: none;
      }
      .MuiDialog-paper {
        margin: 0;
      }
      visibility: visible;
    }

    #coletas-emitidas {
      display: none;
    }

    @page {
      size: auto;
      margin: 4mm;
    }
  }

  @media ${medias.downLg} {
    .hidden__small {
      display: none;
    } 
  }

  @media (min-width: 600px) {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1.5px ${theme.palette.secondary.dark}; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.secondary.dark}; 
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${lighten(0.1, theme.palette.secondary.dark)}; 
    }
  }
`

export default GlobalStyle
