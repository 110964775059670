import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from 'services/api'
import handleErrors from 'services/handleErrors'
import apiEndPoints from 'consts/apiEndPoints'

import {
  getBulletinsRequest,
  getBulletinsSuccess,
  getBulletinsFailure,
  readBulletinRequest,
  unreadedBulletinsRequest,
  unreadedBulletinsSuccess,
} from './reducer'

function* getBulletins({ payload }) {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.bulletins(payload.status),
      {
        params: payload,
      }
    )
    yield put(
      getBulletinsSuccess({
        bulletins: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    yield put(getBulletinsFailure())
    handleErrors(error)
  }
}

function* readBulletin({ payload: bulletin }) {
  try {
    // read bulletin
    yield call(api.get, apiEndPoints.miscellaneous.readBulletin(bulletin.id))

    // fetch the new quantity of unread bulletins
    yield put(unreadedBulletinsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível ler o aviso')
  }
}

function* unreadedBulletins() {
  try {
    const reponse = yield call(
      api.get,
      apiEndPoints.miscellaneous.unreadedBulletins
    )
    yield put(unreadedBulletinsSuccess(reponse.data))
  } catch (error) {
    handleErrors(error)
  }
}

export default all([
  takeLatest(getBulletinsRequest.type, getBulletins),
  takeLatest(readBulletinRequest.type, readBulletin),
  takeLatest(unreadedBulletinsRequest.type, unreadedBulletins),
])
