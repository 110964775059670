import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  carriers: [],
}

const carriers = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    getCarriersRequest(state) {
      state.loading = true
    },
    getCarriersSuccess(state, { payload }) {
      state.loading = false
      state.carriers = payload
    },
    getCarriersFailure(state) {
      state.loading = false
    },
  },
})

export const { getCarriersFailure, getCarriersRequest, getCarriersSuccess } =
  carriers.actions

export default carriers.reducer
