import styled from 'styled-components'
import { GroupContainer, GroupHeader } from 'components/Table/TableGroup'

export const Container = styled(GroupContainer)`
  display: flex;
  flex-direction: column;
`

export const Header = styled(GroupHeader)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex: 1;

  span.label {
    font-weight: bold;
    margin-right: 6px;
  }

  .group__title {
    font-size: 18px;
    line-height: 150%;
  }
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  width: 100%;
`

export const Actions = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 0.5rem;

  .MuiButton-container-root {
    height: 30px;
  }

  .MuiButton-container-root:not(:disabled) {
    border-radius: 5px;

    .MuiButton-label {
      font-weight: normal;
      color: ${({ theme }) => theme.palette.common.white};
    }

    &.success {
      background-color: ${(props) => props.theme.palette.success.dark};
    }

    &.info {
      background-color: ${(props) => props.theme.palette.info.dark};
    }
  }
`
