import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createColumnField } from 'services/utils'
import {
  issuedsRequest,
  cancelEditCollect,
  cancelCollectRequest,
  reOpenCollectRequest,
  displayForEditCollect,
} from 'store/modules/users/schedules/reducer'
import { useSortData, usePagination } from 'hooks'

import Paper from 'components/Paper'
import Table from 'components/Table'
import TablePagination from 'components/TablePagination'

import { StyledDialog } from './styles'

import FormSchedule from '../Schedule/Form'
import ScheduleHeader from './ScheduleHeader'

export function UserIssuedSchedulesPage() {
  const dispatch = useDispatch()

  const schedules = useSelector((state) => state.users.schedules.issueds)
  const scheduleParams = useSelector(
    (state) => state.users.schedules.active.scheduleParams
  )

  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
    reset,
  } = usePagination()

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: 'id_agendamento_for_order',
    initialOrder: 'desc',
  })

  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(schedules, 'id_agendamento_for_order')
  )

  useEffect(() => {
    reset()
  }, [reset, currentSort])

  useEffect(() => {
    dispatch(issuedsRequest())
  }, [dispatch])

  const openedEdit = !!scheduleParams.isEditing

  function handleCloseEdit() {
    dispatch(cancelEditCollect())
  }

  function handleReopen(schedule) {
    dispatch(reOpenCollectRequest(schedule))
  }

  function handleEdit(schedule) {
    dispatch(displayForEditCollect({ ...schedule, collectWeight: 0 }))
  }

  function handleCancel(schedule) {
    dispatch(cancelCollectRequest(schedule))
  }

  return (
    <>
      <Paper className="border-none">
        <Table
          size="medium"
          groupBy="id_agendamento_for_order"
          data={currentPageRecords}
          currentSort={currentSort}
          onSortChange={onSortChange}
          components={{
            Group: (props) => (
              <ScheduleHeader
                {...props}
                onEdit={handleEdit}
                onReopen={handleReopen}
                onCancel={handleCancel}
              />
            ),
          }}
          columns={[
            createColumnField('AGENDAMENTO', 'id_agendamento'),
            createColumnField('PEDIDO', 'cod_pedido'),
            createColumnField('PRODUTO', 'cod_produto'),
            createColumnField('QUANTIDADE', 'vpa_qtd', 'decimal', 'right'),
            createColumnField('OC', 'ford_numero'),
          ]}
        />
        <TablePagination
          count={schedules.length}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
      <StyledDialog
        maxWidth="xs"
        fullWidth
        open={openedEdit}
        onClose={handleCloseEdit}
      >
        <FormSchedule isEditing />
      </StyledDialog>
    </>
  )
}

export default UserIssuedSchedulesPage
