import React from 'react'
import { PATHS } from 'consts/paths'
import { isAdmin } from 'services/utils'

import AdminCompaniesPage from 'pages/Admin/Companies'
import AdminUsersPage from 'pages/Admin/Users'
import Route from './Route'

export function AdminRoutes({ companyType }) {
  return (
    <>
      {isAdmin(companyType) && (
        <Route
          path={PATHS.admin.companies}
          component={AdminCompaniesPage}
          isPrivate
          exact
        />
      )}
      <Route
        path={PATHS.admin.users}
        component={AdminUsersPage}
        isPrivate
        exact
      />
    </>
  )
}

export default AdminRoutes
