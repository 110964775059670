import styled from 'styled-components'
import { colors } from 'theme'

import Paper from '../../Paper'
import Table from '../../Table'
import Chip from '../../Chip'
import { Box } from '@material-ui/core'
import { LocalShipping } from '@material-ui/icons'

export const StyledTable = styled(Table)``

export const ChipArea = styled(Box)`
  display: flex;
  align-items: center;
`

export const StyledChip = styled(Chip)`
  pointer-events: none;
  height: 20px;

  background-color: ${colors.color500};
  padding: 0 5px 0 1px;
  margin-right: 5px;

  .MuiChip-label {
    color: ${colors.white};
  }

  &.Chip-obs {
    background-color: ${colors.color700};
  }
`

export const Container = styled(Paper)`
  padding: 0;
`

export const TruckIcon = styled(LocalShipping)`
  font-size: 2.5em;
  &.emColetaUsuario {
    cursor: pointer;
    color: ${colors.notification};
  }
`
