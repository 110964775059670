import styled from 'styled-components'
import { Form } from '../../../CoForm'

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 15px;

  .MuiFormControl-root {
    max-width: 80px;
  }
`
