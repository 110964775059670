import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'

export const GroupContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding: 10px 0 5px;

  &.small {
    justify-content: space-between;
    align-items: center;
    .group__title {
      font-size: 12px;
    }
  }

  span {
    font-size: 12px;
    line-height: 13px;
    font-weight: normal;
  }

  .group__title {
    color: ${colors.color800};
    font-size: 17px;
    line-height: 21px;
  }

  .group__info {
    margin-left: 20px;
  }

  @media ${medias.sm} {
    .group__title {
      font-size: 14px;
    }
  }

  @media print {
    padding: 5px 0;
    .group__title {
      font-size: 14px;
    }

    &.small .group__title {
      font-size: 10px;
    }
  }
`

export const GroupHeader = styled.div`
  display: flex;
  align-items: center;
`

export const GroupBody = styled.div`
  display: flex;
  flex-direction: column;
`
