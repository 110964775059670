import { LOCALES } from '../locales'

export const ptBR = {
  [LOCALES.PORTUGUESE_BR]: {
    carriers: 'Transportadoras',
    carrier: 'Transportadora',
    toolbar: {
      title: '{companyType}',
      changeLanguage: 'Mudar idioma',
      menus: {
        home: 'Home',
        orders: 'Pedidos',
        collect: 'Coleta',
        issued: 'Emitidas',
        queries: 'Consultas',
      },
    },
    orders: {
      filter: {
        requestsFor: 'Pedidos por {typeFilter}',
      },
      table: {
        order: 'PEDIDO',
        company: 'UNIDADE',
        product: 'PRODUTO',
        yourOrder: 'SEU PEDIDO',
        pallets: 'PAL',
        available: 'DISPONÍVEL',
        balance: 'SALDO',
        quantity: 'QUANT',
        boxes: 'CXS',
        weight: 'PESO (kg)',
        delivery: 'ENTREGA',
        freight: 'FRETE',
        representative: 'REPR',
        scheduling: 'AGENDAMENTO',
      },
    },
    /** Coleta */
    collect: {
      collect: 'Coleta:',
      collectNum: 'COLETA {collect}',
      weightText: 'Peso {typeWeight} {weight}',
      selected: 'Selecionado:',
      total: 'Total:',
      collectBox: {
        truckType: 'Tipo do caminhão',
        datePlanned: 'Data Prevista para Coleta',
        vehicleCapacity: 'Capacidade Total do Veículo',
        collectionWeight: 'Peso Total da Coleta',
        avaialble: 'Disponível para Carregamento',
      },
    },
    /** Emitidas */
    issued: {
      emissionFilter: {
        issuedBy: 'Emitidas por {typeFilter}',
      },
      table: {
        collect: 'COLETA:',
        collectionDate: 'DATA DA COLETA:',
        plate: 'PLACA',
        driver: 'MOTORISTA',
        O_C: 'o.c.',
        chipStatus: {
          approved: 'Aprovado',
          canceled: 'Cancelado',
        },
      },
    },
    /** Consultas */
    queries: {
      shipments: {
        inputDate: 'DATA DA ENTRADA',
        attending: 'ATENDIMENTO',
        plate: 'PLACA',
        driver: 'MOTORISTA',
        password: 'SENHA',
        cargo: 'CARGA',
        OBS: 'obs',
        entry: 'ENTRADA',
        exit: 'SAÍDA',
      },
      invoices: {
        invoice: 'NOTA FISCAL',
        issue: 'EMISSÃO',
        customer: 'CLIENTE',
        city: 'CIDADE',
        uf: 'UF',
        m2: ' M2',
        heigth: 'PESO',
        value: 'VALOR',
        periodFilter: {
          period: 'Informe o período',
          initialDate: 'Data Inicial',
          finalDate: 'Data Final ',
        },
      },
    },

    comumns: {
      filter: {
        loadingText: 'Configurando filtros',
      },
      stateInitials: 'UF',
      city: 'Cidade',
      driver: 'Motorista',
      plate: 'Placa',
      customer: 'Cliente',
      yourOrder: 'Seu Pedido',
      issueDate: 'Data de Emissão',
      approved: 'Aprovada',
      canceled: 'Cancelada',
      loading: 'Carregando',
      finished: 'Finalizada',
      waiting: 'Aguardando',
      released: 'Liberada',
    },

    actions: {
      signout: 'Sair',
      searchBy: 'Pesquisar por',
      includeButtonText: 'Incluir na Coleta',
      deleteButton: 'Excluir Selecionados',
      selectAll: 'Selecionar Todos',
      send: 'Enviar',
      check: 'Consultar Notas Fiscais emitidas',
      consultShipments: 'Consultar carregamentos',
      search: 'Buscar',
      cancel: 'Cancelar',
    },
  },
}
