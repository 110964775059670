import React, { useRef } from 'react'
import { addDays } from 'date-fns'

import { placa } from 'consts/regexp'

import { Search, Delete } from '@material-ui/icons'
import { Title as RankingTitle, Divider } from 'components/Ranking/styles'
import SideBar from 'components/SideBar'
import Button from 'components/Button'

import { FormDatePicker, FormTextField } from 'components/CoForm'
import { ComboboxShipmentUnities as FormComboboxShipmentUnities } from 'components/Combobox/ShipmentUnities'
import {
  StyledForm,
  FieldsContainer,
  ButtonsContainer,
  DateFieldsContainer,
} from './styles'

function Title({ children }) {
  return (
    <RankingTitle>
      <span>{children}</span>
      <Divider />
    </RankingTitle>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function Filter({ onFilter, initialValues, ...props }) {
  const formRef = useRef(null)

  function handleClearForm() {
    formRef.current.setValues(initialValues)
    onFilter(initialValues)
  }

  const minDate = addDays(new Date(), -365)

  return (
    <SideBar
      {...props}
      ContentComponent={
        <StyledForm
          formRef={formRef}
          onSubmit={(values) => onFilter({ ...values })}
          initialData={initialValues}
        >
          <Title>Buscar Notas Fiscais</Title>

          <FieldsContainer>
            <DateFieldsContainer>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="nf_data_emissao.initial"
                label="Data Inicial"
                minDate={minDate}
              />
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="nf_data_emissao.final"
                label="Data Final"
              />
            </DateFieldsContainer>

            <FormComboboxShipmentUnities
              fullwidth
              label="Unidade"
              name="shipment_unity"
            />
            <FormTextField
              margin="none"
              fullwidth
              label="NF"
              name="nf_numero"
            />
            <FormTextField
              margin="none"
              fullwidth
              label="Placa"
              name="nf_placa_numero"
              textMaskProps={{ mask: placa.array }}
              uppercase
            />
            <FormTextField
              margin="none"
              fullwidth
              label="Motorista"
              name="shipping_order.ford_nome_motorista"
            />
          </FieldsContainer>

          <ButtonsContainer>
            <Button
              type="submit"
              size="small"
              color="primary"
              variant="outlined"
              label="Buscar"
              startIcon={<Search />}
            />
            <Button
              type="button"
              size="small"
              color="primary"
              variant="outlined"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClearForm}
            />
          </ButtonsContainer>
        </StyledForm>
      }
    />
  )
}

Filter.propTypes = {}

export default Filter
