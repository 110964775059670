import { all, takeLatest, call, put } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getCustomersRequest,
  getCustomersFailure,
  getCustomersSuccess,
  getDeliveryAddressesRequest,
  getDeliveryAddressesSuccess,
  getDeliveryAddressesFailure,
} from './reducer'

import { store } from '../../..'

function* getCustomers({ payload }) {
  try {
    const response = yield call(
      api.get,
      `${apiEndPoints.miscellaneous.customers}?search=${payload.search}`
    )
    const customers = response.data

    yield put(getCustomersSuccess(customers))
  } catch (error) {
    yield put(getCustomersFailure())
    handleErrors(error, 'Não foi possível buscar os clientes')
  }
}

function* getDeliveryAddresses() {
  try {
    const customerCompanyId =
      store.getState().user.profile.web_company.cod_empresa

    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.deliveryAddresses(customerCompanyId)
    )
    const deliveryAddresses = response.data

    yield put(getDeliveryAddressesSuccess(deliveryAddresses))
  } catch (error) {
    yield put(getDeliveryAddressesFailure())
    handleErrors(error, 'Não foi possível buscar os endereços de entrega')
  }
}

export default all([
  takeLatest(getCustomersRequest.type, getCustomers),
  takeLatest(getDeliveryAddressesRequest.type, getDeliveryAddresses),
])
