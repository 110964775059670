import React from 'react'
import clsx from 'clsx'
import { mergeAll } from 'ramda'
import PropTypes from 'prop-types'

import TextMaskCustom from '../TextMaskCustom'
import { StyledTextField } from './styles'

function TextField({
  hidden,
  large,
  uppercase,
  textMaskProps,
  inputProps,
  InputProps,
  value,
  formatValue,
  InputLabelProps,
  ...props
}) {
  const defaultProps = {
    style: { fontSize: 12 },
  }
  const withDefaults = (...props) => mergeAll([defaultProps, ...props].flat())

  const formattedValue = formatValue ? formatValue(value) : value
  const inputValue =
    formattedValue && uppercase ? formattedValue.toUpperCase() : formattedValue

  const getInputProps = () => {
    const defaults = withDefaults(InputProps)
    if (textMaskProps) defaults.inputComponent = TextMaskCustom
    return defaults
  }

  return (
    <StyledTextField
      value={inputValue}
      className={clsx({ hidden, large })}
      inputProps={{
        ...textMaskProps,
        ...inputProps,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{ ...getInputProps() }}
      InputLabelProps={{ ...withDefaults([InputLabelProps]) }}
      {...props}
    />
  )
}

TextField.propTypes = {
  textMaskProps: PropTypes.objectOf(PropTypes.any),
  hidden: PropTypes.bool,
  large: PropTypes.bool,
  uppercase: PropTypes.bool,
  formatValue: PropTypes.func,
}

TextField.defaultProps = {
  textMaskProps: null,
  InputProps: {},
  variant: 'outlined',
  size: 'small',
  large: false,
  hidden: false,
  uppercase: false,
}

export { TextField }
export default TextField
