import { useSelector } from 'react-redux'
import {
  isDeveloper,
  isAdmin,
  isMasterAdmin,
  isCarrier,
  isCustomer,
} from 'services/utils'

export function useProfile() {
  const profileData = useSelector((state) => state.user.profile)
  const companyType = profileData.web_company.wee_tipo
  const userType = profileData.web_user.weu_tipo

  let profile = { ...profileData }
  profile.id = profileData.web_company.cod_empresa
  profile.isDeveloper = isDeveloper(companyType)
  profile.isAdminCompany = isAdmin(companyType)
  profile.isAdminUser = isAdmin(userType)
  profile.isMasterAdmin = isMasterAdmin(companyType, userType)
  profile.isCarrier = isCarrier(companyType)
  profile.isCustomer = isCustomer(companyType)

  return profile
}

export default useProfile
