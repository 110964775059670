import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useProfile } from 'hooks/index'

import { Divider } from '@material-ui/core'

import { Content } from 'components/index'
import { Truck, Note } from 'components/Icons'
import {
  WidgetsOutlined as Inventory,
  AccountBalanceWalletOutlined as Wallet,
  MenuOpenRounded as Issued,
  PaymentOutlined as Billing,
} from '@material-ui/icons'
import { PATHS } from 'consts/paths'
import {
  Container,
  QueryButtonRow,
  QueryButton,
  QueryIcon,
  QueryInfo,
} from './styles'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function QueriesPage() {
  const profile = useProfile()

  function renderNavigateQueryButtons() {
    if (profile.isCarrier) {
      return (
        <QueryButtonRow>
          <QueryNavigateButton
            path={PATHS.user.queries.shipments}
            rightId={RIGHTS.consultaCarregamentos.id}
            Icon={Truck}
            title="Carregamentos"
            description="Consultar os carregamentos do dia ou de data anterior"
          />
          <QueryNavigateButton
            path={PATHS.user.queries.invoices}
            Icon={Note}
            title="Notas Fiscais"
            description="Consultar as notas fiscais emitidas e baixar documentos fiscais relacionados"
          />
        </QueryButtonRow>
      )
    } else if (profile.isCustomer) {
      return (
        <>
          <QueryButtonRow>
            <QueryNavigateButton
              path={PATHS.user.queries.invoices}
              Icon={Note}
              title="Notas Fiscais"
              description="Consultar as notas fiscais emitidas e baixar documentos fiscais relacionados"
            />
            <QueryNavigateButton
              path={PATHS.user.queries.tradeReceivables}
              Icon={Note}
              title="Duplicatas"
              description="Consultar e baixar as duplicatas(vencidas e à vencer)"
            />
            <QueryNavigateButton
              path={PATHS.user.queries.shipments}
              rightId={RIGHTS.consultaCarregamentos.id}
              Icon={Truck}
              title="Carregamentos"
              description="Consultar os carregamentos do dia ou de data anterior"
            />
            <QueryNavigateButton
              path={PATHS.user.queries.inventory}
              rightId={RIGHTS.relEstoque.id}
              Icon={Inventory}
              title="Estoque"
              description="Consultar saldo em estoque dos produtos, e imprimir ficha do produto"
            />
          </QueryButtonRow>

          <Divider />

          <QueryButtonRow>
            <QueryNavigateButton
              path={PATHS.user.queries.issuedOrders}
              Icon={Issued}
              title="Pedidos Emitidos"
              description="Consultar pedidos emitidos"
            />
            <QueryNavigateButton
              path={PATHS.user.queries.billedOrders}
              rightId={RIGHTS.relPedidosFaturados.id}
              Icon={Billing}
              title="Pedidos Faturados"
              description="Consultar pedidos faturados"
            />
            <QueryNavigateButton
              path={PATHS.user.queries.walletOrders}
              rightId={RIGHTS.relPedidosEmCarteira.id}
              Icon={Wallet}
              title="Pedidos em Carteira"
              description="Consultar pedidos em carteira"
            />
          </QueryButtonRow>
        </>
      )
    } else return null
  }

  return (
    <Content title="Consultas">
      <Container>{renderNavigateQueryButtons()}</Container>
    </Content>
  )
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function QueryNavigateButton({
  path,
  title,
  description,
  Icon,
  rightId,
  ...props
}) {
  const history = useHistory()

  return (
    <UserRight id={rightId}>
      <QueryButton onClick={() => history.push(path)} {...props}>
        <QueryIcon>
          <Icon />
        </QueryIcon>
        <QueryInfo>
          <span>{title}</span>
          <p>{description}</p>
        </QueryInfo>
      </QueryButton>
    </UserRight>
  )
}

QueryNavigateButton.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
}
