import styled from 'styled-components'
import { Checkbox, FormControlLabel } from '@material-ui/core'

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 15px;
  }
`

export const StyledCheckbox = styled(Checkbox)``
