import React from 'react'
import PropTypes from 'prop-types'
import { GroupContainer, GroupHeader, GroupBody } from './TableGroup'
import { StyledCheckBox } from './styles'

export function TableHeader(props) {
  return (
    <GroupContainer>
      {props.checkboxProps && <StyledCheckBox {...props.checkboxProps} />}
      <div>
        <GroupHeader>
          <h3 className="group__title">{props.title}</h3>
          <span className="group__info">{props.helperText}</span>
        </GroupHeader>
        <GroupBody>
          {props.bodyData?.map((db, index) => (
            <span key={index}>{db}</span>
          ))}
        </GroupBody>
      </div>
    </GroupContainer>
  )
}

TableHeader.propTypes = {
  checkboxProps: PropTypes.object,
  title: PropTypes.string,
  helperText: PropTypes.string,
  bodyData: PropTypes.array,
}

export default TableHeader
