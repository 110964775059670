import { all, put, call, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { apiEndPoints } from 'consts/apiEndPoints'
import { PATHS } from 'consts/paths'
import { persistREHYDRATE, ACCEPT_INVITE, RECOVERY } from 'consts/actions'

import api from 'services/api'
import history from 'services/history'
import handleErros from 'services/handleErrors'
import { saveProfileTheme } from 'services/colors'

import {
  signInRequest,
  signInSuccess,
  signOutRequest,
  recoveryRequest,
  recoverySuccess,
  recoveryFailure,
} from './reducer'
import { setUser } from '../user/reducer'

export const configAuthorization = (token) => {
  if (token) api.defaults.headers.Authorization = `Bearer ${token}`
}

export function signOut() {
  history.push(PATHS.signin.link)
}

export async function getRights(profiles) {
  if (profiles.length > 0) {
    const accesses = await Promise.all(
      profiles.map(async (profile) => {
        const req = require(`../../../__access_profiles__/${profile.id_perfil}`)
        return [...req.default].map((r) => r.id)
      })
    )

    // Combine and intersection rights
    return [...new Set([...accesses.flat()])]
  } else {
    const admin = require('../../../__access_profiles__/ADM.js')
    return [...admin.default].map((r) => r.id)
  }
}

function* signIn({ payload }) {
  try {
    const requestBody = {
      weu_email: payload.weu_email,
      weu_id: payload.weu_id,
      password: payload.password,
      token: payload.token,
    }

    switch (payload.operation) {
      case ACCEPT_INVITE:
        yield call(
          api.post,
          apiEndPoints.invites.accept(payload.id),
          requestBody
        )
        toast.success('Cadastro completo com sucesso.')

        yield put(signOutRequest())
        history.push(PATHS.signin.link)
        break
      case RECOVERY:
        const response = yield call(
          api.post,
          apiEndPoints.passwords.updatePassword,
          requestBody
        )
        toast.success(response.data.message)

        yield put(signOutRequest())
        history.push(PATHS.signin.link)
        break
      default:
        const responseAuth = yield call(
          api.post,
          apiEndPoints.signin,
          requestBody
        )

        const token = responseAuth.data.auth_token
        const profile = responseAuth.data.profile

        // Access profile
        const profiles = profile.web_user.profiles
        const rights = yield call(getRights, profiles)

        configAuthorization(token)
        saveProfileTheme(profile.web_company.wee_tipo)

        yield put(signInSuccess({ token, rights }))
        yield put(setUser(profile))

        // Go to company type default path, ex: transportadora/pedidos
        history.push(
          PATHS.defaultPath(
            profile.web_company.wee_tipo,
            profile.web_user.weu_tipo
          )
        )
        break
    }
  } catch (error) {
    handleErros(error, 'Não foi possível iniciar a sessão.')
    yield put(signOutRequest())
  }
}

function* recovery({ payload }) {
  try {
    const response = yield call(
      api.post,
      apiEndPoints.passwords.changePassword,
      payload
    )
    const { message } = response.data

    toast.success(message)
    yield put(recoverySuccess())
  } catch (error) {
    handleErros(error, 'Não foi possível enviar o email.')
    yield put(recoveryFailure(error))
  }
}

// ------------------------------------

export function onRehydrate({ payload }) {
  try {
    if (!payload) return
    configAuthorization(payload.auth.token)
  } catch (error) {}
}

export default all([
  takeLatest(signInRequest.type, signIn),
  takeLatest(signOutRequest.type, signOut),
  takeLatest(recoveryRequest.type, recovery),
  takeLatest(persistREHYDRATE, onRehydrate),
])
