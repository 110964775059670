import styled from 'styled-components'

export const StyledTypography = styled.span`
  white-space: nowrap;

  &.StyledTypography-label {
    margin-right: 4px;
    font-weight: bold;
  }
`
