import enUs from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import ptBR from 'date-fns/locale/pt-BR'

export const LOCALES = {
  ENGLISH: 'en-US',
  SPANISH: 'es',
  PORTUGUESE_BR: 'pt-BR',
}

export const LOCALE_IDS = {
  1: LOCALES.ENGLISH,
  2: LOCALES.SPANISH,
  3: LOCALES.PORTUGUESE_BR,
}

export const LANGUAGE_IDS = {
  [LOCALES.ENGLISH]: 1,
  [LOCALES.SPANISH]: 2,
  [LOCALES.PORTUGUESE_BR]: 3,
}

export const LOCALE_DATE_FNS = {
  [LOCALES.ENGLISH]: enUs,
  [LOCALES.SPANISH]: es,
  [LOCALES.PORTUGUESE_BR]: ptBR,
}

export const LOCALE_SCOPE_LABELS = {
  [LOCALES.ENGLISH]: {
    fulfilled: 'Fulfilled',
    unfulfilled: 'Unfulfilled',
    canceled: 'Canceled',
  },
  [LOCALES.SPANISH]: {
    fulfilled: 'Cumplido',
    unfulfilled: 'Incumplido',
    canceled: 'Cancelado',
  },
  [LOCALES.PORTUGUESE_BR]: {
    fulfilled: 'Baixados',
    unfulfilled: 'Abertos',
    canceled: 'Cancelados',
  },
}

export const DEFAULT_LOCALE = {
  id: LANGUAGE_IDS[LOCALES.PORTUGUESE_BR],
  get idLang() {
    return LOCALE_IDS[this.id]
  },
}
