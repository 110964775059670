import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  options: [],
}

const shipmentUnities = createSlice({
  name: 'shipmentUnities',
  initialState,
  reducers: {
    getShipmentUnitiesRequest(state) {
      state.loading = true
    },
    getShipmentUnitiesSuccess(state, { payload }) {
      state.loading = false
      state.options = payload
    },
    getShipmentUnitiesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getShipmentUnitiesRequest,
  getShipmentUnitiesSuccess,
  getShipmentUnitiesFailure,
} = shipmentUnities.actions

export default shipmentUnities.reducer
