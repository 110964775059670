import React from 'react'

import ContentRoutes from '../../routes/ContentRoutes'
import { AppBar, BlockAccessBulletins } from '../../components'
import { Container, ContentContainer } from './styles'

function LayoutPage() {
  return (
    <Container>
      <AppBar />
      <ContentContainer>
        <ContentRoutes />
      </ContentContainer>
      <BlockAccessBulletins />
    </Container>
  )
}

export default LayoutPage
