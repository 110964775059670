import React, { useState, useEffect } from 'react'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import { isEmptyOrNil } from 'services/utils'
import { bulletinStatus } from 'pages/Users/Bulletins'
import Bulletin from 'pages/Users/Bulletins/Bulletin'
import handleErrors from 'services/handleErrors'

export function BlockAccessBulletins() {
  const [blockedAccessBulletins, setBlockedAccessBulletins] = useState(null)
  const withBlockedAccessBulletins = !isEmptyOrNil(blockedAccessBulletins)

  useEffect(() => {
    ;(async () => {
      try {
        const response = await api.get(
          apiEndPoints.miscellaneous.bulletins(bulletinStatus.BLOCK_ACCESS)
        )

        setBlockedAccessBulletins(response.data)
      } catch (error) {
        handleErrors(
          error,
          'Não foi possível buscar os comunicados - obrigatórios'
        )
      }
    })()
  }, [])

  const handleClose = () => setBlockedAccessBulletins(null)

  return withBlockedAccessBulletins ? (
    <Bulletin
      bulletins={blockedAccessBulletins}
      open={withBlockedAccessBulletins}
      onClose={handleClose}
    />
  ) : null
}

export default BlockAccessBulletins
