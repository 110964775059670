import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useProfile from 'hooks/useProfile'

/**
 *
 * @param {number} id - Id do direito
 * @returns {boolean} - Direito disponível
 */
export const useAvaiableRight = (id) => {
  const userRights = useSelector((state) => state.auth.rights)
  const profile = useProfile()

  // Aplicar controle de direitos apenas a usuários com a companhia do tipo Cliente
  if (profile.isCustomer && id) {
    return userRights.includes(id)
  }

  return true
}

/**
 * @param {object} props
 * @param {number} props.id - Id do direito
 * @param {JSX.Element} props.children
 * @param {JSX.Element} props.NoRightComponent - Componente que será renderizado, caso o usuário não tenha direito
 *
 * @default 'null'
 *
 * @description - Componente que controla os direitos do usuário.
 */
export default function UserRight({ id, children, NoRightComponent }) {
  const isAvailable = useAvaiableRight(id)

  if (isAvailable) return <>{children}</>
  else return <>{NoRightComponent}</>
}

UserRight.propTypes = {
  id: PropTypes.number,
  children: PropTypes.element.isRequired,
  noRightComponent: PropTypes.element,
}

UserRight.defaultProps = {
  noRightComponent: null,
}
