import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as medias from 'consts/medias'
import { usePagination } from 'hooks'
import { createQueryParameters } from 'services/utils'
import { getBulletinsRequest } from 'store/modules/comuns/bulletins/reducer'

import { Tooltip, useMediaQuery } from '@material-ui/core'
import { Message } from '@material-ui/icons'

import Content from 'components/Content'
import Paper from 'components/Paper'
import Table from 'components/Table'
import TablePagination from 'components/TablePagination'
import TextField from 'components/TextField'
import ComboboxBase from 'components/Combobox/Base'

import {
  ComboContainer,
  SmallDeviseContent,
  StyledBulletinCard,
} from './styles'
import Bulletin from './Bulletin'
import formatter from 'services/formatter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const bulletinStatus = {
  ALL: '0',
  UNREAD: '1',
  READ: '2',
  BLOCK_ACCESS: '3',
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function ComboboxBulletinStatus(props) {
  return (
    <ComboContainer>
      <ComboboxBase
        name="status"
        label="STATUS"
        options={[
          { label: 'LIDOS', value: bulletinStatus.READ },
          { label: 'NÃO LIDOS', value: bulletinStatus.UNREAD },
          { label: 'TODOS', value: bulletinStatus.ALL },
        ]}
        renderInput={(provided) => (
          <TextField variant="outlined" {...provided} />
        )}
        disableClearable
        {...props}
      />
    </ComboContainer>
  )
}

function BulletinCard({ current, onClick, ...props }) {
  return (
    <StyledBulletinCard {...props} variant="outlined">
      <div onClick={onClick} className="StyledBulletinCard-subject">
        {current.vc_assunto}
      </div>
      <div className="StyledBulletinCard-content">
        <span>De: {current.vc_remetente}</span>
        <span>{formatter(current.vc_dinicial).toSimpleDate()}</span>
      </div>
    </StyledBulletinCard>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function BulletinsPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const bulletins = useSelector((state) => state.comuns.bulletins.bulletins)
  const unreadedBulletins = useSelector(
    (state) => state.comuns.bulletins.unreadedBulletins
  )

  const loading = useSelector((state) => state.comuns.bulletins.loading)
  const count = useSelector((state) => state.comuns.bulletins.count)

  const [bulletin, setBulletin] = useState(null)
  const [status, setStatus] = useState(
    unreadedBulletins > 0 ? bulletinStatus.UNREAD : bulletinStatus.READ
  )

  const { onChangePage, onChangeRowsPerPage, rowsPerPage, page } =
    usePagination()

  useEffect(() => {
    const queryParameters = createQueryParameters({
      page,
      rowsPerPage: sm ? 999 : rowsPerPage,
      status,
    })

    dispatch(getBulletinsRequest(queryParameters))
  }, [dispatch, status, page, rowsPerPage, sm])

  function handleChangeStatus(e) {
    const newStatus = e.target.value
    setStatus(newStatus)
  }

  return (
    <Content
      title="Avisos"
      loading={loading}
      Controls={
        <ComboboxBulletinStatus value={status} onChange={handleChangeStatus} />
      }
    >
      {sm ? (
        <SmallDeviseContent>
          {bulletins.map((bulletin, index) => (
            <BulletinCard
              key={index}
              current={bulletin}
              onClick={() => setBulletin(bulletin)}
            />
          ))}
        </SmallDeviseContent>
      ) : (
        <Paper className="border-none">
          <Table
            size="medium"
            data={bulletins}
            columns={[
              { title: 'AVISO', field: 'id' },
              {
                title: 'EMISSÃO',
                field: 'vc_dinicial',
                cellFormat: 'date',
              },
              { title: 'REMETENTE', field: 'vc_remetente' },
              { title: 'ASSUNTO', field: 'vc_assunto' },
              {
                renderCell: ({ rowData: bulletin }) => (
                  <Tooltip title="Ler aviso">
                    <Message onClick={() => setBulletin(bulletin)} />
                  </Tooltip>
                ),
                role: 'button',
              },
            ]}
          />
          <TablePagination
            count={count}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Paper>
      )}
      {bulletin && (
        <Bulletin bulletins={[bulletin]} onClose={() => setBulletin(null)} />
      )}
    </Content>
  )
}
