import styled from 'styled-components'
import { List, Popper } from '@material-ui/core'
import { Info } from '@material-ui/icons'

export const StyledInfoIcon = styled(Info)`
  color: ${(props) => props.theme.palette.info.main};
`

export const StyledPopper = styled(Popper)`
  z-index: 100000;
`

export const StyledList = styled(List)`
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: ${(props) => props.theme.shadows[3]};

  max-height: 300px;
  overflow: auto;

  .MuiListItem-root {
    padding-top: 2px;
    padding-bottom: 2px;

    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.secondary};
      margin-right: 0.75rem;
      font-size: 8px;
    }
  }

  .MuiListItem-root.Title {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
