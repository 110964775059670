import { useCallback, useState } from 'react'
import { DEFAULT_PER_PAGE_OPTIONS } from 'components/TablePagination'

// ----------------------------

const DEFAULT_PAGE = 0
const DEFAULT_ROWS_PER_PAGE =
  DEFAULT_PER_PAGE_OPTIONS[DEFAULT_PER_PAGE_OPTIONS.length - 1]

// ----------------------------

export function usePagination({
  initialPage = DEFAULT_PAGE,
  initialRowsPerpage = DEFAULT_ROWS_PER_PAGE,
} = {}) {
  const [page, setPage] = useState(initialPage)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerpage)

  function onChangePage(_, newPage) {
    setPage(newPage)
  }

  function onChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function calculateNewPaginatorData(records) {
    const startIndex = page * rowsPerPage
    const lastIndex = startIndex + rowsPerPage
    const totalPages = Math.ceil(records.length / rowsPerPage)

    const currentPageRecords = records.slice(startIndex, lastIndex)

    return { totalPages, currentPageRecords }
  }

  const reset = useCallback(() => {
    setRowsPerPage(initialRowsPerpage)
    setPage(initialPage)
  }, [initialRowsPerpage, initialPage])

  return {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
    reset,
  }
}

export default usePagination
