import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { sum } from 'ramda'
import { Typography } from '@material-ui/core'

import { RenderTableRow, Table } from 'components/Table'
import { CollectSchedulesContainer } from './styles'

export function CollectSchedules({ data }) {
  const summary = useMemo(() => {
    const weights = data.map((schedule) => schedule.peso)
    return { weight: sum(weights) }
  }, [data])

  if (data.length === 0) return null

  return (
    <CollectSchedulesContainer>
      <Typography component="span">AGENDAMENTOS INCLUÍDOS</Typography>

      <Table
        stripped
        size="medium"
        data={data}
        columns={[
          { field: 'unidade', title: 'UNIDADE' },
          { field: 'ford_senha', title: 'SENHA' },
          { field: 'ford_data', title: 'DATA', cellFormat: 'date' },
          {
            field: 'peso',
            title: 'PESO',
            cellFormat: 'decimal',
            align: 'right',
          },
        ]}
        components={{
          Footer: (footerProps) => {
            return (
              <RenderTableRow
                {...footerProps}
                rowData={{
                  unidade: 'TOTAL AGENDAMENTOS',
                  peso: summary.weight,
                }}
              />
            )
          },
        }}
      />
    </CollectSchedulesContainer>
  )
}

CollectSchedules.propTypes = {
  data: PropTypes.array,
}
