import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formatter } from 'services/formatter'
import { getTruckTypesRequest } from '../../../store/modules/comuns/truckTypes/reducer'
import { FormCombobox } from '../../CoForm'

export function ComboboxTruckTypes({ loading, ...props }) {
  const dispatch = useDispatch()

  const truckTypes = useSelector((state) => state.comuns.truckTypes.options)
  const apiLoading = useSelector((state) => state.comuns.truckTypes.loading)

  const options = truckTypes.map((v) => ({
    ...v,
    value: v.id_tipo_caminhao,
    label: v.dsc_tipo_caminhao,
    secondary: formatter(Number(v.cam_pbt)).toDecimal(),
  }))

  useEffect(() => {
    if (truckTypes.length === 0) dispatch(getTruckTypesRequest())
  }, [dispatch, truckTypes.length])

  return (
    <FormCombobox
      // getOptionPicture={(o) => o.cam_imagemb64}
      options={options}
      loading={apiLoading || loading}
      disabled={apiLoading || loading}
      {...props}
    />
  )
}

export default ComboboxTruckTypes
