import styled from 'styled-components'
import { colors } from 'theme'

export const LoaderArea = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > small {
    color: ${colors.color500};
  }
`
