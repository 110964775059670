import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FormCombobox } from '../../CoForm'
import CheckBox from 'components/Checkbox'
import {
  getCarriersRequest,
  getCarriersSuccess,
} from 'store/modules/comuns/carriers/reducer'
import { Box, Tooltip } from '@material-ui/core'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const CARRIERS_DELAY_TIME = 500 * 1 // 0.5 second

/** This component can only be used inside a `Form` */
export function ComboboxCarriers({
  search: receivedSearch,
  showAllCheck,
  ...props
}) {
  const dispatch = useDispatch()
  const carriers = useSelector((state) => state.comuns.carriers.carriers)
  const loading = useSelector((state) => state.comuns.carriers.loading)
  const [search, setSearch] = useState('')
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    let timeOut = undefined
    const value = search || receivedSearch

    if (value) {
      dispatch(getCarriersSuccess([])) // Remove previous carriers

      timeOut = setTimeout(
        () => {
          dispatch(getCarriersRequest({ search: value, showAll }))
        },
        receivedSearch ? 0 : CARRIERS_DELAY_TIME
      )
    }

    return () => {
      clearTimeout(timeOut)
    }
  }, [dispatch, search, receivedSearch, showAll])

  const handleChangeSearch = (e) => {
    const search = e.target.value
    setSearch(search)
  }

  const handleShowAll = (_, checked) => {
    setShowAll(checked)
  }

  return (
    <Box display="flex" gridGap="1rem">
      <FormCombobox
        options={carriers}
        loading={loading}
        onInputChange={handleChangeSearch}
        noOptionsText="Digite para pesquisar uma transportadora."
        loadingText="Buscando transportadoras..."
        {...props}
      />
      {showAllCheck && (
        <Tooltip title="Mostrar todas as transportadoras">
          <div>
            <CheckBox value={showAll} onChange={handleShowAll} />
          </div>
        </Tooltip>
      )}
    </Box>
  )
}

ComboboxCarriers.propTypes = {
  search: PropTypes.string,
  showAllCheck: PropTypes.bool,
}

ComboboxCarriers.defaultProps = {
  showAllCheck: false,
}

export default ComboboxCarriers
