import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import * as medias from 'consts/medias'
import { colors } from 'theme'
import StyledTablePagination from './styles'

export const DEFAULT_PER_PAGE_OPTIONS = [10, 20, 30, 50]

export function TablePagination({
  onChangePage,
  onChangeRowsPerPage,
  ...props
}) {
  const sm = useMediaQuery(medias.sm)

  return (
    <StyledTablePagination
      SelectProps={{
        style: {
          fontSize: 12,
          color: colors.color500,
        },
      }}
      rowsPerPageOptions={DEFAULT_PER_PAGE_OPTIONS}
      component="div"
      backIconButtonText="Página anterior"
      nextIconButtonText="Proxíma página"
      labelRowsPerPage={sm ? null : 'REGISTROS POR PÁGINA'}
      labelDisplayedRows={(provided) =>
        `${provided.from}- ${provided.to} DE ${provided.count}`
      }
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      {...props}
    />
  )
}

TablePagination.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default TablePagination
