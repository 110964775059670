import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import {
  getProductsRequest,
  getBrandsRequest,
  getLinesRequest,
} from 'store/modules/users/orders/products/reducer'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import Checkbox from 'components/Checkbox'
import SideComponent from 'components/SideBar'
import Ranking from 'components/Ranking2'
import SearchInput from 'components/SearchInput'
import { Container, HeaderContainer } from './styles'

export function FilterProducts() {
  // █░█ █▀█ █▀█ █▄▀ █▀
  // █▀█ █▄█ █▄█ █░█ ▄█

  const sm = useMediaQuery(medias.sm)

  const dispatch = useDispatch()
  const products = useSelector((state) => state.users.orders.products.products)
  const brands = useSelector((state) => state.users.orders.products.brands)
  const formats = useSelector((state) => state.users.orders.products.formats)
  const lines = useSelector((state) => state.users.orders.products.lines)

  const [filters, setFilters] = useState(null)

  useEffect(() => {
    dispatch(getBrandsRequest())
    dispatch(getLinesRequest())
  }, [dispatch])

  useEffect(() => {
    if (filters !== null) {
      dispatch(getProductsRequest(filters))
    }
  }, [dispatch, filters])

  // █▀▄▀█ █▀▀ ▀█▀ ▄▀█ █▀▄ ▄▀█ ▀█▀ ▄▀█
  // █░▀░█ ██▄ ░█░ █▀█ █▄▀ █▀█ ░█░ █▀█

  const disabledRanking = filters?.somente_orc
  const showBProducts = products.some((p) => p.cli_produtos_b_web)

  // █▀▀ █░█ █▄░█ █▀▀ ▀█▀ █ █▀█ █▄░█ █▀
  // █▀░ █▄█ █░▀█ █▄▄ ░█░ █ █▄█ █░▀█ ▄█

  const handleSelect = (filterBy, filters) => {
    setFilters((previousFilters) => ({
      ...previousFilters,
      [filterBy]: filters,
    }))
  }

  const handleSearch = (search) => {
    handleSelect('dsc_produto', search)
  }

  const handleCheck = (event, checked) => {
    const name = event.target.name
    handleSelect(name, checked)
  }

  if (sm) return <SearchInput fullWidth placeholder="Buscar produtos..." />

  return (
    <SideComponent
      HeaderComponent={
        <HeaderContainer>
          <SearchInput
            fullWidth
            placeholder="Buscar produtos..."
            onSearch={handleSearch}
          />
        </HeaderContainer>
      }
      ContentComponent={
        <Container className="Ranking-List">
          <Ranking
            disabled={disabledRanking}
            showSelection
            showSearch={false}
            filterBy="brand"
            title="Marca"
            rows={brands}
            onSelect={handleSelect}
          />
          <Ranking
            disabled={disabledRanking}
            showSelection
            showSearch={false}
            filterBy="format"
            title="Tamanho"
            rows={formats}
            onSelect={handleSelect}
          />
          <Ranking
            disabled={disabledRanking}
            showSelection
            showSearch={false}
            filterBy="line"
            title="Linha"
            rows={lines}
            onSelect={handleSelect}
          />
          <Box marginLeft="5px">
            <Checkbox
              size="small"
              color="primary"
              label="Incluídos no orçamento"
              name="somente_orc"
              onChange={handleCheck}
            />
            {showBProducts && (
              <Checkbox
                size="small"
                color="primary"
                label="Mostrar produtos B"
                name="produtos_b"
                onChange={handleCheck}
              />
            )}
          </Box>
        </Container>
      }
      FooterComponent={null}
    />
  )
}

export default FilterProducts
