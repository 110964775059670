import styled from 'styled-components'
import * as medias from 'consts/medias'
import { ListItem } from '@material-ui/core'

export const StyledListItem = styled(ListItem)`
  @media ${medias.sm} {
    display: flex;
    flex-direction: column;

    ~ .MuiListItemSecondaryAction-root {
      position: relative;
      top: auto;
      right: auto;
      display: flex;
      justify-content: flex-end;
    }

    .MuiListItem-secondaryAction {
      padding-right: 0;
    }

    .MuiListItemAvatar-root {
      min-height: 30px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
