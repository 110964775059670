import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import formatter from 'services/formatter'
import { getCollectRequest } from 'store/modules/users/schedules/reducer'
import { Add } from '@material-ui/icons'
import { useMediaQuery } from '@material-ui/core'
import {
  Container,
  ContainerButton,
  Divider,
  ContainerWeights,
  WeightText,
  StyledButton,
  StyledIconButton,
} from './styles'
export function ScheduleStatus(props) {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const weight = useSelector((state) => state.users.schedules.active.weight)
  const selectedWeight = useSelector(
    (state) => state.users.schedules.selectedWeight
  )

  useEffect(() => {
    dispatch(getCollectRequest())
  }, [dispatch])

  const withoutSelectedWeight = selectedWeight === 0

  const weightLabels = [
    { label: 'Peso Agendamento', value: weight },
    { label: 'Peso Selecionado', value: selectedWeight, className: 'fill' },
    { label: 'Peso Total', value: weight + selectedWeight },
  ]

  return (
    <Container>
      <ContainerButton>
        {sm ? (
          <StyledIconButton
            color="primary"
            disabled={withoutSelectedWeight}
            onClick={props.onPressAction}
          >
            <Add />
          </StyledIconButton>
        ) : (
          <StyledButton
            disabled={withoutSelectedWeight}
            onClick={props.onPressAction}
          >
            Incluir no Agendamento
          </StyledButton>
        )}
      </ContainerButton>
      <Divider />
      <ContainerWeights>
        {weightLabels.map((weight, idx) => (
          <WeightText className={weight.className} key={idx}>
            <span>{weight.label}</span>
            <span>{formatter(weight.value).toDecimal()}</span>
          </WeightText>
        ))}
      </ContainerWeights>
    </Container>
  )
}

ScheduleStatus.propTypes = {
  onPressAction: PropTypes.func,
}

export default ScheduleStatus
