import styled from 'styled-components'
import { colors } from '../../theme'
import { Language } from '../Icons'

import MuiMenu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'

export const Container = styled.div`
  margin-left: 20px;

  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
`

export const ActiveLanguage = styled.span`
  height: 15px;
  min-width: 15px;
  top: 8px;
  right: 2px;

  border-radius: 10px;
  background-color: ${colors.color600};

  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: ${colors.white};
    font-size: 10px;
    font-weight: bold;
  }
`

export const LanguageIcon = styled(Language)`
  margin: auto;
  fill: ${colors.white};
`

export const Menu = styled(MuiMenu)``
export const MenuItem = styled(MuiMenuItem)`
  font-size: 14px;
`
