import { all, put, call, takeLatest } from 'redux-saga/effects'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { copyObject } from 'services/utils'
import formatter from 'services/formatter'
import {
  getOrdersFailure,
  getScheduleOrdersRequest,
  getOrdersRequest,
  getOrdersSuccess,
} from './reducer'

function* getOrders() {
  try {
    const response = yield call(api.get, apiEndPoints.user.ordersForCollect)
    yield put(getOrdersSuccess(response.data))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os pedidos!')
    yield put(getOrdersFailure())
  }
}

function* getScheduleOrders({ payload }) {
  try {
    const draft = copyObject(payload || {})
    draft.ped_data_emissao = formatter(draft.ped_data_emissao).toSimpleDate()

    const response = yield call(api.get, apiEndPoints.user.ordersForSchedule, {
      params: draft,
    })
    yield put(getOrdersSuccess(response.data))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os pedidos!')
    yield put(getOrdersFailure())
  }
}

export default all([
  takeLatest(getOrdersRequest.type, getOrders),
  takeLatest(getScheduleOrdersRequest.type, getScheduleOrders),
])
