import apiEndPoints from 'consts/apiEndPoints'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import {
  getProductsRequest,
  getProductsFailure,
  getProductsSuccess,
  getBrandsRequest,
  getBrandsSuccess,
  getBrandsfailure,
  getLinesFailure,
  getLinesRequest,
  getLinesSuccess,
  getFormatsRequest,
  getFormatSuccess,
  getUnitOfMeasuresRequest,
  getUnitOfMeasuresSuccess,
} from './reducer'

import { store } from '../../../..'
import { isNotEmptyArray } from 'services/utils'

const spCustomerProducts = {
  cod_cliente: '',
  id_cia: 1,
  id_marca: 'Todas',
  id_esp_superficie: 'Todas',
  id_linha_produtos: 'Todas',
  dsc_tamanho_produtos: 'Todos',
  cod_produto: '',
  dsc_produto: '',
  cod_representante: 'Todos',
  id_cia_fatura: 1,
  amostra: 0,
  tipo: 'C',
  orcamento: 1,
  somente_orc: 0,
  produtos_b: 0,
}

const getProfileId = () => store.getState().user.profile.web_company.cod_empresa

function* getProducts({ payload }) {
  try {
    const params = { ...spCustomerProducts }

    const filters = payload || {}
    const brands = filters.brand
    const formats = filters.format
    const lines = filters.line

    params.cod_cliente = getProfileId()
    params.somente_orc = Number(filters.somente_orc || 0)
    params.produtos_b = Number(filters.produtos_b || 0)
    params.dsc_produto = filters.dsc_produto || ''

    if (params.somente_orc === 0) {
      params.id_marca = isNotEmptyArray(brands)
        ? brands.join(',')
        : params.id_marca

      params.dsc_tamanho_produtos = isNotEmptyArray(formats)
        ? formats.map((f) => `''${f}''`).join(',')
        : params.dsc_tamanho_produtos

      params.id_linha_produtos = isNotEmptyArray(lines)
        ? lines.join(',')
        : params.id_linha_produtos
    }

    const response = yield call(
      api.post,
      apiEndPoints.miscellaneous.customerProducts,
      { filtros: params }
    )

    yield put(getProductsSuccess(response.data))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os produtos!')
    yield put(getProductsFailure())
  }
}

function* getBrands() {
  try {
    const profileId = getProfileId()
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.brands(profileId)
    )
    const brands = response.data.map((b) => ({ ...b, label: b.descr }))

    yield put(getBrandsSuccess(brands))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as marcas!')
    yield put(getBrandsfailure())
  }
}

function* getLines() {
  try {
    const response = yield call(api.get, apiEndPoints.miscellaneous.lines)
    const lines = response.data.map((l) => ({
      ...l,
      label: l.dsc_linha_produtos,
    }))

    yield put(getLinesSuccess(lines))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as linhas!')
    yield put(getLinesFailure())
  }
}

function* getFormats() {
  try {
    const response = yield call(api.get, apiEndPoints.miscellaneous.formats)

    const formats = response.data.filter((f) => f.id !== '')
    yield put(getFormatSuccess(formats))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os formatos!')
  }
}

function* getUnitOfMeasures() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.unitOfMeasures
    )

    yield put(getUnitOfMeasuresSuccess(response.data))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as unidades de medida!')
  }
}

export default all([
  takeLatest(getProductsRequest.type, getProducts),
  takeLatest(getBrandsRequest.type, getBrands),
  takeLatest(getLinesRequest.type, getLines),
  takeLatest(getFormatsRequest.type, getFormats),
  takeLatest(getUnitOfMeasuresRequest.type, getUnitOfMeasures),
])
