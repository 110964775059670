import { ptBR } from './pt-BR'
import { en } from './en-US'
import { es } from './es'

export const messages = {
  ...ptBR,
  ...en,
  ...es,
}

export default messages
