import React, { useCallback } from 'react'
import clsx from 'clsx'
import { sum } from 'ramda'

import formatter from 'services/formatter'

import { Table, TableCell, DescriptionText } from 'components/index'
import * as SC from '../../styles'

export function BilledOrdersTable({ data }) {
  // ╦ ╦╔╦╗╦╦  ╔═╗
  // ║ ║ ║ ║║  ╚═╗
  // ╚═╝ ╩ ╩╩═╝╚═╝
  const getDetailPanel = ({ row: order, show }) => {
    if (!show) return null

    return (
      <>
        <SC.TableRow>
          <TableCell colSpan={3}>
            <DescriptionText
              label="TRANSPORTADORA:"
              value={order.transportadora}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <DescriptionText label="FONE:" value={order.emp_telefone} />
          </TableCell>
          <TableCell colSpan={3}>
            <DescriptionText label="MOTORISTA:" value={order.motorista} />
          </TableCell>
          <TableCell colSpan={2}>
            <DescriptionText label="FONE:" value={order.brd_fone_motorista} />
          </TableCell>
          <TableCell colSpan={2}>
            <DescriptionText
              label="COND. PAGTO.:"
              value={order.desc_condpagto}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <DescriptionText label="FICHA CRÉDITO:" value={order.cec_num_doc} />
          </TableCell>
        </SC.TableRow>
      </>
    )
  }

  const displayTotal = useCallback(({ data, reportTitle }) => {
    const sumOf = (field) =>
      String(sum(data.map((order) => (order[field] ? order[field] : 0))))

    return (
      <>
        <SC.TableRow className="highlightText">
          <TableCell role="button" colSpan={1} />
          <TableCell colSpan={5} />
          <TableCell colSpan={3} align="right">
            {reportTitle}
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            {formatter(sumOf('qtdade')).toDecimal(2)} m²
          </TableCell>
          <TableCell colSpan={2} />
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            R$ {formatter(sumOf('valor')).toDecimal(2)}
          </TableCell>
          <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
            R$ {formatter(sumOf('cnf_valor_com_st')).toDecimal(2)}
          </TableCell>
        </SC.TableRow>
      </>
    )
  }, [])

  const renderProductDescription = ({ rowData: order }) => {
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        {order.cod_produto} - {order.dsc_abreviado}
      </span>
    )
  }

  // ╔╦╗╔═╗╦╔╗╔
  // ║║║╠═╣║║║║
  // ╩ ╩╩ ╩╩╝╚╝
  return (
    <Table
      groupBy="cod_pedido"
      size="medium"
      data={data}
      components={{
        Footer: displayTotal,
        GroupFooter: displayTotal,
      }}
      columns={[
        {
          title: 'NOTA FISCAL',
          field: 'nf_numero',
          cellFormat: 'number',
        },
        { title: 'SAÍDA', field: 'nf_data_saida', align: 'center' },
        { title: 'PEDIDO.', field: 'cod_pedido' },
        { title: 'SEU PED.', field: 'ped_seupedido' },
        {
          title: 'REPRESENTANTE',
          field: 'sigla_rep',
        },
        {
          title: 'PRODUTO',
          field: 'dsc_abreviado',
          renderCell: renderProductDescription,
        },
        { title: 'REF.', field: 'prd_referencia', align: 'center' },
        {
          title: 'UN.',
          field: 'dsc_un_medidas',
        },
        {
          title: 'QTDE',
          field: 'qtdade',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'VALOR UN.',
          field: 'it_valor',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'VALOR DESC.',
          field: 'cnf_valor_desconto',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'VALOR S/ IMP.',
          field: 'valor',
          align: 'right',
          cellFormat: 'decimal',
        },
        {
          title: 'VALOR C/ IMP.',
          field: 'cnf_valor_com_st',
          align: 'right',
          cellFormat: 'decimal',
        },
      ]}
      detailPanel={getDetailPanel}
      detailPanelIcon={({ open, ...props }) => (
        <SC.DetailIcon className={clsx({ open })} {...props} />
      )}
    />
  )
}

export default React.memo(BilledOrdersTable)
