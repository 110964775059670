import React, { useEffect, useState } from 'react'
import { formatISO } from 'date-fns'
import {
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Button } from '@material-ui/core'
import { ChevronLeft, Print } from '@material-ui/icons'

import api from 'services/api'
import formatter from 'services/formatter'
import handleErrors from 'services/handleErrors'
import apiEndPoints from 'consts/apiEndPoints'
import * as medias from 'consts/medias'
import { themeForReport } from 'theme'

import logoColorido from 'assets/images/logo-colorido.png'

import Dialog from 'components/Dialog'
import SplashScreen from 'components/SplashScreen'
import {
  Buttons,
  DescriptionContainer,
  Container,
  LoaderContainer,
} from './styles'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const CLASSES_LIMPABILIDADE = [
  '1.Impossibilidade da Remoção da Mancha',
  '2.Ácido Clorídrico',
  '3.Produto Abrasivo Forte',
  '4.Detergente Convencional',
  '5.Água Quente',
]

const RESISTENCIA_AGENTES_QUIMICOS = [
  'Produto químico doméstico e tratamento de água',
  'Ácido e álcalis de baixa concentração',
  'Ácido e álcalis de alta concentração',
  'A - Resistência Química Alta / B - Resistência Química Média / C - Resistência Química Baixa',
]

export function ProductDataSheet({ current, ...props }) {
  // ╦ ╦╔═╗╔═╗╦╔═╔═╗
  // ╠═╣║ ║║ ║╠╩╗╚═╗
  // ╩ ╩╚═╝╚═╝╩ ╩╚═╝
  const sm = useMediaQuery(medias.sm)

  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState({})

  useEffect(() => {
    const initializeDataSheet = async () => {
      try {
        const response = await api.post(
          apiEndPoints.queries.product_data_sheet(current.cod_prod)
        )
        setProduct(response.data[0])
      } catch (error) {
        handleErrors(
          error,
          'Não foi possível buscar a ficha técnica do produto!'
        )
      }

      setLoading(false)
    }

    initializeDataSheet()
  }, [current])

  // ╦ ╦╔═╗╔╗╔╔╦╗╦  ╔═╗╦═╗╔═╗
  // ╠═╣╠═╣║║║ ║║║  ║╣ ╠╦╝╚═╗
  // ╩ ╩╩ ╩╝╚╝═╩╝╩═╝╚═╝╩╚═╚═╝
  const handlePrint = () => {
    window.print()
  }

  return (
    <Dialog
      fullWidth
      title={`ESPECIFICAÇÃO TÉCNICA PRODUTO ${product?.cod_produto}`}
      fullScreen={sm}
      maxWidth="lg"
      actions={
        <>
          <Buttons>
            <Button
              size="medium"
              variant="text"
              color="primary"
              onClick={props.onClose}
              startIcon={<ChevronLeft />}
            >
              Voltar
            </Button>
            <Button
              size="medium"
              variant="contained"
              endIcon={<Print />}
              color="primary"
              onClick={handlePrint}
            >
              Imprimir
            </Button>
          </Buttons>
        </>
      }
      {...props}
    >
      {!loading && product ? (
        <ThemeProvider theme={themeForReport}>
          <Container className="printable">
            {/* ==== HEADER ==== */}

            <header>
              <div className="header__div">
                <Typography variant="body1">
                  <b>DELTA PORCELANATO</b>
                </Typography>
                <div className="header__image">
                  <img src={logoColorido} alt="logo" />
                </div>
              </div>
              <Grid container spacing={1} alignItems="center">
                <Description size={12} label="" value="" />
                <Description size={12} label="" value="" />
                <Description size={12} label="" value="" />
                <Description size={12} label="" value="" />
                <Description
                  size={1}
                  label="Data"
                  value={formatter(formatISO(new Date())).toSimpleDate()}
                />
                <Description
                  size={1}
                  label="Hora"
                  value={formatter(
                    formatISO(new Date().getTime())
                  ).toTimeDate()}
                />
              </Grid>
              <div className="header__divider"></div>
            </header>

            {/* ==== ESPECIFICAÇÕES TÉCNICAS DO PRODUTO ==== */}
            <section>
              <Typography
                component="h5"
                variant="h5"
                align="center"
                style={{
                  borderBottom: 'none',
                  marginBottom: '0',
                }}
              >
                ESPECIFICAÇÕES TÉCNICAS DO PRODUTO
              </Typography>
            </section>
            {/* ==== INFORMAÇOES GERAIS ==== */}
            <section>
              <Typography component="h5" variant="h5">
                INFORMAÇÕES GERAIS
              </Typography>

              <Grid container spacing={1} alignItems="center">
                <Description
                  size={6}
                  label="Código NF"
                  value={product.cod_produto}
                />
                <Description
                  size={6}
                  label="Referência"
                  value={current?.classif}
                />
                <Description
                  size={12}
                  label="Descrição"
                  value={product.dsc_item}
                />
                <Description
                  size={6}
                  label="Superfície"
                  value={product.dsc_esp_superficie}
                />
                <Description
                  size={6}
                  label="Formato (cm)"
                  value={product.dsc_tamanho_produtos}
                />
                <Description size={6} label="Marca" value={product.dsc_marca} />
                <Description
                  size={6}
                  label="Tamanho Fabricação (mm)"
                  value={product.dsc_tamanho_fabric}
                />
                <Description
                  size={12}
                  label="Aplicação Técnica"
                  value={product.dsc_aplic_tec}
                />
                <Description
                  size={6}
                  label="Indicação de Uso/Variação"
                  value={`${product.dsc_indic_uso}/${product.prd_var_ton}`}
                />
                <Description
                  size={6}
                  label="Retificado (SIM / NÃO)"
                  value={product.dsc_retificado}
                />
                {/* <Description
                                    size={12}
                                    label="Link"
                                    value={product.prd_link_produto}
                                /> */}
              </Grid>
            </section>
            {/* ==== CARACTERÍSTICAS TÉCNICAS ==== */}
            <section>
              <Typography component="h5" variant="h5">
                CARACTERÍSTICAS TÉCNICAS
              </Typography>

              <Grid container spacing={1} alignItems="center">
                <Description
                  size={6}
                  label="Grupo de Absorção de Água"
                  value={product.dsc_absorcao}
                />
                <Description
                  size={6}
                  label="Natureza da Superfície"
                  value={product.dsc_nat_sup}
                />
                <Description
                  size={6}
                  label="Carga de Ruptura"
                  value={product.gra_res_ruptura}
                />
                <Description
                  size={6}
                  label="Módulo de Resistência à Flexão"
                  value={product.gra_res_flexao}
                />
                <Description
                  size={6}
                  label="Expansão por Umidade"
                  value={product.gra_exp_umidade}
                />
                <Description
                  size={6}
                  label="Coeficiente Atrito"
                  value={product.dsc_coef_atrito}
                />
                <Description
                  size={12}
                  label="Classe de Limpabilidade"
                  value={product.gra_res_manchamento}
                />
                <Description
                  size={12}
                  label=""
                  value={CLASSES_LIMPABILIDADE.join(' / ')}
                />
                <Description
                  size={6}
                  label="Resistência aos Agentes Químicos"
                  value=""
                />
                <Description size={6} label="Classificação" value="" />
                <Description
                  size={6}
                  label=""
                  value={RESISTENCIA_AGENTES_QUIMICOS[0]}
                />
                <Description
                  size={6}
                  label=""
                  value={product.gra_res_at_quimico}
                />
                <Description
                  size={6}
                  label=""
                  value={RESISTENCIA_AGENTES_QUIMICOS[1]}
                />
                <Description
                  size={6}
                  label=""
                  value={product.gra_res_at_quimico}
                />
                <Description
                  size={6}
                  label=""
                  value={RESISTENCIA_AGENTES_QUIMICOS[2]}
                />
                <Description
                  size={6}
                  label=""
                  value={product.gra_res_at_quimico}
                />
                <Description
                  size={6}
                  label=""
                  value={RESISTENCIA_AGENTES_QUIMICOS[3]}
                />
                <Description size={6} label="" value="" />
                <Description
                  size={6}
                  label="Acabamento Superficial"
                  value={product.dsc_esp_superficie}
                />
                <Description
                  size={6}
                  label="Rejunte Mínimo (mm)"
                  value={product.eti_rejunte}
                />
              </Grid>
            </section>
            {/* ==== INFORMAÇÕES DE EMBALAGEM ==== */}
            <section>
              <Typography component="h5" variant="h5">
                INFORMAÇÕES DE EMBALAGEM
              </Typography>

              <Grid container spacing={1} alignItems="center">
                <Description
                  size={6}
                  label="Peso da Caixa (kg)"
                  value={formatter(product.it_peso_liq_cx).toDecimal()}
                />
                <Description
                  size={6}
                  label="Peso do Pallet (kg)"
                  value={formatter(product.it_peso_bru_pallet).toDecimal()}
                />
                <Description
                  size={6}
                  label="M2 por Caixa"
                  value={formatter(product.prd_m2_caixa).toDecimal()}
                />
                <Description
                  size={6}
                  label="M2 do Pallet"
                  value={formatter(product.prd_m2_pallet).toDecimal()}
                />
                <Description
                  size={6}
                  label="Peça por Caixa (un)"
                  value={product.prd_pecas_caixa}
                />
                <Description
                  size={6}
                  label="Caixa por Pallet (un)"
                  value={product.ctp_caixa_pallet}
                />
                <Description
                  size={12}
                  label="Cód. Barras (GS1-13)"
                  value={product.it_cbarra}
                />
              </Grid>
            </section>
            {/* ==== INFORMAÇÕES FISCAIS ==== */}
            <section>
              <Typography component="h5" variant="h5">
                INFORMAÇÕES FISCAIS
              </Typography>

              <Grid container spacing={1} alignItems="center">
                <Description size={6} label="NCM" value={product.it_nbm} />
                <Description size={6} label="CEST" value={product.it_cest} />
                <Description
                  size={6}
                  label="Origem"
                  value={product.dsc_conteudo}
                />
                <Description
                  size={6}
                  label="IPI"
                  value={formatter(product.it_percipi / 100).toPercent()}
                />
              </Grid>
            </section>
          </Container>
        </ThemeProvider>
      ) : (
        <LoaderContainer>
          <SplashScreen
            style={{ position: 'initial' }}
            loadingText="Carregando ficha técnica do produto..."
          />
        </LoaderContainer>
      )}
    </Dialog>
  )
}

function Description({ value = '', size = 12, label, variant = 'body2' }) {
  return (
    <Grid className="description-grid-item" item sm={size} xs={12}>
      <DescriptionContainer>
        <Typography className="label" variant={variant}>
          {label}
          {label && value !== '' ? ':' : ''}
        </Typography>
        <Typography className="value" variant={variant}>
          {value}
        </Typography>
      </DescriptionContainer>
    </Grid>
  )
}

export default ProductDataSheet
