import styled from 'styled-components'

export const FormRow = styled.div`
  display: flex;

  .MuiAutocomplete-root,
  .MuiFormControl-root {
    margin-right: 9px;
    flex: 1;

    // CPF/CNPJ
    &.FormRow-document-number {
      max-width: 130px;
    }

    // ex: GFD-9173
    &.FormRow-plate {
      max-width: 120px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
