import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { confirmationDialog } from 'components/ConfirmationDialog'
import { apiEndPoints } from 'consts/apiEndPoints'
import {
  getCollectRequest,
  getCollectSuccess,
  getCollectFailure,
  createCollectRequest,
  createCollectSuccess,
  createCollectFailure,
  finishCollectRequest,
  finishCollectSuccess,
  finishCollectFailure,
  finishCollectCancel,
  updateCollectFailure,
  updateCollectRequest,
  updateCollectSuccess,
  removeCollectItemsRequest,
  issuedsRequest,
  issuedsSuccess,
  issuedsFailure,
  updateCollectItemRequest,
  updateCollectItemFailure,
  cancelCollectFailure,
  cancelCollectRequest,
  reOpenCollectRequest,
  reOpenCollectFailure,
  reOpenCollectSuccess,
  collectCountRequest,
  collectCountSuccess,
} from './reducer'
import { getOrdersRequest } from '../ordersForCollect/reducer'

function* getSchedule() {
  try {
    const response = yield call(api.get, apiEndPoints.user.schedules.root)
    const schedule = response.data

    yield put(getCollectSuccess(schedule))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar o agendamento')
    yield put(getCollectFailure())
  }
}

function* createSchedule({ payload }) {
  try {
    const schedules = payload.map((schedule) => ({
      cod_pedido: schedule.cod_pedido,
      id_cia: schedule.id_cia,
      id_itens: schedule.id_itens,
      id_coleta: schedule.id_coleta,
      id_un_medidas: schedule.id_un_medidas,
      cod_produto: schedule.cod_produto,
      qtd_saldo_disp: schedule.qtd_saldo_disp,
      id_agendamento: schedule.id_agendamento,
      peso_bru_disp: schedule.peso_bru_disp,
      prd_m2_caixa: schedule.prd_m2_caixa,
      itped_cx_pallet: schedule.itped_cx_pallet,
      qtd_pallet_disp: schedule.qtd_pallet_disp,
      qtd_caixas_disp: schedule.qtd_caixas_disp,
    }))

    yield call(api.post, apiEndPoints.user.schedules.root, {
      schedules,
    })

    const response = yield call(api.get, apiEndPoints.user.schedules.root)
    const schedule = response.data

    yield put(getCollectSuccess(schedule))
    yield put(getOrdersRequest())

    yield put(createCollectSuccess())
  } catch (error) {
    handleErrors(error, 'Não foi possível adicionar os pedidos ao agendamento')
    yield put(createCollectFailure())
  }
}

function* finishSchedule({ payload }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Finalizar Agendamento',
      'Confirma a finalização e envio desse Agendamento ?'
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(finishCollectCancel())
      return
    }

    const requestBody = {
      schedule_params: payload,
    }

    const response = yield call(
      api.post,
      apiEndPoints.user.orderSchedules.root,
      requestBody
    )

    const scheduleId = response.data[0].id_agendamento
    toast.success(`Agendamento ${scheduleId} enviado com sucesso`)

    yield put(finishCollectSuccess())
  } catch (error) {
    handleErrors(error, 'Não foi possível finalizar o agendamento')
    yield put(finishCollectFailure(error.response.data.not_available))
  }
}

function* updateSchedule({ payload }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Atualizar Agendamento',
      'Confirma a atualização dos dados do Agendamento ?'
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(updateCollectFailure())
      return
    }

    yield call(api.post, apiEndPoints.user.orderSchedules.updateParams, payload)
    toast.success('Agendamento atualizado com sucesso')

    yield put(updateCollectSuccess())
    yield put(issuedsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o agendamento')
    yield put(updateCollectFailure())
  }
}

function* removeScheduleItems({ payload }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Remover',
      'Deseja remover os items selecionados ?'
    )

    confirmationDialog.close()
    if (!confirm) return

    yield call(api.post, apiEndPoints.user.schedules.destroySelected, {
      schedules: payload,
    })

    const response = yield call(api.get, apiEndPoints.user.schedules.root)
    const collect = response.data

    yield put(getCollectSuccess(collect))
  } catch (error) {
    handleErrors(error, 'Não foi possível remover os items')
  }
}

function* updateScheduleItem({ payload }) {
  try {
    const { current, updateParams } = payload

    if (Number(updateParams.vpa_pallet) > Number(current.vpa_pallet)) {
      toast.error('Quantidade insuficiente')
      yield put(updateCollectItemFailure())

      return
    }

    yield call(api.patch, apiEndPoints.user.schedules.onMember(current.id), {
      schedules: updateParams,
    })

    const response = yield call(api.get, apiEndPoints.user.schedules.root)
    const schedule = response.data

    yield put(getCollectSuccess(schedule))
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o item')
    yield put(updateCollectItemFailure())
  }
}

function* issueds() {
  try {
    const response = yield call(api.get, apiEndPoints.user.orderSchedules.root)
    const issueds = response.data
    yield put(issuedsSuccess(issueds))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os agendamentos emitidos')
    yield put(issuedsFailure())
  }
}

function* cancel({ payload: collect }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Cancelar',
      `Confirma o cancelamento do agendamento ${collect.id_agendamento} ?`
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(cancelCollectFailure())
      return
    }

    yield call(api.post, apiEndPoints.user.orderSchedules.cancel, {
      id_agendamento: collect.id_agendamento,
    })

    toast.success(`Agendamento ${collect.id_agendamento} cancelado`)
    yield put(issuedsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível cancelar o agendamento')
    yield put(cancelCollectFailure())
  }
}

function* reopen({ payload: collect }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Reabrir',
      `Confirma a reabertura do agendamento ${collect.id_agendamento} ?`
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(reOpenCollectFailure())
      return
    }

    const response = yield call(
      api.post,
      apiEndPoints.user.orderSchedules.reopen,
      {
        id_agendamento: collect.id_agendamento,
      }
    )

    const collectParams = response.data
    yield put(reOpenCollectSuccess(collectParams))
    yield put(issuedsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível reabrir o agendamento')
    yield put(reOpenCollectFailure())
  }
}

function* count() {
  try {
    const response = yield call(api.post, apiEndPoints.user.schedules.count)
    yield put(collectCountSuccess(response.data))
  } catch (error) {
    handleErrors(error)
  }
}

export default all([
  takeLatest(createCollectRequest.type, createSchedule),
  takeLatest(finishCollectRequest.type, finishSchedule),
  takeLatest(updateCollectRequest.type, updateSchedule),
  takeLatest(getCollectRequest.type, getSchedule),
  takeLatest(removeCollectItemsRequest.type, removeScheduleItems),
  takeLatest(issuedsRequest.type, issueds),
  takeLatest(updateCollectItemRequest.type, updateScheduleItem),
  takeLatest(cancelCollectRequest.type, cancel),
  takeLatest(reOpenCollectRequest.type, reopen),
  takeLatest(collectCountRequest.type, count),
])
