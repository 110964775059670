import styled from 'styled-components'
import { colors } from '../../theme'

export const Container = styled.div`
  flex: 1;
  height: 100%;
  padding: 10px 8px;

  display: flex;
  flex-direction: column;

  &.loaderContainer {
    position: absolute;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      > span {
        color: ${colors.color500};
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
`

export const HeaderComponent = styled.div`
  > .ChipListSpacing {
    margin-bottom: 30px;
  }
  > .ChipList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div + div {
      margin: 3px;
    }
  }
`

export const ContentComponent = styled.div`
  > .Ranking-List {
    > div + div {
      margin-top: 23px;
    }
  }
`

export const FooterComponent = styled.div`
  margin-top: 28px;
`
