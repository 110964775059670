import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Yup, handleValidationError, validateWithSchema } from 'services/yup'
import { company } from 'consts/types'
import { isActive, isCustomer } from 'services/utils'
import { handleSaveCompany } from 'store/modules/admin/companies/reducer'
import { Form, FormTextField, FormCheckbox } from 'components/CoForm'
import {
  ComboboxCompanyTypes,
  ComboboxCarriers,
  ComboboxCustomers,
} from 'components/Combobox'
import { Dialog } from 'components/Dialog'
import { TabNavigation, Tabs } from 'components/TabNavigation'
import {
  StyledButton,
  FormWrapper,
  Panel,
  Container,
  StyledList,
} from './styles'
import AccessButton from './AccessButton'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const DEFAULT_COMPANY_TYPE = company.TRANSPORTADORA

const initialFormData = {
  wee_tipo: DEFAULT_COMPANY_TYPE,
  wee_email: '',
  wee_ativo: true,
  company: {
    id: '',
  },
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const schema = Yup.object().shape({
  wee_tipo: Yup.string().required('Informe o tipo da empresa'),
  wee_email: Yup.string().required('Informe o email da empresa'),
  company: Yup.object().shape({
    id: Yup.string()
      .required('Informe a empresa (Cliente, Representante ou Transportadora)')
      .nullable(),
  }),
})

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormEdit({ current: webCompany, onClose, ...props }) {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.admin.companies.loading)

  const formRef = useRef(null)
  const [companyType, setCompanyType] = useState(DEFAULT_COMPANY_TYPE)
  const isEditing = Boolean(webCompany.id)

  useEffect(() => {
    if (webCompany.id) {
      const company = { ...webCompany }
      company.wee_ativo = isActive(webCompany.wee_ativo)

      setCompanyType(company.wee_tipo)
      formRef.current.setData(company)
    }
  }, [webCompany])

  const handleCompanyType = (_, companyType) => {
    setCompanyType(companyType?.value || DEFAULT_COMPANY_TYPE)
    formRef.current.setFieldValue('company.id', null)
  }

  const handleSave = () => {
    const values = formRef.current.values
    validateWithSchema(
      schema,
      values,
      (formValues) => dispatch(handleSaveCompany({ formValues, webCompany })),
      handleValidationError(formRef.current)
    )
  }

  const renderCurrentCombobox = () => {
    const props = {
      label: '',
      name: 'company.id',
      disabled: isEditing,
      inputProps: {
        size: 'medium',
        variant: 'standard',
      },
    }

    switch (companyType) {
      case company.TRANSPORTADORA:
        props.label = 'Escolha a Transportadora'
        return <ComboboxCarriers {...props} />
      case company.CLIENTE:
        props.label = 'Escolha o Cliente'
        props.defaultValue = isEditing
          ? webCompany.company.emp_razao_social
          : null
        return <ComboboxCustomers {...props} />
      default:
        return null
    }
  }

  const isCustomerCompany = isEditing ? isCustomer(webCompany.wee_tipo) : false

  const title = isEditing
    ? webCompany.company.emp_razao_social
    : 'Cadastrar Empresa'

  return (
    <Form formRef={formRef} initialData={initialFormData} schema={schema}>
      <Dialog
        {...props}
        fullWidth
        title={title}
        actions={
          <>
            <StyledButton
              className="success light regular"
              loading={loading}
              label="Salvar"
              variant="contained"
              onClick={handleSave}
            />
            <StyledButton
              className="error light regular"
              label="Cancelar"
              variant="contained"
              onClick={onClose}
            />
          </>
        }
      >
        <TabNavigation>
          <Container>
            {isCustomerCompany && (
              <Tabs
                className="unstyled"
                tabs={[
                  { value: 0, label: 'Perfil' },
                  { value: 1, label: 'Acessos' },
                ]}
              />
            )}
            <Panel index={0}>
              <FormWrapper>
                <ComboboxCompanyTypes
                  onAfterChange={handleCompanyType}
                  label="Tipo"
                  name="wee_tipo"
                  disabled={isEditing}
                  inputProps={{
                    size: 'medium',
                    variant: 'standard',
                  }}
                />
                {renderCurrentCombobox(companyType)}
                <FormTextField
                  name="wee_email"
                  label="E-mail"
                  type="email"
                  size="medium"
                  variant="standard"
                />
                {isEditing && (
                  <div>
                    <FormCheckbox name="wee_ativo" label="Ativo" />
                  </div>
                )}
              </FormWrapper>
            </Panel>
            <Panel index={1} className="Panel-accesses">
              <StyledList>
                <AccessButton />
              </StyledList>
            </Panel>
          </Container>
        </TabNavigation>
      </Dialog>
    </Form>
  )
}

FormEdit.propTypes = {
  current: PropTypes.object,
}

export default FormEdit
