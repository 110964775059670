import React from 'react'
import { StyledTypography } from './styles'

export function DescriptionText({ label, value = '' }) {
  return (
    <div>
      <StyledTypography className="StyledTypography-label">
        {label}
      </StyledTypography>
      <StyledTypography>{value}</StyledTypography>
    </div>
  )
}
