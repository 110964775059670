import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import formatter from 'services/formatter'
import { calculateProductQuantity } from 'components/Product'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Button from 'components/Button'
import Dialog from 'components/Dialog'
import { Form, FormTextField } from 'components/CoForm'

import { StyledLink } from './styles'

export function OrderItemFormEdit({ current, onSave, ...props }) {
  const formRef = useRef()

  useEffect(() => {
    if (current) {
      formRef.current.setData({
        id: current.id,
        pallets: current.no_pallets,
        boxes: current.total_boxes,
        quantity: current.itped_qtd,
        formatted_quantity: formatter(current.itped_qtd).toDecimal(),
      })
    }
  }, [current])

  const handleCalculateQuantity = (e) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value
    const product = current.product

    const newValues = calculateProductQuantity(
      fieldName,
      fieldValue,
      product?.ctp_caixa_pallet,
      product?.prd_m2_caixa
    )

    formRef.current.setData({ ...newValues })
  }

  const handleSave = () => {
    const values = formRef.current.values
    onSave(values)
  }

  const title = current
    ? `${current.product.cod_produto} - ${current.product.dsc_item_ingles}`
    : '...'

  return (
    <Form formRef={formRef}>
      <Dialog
        {...props}
        fullWidth
        title={title}
        maxWidth="xs"
        actions={
          <>
            <Button
              className="large success light"
              label="Atualizar"
              onClick={handleSave}
            />
            <Button
              className="large error light"
              label="Cancelar"
              onClick={props.onClose}
            />
          </>
        }
      >
        <Grid
          container
          spacing={2}
          style={{
            padding: '1rem 0',
          }}
        >
          <Grid item xs={12}>
            <StyledLink
              rel="noreferrer"
              target="_blank"
              href={current?.product.prd_link_produto}
            >
              <img src={current?.product.prd_link_img_produto} alt="/" />
            </StyledLink>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              {current?.product.dsc_tamanho_produtos} {' - '}
              {current?.product.line.dsc_linha_produtos}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {current?.rep_nome}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              fullWidth
              name="pallets"
              label="Pallets"
              type="number"
              size="medium"
              onChange={handleCalculateQuantity}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              fullWidth
              name="boxes"
              label="Caixas"
              type="number"
              size="medium"
              onChange={handleCalculateQuantity}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="formatted_quantity"
              label="Quantidade"
              size="medium"
              disabled
            />
          </Grid>
        </Grid>
      </Dialog>
    </Form>
  )
}

OrderItemFormEdit.propTypes = {
  current: PropTypes.object,
  onSave: PropTypes.func,
}

export default OrderItemFormEdit
