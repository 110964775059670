import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { getPossibleDatesRequest } from 'store/modules/comuns/possibleDates/reducer'
import { findMaxDate, dateNotInDates } from 'services/utils'

import { FormDatePicker } from 'components/CoForm'

export function DatePickerCollect({ type, ...props }) {
  const dispatch = useDispatch()
  const possibleDates = useSelector((state) => state.comuns.possibleDates.dates)

  useEffect(() => {
    dispatch(getPossibleDatesRequest({ type }))
  }, [dispatch, type])

  const shouldDisableDate = (date) => {
    if (possibleDates.length > 0) return dateNotInDates(date, possibleDates)
    else return false
  }

  return (
    <FormDatePicker
      disableToolbar
      disablePast
      variant="inline"
      shouldDisableDate={shouldDisableDate}
      maxDate={findMaxDate(possibleDates)}
      {...props}
    />
  )
}

DatePickerCollect.propTypes = {
  type: PropTypes.oneOf(['collect', 'schedule']),
}

DatePickerCollect.defaultProps = {
  type: 'collect',
}

export default DatePickerCollect
