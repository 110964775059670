import styled from 'styled-components'
import { colors } from 'theme'
import { Error as ErrorIcon } from '@material-ui/icons'

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  > font,
  svg {
    margin-left: 5px;
    color: ${colors.notification};
  }
`

export function ErrorMessage({ message, show, ...props }) {
  return (
    show && (
      <ErrorContainer>
        <ErrorIcon />
        <font {...props}>{message}</font>
      </ErrorContainer>
    )
  )
}

export default ErrorMessage
