import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import { groupWithCustomer, notEmpty } from 'services/utils'
import { getItemId } from 'services/schedules'
import {
  getCollectRequest,
  removeCollectItemsRequest,
} from 'store/modules/users/schedules/reducer'
import { useMediaQuery } from '@material-ui/core'
import { Trash } from 'components/Icons'
import { ErrorMessage } from 'components/index'
import TableSchedule from 'components/Cliente/TableSchedule'
import {
  Container,
  Header,
  StyledCheckBox,
  StyledButton,
  CollectList,
} from './styles'

import FormSchedule from './Form'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function SchedulePage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const scheduleItems = useSelector(
    (state) => state.users.schedules.active.schedule
  )
  const scheduleItemsWithError = useSelector(
    (state) => state.users.schedules.errors
  )

  const tablesRef = useRef([])

  useEffect(() => {
    dispatch(getCollectRequest())
  }, [dispatch])

  // responsible effect for selecting the items with error in the collection
  useEffect(() => {
    const items = scheduleItemsWithError.map(getItemId).map((id) => String(id))
    tablesRef.current.forEach((provided) => {
      provided.selectBy(
        (tableItem) => items.includes(String(tableItem.id)),
        true,
        { withError: true }
      )
    })
  }, [scheduleItemsWithError])

  function removeSelectedItems() {
    let selected = []
    tablesRef.current.forEach((provided) => {
      selected.push(provided.getSelectedItems())
    })

    dispatch(removeCollectItemsRequest(selected.flat()))
  }

  function selectAll(_, checked) {
    tablesRef.current.forEach((methods) => {
      methods.selectAll(checked)
    })
  }

  const groups = groupWithCustomer(scheduleItems)

  return notEmpty(scheduleItems) ? (
    <Container>
      <CollectList>
        <Header>
          <StyledCheckBox onChange={selectAll} label="Selecionar Todos" />
          <ErrorMessage
            show={notEmpty(scheduleItemsWithError)}
            message="Alguns itens não possuem saldo suficiente"
          />
          <StyledButton
            className="red"
            label={sm ? 'Excluir' : 'Excluir Selecionados'}
            endIcon={<Trash />}
            onClick={removeSelectedItems}
          />
        </Header>
        {groups.map((orders, index) => (
          <TableSchedule
            key={index}
            data={orders}
            getRef={(provided) => (tablesRef.current[index] = provided)}
          />
        ))}
      </CollectList>
      <FormSchedule />
    </Container>
  ) : (
    <Container>
      <span className="empty-text">O Agendamento não possui nenhum item</span>
    </Container>
  )
}
