import styled from 'styled-components'
import { md } from 'consts/medias'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'

export const StyledFormControl = styled(FormControl)``

export const StyledFormLabel = styled(FormLabel)`
  font-size: 12px;
`

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${md} {
    flex-direction: column;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
  }
`

export const StyledRadio = styled(Radio)``
