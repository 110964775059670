import styled from 'styled-components'

import * as medias from 'consts/medias'
import { colors } from 'theme'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

export const StyledDialog = styled(Dialog)`
  &.MuiDialogContent-small .MuiDialogContent-root {
    padding: 8px;
  }

  @media print {
    .MuiDialog-scrollPaper {
      align-items: flex-start;
    }
    .MuiPaper-root {
      width: 100%;
      height: 100%;
      padding: 0 20px;
    }

    &.printable .MuiDialogContent-root {
      padding: 0;
      margin: 0;
      * {
        visibility: initial;
      }
    }
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  position: relative;
  background-color: ${colors.color100};

  h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
  }

  // draggable-effect
  &.draggable {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  @media ${medias.md} {
    h2 {
      text-align: center;
      white-space: normal;
      font-size: 14px;
    }
  }

  @media print {
    display: none;
  }
`

export const StyledDialogContent = styled(DialogContent)``

export const StyledDialogActions = styled(DialogActions)``
