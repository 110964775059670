import React, { useRef } from 'react'
import { Grid } from '@material-ui/core'
import { Search, Delete } from '@material-ui/icons'

import SideBar from 'components/SideBar'
import Button from 'components/Button'
import FilterTitle from 'components/FilterTitle'
import StyledFilterForm from 'components/StyledFilterForm'

import { FormTextField, FormRadioGroup } from 'components/CoForm'
import ComboboxBrands from 'components/Combobox/Brands'
import ComboboxFormats from 'components/Combobox/Formats'
import ComboboxUnitOfMeasures from 'components/Combobox/UnitOfMeasures'
import ComboboxShipmentUnities from 'components/Combobox/ShipmentUnities'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const OUT_OF_LINE_OPTIONS = [
  { label: 'Todos', value: '-1' },
  { label: 'Em Linha', value: '0' },
  { label: 'Fora de Linha', value: '1' },
]

const initialData = {
  it_fora_linha: '0',
}

export default function Filter({ onSubmit, onClear, ...props }) {
  const formRef = useRef(null)

  const handleSubmit = (values) => {
    const draft = { ...values }
    onSubmit(draft)
  }

  const handleClear = () => {
    formRef.current.setValues({ ...initialData })
    onClear()
  }

  return (
    <SideBar
      {...props}
      ContentComponent={
        <StyledFilterForm
          initialData={{ ...initialData }}
          formRef={formRef}
          onSubmit={handleSubmit}
        >
          <FilterTitle>Pesquisar</FilterTitle>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ComboboxShipmentUnities
                fullWidth
                name="id_cia"
                label="Companhia"
                disableClearable
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                name="filtro_produto"
                label="Produto"
                placeholder="Código ou decrição..."
              />
            </Grid>
            {/* <Grid item xs={12}>
                            <FormTextField
                                fullWidth
                                disabled
                                name="cod_barras"
                                label="Código de Barras"
                            />
                        </Grid> */}
            <Grid item xs={12}>
              <ComboboxBrands fullWidth name="id_marca" label="Marca" />
            </Grid>
            <Grid item xs={8}>
              <ComboboxFormats
                fullWidth
                name="dsc_tamanho_produtos"
                label="Formato"
              />
            </Grid>
            {/* <Grid item xs={12}>
                            <FormTextField
                                fullWidth
                                disabled
                                name="dsc_esp_superficie"
                                label="Superfície"
                            />
                        </Grid> */}
            <Grid item xs={4}>
              <ComboboxUnitOfMeasures
                fullWidth
                name="id_un_medidas"
                label="Un"
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                name="it_fora_linha"
                label="Status"
                options={OUT_OF_LINE_OPTIONS}
              />
            </Grid>
          </Grid>

          <div className="buttons">
            <Button type="submit" label="Buscar" startIcon={<Search />} />
            <Button
              type="button"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClear}
            />
          </div>
        </StyledFilterForm>
      }
    />
  )
}
