import { toast } from 'react-toastify'

export const handleErrors = (err, alternativeMessage) => {
  try {
    const errors = err.isAxiosError
      ? typeof err.response.data === 'object'
        ? err.response.data.errors
          ? err.response.data.errors
          : err.response.data
        : alternativeMessage
      : err

    if (typeof errors === 'object') {
      Object.values(errors).forEach((error) => {
        handleErrors(error, alternativeMessage)
      })
    } else
      toast.error(errors, {
        toastId: `toast-${errors}`,
      })
  } catch (error) {
    toast.error(alternativeMessage)
  }
}

export default handleErrors
