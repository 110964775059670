import React, { createContext, useContext, useState, useCallback } from 'react'
import { lensPath, lensProp, set, view, split, merge } from 'ramda'
import PropTypes from 'prop-types'

import { handleValidationError, validateWithSchema } from 'services/yup'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const DEFAULT_SEPARATOR = '.'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

export const FormContext = createContext({ values: {}, valid: false })
FormContext.displayName = 'FormContext'

export const useCurrentForm = () => useContext(FormContext)

export const useForm = ({ readOnly = false, initialValues = {} } = {}) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})

  const handleChange = (separator) => (event) => {
    if (!readOnly) {
      const {
        target: { name, value },
      } = event

      setFieldValue(name, value, separator)
    }
  }

  const getFieldValue = (fieldPath, separator = DEFAULT_SEPARATOR) =>
    view(lensPath(split(separator, fieldPath)), values)

  const setData = (newData) => setValues((previous) => merge(previous, newData))

  const setFieldValue = (fieldPath, newValue, splitter = DEFAULT_SEPARATOR) => {
    const fieldLens = lensPath(split(splitter, fieldPath))
    setValues(set(fieldLens, newValue))
  }

  const setFieldError = (errorPath, value) => {
    const fieldLens = lensProp(errorPath)
    setErrors(set(fieldLens, value))
  }

  const reset = useCallback(() => {
    setValues(initialValues)
    setErrors({})
  }, [initialValues])

  return {
    valid: true,
    values,
    errors,
    readOnly,
    setData,
    setValues,
    setErrors,
    setFieldError,
    getFieldValue,
    setFieldValue,
    handleChange,
    reset,
  }
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormProvider({ form, children }) {
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>
}

export function Form({
  formRef,
  children,
  onSubmit,
  initialData,
  schema,
  ...props
}) {
  const form = useForm({ initialValues: initialData })

  function handleSubmit(event, values, { reset }) {
    event.preventDefault()
    if (schema) {
      form.setErrors({})
      validateWithSchema(
        schema,
        values,
        (values) => onSubmit(values, { reset }, event),
        handleValidationError(form)
      )
    } else onSubmit(values, { reset }, event)
  }

  return (
    <FormProvider form={form}>
      <FormContext.Consumer>
        {({ values, reset } = {}) => (
          <form
            ref={(node) =>
              formRef
                ? (formRef.current = {
                    ...form,
                    FormComponent: node,
                  })
                : null
            }
            onSubmit={(e) => handleSubmit(e, values, { reset })}
            {...props}
          >
            {children}
          </form>
        )}
      </FormContext.Consumer>
    </FormProvider>
  )
}

Form.propTypes = {
  schema: PropTypes.object,
  initialData: PropTypes.object,
  formRef: PropTypes.exact({
    current: PropTypes.any,
  }).isRequired,
  onSubmit: PropTypes.func,
}

export * from './components'
export { FormRow } from './styles'
