import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useProfile } from 'hooks/useProfile'

import { PATHS } from 'consts/paths'
import { order } from 'consts/types'
import { translate } from '_i18n'
import { collectCountRequest } from 'store/modules/users/schedules/reducer'

import {
  ViewList as Budget,
  EventNote as Schedule,
  Launch as Release,
} from '@material-ui/icons'
import { MenuItem } from './MenuItem'
import { Queries, Home } from '../Icons'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'

export const CustomerMenuItems = () => {
  const dispatch = useDispatch()
  const profile = useProfile()

  const collectCount = useSelector((state) => state.users.schedules.count)
  const collect = useSelector((state) => state.users.schedules.active.schedule)

  useEffect(() => {
    dispatch(collectCountRequest())
  }, [dispatch, collect])

  if (!profile.isCustomer) return null

  return (
    <>
      <MenuItem
        Icon={Home}
        label={translate('toolbar.menus.home')}
        path={PATHS.user.home}
      />
      <UserRight id={RIGHTS.incluiAgendamento.id}>
        <MenuItem
          Icon={Schedule}
          label="Agenda"
          path={PATHS.user.schedule}
          badgeContent={collectCount}
          badgePosition={{
            top: 6,
            right: 5,
            topactive: 6,
            rightactive: 6,
          }}
        />
      </UserRight>
      <UserRight id={RIGHTS.incluiOrcamento.id}>
        <MenuItem
          Icon={Budget}
          label="Orçamento"
          path={PATHS.user.orders(order.BUDGET)}
        />
      </UserRight>
      <MenuItem
        Icon={Queries}
        label="Consulta"
        path={PATHS.user.queries.root}
      />
      <UserRight id={RIGHTS.consultaLancamentos.id}>
        <MenuItem
          Icon={Release}
          label="Lançamento"
          path={PATHS.user.releases}
        />
      </UserRight>
    </>
  )
}

export default CustomerMenuItems
