import React, { useRef, useEffect, useState } from 'react'
import { slice, map, prop, isEmpty, sum } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'

import * as medias from 'consts/medias'
import { colors } from 'theme'
import { groupWithCustomer } from 'services/utils'
import { getOrdersRequest } from 'store/modules/users/ordersForCollect/reducer'
import {
  createCollectRequest,
  updateSelectedWeight,
} from 'store/modules/users/collections/reducer'

import { useMediaQuery } from '@material-ui/core'
import { Content, CollectStatus } from 'components'
import { LoaderArea } from './styles'

import ScaleLoader from 'react-spinners/ScaleLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import TableCustomerOrders from 'components/Transportadora/TableOrder'
import Filter from './Filter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const LIMITER = 100

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const sumWeight = (list) => sum(map(prop('peso_bru'), list))

const getSelectedItems = (tables) => {
  let selectedItems = []

  tables.forEach((table) => {
    selectedItems.push(table.getSelectedItems())
  })

  return selectedItems.flat()
}

const removeTablesSelection = (tables) =>
  tables.forEach((table) => table.selectAll(false))

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function OrdersPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const tablesRef = useRef([])
  const [tableLimiter, setTableLimiter] = useState(LIMITER)

  const orders = useSelector(
    (state) => state.users.ordersForCollect.filteredOrders
  )
  const selectedFilters = useSelector(
    (state) => state.users.ordersForCollect.selectedFilters
  )

  const loading = useSelector((state) => state.users.ordersForCollect.loading)
  const records = groupWithCustomer([...orders])

  useEffect(() => {
    dispatch(getOrdersRequest())
  }, [dispatch])

  useEffect(() => {
    // effect responsible for resetting the states that depend on the requests, after their eventual filtering
    removeTablesSelection(tablesRef.current)
    setTableLimiter(LIMITER)
    dispatch(updateSelectedWeight())
  }, [dispatch, orders])

  const handlePrint = () => {
    setTableLimiter(records.length)
    setTimeout(() => {
      window.print()
    }, 0)
  }

  const handleAddItemsInCollect = () =>
    dispatch(createCollectRequest(getSelectedItems(tablesRef.current)))

  const handleUpdateWeight = (updatedItens) => {
    const added = sumWeight(updatedItens.filter((item) => item.selected))
    const removed = sumWeight(updatedItens.filter((item) => !item.selected))

    dispatch(
      updateSelectedWeight({
        added,
        removed,
      })
    )
  }

  const fetchMoreData = () => {
    setTimeout(() => {
      setTableLimiter((limiter) => limiter + LIMITER)
    }, 200)
  }

  const calculateCurrentRecords = () =>
    records.length < LIMITER ? records : slice(0, tableLimiter, records)

  const currentRecords = calculateCurrentRecords()

  return (
    <Content
      title={sm ? 'Pedidos' : null}
      containerId="carrier-orders-container"
      loading={loading}
      sideBarStart={!isEmpty(selectedFilters)}
      SideComponent={<Filter selectedFilters={selectedFilters} />}
      HeaderComponent={
        <CollectStatus
          onPrint={handlePrint}
          onClick={handleAddItemsInCollect}
        />
      }
    >
      <InfiniteScroll
        dataLength={currentRecords.length}
        hasMore={!loading && currentRecords.length < records.length}
        next={fetchMoreData}
        scrollableTarget="carrier-orders-container"
        className="printable"
        scrollThreshold={1}
        loader={
          <LoaderArea>
            <ScaleLoader color={colors.color500} />
            <small>Carregando mais pedidos...</small>
          </LoaderArea>
        }
      >
        {currentRecords.map((data, index) => (
          <TableCustomerOrders
            key={index}
            data={[...data]}
            onUpdate={handleUpdateWeight}
            getRef={(table) => (tablesRef.current[index] = table)}
          />
        ))}
      </InfiniteScroll>
    </Content>
  )
}
