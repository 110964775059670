import React from 'react'
import PropTypes from 'prop-types'

import { colors } from 'theme'
import { Container } from './styles'
import ScaleLoader from 'react-spinners/ScaleLoader'

export function SplashScreen(props) {
  const width = props.loaderSize
  const height = width * 8.75
  const margin = width / 2

  return (
    <Container style={props.style}>
      <div>
        <ScaleLoader
          margin={margin}
          height={height}
          width={width}
          radius={10}
          size={props.loaderSize}
          color={colors.color500}
        />
        <span className="loading-text">{props.loadingText}</span>
      </div>
    </Container>
  )
}

SplashScreen.propTypes = {
  loadingText: PropTypes.string,
  loaderSize: PropTypes.number,
}

SplashScreen.defaultProps = {
  loaderSize: 5,
}

export default SplashScreen
