import styled from 'styled-components'
import { TabPanel } from 'components/TabNavigation'
import { colors } from 'theme'

export const Container = styled.div``

export const TabsContainer = styled.div`
  background-color: ${colors.color100};
`

export const StyledTabPanel = styled(TabPanel)`
  &.cover {
    background-color: ${colors.white};
  }
`
