import React, { useState } from 'react'
import { sum } from 'ramda'
import theme from 'theme'
import * as medias from 'consts/medias'
import { translate } from '_i18n'
import formatter from 'services/formatter'
import { createCollectTableData } from 'services/collects'
import { Box, useMediaQuery } from '@material-ui/core'
import { Edit } from 'components/Icons'
import { RenderTableRow, StyledCheckBox } from 'components/Table'
import {
  GroupContainer,
  GroupHeader,
  GroupBody,
} from 'components/Table/TableGroup'
import { Container } from './styles'

import FormEdit from './FormEdit'
import MultiSelectionTable from 'components/MultiSelectionTable'
import ChipStatus from 'components/ChipStatus'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const groupBy = 'cod_pedido'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function TableCollect({ data, getRef, onUpdate, ...props }) {
  const sm = useMediaQuery(medias.sm)
  const [selected, setSelected] = useState(null)
  const isEditing = Boolean(selected)

  const common = data[0]
  const summary = common.customer_summary
  const customer = common.customer

  function CellPallets({ rowData: order, cellValue, isGroupRow }) {
    const isEditable = order.vpt_pallet > 1
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {!isGroupRow && isEditable && (
          <Box className="hoverable" marginRight="5px">
            <Edit onClick={() => setSelected(order)} />
          </Box>
        )}
        <span>{cellValue}</span>
      </Box>
    )
  }

  function CellSchedule({ cellValue: schedule }) {
    const currentDate = new Date()
    const isImmediate = new Date(schedule) <= new Date(currentDate)

    return schedule ? (
      !isImmediate ? (
        <ChipStatus
          label={isImmediate ? 'Imediata' : formatter(schedule).toSimpleDate()}
          background={theme.palette.warning.main}
        />
      ) : (
        'Imediata'
      )
    ) : null
  }

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy={groupBy}
        data={createCollectTableData(data)}
        getRef={getRef}
        onUpdate={onUpdate}
        isAvailable={() => true}
        columns={({ onSelectRow }) => [
          {
            title: translate('orders.table.order'),
            field: 'cod_pedido',
            renderCell: ({ rowData: order }) => (
              <StyledCheckBox
                value={order.selected}
                checked={order.selected}
                onChange={onSelectRow(order)}
              />
            ),
          },
          {
            title: translate('orders.table.product'),
            field: 'product.dsc_abreviado',
            renderCell: ({ rowData: collect }) =>
              collect.product
                ? `${collect.product.cod_item} - ${collect.product.dsc_abreviado}`
                : collect.cod_produto,
          },
          {
            title: translate('orders.table.quantity'),
            field: 'vpt_qtd',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.weight'),
            field: 'vpt_peso_bruto',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.pallets'),
            field: 'vpt_pallet',
            align: 'right',
            renderCell: CellPallets,
          },
          {
            title: translate('orders.table.boxes'),
            field: 'vpt_qtd_caixas',
            align: 'right',
          },
          {
            title: translate('orders.table.delivery'),
            field: 'vpt_data_prev_entrega',
            renderCell: CellSchedule,
          },
          {
            title: translate('orders.table.freight'),
            field: 'ped_tipo_frete',
          },
          {
            title: translate('orders.table.representative'),
            field: 'representative',
          },
          {
            title: translate('orders.table.scheduling'),
            field: 'id_agendamento',
            renderCell: ({ cellValue: scheduleId }) =>
              scheduleId !== '0' ? <span>{scheduleId}</span> : null,
          },
        ]}
        components={({
          onSelectTable,
          onSelectGroup,
          getTableChecked,
          getGroupChecked,
          tableData,
        }) => ({
          Header: () => {
            const checked = getTableChecked()

            return (
              <GroupContainer>
                <StyledCheckBox checked={checked} onChange={onSelectTable} />
                <div>
                  <GroupHeader>
                    <h3 className="group__title">
                      {customer.emp_razao_social}
                    </h3>
                    <span className="group__info">{customer.emp_telefone}</span>
                  </GroupHeader>
                  <GroupBody>
                    <span>{customer.emp_endereco}</span>
                    <span>{customer.emp_cidade}</span>
                    <span>{formatter(summary.cod_cliente).toCNPJorCPF()}</span>
                  </GroupBody>
                </div>
              </GroupContainer>
            )
          },
          Group: ({ rowData: orderItem, ...props }) => {
            const order = data.find(
              (o) => o.cod_pedido === orderItem.cod_pedido
            )

            const date = formatter(order?.ped_data_emissao).toSimpleDate()
            const checked = getGroupChecked(orderItem)

            return order ? (
              <RenderTableRow
                {...props}
                rowData={{
                  cod_produto: sm ? date : `Emitido em ${date}`,
                  [groupBy]: () => (
                    <span>
                      <StyledCheckBox
                        checked={checked}
                        value={checked}
                        onChange={onSelectGroup(order)}
                        disabled={!order}
                      />
                      {order.cod_pedido}
                    </span>
                  ),
                }}
              />
            ) : null
          },
          Footer: (props) => {
            const sumOf = (field) => sum([...tableData.map((d) => d[field])])

            return (
              <RenderTableRow
                {...props}
                rowData={{
                  cod_pedido: 'TOTAL',
                  vpt_pallet: sumOf('vpt_pallet'),
                  vpt_qtd: sumOf('vpt_qtd'),
                  vpt_qtd_caixas: sumOf('vpt_qtd_caixas'),
                  vpt_peso_bruto: sumOf('vpt_peso_bruto'),
                }}
              />
            )
          },
        })}
      />
      {isEditing && (
        <FormEdit
          current={selected}
          open={isEditing}
          onClose={() => setSelected(null)}
        />
      )}
    </Container>
  )
}

export default TableCollect
