import React, { useEffect } from 'react'
import { isEmpty } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { FormCombobox } from '../../CoForm'
import { getDeliveryAddressesRequest } from 'store/modules/comuns/customers/reducer'

export function ComboboxDeliveryAddresses(props) {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.comuns.customers.loading)
  const deliveryAddresses = useSelector(
    (state) => state.comuns.customers.deliveryAddresses
  )

  useEffect(() => {
    if (isEmpty(deliveryAddresses)) dispatch(getDeliveryAddressesRequest())
  }, [dispatch, deliveryAddresses])

  const deliveryAddressesOptions = deliveryAddresses.map((deliveryAddress) => ({
    value: deliveryAddress.id,
    label: deliveryAddress.descr,
  }))

  return (
    <FormCombobox
      options={deliveryAddressesOptions}
      loading={loading}
      {...props}
    />
  )
}

export default ComboboxDeliveryAddresses
