import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { PATHS } from '../consts/paths'
import { store } from '../store'

import UserRight from 'components/UserRight'

function RouteWrapper({ component: Component, isPrivate, rightId, ...props }) {
  const { pathname } = useLocation()
  const signed = store.getState().auth.signed

  if (!signed && isPrivate) {
    return <Redirect to={PATHS.signin.link} />
  }

  const token = /(\/login\/)(\w+)/.test(pathname)
    ? pathname.replace(`${PATHS.signin.link}/`, '')
    : null

  if (signed && !token && !isPrivate) {
    const profile = store.getState().user.profile
    return (
      <Redirect
        to={PATHS.defaultPath(
          profile.web_company.wee_tipo,
          profile.web_user.weu_tipo
        )}
      />
    )
  }

  return (
    <UserRight id={rightId}>
      <Route {...props} component={Component} />
    </UserRight>
  )
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
