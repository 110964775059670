import styled from 'styled-components'
import MailIcon from '@material-ui/icons/Mail'
import { Tooltip } from '@material-ui/core'

import { inviteStatus } from 'consts/types'

// ----------------------------

const StyledIcon = styled(MailIcon)`
  &[disabled],
  &.${inviteStatus.ACCEPTED} {
    pointer-events: none;
  }

  &[disabled] {
    color: ${(props) => props.theme.palette.action.disabled};
  }

  &.${inviteStatus.ACCEPTED} {
    color: ${(props) => props.theme.palette.success.main};
  }

  &.${inviteStatus.PENDING} {
    color: ${(props) => props.theme.palette.info.main};
  }
`

// ----------------------------

const Mail = ({ title, ...props }) =>
  title ? (
    <Tooltip title={title}>
      <StyledIcon {...props} />
    </Tooltip>
  ) : (
    <StyledIcon {...props} />
  )

export { Mail }
export default Mail
