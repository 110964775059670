import React, { useState } from 'react'
import { sum } from 'ramda'

import * as medias from 'consts/medias'
import { translate } from '_i18n'
import formatter from 'services/formatter'
import { cleateScheduleTableData } from 'services/schedules'

import { Box, useMediaQuery } from '@material-ui/core'
import { Edit } from 'components/Icons'
import { RenderTableRow, StyledCheckBox } from 'components/Table'
import { TableHeader } from 'components/Table/TableHeader'
import { Container } from './styles'

import FormEdit from './FormEdit'
import MultiSelectionTable from 'components/MultiSelectionTable'

export function TableSchedule({ data, getRef, onUpdate, ...props }) {
  const sm = useMediaQuery(medias.sm)
  const [selected, setSelected] = useState(null)
  const isEditing = Boolean(selected)

  function CellPallets({ rowData: orderItem, cellValue, isGroupRow }) {
    const isEditable = orderItem.vpa_qtd_caixas === 0
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {!isGroupRow && isEditable && (
          <Box className="hoverable" marginRight="5px">
            <Edit onClick={() => setSelected(orderItem)} />
          </Box>
        )}
        <span>{cellValue}</span>
      </Box>
    )
  }

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy="cod_pedido"
        data={cleateScheduleTableData(data)}
        isAvailable={(item) => item !== null}
        getRef={getRef}
        onUpdate={onUpdate}
        columns={({ onSelectRow }) => [
          {
            title: translate('orders.table.order'),
            field: 'cod_pedido',
            renderCell: ({ rowData: orderItem }) => (
              <StyledCheckBox
                value={orderItem.selected}
                checked={orderItem.selected}
                onChange={onSelectRow(orderItem)}
              />
            ),
          },
          {
            title: translate('orders.table.product'),
            field: 'product.dsc_abreviado',
            renderCell: ({ rowData: collect }) =>
              collect.product
                ? [
                    collect.product.cod_item,
                    collect.product.dsc_abreviado,
                    collect.line?.dsc_linha_produtos,
                  ].join(' - ')
                : collect.cod_produto,
          },
          {
            title: translate('orders.table.quantity'),
            field: 'vpa_qtd',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.weight'),
            field: 'vpa_peso_bruto',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.pallets'),
            field: 'vpa_pallet',
            align: 'right',
            renderCell: CellPallets,
          },
          {
            title: translate('orders.table.boxes'),
            field: 'vpa_qtd_caixas',
            align: 'right',
          },
          {
            title: translate('orders.table.delivery'),
            field: 'vpa_dcoleta',
            cellFormat: 'date',
          },
          {
            title: translate('orders.table.freight'),
            field: 'ped_tipo_frete',
          },
          {
            title: translate('orders.table.representative'),
            field: 'representative',
          },
          {
            title: translate('orders.table.scheduling'),
            field: 'id_agendamento',
            renderCell: ({ rowData: schedule }) =>
              schedule.id_agendamento !== '0' && (
                <span>{schedule.id_agendamento}</span>
              ),
          },
        ]}
        components={({
          onSelectTable,
          onSelectGroup,
          getTableChecked,
          getGroupChecked,
          tableData,
        }) => ({
          Header: () => {
            const checked = getTableChecked()
            const customer = data[0].customer
            return (
              <TableHeader
                title={customer.emp_razao_social}
                helperText={customer.emp_telefone}
                bodyData={[
                  customer.emp_endereco,
                  customer.emp_cidade,
                  formatter(data[0].cod_cliente).toCNPJ(),
                ]}
                checkboxProps={{
                  checked,
                  onChange: onSelectTable,
                }}
              />
            )
          },
          Group: ({ rowData: orderItem, ...props }) => {
            const checked = getGroupChecked(orderItem)
            const date = sm ? orderItem.ped_data_emissao : orderItem.issued_at

            return (
              <RenderTableRow
                {...props}
                columns={[
                  { field: 'group' },
                  { field: 'date' },
                  {
                    field: '_',
                    colSpan: props.columns?.length - 2,
                  },
                ]}
                rowData={{
                  date,
                  group: (
                    <span>
                      <StyledCheckBox
                        checked={checked}
                        value={checked}
                        onChange={onSelectGroup(orderItem)}
                      />
                      {orderItem.cod_pedido}
                    </span>
                  ),
                }}
              />
            )
          },
          Footer: (props) => {
            const sumOf = (field) => sum(tableData.map((i) => i[field]))

            return (
              <RenderTableRow
                {...props}
                rowData={{
                  cod_pedido: 'TOTAL',
                  vpa_pallet: sumOf('vpa_pallet'),
                  vpa_qtd: sumOf('vpa_qtd'),
                  vpa_qtd_caixas: sumOf('vpa_qtd_caixas'),
                  vpa_peso_bruto: sumOf('vpa_peso_bruto'),
                }}
              />
            )
          },
        })}
      />
      {isEditing && (
        <FormEdit
          current={selected}
          open={isEditing}
          onClose={() => setSelected(null)}
        />
      )}
    </Container>
  )
}

export default TableSchedule
