import styled from 'styled-components'
import theme from 'theme'

export const Container = styled.div`
  padding: 1rem 0;

  header {
    .header__div {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .header__div .header__image {
      position: relative;
    }

    .header__divider {
      margin: 0.25rem 0;
      border-bottom: 2px solid ${theme.palette.primary.light};
    }

    img {
      max-width: 120px;
      position: absolute;
      top: -28px;
      right: 0;
    }
  }

  section {
    margin: 0.5rem 0;
  }

  h5 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: ${theme.palette.primary.main};
    border-bottom: 2px solid ${theme.palette.divider};
  }

  .description-grid-item {
    padding-bottom: 1px;
    padding-top: 1px;
  }
`

export const LoaderContainer = styled.div`
  padding: 4rem 0rem;
`

export const Buttons = styled.div`
  margin: 1rem;
  width: 100%;

  display: flex;
  justify-content: space-between;
  gap: 1rem;
`

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 0.25rem;

  .label {
    font-weight: bold;
    white-space: nowrap;
  }

  .value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`
