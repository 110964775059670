import styled from 'styled-components'
import { colors } from '../../theme'
import MuiChip from '@material-ui/core/Chip'

export default styled(MuiChip)`
  height: 24px;
  max-width: 140px;
  background-color: ${colors.color100};

  > .MuiSvgIcon-root {
    color: ${colors.white};
    height: 18px;
  }

  .MuiChip-label {
    padding: 0 5px 0 9px;
    font-size: 10px;
    color: ${colors.color700};
  }

  &:hover {
    background-color: ${colors.color200};
  }

  &.read__only .MuiChip-label {
    padding-left: 12px;
    padding-right: 12px;
  }
`
