import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from '../../theme'

const Paper = styled.div`
  /* min-height: 78vh; */
  background: ${colors.white};
  border: 1px solid ${colors.color100};

  padding: 10px 14px;

  & + & {
    margin-top: 9px;
  }

  &.border-none {
    border: none;
  }

  &.mh-unset {
    min-height: unset;
  }

  .empty-text {
    color: ${colors.color400};
  }

  @media ${medias.sm} {
    padding: 12px;
  }
`

export { Paper }
export default Paper
