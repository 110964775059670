import styled from 'styled-components'
import { colors } from 'theme'
import * as medias from 'consts/medias'

import Paper from 'components/Paper'
import { Container as CardContainer } from 'components/Card/styles'

export const Container = styled(Paper)`
  border: none;
  min-height: 76vh;

  padding-left: 35px;
  padding-right: 35px;

  @media ${medias.sm} {
    padding-left: 9px;
    padding-right: 9px;
  }
`

export const ColumnCards = styled.div`
  margin-left: auto;

  width: 100%;
  min-width: 300px;
  max-width: 300px;

  > ${CardContainer} + ${CardContainer} {
    margin-top: 18px;
  }

  &.reverse ${CardContainer} {
    background-color: ${colors.color200};
    > div:first-child {
      // CARD-ICON
      background-color: ${colors.color700};
      border-radius: 8px 0 7px 0;
      svg {
        color: ${colors.white};
      }
    }
    > div:nth-child(2) {
      .title {
        color: #000;
      }
    }
  }

  @media ${medias.md} {
    max-width: unset;
    margin-bottom: 18px;
    &.last-child {
      margin-bottom: 9px;
    }
  }
`

export const DashBoardCardList = styled.div`
  max-width: min(1240px, 100%);

  margin: 20px auto;
  display: flex;

  ${CardContainer} {
    flex: 1;
  }

  > ${CardContainer} + ${CardContainer} {
    // Graph spacing
    margin-left: 30px;
  }

  .cardListGrid {
    margin-right: 6px;
    .cardListGrid-columns {
      display: flex;
      flex-direction: row;

      ${ColumnCards}:nth-child(odd) {
        margin-right: 6px;
      }

      margin-bottom: 18px;
    }
  }

  @media ${medias.md} {
    display: flex;
    flex-direction: column-reverse;

    padding: 0;

    .cardListGrid {
      margin-right: unset;
      margin-bottom: 18px;
      .cardListGrid-columns {
        display: flex;
        flex-direction: column;
        border: 0;

        ${ColumnCards}:nth-child(odd) {
          margin-right: 0;
        }

        margin-bottom: 0;
      }
    }

    > ${CardContainer} + ${CardContainer} {
      margin-left: 0;
      margin-bottom: 18px;
    }
  }
`

export const CardBody = styled.div`
  margin-top: 18px;
`

export const InfoContent = styled.div`
  color: ${colors.color500};
  font-size: 50px;
  text-align: end;
`
