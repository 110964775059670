import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { equals, sum } from 'ramda'
import { useSortData, usePagination } from 'hooks'
import formatter from 'services/formatter'
import { createCollectForPrint } from 'services/collects'
import { createQueryParameters } from 'services/utils'
import {
  issuedsRequest,
  cancelCollectRequest,
  reOpenCollectRequest,
  displayForEditCollect,
  cancelEditCollect,
  updateCollectRequest,
} from 'store/modules/users/collections/reducer'
import {
  CancelOutlined as CancelCollect,
  MenuOpen as OpenCollect,
  Edit as EditCollect,
  Print as PrinCollect,
} from '@material-ui/icons'
import { Typography, Box } from '@material-ui/core'
import { TableRow, TableCell } from 'components/Table/styles'
import {
  Button,
  ChipStatus,
  Content,
  Paper,
  Table,
  TablePagination,
  Chip,
} from 'components/index'
import {
  StyledGroupContainer,
  StyledGroupHeader,
  StyledGroupBody,
  Actions,
  StyledDialog,
} from './styles'
import CollectPrint, {
  collectStatus,
  getColorById,
} from 'components/Transportadora/CollectPrint'
import FormCollect from '../Collect/Form'
import Filter from './Filter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const INITIAL_SORT = 'id_coleta_for_order'
const GROUPED_BY = 'id_coleta_for_order'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function CollectHeader({ rowData: collectItem, columns, onPrint, collects }) {
  const dispatch = useDispatch()

  const status = collectItem.status
  const statusIs = equals(status.id)

  const editable = [
    collectStatus.blockedByLinkedCollect,
    collectStatus.scheduled,
  ].some(statusIs)

  const reopenable = [
    collectStatus.awaitValidation,
    collectStatus.validated,
  ].some(statusIs)

  const cancellable = [
    collectStatus.awaitValidation,
    collectStatus.validated,
    collectStatus.validatedBlocked,
  ].some(statusIs)

  /** Reopen collect */
  const handleReopen = () => dispatch(reOpenCollectRequest(collectItem))

  /** Print collect */
  const handlePrint = () => {
    const collectForPrint = createCollectForPrint(collectItem, collects)
    onPrint(collectForPrint)
  }

  /** Display for edit */
  const handleEdit = () => {
    const collectWeight = sum(
      collects
        .filter((ci) => ci.id_coleta === collectItem.id_coleta)
        .map((ci) => ci.vpt_peso_bruto)
    )
    dispatch(displayForEditCollect({ ...collectItem, collectWeight }))
  }

  /** Cancel collect */
  const handleCancel = () => dispatch(cancelCollectRequest(collectItem))

  const belongsToCollect =
    collectItem.id_coleta_agrupa &&
    collectItem.id_coleta_agrupa !== collectItem.id_coleta

  const showMultipleUnityDisclaimerMessage =
    belongsToCollect ||
    collects.some(
      (c) =>
        c.id_coleta_agrupa === collectItem.id_coleta &&
        c.id_coleta !== collectItem.id_coleta
    )

  return (
    <TableRow className="groupRow groupRowBorderered">
      <TableCell colSpan={columns.length}>
        <StyledGroupContainer>
          <StyledGroupHeader>
            <h3 className="group__title">
              {collectItem.id_coleta}

              {belongsToCollect && (
                <Chip
                  title="Coleta agrupada"
                  label={collectItem.id_coleta_agrupa}
                />
              )}
            </h3>

            <div>
              <span className="group__info">
                <b>EMISSÃO:</b>
                {formatter(collectItem.vpt_data_emissao).toSimpleDate()}
              </span>
              <span className="group__info">
                <b>COLETA:</b>
                {formatter(collectItem.vpt_dcoleta).toSimpleDate()}
              </span>
            </div>
          </StyledGroupHeader>

          <StyledGroupBody>
            <span>{collectItem.vpt_motorista}</span>
            <span>{formatter(collectItem.vpt_cod_motorista).toCPF()}</span>
            <span>
              {[
                collectItem.vpt_placa,
                collectItem.truck_type?.dsc_tipo_caminhao,
              ].join(' - ')}
            </span>

            {collectItem.vpt_obs && (
              <Box marginY="5px">
                <Typography component="b" variant="body2">
                  <li style={{ listStyle: 'inside' }}>
                    <b>{collectItem.vpt_obs}</b>
                  </li>
                </Typography>
              </Box>
            )}

            {showMultipleUnityDisclaimerMessage && (
              <div>
                <Typography color="error" className="multiple-unity-disclaimer">
                  ORDEM DE CARGA PARA CARREGAMENTO NA UNIDADE:{' '}
                  {collectItem.cia_descricao_carreg}
                </Typography>
              </div>
            )}

            <Box marginTop="0.5rem">
              <Actions>
                <Button
                  label="Imprimir"
                  startIcon={<PrinCollect />}
                  className="edit"
                  onClick={handlePrint}
                />
                {editable ? (
                  <Button
                    disabled={!editable}
                    label="Editar"
                    className="edit"
                    startIcon={<EditCollect />}
                    onClick={handleEdit}
                  />
                ) : (
                  <Button
                    disabled={!reopenable}
                    label="Reabrir"
                    startIcon={<OpenCollect />}
                    onClick={handleReopen}
                  />
                )}
                <Button
                  disabled={!cancellable}
                  label="Cancelar"
                  startIcon={<CancelCollect />}
                  className="cancel"
                  onClick={handleCancel}
                />
              </Actions>

              {status && (
                <ChipStatus
                  label={status.descr}
                  background={getColorById(status.id)}
                  className="large"
                />
              )}
            </Box>
          </StyledGroupBody>
        </StyledGroupContainer>
      </TableCell>
    </TableRow>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function UserIssuedCollectionsPage() {
  const dispatch = useDispatch()

  const collects = useSelector(
    (state) => state.users.collections.issueds.issueds
  )
  const count = useSelector((state) => state.users.collections.issueds.count)
  const loading = useSelector((state) => state.users.collections.loading)
  const collectParams = useSelector(
    (state) => state.users.collections.active.collectParams
  )

  const isEditing = !!collectParams.isEditing

  const [selectedFilters, setSelectedFilters] = useState([])
  const [selected, setSelected] = useState(null)

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, reset } =
    usePagination({
      initialRowsPerpage: 10,
    })

  const { currentSort, onSortChange } = useSortData({
    initialField: INITIAL_SORT,
    initialOrder: 'asc',
  })

  useEffect(() => {
    const filters = selectedFilters.reduce((acc, filter) => {
      const filterValue = filter.value?.trim()

      // key already exists
      if (acc[filter.filterBy])
        acc[filter.filterBy] = acc[filter.filterBy].concat(filterValue)
      else acc[filter.filterBy] = [filterValue]

      return acc
    }, {})

    const queryParameters = createQueryParameters({
      currentSort,
      page,
      rowsPerPage,
      filters,
    })

    dispatch(issuedsRequest(queryParameters))
  }, [dispatch, currentSort, page, rowsPerPage, selectedFilters])

  useEffect(() => {
    reset()
  }, [reset, currentSort])

  const handleSubmit = (values) => dispatch(updateCollectRequest(values))

  const handleCancelEdit = () => dispatch(cancelEditCollect())

  const columns = [
    {
      title: 'COLETA',
      field: 'id_coleta_for_order',
      renderCell: ({ rowData: collect }) => collect.id_coleta,
    },
    { title: 'PEDIDO', field: 'cod_pedido' },
    { title: 'CLIENTE', field: 'emp_razao_social' },
    { title: 'CIDADE', field: 'emp_cidade' },
    { title: 'UF', field: 'dsc_uf' },
    {
      title: 'PRODUTO',
      field: 'dsc_abreviado',
      renderCell: ({ rowData: collect }) =>
        `${collect.cod_produto} - ${collect.dsc_abreviado}`,
    },
    {
      title: 'PESO',
      field: 'vpt_peso_bruto',
      cellFormat: 'decimal',
      align: 'right',
    },
    {
      title: 'QUANTIDADE',
      field: 'vpt_qtd',
      cellFormat: 'decimal',
      align: 'right',
    },
    { title: 'OC', field: 'ford_numero' },
  ]

  return (
    <Content
      containerId="coletas-emitidas"
      title="Emitidas"
      loading={loading}
      SideComponent={
        <Filter
          data={collects}
          loading={loading}
          onFiltering={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      }
    >
      <Paper className="border-none">
        <Table
          size="medium"
          stripped={false}
          groupBy={GROUPED_BY}
          data={collects}
          columns={columns}
          // currentSort={currentSort}
          onSortChange={onSortChange}
          components={{
            Group: (props) => (
              <CollectHeader
                {...props}
                onPrint={setSelected}
                collects={collects}
              />
            ),
          }}
        />
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
        <StyledDialog open={isEditing} onClose={handleCancelEdit}>
          <FormCollect isEditing onSubmit={handleSubmit} />
        </StyledDialog>
      </Paper>
      {selected && (
        <CollectPrint
          collect={selected}
          open={Boolean(selected)}
          onClose={() => setSelected(null)}
        />
      )}
    </Content>
  )
}
