import { createSlice } from '@reduxjs/toolkit'
import { sum } from 'ramda'
import { precisionRound } from 'services/utils'

const initialState = {
  loading: false,
  issueds: {
    count: 0,
    issueds: [],
  },
  errors: [],
  active: {
    collect: [],
    collectForPrint: null,
    collectParams: {},
    weight: 0,
  },
  selectedWeight: 0,
  count: 0,
}

const createCollectParams = (collect = {}, isEditing = false) => {
  return collect.vpt_dcoleta
    ? {
        id_coleta: collect.id_coleta,
        id_tipo_caminhao: collect.id_tipo_caminhao,
        vpt_dcoleta: collect.vpt_dcoleta,
        vpt_placa: collect.vpt_placa,
        vpt_motorista: collect.vpt_motorista,
        vpt_cod_motorista: collect.vpt_cod_motorista,
        vpt_obs: collect.vpt_obs ?? '',
        isEditing,
      }
    : initialState.active.collectParams
}

const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    // get active collect
    getCollectRequest(state) {
      state.loading = true
    },
    getCollectSuccess(state, { payload: collect }) {
      state.active.collect = collect

      const collectItens = collect.flatMap((collectItem) => collectItem.itens)
      state.active.collectParams = createCollectParams(collectItens[0])

      state.active.weight = sum(collectItens.map((item) => item.vpt_peso_bruto))

      state.errors = []
      state.loading = false
    },
    getCollectFailure(state) {
      state.loading = false
    },

    // create collection in progress
    createCollectRequest(state) {
      state.loading = true
    },
    createCollectSuccess(state) {
      state.selectedWeight = initialState.selectedWeight
      state.loading = false
    },
    createCollectFailure(state) {
      state.loading = false
    },

    // finish collection in progress
    finishCollectRequest(state) {
      state.loading = true
    },
    finishCollectSuccess(state, { payload }) {
      state.active.weight = initialState.active.weight
      state.active.collectParams = initialState.active.collectParams
      state.active.collect = initialState.active.collect
      state.active.collectForPrint = payload
      state.loading = false
    },
    finishCollectFailure(state, { payload }) {
      state.loading = false
      if (payload) state.errors = payload
    },
    finishCollectCancel(state) {
      state.loading = false
      state.errors = initialState.errors
    },

    // update collect
    updateCollectRequest(state) {
      state.loading = true
    },
    updateCollectSuccess(state) {
      state.loading = false
      state.active.collectParams = initialState.active.collectParams
    },
    updateCollectFailure(state) {
      state.loading = false
    },

    // remove
    removeCollectItemsRequest() {},

    // update
    updateCollectItemRequest(state) {
      state.loading = true
    },

    updateCollectItemFailure(state) {
      state.loading = false
    },

    // handle weight
    updateSelectedWeight(state, { payload }) {
      if (!payload) state.selectedWeight = 0
      else {
        const weight = state.selectedWeight
        const newWeight = weight + payload.added - payload.removed
        state.selectedWeight = precisionRound(newWeight, 1)
      }
    },

    // issueds
    issuedsRequest(state) {
      state.loading = true
    },
    issuedsSuccess(state, { payload }) {
      state.issueds = payload
      state.loading = false
    },
    issuedsFailure(state) {
      state.loading = false
    },

    // cancel collect
    cancelCollectRequest(state) {
      state.loading = true
    },
    cancelCollectSuccess(state) {
      state.loading = false
    },
    cancelCollectFailure(state) {
      state.loading = false
    },

    // reopen collect
    reOpenCollectRequest(state) {
      state.loading = true
    },

    reOpenCollectFailure(state) {
      state.loading = false
    },
    reOpenCollectSuccess(state, { payload }) {
      state.loading = false
      state.active.collectParams = payload
    },

    // edit collect
    displayForEditCollect(state, { payload: collectItem }) {
      state.active.collectParams = createCollectParams(collectItem, true)
      state.active.weight = collectItem.collectWeight
    },
    cancelEditCollect(state) {
      state.active.collectParams = initialState.active.collectParams
    },

    // collect count
    collectCountRequest() {},
    collectCountSuccess(state, { payload }) {
      state.count = payload
    },

    // collect print
    cancelCollectPrint(state) {
      state.active.collectForPrint = initialState.active.collectForPrint
    },
  },
})

export const {
  getCollectRequest,
  getCollectSuccess,
  getCollectFailure,
  createCollectRequest,
  createCollectSuccess,
  createCollectFailure,
  finishCollectRequest,
  finishCollectSuccess,
  finishCollectFailure,
  finishCollectCancel,
  updateSelectedWeight,
  removeCollectItemsRequest,
  issuedsRequest,
  issuedsSuccess,
  issuedsFailure,
  updateCollectItemRequest,
  updateCollectItemFailure,
  cancelCollectFailure,
  cancelCollectRequest,
  cancelCollectSuccess,
  reOpenCollectRequest,
  reOpenCollectFailure,
  reOpenCollectSuccess,
  updateCollectFailure,
  updateCollectRequest,
  updateCollectSuccess,
  displayForEditCollect,
  cancelEditCollect,
  collectCountRequest,
  collectCountSuccess,
  cancelCollectPrint,
} = collectionsSlice.actions

/** SELECTORS */
export const collectWeightSelector = (state) =>
  state.users.collections.active.weight

export const collectSelectedWeightSelector = (state) =>
  state.users.collections.selectedWeight

export default collectionsSlice.reducer
