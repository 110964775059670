import styled from 'styled-components'
import { colors } from 'theme'
import { Form } from 'components/CoForm'
import { Title } from 'components/Ranking/styles'

export const StyledForm = styled(Form)`
  ${Title} {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  > .MuiFormControl-root {
    margin-top: 7px;
    width: 100%;
  }

  > div.row__fields {
    display: flex;
    > .MuiFormControl-root:first-child {
      margin-right: 7px;
    }
  }

  > .buttons {
    margin-top: 14px;

    display: flex;
    justify-content: flex-end;

    > button {
      margin-left: 7px;
    }

    .MuiButton-label {
      color: ${colors.color500};
    }
  }
`
