import styled from 'styled-components'
import Scroll from 'components/InfiniteScroll'
import * as medias from 'consts/medias'

export const ScrollContainer = styled(Scroll)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 4%;

  > div {
    margin: 12px;
  }

  @media ${medias.md} {
    margin: initial;
    justify-content: center;
  }
`
