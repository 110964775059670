import React from 'react'
import clsx from 'clsx'
import { InfoRounded as SheetIcon, Launch } from '@material-ui/icons'
import { IconButton, Tooltip } from '@material-ui/core'

import { Table, TableCell, DescriptionText } from 'components/index'

import * as SC from '../../styles'

export function InventoryTable({ data, onShowDataSheet }) {
  const renderBalance = ({ cellValue: balance, rowData: product }) => {
    return (
      <span
        style={product.indisponivel ? { fontWeight: 'bold', color: 'red' } : {}}
      >
        {product.indisponivel ? 'Indisponível' : balance}
      </span>
    )
  }

  const renderProductLink = ({ cellValue }) => {
    return (
      <a target="_blank" rel="noreferrer" href={cellValue}>
        <IconButton size="small">
          <Launch color={cellValue ? 'primary' : 'disabled'} fontSize="small" />
        </IconButton>
      </a>
    )
  }

  const getDetailPanel = ({ row: product, show }) => {
    if (!show) return null

    return (
      <>
        <SC.TableRow className="large">
          <TableCell colSpan={2} />
          <TableCell colSpan={11}>
            <SC.HighlightText>{product.descrição}</SC.HighlightText>
          </TableCell>
        </SC.TableRow>
        <SC.TableRow>
          <TableCell colSpan={2} />
          <TableCell>
            <DescriptionText label="NCM:" value={product.ncm} />
          </TableCell>
          <TableCell>
            <DescriptionText label="SEU CÓD:" value={product.cod_produto_cli} />
          </TableCell>
          <TableCell>
            <DescriptionText label="PESO LIQ:" value={product.peso_liq} />
          </TableCell>
          <TableCell>
            <DescriptionText label="PESO BRUTO:" value={product.peso_bruto} />
          </TableCell>
          <TableCell>
            <DescriptionText label="PESO CAIXA:" value={product.peso_caixa} />
          </TableCell>
          <TableCell colSpan={5}>
            <DescriptionText label="PESO PALLET:" value={product.peso_pallet} />
          </TableCell>
          <TableCell role="action" align="center">
            <Tooltip title="Acessar ficha técnica do produto">
              <IconButton size="small" onClick={() => onShowDataSheet(product)}>
                <SheetIcon color="primary" />
              </IconButton>
            </Tooltip>
          </TableCell>
        </SC.TableRow>
      </>
    )
  }

  return (
    <Table
      size="medium"
      data={data}
      columns={[
        { title: 'CÓDIGO', field: 'cod_prod', align: 'left' },
        { title: 'PRODUTO', field: 'abreviado', align: 'left' },
        {
          title: 'MAIOR LOTE/DISP',
          field: 'saldo_disponivel',
          align: 'right',
          renderCell: renderBalance,
        },
        { title: 'TAMANHO', field: 'tamanho', align: 'left' },
        { title: 'SUPERFÍCIE', field: 'linha', align: 'left' },
        { title: 'CÓD/BARRAS', field: 'cod_barras', align: 'left' },
        { title: 'CLASSIF', field: 'classif', align: 'center' },
        { title: 'M2/CX', field: 'm2_caixa', align: 'right' },
        { title: 'CX/PAL', field: 'cx_pallet', align: 'right' },
        { title: 'M2/PAL', field: 'm2_pallet', align: 'right' },
        { title: 'MARCA', field: 'marca', align: 'left' },
        {
          title: '',
          field: 'link_site_produto',
          align: 'center',
          renderCell: renderProductLink,
          role: 'action',
        },
      ]}
      detailPanel={getDetailPanel}
      detailPanelIcon={({ open, ...props }) => (
        <SC.DetailIcon className={clsx({ open })} {...props} />
      )}
    />
  )
}

export default React.memo(InventoryTable)
