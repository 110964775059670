import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSortData, usePagination } from 'hooks'

import Paper from '../Paper'
import Table from '../Table'
import TablePagination from '../TablePagination'
export function TableWithPagination({ initialSort, count, ...props }) {
  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
    reset,
  } = usePagination()

  const { currentSort, onSortChange, sortData } = useSortData({
    ...initialSort,
  })

  useEffect(() => {
    reset()
  }, [reset, currentSort])

  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(props.data, props.groupBy)
  )

  return (
    <Paper className="border-none">
      <Table
        {...props}
        data={currentPageRecords}
        currentSort={currentSort}
        onSortChange={onSortChange}
      />
      <TablePagination
        count={count}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Paper>
  )
}

TableWithPagination.propTypes = {
  count: PropTypes.number,
  groupBy: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  initialSort: PropTypes.exact({
    initialField: PropTypes.string.isRequired,
    initialOrder: PropTypes.string,
  }).isRequired,
}

export default TableWithPagination
