import styled from 'styled-components'

export const CollectSchedulesContainer = styled.div`
  margin-top: 1rem;

  span {
    font-weight: bold;
    display: inline-block;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
`
