import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { lighten } from 'polished'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { StyledCard } from './styles'

import { useCurrentForm } from 'components/CoForm'

// █▀▀ █░█ █▄░█ █▀▀ ▀█▀ █ █▀█ █▄░█ █▀
// █▀░ █▄█ █░▀█ █▄▄ ░█░ █ █▄█ █░▀█ ▄█

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    border: `1px solid ${lighten(0.5, theme.palette.primary.main)}`,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1)

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1)

const union = (a, b) => [...a, ...not(b, a)]

export default function TransferList({
  leftName,
  rightName,
  leftLabel,
  rightLabel,
  initialLeftList,
  initialRightList,
  disabled,
}) {
  // █░█ █▀█ █▀█ █▄▀ █▀
  // █▀█ █▄█ █▄█ █░█ ▄█

  /** {TransferList} Active only when used inside a FormContext */
  const currentForm = useCurrentForm()

  const classes = useStyles()

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([...initialLeftList])
  const [right, setRight] = useState([...initialRightList])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) newChecked.push(value)
    else newChecked.splice(currentIndex, 1)

    setChecked(newChecked)
  }

  const numberOfChecked = (items) => intersection(checked, items).length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleUpdateForm = (left, right) => {
    if (currentForm) {
      currentForm.setData({
        [leftName]: left,
        [rightName]: right,
      })
    }
  }

  const handleCheckedRight = () => {
    const newLeft = not(left, leftChecked)
    const newRight = right.concat(leftChecked)

    setChecked(not(checked, leftChecked))
    setLeft(newLeft)
    setRight(newRight)

    handleUpdateForm(newLeft, newRight)
  }

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked)
    const newRight = not(right, rightChecked)

    setChecked(not(checked, rightChecked))
    setLeft(newLeft)
    setRight(newRight)

    handleUpdateForm(newLeft, newRight)
  }

  const customList = (title, items) => (
    <StyledCard>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
            color="primary"
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((option) => {
          const labelId = `transfer-list-all-item-${option.value}-label`

          return (
            <ListItem
              key={option.value}
              role="listitem"
              button
              onClick={handleToggle(option)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(option) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={option.label} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </StyledCard>
  )

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={5}>
        {customList(leftLabel, left)}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={disabled || leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={disabled || rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(rightLabel, right)}
      </Grid>
    </Grid>
  )
}

const Option = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
})

TransferList.propTypes = {
  leftName: PropTypes.string,
  rightName: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  initialLeftList: PropTypes.arrayOf(Option),
  initialRightList: PropTypes.arrayOf(Option),
}

TransferList.defaultProps = {
  initialLeftList: [],
  initialRightList: [],
  leftLabel: '',
  rightLabel: '',
}
