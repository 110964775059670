import React from 'react'
import PropTypes from 'prop-types'

import { StyledCheckbox, StyledFormControlLabel } from './styles'
import { FormHelperText } from '@material-ui/core'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function CheckBox({ label, ...props }) {
  return (
    <StyledFormControlLabel
      label={label}
      control={<StyledCheckbox color="primary" {...props} />}
    />
  )
}

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
}

CheckBox.defaultProps = {
  label: '',
  size: 'small',
}

export default CheckBox

// --------------- ℂ𝕠𝕟𝕥𝕣𝕠𝕝𝕝𝕖𝕕 𝔽𝕠𝕣𝕞 𝕍𝕖𝕣𝕤𝕚𝕠𝕟 ---------------

export function FormCheckBox({
  name,
  label,
  value,
  onChange,
  error,
  helperText,
  ...props
}) {
  function handleChange(e) {
    const checked = e.target.checked
    onChange({ target: { name, value: checked } })
  }

  return (
    <>
      <CheckBox
        label={label}
        onChange={handleChange}
        checked={value}
        {...props}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  )
}
