import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import handleErrors from 'services/handleErrors'

import {
  Typography,
  IconButton,
  Tooltip,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'

import TransferList from 'components/TransferList'
import {
  StyledPopper,
  StyledList,
} from 'components/Combobox/WebProfiles/styles'
import { Container } from './styles'

export default function ProfileTransferList({ userProfiles, ...props }) {
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(true)

  const [menuProfileConfig, setMenuProfileConfig] = useState({
    anchorEl: null,
    rights: [],
  })

  const availableProfiles = profiles.filter(
    (p) => !userProfiles.includes(p.value)
  )

  const selectedProfiles = profiles.filter((p) =>
    userProfiles.includes(p.value)
  )

  useEffect(() => {
    const fetchWebProfiles = () => {
      api
        .get(apiEndPoints.miscellaneous.profiles)
        .then((response) => response.data)
        .then((profiles) =>
          setProfiles(
            profiles.map((p) => ({
              value: p.id_perfil,
              label: p.dsc_perfil,
            }))
          )
        )
        .catch((error) =>
          handleErrors(error, 'Não foi possível buscar os perfis de acesso')
        )
        .finally(() => {
          setLoading(false)
        })
    }

    fetchWebProfiles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSetupMenuProfile = async (event, profileId) => {
    const newAnchorEl = menuProfileConfig.anchorEl ? null : event.currentTarget

    if (profileId) {
      const userRights =
        await require(`../../../__access_profiles__/${profileId}`)

      const newRigths = [...userRights.default].sort((a, b) => a.id > b.id)

      setMenuProfileConfig({
        anchorEl: newAnchorEl,
        rights: newRigths,
      })
    }
  }

  const handleClose = () => {
    setMenuProfileConfig({
      anchorEl: null,
      rights: [],
    })
  }

  if (loading) {
    return (
      <Typography variant="body2" color="textSecondary">
        Carregando...
      </Typography>
    )
  }

  return (
    <>
      {props.disabled ? (
        <Container>
          <ul>
            {selectedProfiles.map((profile) => (
              <li key={profile.value}>
                <Typography>{profile.label}</Typography>

                <Tooltip title="Acessos">
                  <IconButton
                    size="small"
                    onClick={(e) => handleSetupMenuProfile(e, profile.value)}
                  >
                    <Info className="info-icon" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </li>
            ))}
          </ul>
        </Container>
      ) : (
        <TransferList
          initialRightList={selectedProfiles}
          initialLeftList={availableProfiles}
          rightLabel="Perfis do Usuário"
          leftLabel="Perfis Disponíveis"
          rightName="perfis_usuario"
          leftName="perfis_disponiveis"
          {...props}
        />
      )}

      <StyledPopper
        open={Boolean(menuProfileConfig.anchorEl)}
        anchorEl={menuProfileConfig.anchorEl}
        onClose={handleClose}
      >
        <StyledList>
          {menuProfileConfig.rights.map((r) => (
            <ListItem key={r.id}>
              <ListItemText primary={r.label} />
            </ListItem>
          ))}
        </StyledList>
      </StyledPopper>
    </>
  )
}

ProfileTransferList.propTypes = {
  userProfiles: PropTypes.array,
  disabled: PropTypes.bool,
}

ProfileTransferList.defaultProps = {
  userProfiles: [],
  disabled: false,
}
