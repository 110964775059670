import React from 'react'

import { Title as RankingTitle, Divider } from 'components/Ranking/styles'

export default function FilterTitle({ children }) {
  return (
    <RankingTitle>
      <span>{children}</span>
      <Divider />
    </RankingTitle>
  )
}
