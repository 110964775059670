import React from 'react'
import { useProfile } from 'hooks/useProfile'
import { PATHS } from 'consts/paths'
import { MenuItem } from './MenuItem'
import { Domain, Group } from '../Icons'

export const AdminMenuItems = () => {
  const profile = useProfile()
  return (
    <>
      {profile.isAdminCompany && (
        <MenuItem Icon={Domain} label="Empresas" path={PATHS.admin.companies} />
      )}
      <MenuItem Icon={Group} label="Usuários" path={PATHS.admin.users} />
    </>
  )
}

export default AdminMenuItems
