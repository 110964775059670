import React from 'react'
import { PATHS } from 'consts/paths'

import BulletinsPage from '../pages/Users/Bulletins'
import QueriesPage from '../pages/Users/Queries'
import Route from './Route'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'

export function CommonRoutes() {
  return (
    <>
      <UserRight id={RIGHTS.consultaAvisos.id}>
        <Route
          path={PATHS.common.bulletins}
          component={BulletinsPage}
          isPrivate
          exact
        />
      </UserRight>
      <Route
        path={PATHS.user.queries.root}
        component={QueriesPage}
        isPrivate
        exact
      />
    </>
  )
}

export default CommonRoutes
