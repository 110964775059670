import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '../../TextField'
import Image from '../../B64Image'

export const StyledAutoComplete = styled(Autocomplete)``

export const StyledTextField = styled(TextField)``

export const OptionPicture = styled(Image)`
  max-width: 210px;
`

export const InputContainer = styled.div`
  position: relative;

  .MuiFormControl-root {
    z-index: 1;
  }

  .spinner-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.hasPicture {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 4px 0;

    ${OptionPicture} {
      pointer-events: none;
      margin-bottom: 4px;
      max-width: 230px;

      transition: max-width 200ms ease-in;
    }
  }
`

export const Parts = styled.span`
  font-size: 12px;
`

export const OptionContainer = styled.div`
  width: 100%;

  > div {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .secondary-text {
      font-size: 10px;
    }
  }

  &.hasPicture {
    padding: 4px 0;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

    margin: 0 auto;

    > div {
      margin: 3px 0;
    }

    ${OptionPicture} {
      border-radius: 5px;
    }
  }
`
