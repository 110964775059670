import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from '../../theme'
import { Button } from '..'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ButtonsContainer = styled.div`
  flex: 2;

  display: flex;
  justify-content: flex-start;

  @media ${medias.sm} {
    > div {
      margin: auto;
    }
  }
`

export const Divider = styled.div`
  width: 59px;
  height: 0;

  border: 1px solid ${colors.color100};
  background-color: ${colors.color100};
  transform: rotate(90deg);
`

export const ContainerWeights = styled.div`
  flex: 1;
  max-width: 350px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 11px;
    font-weight: 600;
    color: ${colors.color800};
    line-height: 17px;
  }

  div:nth-child(1) {
    span {
      font-size: 13px;
    }
  }

  div:nth-child(2) {
    span {
      display: flex;
      justify-content: space-between;

      strong {
        margin-left: 14px;
        font-size: 15px;
      }
    }
  }

  .fill {
    color: ${colors.color400};
  }

  @media ${medias.sm} {
    margin: 5px 0;
    width: 100%;

    > div {
      flex: 1;
    }
  }
`

export const StyledButton = styled(Button)`
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 7px;
  transition: opacity 100ms ease-in;

  &.add:not(:disabled) {
    animation: shake 300ms backwards;
    background-color: ${colors.color500};

    > span {
      color: ${colors.white};
    }

    @keyframes shake {
      0% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }
      100% {
        transform: translateX(0);
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.print {
    transition: 200ms ease-in;
    background-color: ${colors.white};
    border-color: ${colors.color500};
    color: ${colors.color500};

    &:hover {
      background-color: ${colors.color500};
      color: ${colors.white};
    }
  }
`

export const WeightText = styled.span`
  display: flex;
  justify-content: space-between;
  min-width: 180px;
`
