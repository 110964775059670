import { createSlice } from '@reduxjs/toolkit'
import formatter from 'services/formatter'
import { defaultComparator, applyFilters, createFilters } from 'services/utils'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const DATE_FIELDS = ['ped_data_emissao']
const AVAILABLE_FILTERS = ['dsc_uf', 'cli_nome', 'cli_cidade']

const initialState = {
  loading: false,
  orders: [],
  filteredOrders: [],
  filters: {},
  selectedFilters: [],
  searchFilters: null,
}

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const toISOString = (d) => formatter(d).toISOString(d)

const comparator = (value, pivots, attr) =>
  DATE_FIELDS.includes(attr)
    ? pivots.map(toISOString).includes(toISOString(value))
    : defaultComparator(value, pivots, attr)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

const ordersForCollect = createSlice({
  name: 'ordersForCollect',
  initialState,
  reducers: {
    // Orders
    getOrdersRequest(state) {
      state.loading = true
    },
    getScheduleOrdersRequest(state, { payload }) {
      state.loading = true
      state.searchFilters = payload
    },
    getOrdersSuccess(state, { payload }) {
      const orders = payload.orders
      state.orders = orders

      const filteredOrders = applyFilters(
        state.selectedFilters,
        orders,
        comparator
      )
      state.filteredOrders = filteredOrders
      state.filters = createFilters(AVAILABLE_FILTERS, filteredOrders)

      state.loading = false
    },
    getOrdersFailure(state) {
      state.loading = false
    },

    // Filter
    selectFilter(state, { payload }) {
      const draftSelectedFilters = [...state.selectedFilters]
      const isNewFilter = !draftSelectedFilters.find((f) => f.id === payload.id)

      if (isNewFilter) {
        draftSelectedFilters.push(payload)
        state.selectedFilters = draftSelectedFilters

        const filteredOrders = applyFilters(
          draftSelectedFilters,
          state.orders,
          comparator
        )
        state.filters = createFilters(AVAILABLE_FILTERS, filteredOrders)
        state.filteredOrders = filteredOrders
      }
    },
    removeFilter(state, { payload }) {
      const draftSelectedFilters = [...state.selectedFilters].filter(
        (f) => f.id !== payload.id
      )

      state.selectedFilters = draftSelectedFilters
      const filteredOrders = applyFilters(
        draftSelectedFilters,
        state.orders,
        comparator
      )
      state.filters = createFilters(AVAILABLE_FILTERS, filteredOrders)
      state.filteredOrders = filteredOrders
    },
    removeAllFilters(state) {
      const orders = state.orders
      state.selectedFilters = []

      state.filteredOrders = orders
      state.filters = createFilters(AVAILABLE_FILTERS, orders)
    },
  },
})

export const {
  getScheduleOrdersRequest,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFailure,
  selectFilter,
  removeFilter,
  removeAllFilters,
} = ordersForCollect.actions

export default ordersForCollect.reducer
