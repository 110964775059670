import React from 'react'
import styled from 'styled-components'
import { isEmpty, findIndex, propEq } from 'ramda'

import { cpf } from 'consts/regexp'
import {
  filterProps,
  createFilters,
  toRankingOptions,
  formatFilters,
} from 'services/utils'

import { Tooltip } from '@material-ui/core'
import { Chip, SideBar, Ranking, SearchFilter } from 'components/index'
import { Trash } from 'components/Icons'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const AVAILABLE_FILTERS = ['company.emp_razao_social']

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const createCollectsFilter = createFilters(AVAILABLE_FILTERS)

const removeElem = (elem, previousState) =>
  [...previousState].filter((e) => JSON.stringify(e) !== JSON.stringify(elem))

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

const StyledTrashIcon = styled(Trash)`
  margin-right: 20px;

  cursor: pointer;
  path {
    fill: ${(props) => props.theme.palette.primary.main};
  }
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function Filter({
  data,
  onFiltering,
  loading,
  selectedFilters,
  ...props
}) {
  const rankings = createCollectsFilter(data)

  function handleClick(filter) {
    if (findIndex(propEq(filterProps.ID, filter.id), selectedFilters) === -1)
      onFiltering([...selectedFilters].concat(filter))
  }

  function handleDelete(filter) {
    onFiltering(removeElem(filter, [...selectedFilters]))
  }

  function handleSubmit(values) {
    onFiltering(
      formatFilters({
        ...values,
        weu_cpf: values.weu_cpf?.replace(/\D/g, ''),
      })
    )
  }

  return (
    <SideBar
      {...props}
      loading={loading}
      HeaderComponent={
        !isEmpty(selectedFilters) ? (
          <div className="ChipList ChipListSpacing">
            {selectedFilters.map((filter, index) => (
              <Chip
                onDelete={() => handleDelete(filter)}
                label={filter.value}
                key={index}
              />
            ))}
            <Tooltip title="Excluir filtros">
              <div>
                <StyledTrashIcon onClick={() => onFiltering([])} />
              </div>
            </Tooltip>
          </div>
        ) : null
      }
      ContentComponent={
        <div className="Ranking-List">
          <Ranking
            filterBy="company.emp_razao_social"
            title="Empresa"
            rows={toRankingOptions(rankings['company.emp_razao_social'])}
            onFiltering={handleClick}
          />
        </div>
      }
      FooterComponent={
        <SearchFilter
          onSubmit={handleSubmit}
          title="Pesquisar por"
          buttonLabel="Enviar"
          fields={[
            { name: 'weu_nome', label: 'Usuário' },
            {
              name: 'weu_cpf',
              label: 'CPF',
              textMaskProps: { mask: cpf.array },
            },
            { name: 'weu_email', label: 'E-mail' },
          ]}
        />
      }
    />
  )
}
