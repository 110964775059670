import styled from 'styled-components'
import { colors } from '../../theme'

import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'
import { lighten } from 'polished'

export const Container = styled.div`
  position: relative;
  display: flex;

  align-items: center;
  justify-content: center;
`

export const StyledButton = styled(Button)`
  z-index: 1;
  text-transform: unset;
  background-color: ${colors.color100};

  .MuiButton-startIcon {
    margin-right: 12px;
  }

  &.light {
    .MuiButton-label {
      color: ${colors.white};
    }
  }

  &.regular {
    .MuiButton-label {
      font-weight: normal;
    }
  }

  &.large {
    padding: 4px 16px;
    height: 35px;
  }

  &.main {
    background-color: ${lighten(0.07, colors.color300)};
  }

  &.success {
    background-color: ${(props) => props.theme.palette.success.main};
  }

  &.error {
    background-color: ${(props) => props.theme.palette.error.main};
  }

  &.info {
    background-color: ${(props) => props.theme.palette.info.main};
  }

  &.dark {
    background-color: ${colors.color500};
  }

  &.transparent {
    background-color: ${lighten(0.2, colors.error)};
    height: 35px;
  }

  &.font-white {
    .MuiButton-label {
      color: ${colors.white};
    }
  }

  &.MuiButton-root.Mui-disabled,
  &.loading {
    background-color: ${(props) => props.theme.palette.action.disabled};
  }

  &.loading {
    pointer-events: none;
  }

  &:hover {
    opacity: 0.8;
  }
`

export const Progress = styled(CircularProgress)`
  z-index: 2;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
`
