import { RIGHTS } from '.'

export const chargeRights = [
  RIGHTS.consultaTitulosVencidos,
  RIGHTS.consultaTitulosAVencer,
  RIGHTS.consultaNotasFiscais,
  RIGHTS.alteraSenha,
  RIGHTS.consultaAvisos,
  RIGHTS.consultaLancamentos,
  RIGHTS.leituraTermo,
]

export default chargeRights
