import styled from 'styled-components'
import theme, { colors } from 'theme'

export const Container = styled.div`
  border: 1px solid ${theme.palette.divider};

  li {
    padding: 0.75rem;
    border-bottom: 1px solid ${theme.palette.divider};

    display: flex;
    justify-content: space-between;
    gap: 1rem;

    &:nth-child(even) {
      background-color: ${colors.backgroundTable};
    }

    &:last-child {
      border-bottom-color: transparent;
    }

    .info-icon {
      color: ${theme.palette.info.main};
    }
  }
`
