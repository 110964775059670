import styled from 'styled-components'
import { colors } from 'theme'
import { Paper } from '@material-ui/core'

export const Container = styled(Paper)`
  border-radius: 10px;
  margin-bottom: 10px;

  background-color: ${colors.background};

  display: flex;
  padding: 10px 12px;

  .MuiButtonBase-root {
    height: fit-content;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  font-size: 12px;

  small {
    color: ${colors.color500};
    border-bottom: 1px solid ${colors.main};

    margin-top: 6px;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;

  &.invoice-header span {
    color: ${colors.color500};
    font-size: 14px;
  }
`

export const Downloads = styled.div`
  display: flex;
  margin-top: 6px;

  span {
    display: block;
    margin-right: 10px;
    border-radius: 35px;

    padding: 5px 10px;
    background-color: ${colors.color500};
    color: ${colors.white};

    &.pdf {
      background-color: ${colors.notification};
    }
  }
`
