import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  invoices: [],
  count: 0,

  filters: [],
  selectedFilters: [],
}

const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    invoicesRequest(state) {
      state.loading = true
    },
    invoicesSuccess(state, { payload }) {
      state.invoices = payload.invoices
      state.count = payload.count
      state.loading = false
    },
    invoicesFailure(state) {
      state.loading = false
    },
  },
})

export const { invoicesRequest, invoicesSuccess, invoicesFailure } =
  invoices.actions
export default invoices.reducer
