import React from 'react'
import PropTypes from 'prop-types'
import { colors } from 'theme'

import Loader from 'react-spinners/ScaleLoader'
import InfiniteScrollComponent from 'react-infinite-scroll-component'
import { LoaderContainer } from './styles'

export function InfiniteScroll(props) {
  return (
    <InfiniteScrollComponent
      dataLength={props.dataLength}
      hasMore={props.hasMore}
      next={props.onNext}
      className={props.className}
      scrollableTarget={props.scrollableTarget}
      scrollThreshold={props.scrollThreshold}
      loader={
        <LoaderContainer className="InfiniteScroll-LoaderContainer">
          <Loader color={colors.color500} />
          <small>{props.loaderText}</small>
        </LoaderContainer>
      }
    >
      {props.children}
    </InfiniteScrollComponent>
  )
}

InfiniteScroll.propTypes = {
  loaderText: PropTypes.string,
  hasMore: PropTypes.bool,
  scrollableTarget: PropTypes.string,
  scrollThreshold: PropTypes.number,
  dataLength: PropTypes.number,
  onNext: PropTypes.func.isRequired,
}

InfiniteScroll.defaultProps = {
  loaderText: 'Carregando dados...',
  scrollThreshold: 1,
}

export default InfiniteScroll
