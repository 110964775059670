import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { copyObject } from 'services/utils'

import { Grid, Box, Button } from '@material-ui/core'

import {
  Form,
  FormCheckbox,
  FormMultipleAutoComplete,
  FormTextField,
} from 'components/CoForm'

import Dialog from 'components/Dialog'

export default function Mail({ initialData, onSend, ...props }) {
  // ╦ ╦╔═╗╔═╗╦╔═╔═╗
  // ╠═╣║ ║║ ║╠╩╗╚═╗
  // ╩ ╩╚═╝╚═╝╩ ╩╚═╝
  const formRef = useRef()

  // ╦ ╦╔═╗╔╗╔╔╦╗╦  ╔═╗╦═╗╔═╗
  // ╠═╣╠═╣║║║ ║║║  ║╣ ╠╦╝╚═╗
  // ╩ ╩╩ ╩╝╚╝═╩╝╩═╝╚═╝╩╚═╚═╝
  const handleSend = () => {
    const draft = copyObject(formRef.current.values)
    onSend(draft)
  }

  // ╔╦╗╔═╗╦╔╗╔
  // ║║║╠═╣║║║║
  // ╩ ╩╩ ╩╩╝╚╝
  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth="sm"
      actions={
        <Box display="flex" gridGap={8}>
          <Button
            size="medium"
            variant="text"
            color="primary"
            onClick={props.onClose}
          >
            Cancelar
          </Button>
          <Button
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleSend}
          >
            Enviar
          </Button>
        </Box>
      }
    >
      <Box marginY={4}>
        <Form initialData={initialData} formRef={formRef}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField disabled fullWidth label="Para" name="email" />
            </Grid>
            <Grid item xs={12}>
              <FormMultipleAutoComplete
                options={[]}
                label="Cc"
                name="copy_to"
                freeSolo
                limitTags={3}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckbox
                label="Enviar pedido para o meu e-mail"
                name="self_copy"
              />
            </Grid>
          </Grid>
        </Form>
      </Box>
    </Dialog>
  )
}

Mail.propTypes = {
  onSend: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    email: PropTypes.string,
    self_copy: PropTypes.bool,
    copy_to: PropTypes.arrayOf(PropTypes.string),
  }),
}
