import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'
import { TablePagination } from '@material-ui/core'

export default styled(TablePagination)`
  background-color: rgba(207, 216, 220, 0.5);
  .MuiTablePagination-toolbar {
    min-height: 32px;
  }
  .MuiToolbar-root .MuiTypography-root {
    font-size: 10px;
    font-weight: bold;
    color: ${colors.color500};
  }
  .MuiIconButton-root {
    svg,
    path {
      fill: ${colors.color500};
    }

    border-radius: none;
    padding: 6px 12px;
    &:hover {
      background-color: unset;
    }
  }

  .MuiIconButton-root[disabled] {
    svg,
    path {
      fill: ${colors.color300};
    }
  }

  .MuiIconButton-root:hover {
    svg,
    path {
      fill: ${colors.color700};
    }
  }

  @media ${medias.sm} {
  }
`
