import styled from 'styled-components'
import React from 'react'
// --> ASSETS
import { colors } from '../../theme'
import backgroundImage from '../../assets/images/background.jpg'
import logoEmpresa from '../../assets/images/logo-delta-colorido.png'
// --> COMPONENTS
import { Link } from 'react-router-dom'
import { Button } from '../../components'
// --> MEDIAS
import { sm } from '../../consts/medias'
import { Form } from 'components/CoForm'

const Logo = (props) => <img {...props} src={logoEmpresa} alt="Logo" />

const Container = styled.div`
  position: relative;
  height: 100vh;

  display: flex;
  justify-content: flex-end;

  background-image: url(${backgroundImage});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`

const HocLink = ({ className, ...props }) => (
  <div className={className}>
    <Link {...props} />
  </div>
)

const StyledLink = styled(HocLink)`
  font-size: 13px;
  margin-top: 8px;
`

const StyledForm = styled(Form)`
  flex: 1;
  max-width: max(600px, 45%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(999, 999, 999, 0.9);

  .subtitle {
    text-align: center;
    line-height: 150%;
  }

  .subtitle,
  > footer small {
    color: ${colors.color300};
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
  }

  > main {
    width: 60%;

    header {
      display: flex;
      flex-direction: column;

      margin-bottom: 60px;

      img {
        max-width: 240px;
        align-self: center;
        margin: 10px 0;
      }
    }

    .fields {
      display: flex;
      flex-direction: column;
      margin: 8px 0;

      label {
        font-size: 13px;
      }

      .MuiFilledInput-root,
      .MuiInputBase-input:-webkit-autofill {
        background-color: transparent !important;
      }

      .MuiFormControl-root,
      .MuiAutocomplete-root {
        flex: 1 1 auto;
        margin: 8px 0;
      }

      .MuiButton-container-root {
        margin-top: 8px;
        display: flex;
      }

      .hidden {
        display: none;
      }
    }

    .links {
      ${StyledLink} {
        &:nth-child(2) {
          margin-top: 0px;
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom: 0;

    margin-top: 60px;
    padding: 8px;

    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  @media ${sm} {
    padding: 24px;

    .subtitle,
    footer {
      display: none;
    }

    > main {
      width: 100%;
      header img {
        margin: 0;
        margin-bottom: 32px;
      }
    }
  }
`

const SubmitButton = styled(Button)`
  width: 100%;

  height: 45px;
  border-radius: 8px;
  margin: 0 auto;

  > .MuiButton-label {
  }
`

const PasswordControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.MuiTypography-root,
  a {
    font-size: 13px;
  }

  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
`

export {
  Container,
  StyledForm,
  SubmitButton,
  PasswordControl,
  StyledLink,
  Logo,
}
