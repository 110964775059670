import { company } from './types'

export const PATHS = {
  root: process.env.PUBLIC_URL,
  signin: {
    get root() {
      return `${PATHS.root}/login/:token?`
    },
    get link() {
      return `${PATHS.root}/login`
    },
  },
  get signup() {
    return `${PATHS.root}/novousuario`
  },
  get reset() {
    return `${PATHS.root}/novasenha`
  },
  get profile() {
    return `${this.root}/profile`
  },

  admin: {
    get root() {
      return `${PATHS.root}/admin`
    },
    get companies() {
      return `${this.root}/empresas`
    },
    get users() {
      return `${this.root}/usuarios`
    },
  },

  user: {
    get root() {
      return `${PATHS.root}/usuario`
    },
    get home() {
      return `${this.root}/home`
    },
    orders(orderType = ':orderType') {
      return `${this.root}/pedidos/${orderType}`
    },
    get collect() {
      return `${this.root}/coleta`
    },
    get ordersForCollect() {
      return `${this.root}/pedidos/coleta`
    },
    get issueds() {
      return `${this.root}/emitidas`
    },
    get schedule() {
      return `${this.root}/agendamento`
    },
    queries: {
      get root() {
        return `${PATHS.user.root}/consultas`
      },
      get invoices() {
        return `${this.root}/notas-fiscais`
      },
      get shipments() {
        return `${this.root}/carregamentos`
      },
      get tradeReceivables() {
        return `${this.root}/duplicatas`
      },
      get inventory() {
        return `${this.root}/estoque`
      },
      get issuedOrders() {
        return `${this.root}/pedidos-emitidos`
      },
      get billedOrders() {
        return `${this.root}/pedidos-faturados`
      },
      get walletOrders() {
        return `${this.root}/pedidos-em-carteira`
      },
    },

    get releases() {
      return `${this.root}/lancamentos`
    },
  },
  common: {
    get root() {
      return `/comuns`
    },
    get bulletins() {
      return `${this.root}/avisos`
    },
  },
  defaultPath(companyType, _userType) {
    switch (companyType) {
      case company.ECOSIS:
      case company.ADMIN:
        return this.admin.companies
      case company.TRANSPORTADORA:
      case company.CLIENTE:
        return this.user.home
      default:
        return this.common.bulletins
    }
  },
}

export default PATHS
