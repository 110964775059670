import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  options: [],
}

const vehicles = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    getTruckTypesRequest(state) {
      state.loading = true
    },
    getTruckTypesSuccess(state, { payload }) {
      state.loading = false
      state.options = payload
    },
    getTruckTypesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getTruckTypesFailure,
  getTruckTypesRequest,
  getTruckTypesSuccess,
} = vehicles.actions

export default vehicles.reducer
