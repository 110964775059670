import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

export function useSearch(dispatchSearchAction) {
  const dispatch = useDispatch()

  return useCallback(
    (query, min = 3, max = 11) => {
      if (query.length >= min && query.length <= max)
        dispatch(dispatchSearchAction({ search: query }))
    },
    [dispatch, dispatchSearchAction]
  )
}

export default useSearch
