import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import handleErrors from 'services/handleErrors'

import {
  getShipmentUnitiesRequest,
  getShipmentUnitiesSuccess,
  getShipmentUnitiesFailure,
} from './reducer'

function* getShipmentUnities() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.shipmentUnities
    )

    yield put(getShipmentUnitiesSuccess(response.data))
  } catch (error) {
    yield put(getShipmentUnitiesFailure())
    handleErrors(error, 'Não foi possível buscar as unidades de carregamento')
  }
}

export default all([
  takeLatest(getShipmentUnitiesRequest.type, getShipmentUnities),
])
