import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLastDriversRequest } from 'store/modules/comuns/lastDrivers/reducer'
import { FormCombobox } from 'components/CoForm/components'

export function ComboboxLastDrivers(props) {
  const dispatch = useDispatch()
  const { loading, drivers } = useSelector((state) => state.comuns.lastDrivers)
  const options = drivers.map((d) => ({ ...d, value: d.driver }))

  useEffect(() => {
    if (drivers.length === 0) dispatch(getLastDriversRequest())
  }, [dispatch, drivers.length])

  return (
    <FormCombobox
      id="combo-last-drivers"
      options={options}
      loading={loading}
      freeSolo
      autoSelect={false}
      clearOnEscape={false}
      selectOnFocus={false}
      clearOnBlur={false}
      {...props}
    />
  )
}

export default ComboboxLastDrivers
