import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  shipmentCount: [],
  shipmentSqmtr: [],
  orders: {},
  loadings: {},
}

const dashboard = createSlice({
  name: 'carriersDashboard',
  initialState,
  reducers: {
    getDashboardRequest(state) {
      state.loading = true
    },
    getDashboardSuccess(state, { payload }) {
      state.loading = false
      state.shipmentCount = payload.dashb_shipments_count
      state.shipmentSqmtr = payload.dashb_shipments_sqmtr
      state.orders = payload.dashb_orders[0]
      state.loadings = payload.dashb_loadings[0]
    },
    getDashboardFailure(state) {
      state.loading = false
    },
  },
})

export const { getDashboardFailure, getDashboardRequest, getDashboardSuccess } =
  dashboard.actions

export default dashboard.reducer
