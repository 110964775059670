import React, { useEffect, useState } from 'react'
import { ScrollContainer } from './styles'
import { useSelector, useDispatch } from 'react-redux'

import { extractLens } from 'services/utils'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'
import { Product } from 'components/Product'
import { Content } from 'components/Content'

import {
  getReleasesRequest,
  setAddSampleRequest,
} from 'store/modules/users/releases/reducer'
import FilterReleases from './Filter'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function CustomerReleasesPage() {
  const dispatch = useDispatch()
  const releases = useSelector((state) => state.users.releases.releases)
  const loading = useSelector((state) => state.users.releases.loading)

  const [rankings, setRankings] = useState(null)

  useEffect(() => {
    dispatch(getReleasesRequest())
  }, [dispatch])

  const { calculateCurrentRecords, onNext } = useInfiniteScroll({
    step: 25,
  })

  const getFilteredReleases = (releases) => {
    if (rankings !== null) {
      let filteredReleases = releases

      Object.entries(rankings).forEach(([field, filters]) => {
        if (field === 'dsc_produto') {
          filteredReleases = filteredReleases.filter((r) =>
            filters?.length > 0
              ? filters.some((filter) =>
                  r.dsc_produto.toLowerCase().includes(filter.toLowerCase())
                )
              : true
          )
        } else {
          filteredReleases = filteredReleases.filter((r) =>
            filters?.length > 0 ? filters.includes(extractLens(field, r)) : true
          )
        }
      })

      return filteredReleases
    } else {
      return releases
    }
  }

  const filteredReleases = getFilteredReleases(releases)

  const currentRecords = calculateCurrentRecords(filteredReleases)
  const hasMore = currentRecords.length < filteredReleases.length

  const handleAddSample = (product) => {
    dispatch(setAddSampleRequest(product))
  }

  const handleClickBanner = (product) => {
    const a = document.createElement('a')
    a.href = product.prd_link_produto
    a.target = '_blank'
    a.click()
  }

  const handleFilter = (ranking) => {
    setRankings((previousRankings) => ({
      ...previousRankings,
      ...ranking,
    }))
  }

  return (
    <Content
      title="Lançamentos"
      containerId="list-releases-container"
      loading={loading}
      SideComponent={
        releases.length > 0 ? (
          <FilterReleases
            onFilter={handleFilter}
            loading={loading}
            data={releases}
          />
        ) : null
      }
      sideBarOpen
      sideBarStart
    >
      <div>
        <ScrollContainer
          dataLength={currentRecords.length}
          hasMore={hasMore}
          onNext={onNext}
          scrollableTarget="list-releases-container"
          className=""
          loaderText="Carregando Lançamentos..."
        >
          {currentRecords.map((product, index) => (
            <Product
              key={index}
              isSample
              product={product}
              onAddSample={handleAddSample}
              onClickBanner={handleClickBanner}
            />
          ))}
        </ScrollContainer>
      </div>
    </Content>
  )
}

export default CustomerReleasesPage
