import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { copyObject } from 'services/utils'
import { getScheduleOrdersRequest } from 'store/modules/users/ordersForCollect/reducer'
import { translate } from '_i18n'

import SideBar from 'components/SideBar'
import SearchFilter from 'components/SearchFilter'
import { FormDatePicker } from 'components/CoForm'

const InlineDatePicker = (props) => (
  <FormDatePicker disableToolbar variant="inline" {...props} />
)

export default function Filter() {
  const dispatch = useDispatch()
  const searchFilters = useSelector(
    (state) => state.users.ordersForCollect.searchFilters
  )

  const handleSubmit = (values) => {
    const filters = copyObject(values)
    dispatch(getScheduleOrdersRequest(filters))
  }

  const handleClear = () => {
    dispatch(getScheduleOrdersRequest({}))
  }

  return (
    <SideBar
      ContentComponent={
        <>
          <SearchFilter
            inline={false}
            initialData={searchFilters}
            onSubmit={handleSubmit}
            onClear={handleClear}
            title={translate('actions.searchBy')}
            buttonLabel="Pesquisar"
            fields={[
              { name: 'cod_pedido', label: 'Pedido' },
              { name: 'ped_seupedido', label: 'Seu Pedido' },
              {
                name: 'ped_data_emissao',
                label: 'Data de Emissão',
                Component: InlineDatePicker,
                gutterBottom: true,
              },
              {
                name: 'cod_produto',
                label: 'Código do Produto',
              },
              {
                name: 'dsc_abreviado',
                label: 'Descrição do Produto',
              },
            ]}
          />
        </>
      }
    />
  )
}
