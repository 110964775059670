import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as medias from 'consts/medias'
import { useMediaQuery } from '@material-ui/core'
import Content from 'components/Content'
import { Tabs, TabNavigation, useTabContext } from 'components/TabNavigation'
import { OrderPage } from '../../Orders/Order'
import { OrderProductsPage, FilterProducts } from '../../Orders/Products'
import { FilterIssued, IssuedOrders } from '../../Orders/IssuedOrders'
import { Container, TabsContainer, StyledTabPanel } from './styles'

const CONTAINER_PRODUCTS_ID = 'list-products-container'

export function CustomerBudgetsContent() {
  const md = useMediaQuery(medias.md)
  const { activeTab } = useTabContext()

  const ordersLoading = useSelector(
    (state) => state.users.orders.orders.loading
  )

  const productsLoading = useSelector(
    (state) => state.users.orders.products.loading
  )

  const [containerElem, setContainerElem] = useState(null)

  useEffect(() => {
    const container = document.querySelector(`#${CONTAINER_PRODUCTS_ID}`)
    if (container) setContainerElem(container)
  }, [])

  const getSideComponent = (contentProps) => {
    switch (activeTab) {
      case 0:
        contentProps.SideComponent = <FilterProducts />
        break
      case 2:
        contentProps.SideComponent = <FilterIssued />
        break
      default:
        break
    }
  }

  const getContentProps = () => {
    const contentProps = {
      sideBarOpen: true,
      sideBarStart: true,
      loading: ordersLoading || productsLoading,
      SideComponent: md ? null : <div />,
    }

    getSideComponent(contentProps)

    return contentProps
  }

  return (
    <Content {...getContentProps()}>
      <Container>
        <TabsContainer>
          <Tabs
            tabs={[
              { label: 'Produtos' },
              { label: 'Orçamento' },
              { label: 'Emitidos' },
            ]}
          />
        </TabsContainer>
        <StyledTabPanel
          className="filled"
          index={0}
          containerId={CONTAINER_PRODUCTS_ID}
        >
          <OrderProductsPage
            scrollableTargetId={CONTAINER_PRODUCTS_ID}
            scrollableTarget={containerElem}
          />
        </StyledTabPanel>
        <StyledTabPanel index={1}>
          <OrderPage />
        </StyledTabPanel>
        <StyledTabPanel index={2}>
          <IssuedOrders />
        </StyledTabPanel>
      </Container>
    </Content>
  )
}

export default function CustomerBudgetsPage(props) {
  return (
    <TabNavigation>
      <CustomerBudgetsContent {...props} />
    </TabNavigation>
  )
}
