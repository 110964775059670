import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  dates: [],
}

const datesSlicer = createSlice({
  name: 'dates',
  initialState,
  reducers: {
    getPossibleDatesRequest(state) {
      state.loading = true
    },
    getPossibleDatesSuccess(state, { payload }) {
      state.loading = false
      state.dates = payload
    },
    getPossibleDatesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getPossibleDatesFailure,
  getPossibleDatesRequest,
  getPossibleDatesSuccess,
} = datesSlicer.actions

export default datesSlicer.reducer
