import styled from 'styled-components'
import SideComponent from 'components/SideBar'
import { Rows } from 'components/Ranking/styles'

export const StyledSideComponent = styled(SideComponent)`
  background-color: #fff;
  padding: 1rem;

  .Header {
    margin: 1rem 0;
  }

  ${Rows} {
    max-height: calc(22px * 8.5);
  }
`
