import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Draggable from 'react-draggable'
import { Paper } from '@material-ui/core'
import {
  StyledDialog,
  StyledDialogTitle,
  StyledDialogActions,
  StyledDialogContent,
} from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const draggableId = 'draggable-dialog-title'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function PaperComponent(props) {
  return (
    <Draggable
      handle={`#${draggableId}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function Dialog({ draggable, title, children, actions, ...props }) {
  return (
    <StyledDialog PaperComponent={PaperComponent} {...props}>
      <StyledDialogTitle
        className={clsx({ draggable })}
        id={draggable ? draggableId : ''}
      >
        {title}
      </StyledDialogTitle>
      <StyledDialogContent>{children}</StyledDialogContent>
      {actions && <StyledDialogActions>{actions}</StyledDialogActions>}
    </StyledDialog>
  )
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  draggable: PropTypes.bool,
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
}

Dialog.defaultProps = {
  draggable: true,
}

export default Dialog
