import React from 'react'
import { Switch } from 'react-router-dom'
import PATHS from '../consts/paths'
import { SigninPage, ResetPasswordPage } from '../pages/Auth'
import LayoutPage from '../pages/Layout'
import Route from './Route'

export default function Routes() {
  return (
    <Switch>
      <Route path={PATHS.signin.root} exact component={SigninPage} />
      <Route path={PATHS.reset} exact component={ResetPasswordPage} />
      <Route path={PATHS.root} component={LayoutPage} isPrivate />
    </Switch>
  )
}
