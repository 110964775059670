import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Checkbox, TextField } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const DEFAULT_COLOR = '#202020'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

/**
 *
 * @param {Array} options - array of options
 * @param {string} name - array of options
 * @returns
 */

export function MultipleAutoComplete({
  value,
  options,
  name,
  onChange,
  onSearch,
  label,
  InputLabelProps,
  ...props
}) {
  const handleChange = (_event, options) => {
    onChange({
      target: {
        name,
        value: options.map((option) => option.value || option),
      },
    })
  }
  const handleSearch = (event) => {
    if (typeof onSearch === 'function') {
      const value = event.target.value
      onSearch({ value, name })
    }
  }

  const getOptionLabel = (option) => {
    switch (typeof option) {
      case 'string':
        return option
      case 'object':
      default:
        return option.label ?? option.value.toString()
    }
  }

  const multipleAutoCompleteValue =
    typeof value === 'object' ? value : undefined

  return (
    <Autocomplete
      multiple
      value={multipleAutoCompleteValue}
      options={options}
      disableCloseOnSelect
      noOptionsText="Nenhuma opção encontrada."
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <span style={{ color: option.color ?? DEFAULT_COLOR }}>
            {option.label}
          </span>
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={props.variant}
          onChange={handleSearch}
          InputLabelProps={InputLabelProps}
        />
      )}
      {...props}
    />
  )
}

MultipleAutoComplete.propTypes = {
  value: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onSearch: PropTypes.func,
}

export default MultipleAutoComplete
