import React from 'react'
import PropTypes from 'prop-types'
import { FormHelperText } from '@material-ui/core'
import {
  StyledFormControl,
  StyledFormLabel,
  StyledRadioGroup,
  StyledFormControlLabel,
  StyledRadio,
} from './styles'

export function RadioGroup({
  options,
  label,
  onChange,
  onAfterChange,
  error,
  helperText,
  ...props
}) {
  function handleChange(e) {
    e.persist()
    const value = e.target.value
    onChange({ ...e, target: { name: props.name, value } }, value)

    if (onAfterChange) {
      onAfterChange(value, e)
    }
  }

  return (
    <StyledFormControl
      fullWidth
      size="small"
      component="fieldset"
      error={error}
      margin="dense"
    >
      {label && <StyledFormLabel component="legend">{label}</StyledFormLabel>}
      <StyledRadioGroup onChange={handleChange} {...props}>
        {options.map((option, index) => (
          <StyledFormControlLabel
            key={index}
            value={option.value}
            disabled={option.disabled}
            control={<StyledRadio color="primary" size="small" />}
            label={option.label || option.value}
          />
        ))}
      </StyledRadioGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  )
}

RadioGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      disabled: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ).isRequired,
}

export default RadioGroup
