import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'

import Paper from 'components/Paper'
import { Form } from 'components/CoForm'
import Button from 'components/Button'
import CheckBox from 'components/Checkbox'

import { Send } from 'components/Icons'

export const Container = styled(Paper)`
  margin: 15px 0;
  border: none;
  position: relative;
  display: flex;

  .empty-text {
    color: ${colors.color400};
  }

  @media ${medias.downLg} {
    flex-direction: column;
    align-items: center;
  }
`

export const CollectList = styled.div`
  flex: 3;
  margin-right: 13px;
  width: 100%;

  @media ${medias.downLg} {
    flex: 1;
    margin-right: 0;

    margin-bottom: 13px;
    padding-bottom: 13px;
    border-bottom: 2px solid ${colors.color100};
  }
`

export const Header = styled.div`
  margin-bottom: 10px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const StyledCheckBox = styled(CheckBox)`
  ~ span.MuiTypography-root {
    font-size: 12.5px;
    color: ${colors.color500};
  }
`

// ------------------------------------

export const FinishForm = styled(Form)`
  position: sticky;
  top: 0;

  max-width: 400px;
  min-width: 300px;
  height: fit-content;

  background-color: ${colors.white};
  border: 1px solid ${colors.color100};

  border-radius: 3px;
  padding: 8px 16px;

  flex: 1;
  display: flex;
  flex-direction: column;

  > h4 {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 17px;
  }

  > h5 {
    font-weight: normal;
    color: ${colors.color300};
    border-bottom: 1px solid ${colors.color100};
  }

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 9px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .buttons {
    display: flex;
    justify-content: center;
  }

  @media ${medias.downLg} {
    position: relative;
    flex: 1;

    width: 100%;
    max-width: min(100%, 400px);
  }
`

export const Margin = styled.span`
  display: block;
  margin-bottom: 45px;

  @media ${medias.downLg} {
    margin-bottom: 25px;
  }
`

export const StyledButton = styled(Button)`
  border-radius: 7px;
  padding: 6px 12px;
  background-color: ${(props) => props.theme.palette.success.main};

  &:hover {
    background-color: ${(props) => props.theme.palette.success.light};
  }

  &.red {
    background: ${(props) => props.theme.palette.error.main};
    &:hover {
      background: ${(props) => props.theme.palette.error.light};
    }
  }

  .MuiButton-label {
    color: ${colors.white};
  }

  .MuiButton-endIcon {
    margin-bottom: 4px;
  }
`

export const SendIcon = styled(Send)`
  color: ${colors.white};
  transform: rotate(-38.87deg);
`
