import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOrderItemRequest } from 'store/modules/users/orders/orders/reducer'
import { getProductsRequest } from 'store/modules/users/orders/products/reducer'
import { useInfiniteScroll } from 'hooks/useInfiniteScroll'

import Product from 'components/Product'
import FilterProducts from './Filter'
import { ScrollContainer } from './styles'

export function OrderProductsPage({ scrollableTargetId, scrollableTarget }) {
  const dispatch = useDispatch()
  const products = useSelector((state) => state.users.orders.products.products)

  const { calculateCurrentRecords, onNext, reset } = useInfiniteScroll({
    step: 25,
  })

  useEffect(() => {
    dispatch(getProductsRequest())
  }, [dispatch])

  useEffect(() => {
    reset()

    // Return scroll to beginning
    if (scrollableTarget) {
      scrollableTarget.scrollTop = 0
    }
  }, [reset, scrollableTarget, products])

  const handleCreateItem = (orderItem) => {
    dispatch(createOrderItemRequest(orderItem))
  }

  const currentRecords = calculateCurrentRecords(products)
  const hasMore = currentRecords.length < products.length

  return (
    <ScrollContainer
      dataLength={currentRecords.length}
      hasMore={hasMore}
      onNext={onNext}
      scrollableTarget={scrollableTargetId}
      className="MuiContainer-root MuiContainer-maxWidthXl"
      loaderText="Carregando produtos..."
    >
      {currentRecords.map((product, index) => (
        <Product key={index} product={product} onAddItem={handleCreateItem} />
      ))}
    </ScrollContainer>
  )
}

export { FilterProducts }
export default OrderProductsPage
