/**
 * Configuração dos perfis de acesso.
 */
export const RIGHTS = {
  // ==== USUARIO (1 - 100) ==== //
  alteraSenha: {
    id: 1,
    label: 'Alteração da senha',
  },
  alteraPreferencias: {
    id: 2,
    label: 'Alteração das preferências',
  },
  leituraTermo: {
    id: 3,
    label: 'Termo',
  },
  solicitacaoAltorizacao: {
    id: 4,
    label: 'Solicitações de Autorização',
  },
  // ==== PEDIDOS[AMOSTRA, ORÇAMENTO, VENDA] (101 - 200) ==== //
  reImprimePedidos: {
    id: 101,
    label: 'Reimpressão de pedidos',
  },
  incluiOrcamento: {
    id: 102,
    label: 'Inclusão de orçamentos',
  },
  alteraOrcamento: {
    id: 103,
    label: 'Alteração de orçamentos',
  },
  aprovaOrcamento: {
    id: 104,
    label: 'Aprovação de orçamentos',
  },
  // ==== AGENDAMENTOS (201 - 300) ==== //
  incluiAgendamento: {
    id: 201,
    label: 'Inclusão de agendamentos',
  },
  alteraAgendamento: {
    id: 202,
    label: 'Alteração de agendamentos',
  },
  // ==== RELATORIOS (301 - 400) ==== //
  relPedidosEmCarteira: {
    id: 301,
    label: 'Relatório de pedidos em carteira',
  },
  relPedidosFaturados: {
    id: 302,
    label: 'Relatório de pedidos em carteira',
  },
  relEstoque: {
    id: 303,
    label: 'Relatório de estoque',
  },
  // ==== CONSULTAS (401 - 500) ==== //
  consultaBoletos: {
    id: 401,
    label: 'Consulta de boletos',
  },
  consultaTitulosVencidos: {
    id: 402,
    label: 'Consulta de titulos vencidos',
  },
  consultaTitulosAVencer: {
    id: 403,
    label: 'Consulta de titulos a vencer',
  },
  consultaCarregamentos: {
    id: 404,
    label: 'Consulta carregamentos',
  },
  consultaNotasFiscais: {
    id: 405,
    label: 'Consulta e reimpressão das notas fiscais',
  },
  consultaAvisos: {
    id: 406,
    label: 'Avisos',
  },
  // ==== LANÇAMENTOS (501 - 600) ==== //
  consultaLancamentos: {
    id: 501,
    label: 'Lançamentos',
  },
}

export default RIGHTS
