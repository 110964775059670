import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { updateCollectItemRequest } from 'store/modules/users/schedules/reducer'

import { copyObject } from 'services/utils'

import Dialog from 'components/Dialog'
import Button from 'components/Button'
import { FormTextField } from 'components/CoForm'
import { StyledForm } from './styles'

function CollectRowFormEdit({ current, onClose, ...props }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  function handleSave() {
    const draft = copyObject(formRef.current.values)

    dispatch(updateCollectItemRequest({ current, updateParams: draft }))
    onClose()
  }

  function Actions() {
    return (
      <>
        <Button
          className="large success light regular"
          label="Salvar"
          onClick={handleSave}
        />
        <Button
          className="large error light regular"
          label="Cancelar"
          onClick={onClose}
        />
      </>
    )
  }

  return (
    <Dialog
      actions={<Actions />}
      title="Alterar a Qtdade. de Pallets"
      fullScreen={false}
      onClose={onClose}
      {...props}
    >
      <StyledForm onSubmit={handleSave} formRef={formRef} initialData={current}>
        <FormTextField
          fullWidth
          label="Pallets"
          type="number"
          name="vpa_pallet"
          variant="standard"
          inputProps={{
            min: 1,
            max: Number(current.vpa_pallet),
          }}
        />
      </StyledForm>
    </Dialog>
  )
}

CollectRowFormEdit.propTypes = {
  current: PropTypes.object,
}

export default CollectRowFormEdit
