import React, { useEffect, useState } from 'react'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'

import TextField from 'components/TextField'
import ComboboxBase from '../Base'
import handleErrors from 'services/handleErrors'

export function ComboboxOrderCancelReasons(props) {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const fetchCancelReasons = () => {
      api
        .get(apiEndPoints.miscellaneous.cancelReasons)
        .then((response) => response.data)
        .then((reasons) => {
          const options = reasons.map((r) => ({
            ...r,
            value: r.ctt_campo,
            label: r.dsc_conteudo,
          }))

          setOptions(options)
        })
        .catch((err) =>
          handleErrors(
            err,
            'Não foi poosível buscar os motivos de cancelamento'
          )
        )
    }
    fetchCancelReasons()
  }, [])

  return (
    <ComboboxBase
      options={options}
      renderInput={(inputProps) => (
        <TextField
          variant="outlined"
          label="Motivo do Cancelamento"
          {...inputProps}
        />
      )}
      {...props}
    />
  )
}

export default ComboboxOrderCancelReasons
