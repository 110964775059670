import { IconButton } from '@material-ui/core'

const defaultSize = { height: 20, width: 20 }
// const svgs = require.context('../../assets/images/bancos', false, /\.svg$/)

// const logos = svgs.keys().reduce((acc, cur) => {
//   const name = cur.replace('./', '').replace('.svg', '')
//   acc[name] = svgs(cur)
//   return acc
// }, {})

// console.log(logos)

export function Logo(props) {
  // const logo = logos[props.bank]
  // console.log(logo)

  const image = `/images/bancos/${props.bank}.svg`

  return (
    <IconButton title="Emitir boleto" size="small" {...props}>
      <img
        src={image}
        alt={props.bank}
        style={{
          height: defaultSize.height,
          width: defaultSize.width,
        }}
      />
    </IconButton>
  )
}

export default Logo
