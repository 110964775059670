import { LOCALES } from '../locales'

export const en = {
  [LOCALES.ENGLISH]: {
    carriers: 'Carriers',
    carrier: 'Carrier',
    toolbar: {
      title: '{companyType}',
      changeLanguage: 'Change Language',
      menus: {
        orders: 'Orders',
        collect: 'Collect',
        issued: 'Issued',
        queries: 'Queries',
      },
    },
    orders: {
      filter: {
        requestsFor: 'Order by {typeFilter}',
      },
      table: {
        order: 'ORDER',
        company: 'CMP',
        product: 'PRODUCT',
        pallets: '#PALLETS',
        available: 'AVAILABLE',
        balance: 'BALANCE',
        boxes: 'BOXES',
        weight: 'WEIGHT (lb)',
        delivery: 'DELIVERY',
        freight: 'FREIGHT',
        representative: 'REPRESENTATIVE',
        scheduling: 'SCHEDULING',
      },
    },
    issued: {
      emissionFilter: {
        issuedBy: 'Issued by {typeFilter}',
      },
      table: {
        collect: 'COLLECT:',
        collectionDate: 'COLLECTION DATE:',
        plate: 'PLATE',
        driver: 'DRIVER',
        O_C: 'o.c.',
      },
    },

    collect: {
      includeButtonText: 'Include on Collect',
      collect: 'Collect:',
      collectNum: 'COLETA {collect}',
      weightText: 'Peso {typeWeight} {weight}',
      selected: 'selected:',
      total: 'Total:',
      collectBox: {
        truckType: 'Type of truck',
        datePlanned: 'Expected Collection Date',
        vehicleCapacity: 'Total Vehicle Capacity',
        collectionWeight: 'Total weight of the collection',
        avaialble: 'Available for loading',
      },
    },
    queries: {
      uploads: {
        inputDate: 'INPUT DATE',
        attending: 'ATTENDANCE',
        plate: 'PLATE',
        driver: 'DRIVER',
        password: 'PASSWORD',
        cargo: 'CARGO',
        entry: 'ENTRY',
        exit: 'EXIT',
      },
      invoices: {
        invoice: 'INVOICE',
        issue: 'ISSUE',
        customer: 'CUSTOMER',
        city: 'CITY',
        M2: 'UF',
        heigth: 'HEIGTH',
        value: 'VALUE',
        periodFilter: {
          period: 'Inform the period of time',
          initialDate: 'Initial Date',
          finalDate: 'Final Date',
        },
      },
    },
    comumns: {
      filter: {
        loadingText: 'Configuring Filters',
      },
      stateInitials: 'UF',
      city: 'City',
      driver: 'Driver',
      plate: 'Plate',
      yourOrder: 'Your Order',
      issueDate: 'Issue Date',
      approved: 'Approved',
      canceled: 'Canceled',
      loading: 'Loading',
      finished: 'Finished',
      waiting: 'Waiting',
      released: 'released',
    },
    actions: {
      signout: 'Sign out',
      searchBy: 'Search by',
      includeButtonText: 'Include in the Collection',
      deleteButton: 'Delete Selected',
      selectAll: 'Select All',
      send: 'Send',
      check: 'See issued invoices',
      consultShipments: 'Consult shipments',
      search: 'Search',
    },
  },
}
