import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { PATHS } from '../../consts/paths'
import { translate } from '../../_i18n'
import { collectCountRequest } from 'store/modules/users/collections/reducer'

import { MenuItem } from './MenuItem'
import { Grid, Collect, Issued, Queries, Home } from '../Icons'

export const CarrierMenuItems = () => {
  const dispatch = useDispatch()

  const collectCount = useSelector((state) => state.users.collections.count)
  const collect = useSelector((state) => state.users.collections.active.collect)

  useEffect(() => {
    dispatch(collectCountRequest())
  }, [dispatch, collect])

  return (
    <>
      <MenuItem
        Icon={Home}
        label={translate('toolbar.menus.home')}
        path={PATHS.user.home}
      />
      <MenuItem
        Icon={Grid}
        label={translate('toolbar.menus.orders')}
        path={PATHS.user.ordersForCollect}
      />
      <MenuItem
        Icon={Collect}
        label={translate('toolbar.menus.collect')}
        path={PATHS.user.collect}
        badgeContent={collectCount}
      />
      <MenuItem
        Icon={Issued}
        label={translate('toolbar.menus.issued')}
        path={PATHS.user.issueds}
      />
      <MenuItem
        Icon={Queries}
        label={translate('toolbar.menus.queries')}
        path={PATHS.user.queries.root}
      />
    </>
  )
}

export default CarrierMenuItems
