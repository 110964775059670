import styled from 'styled-components'
import { rgba } from 'polished'
import { Badge, IconButton } from '@material-ui/core'

import { colors } from 'theme'
import * as medias from 'consts/medias'

const px = (n) => `${n}px`

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const StyledButton = styled(IconButton)`
  .MuiSvgIcon-root {
    color: ${colors.white};
  }
`

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    top: ${(props) => px(props.top)};
    right: ${(props) => px(props.right)};

    height: 16px;
    border-radius: 0px 5px 0px 4px;
    background-color: ${colors.notification};
    box-shadow: 0 0 0 0 ${rgba(colors.notification, 1)};

    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    transform: scale(1);
    transition: 200ms ease-in-out;
  }

  .MuiBadge-pulse {
    animation: pulse 2000ms backwards;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${rgba(colors.notification, 0.7)};
    }

    70% {
      box-shadow: 0 0 0 10px ${rgba(colors.notification, 0)};
    }

    100% {
      box-shadow: 0 0 0 0 ${rgba(colors.notification, 0)};
    }
  }

  @media ${medias.md} {
    .MuiBadge-badge {
      top: 0;
      right: 0;

      height: unset;
      border-radius: 50%;
    }

    &.active .MuiBadge-badge {
      top: 0;
      right: 0;
    }
  }

  @media ${medias.sm} {
    .MuiBadge-pulse {
      animation: none;
    }
  }
`

export const StyledMenuItem = styled.div`
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }

  > a {
    color: ${colors.color500};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MuiSvgIcon-root {
    color: ${colors.white};
    font-size: 3rem;
  }

  .MenuItem-label {
    font-size: 13px;
    line-height: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    border-bottom: 4px solid transparent;
    width: 100%;
    max-width: 48px;
    margin-top: 13px;
  }

  &[disabled] {
    user-select: none;
    pointer-events: none;
    display: none;
    > a {
      color: ${(props) => props.theme.palette.action.disabled};
    }
  }

  &.active {
    &::after {
      border-bottom-color: ${colors.color500};
    }
  }

  &.active,
  &:hover {
    .MenuItem-label {
      font-weight: bold;
      color: ${colors.color700};
    }

    svg path {
      fill: ${colors.main};
    }
  }

  @media ${medias.md} {
    /* min-width: min(200px, 25vw); */
    margin-right: 0;

    .MenuItem-label {
      display: none;
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
    }

    > a {
      padding: 6px 12px;
      color: ${colors.white};

      border-bottom: 1px solid ${colors.color600};

      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }

    &::after {
      display: none;
    }

    &.active,
    &:hover {
      > a {
        background-color: ${colors.color600};
        text-decoration: none;
        .MuiSvgIcon-root {
          font-size: 2rem;
        }
        .MenuItem-label {
          color: ${colors.white};
        }
      }
    }
  }
`
