import { all, takeLatest, call, put } from 'redux-saga/effects'
import { merge } from 'ramda'
import { toast } from 'react-toastify'

import { PATHS } from 'consts/paths'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import history from 'services/history'
import handleErrors from 'services/handleErrors'
import { saveProfileTheme } from 'services/colors'

import { store } from 'store/index'
import { confirmationDialog } from 'components/ConfirmationDialog'

import { configAuthorization, getRights } from '../../auth/sagas'
import { signInSuccess } from '../../auth/reducer'
import { setUser } from '../../user/reducer'

import {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  createUserRequest,
  createUserSuccess,
  updateUserRequest,
  updateUserSuccess,
  removeUserRequest,
  removeUserSuccess,
  removeUserCancel,
  handleSaveUser,
  errorOnSave,
  closeFormEdit,
  sendInviteRequest,
  sendInviteSuccess,
  sendInviteCancel,
  logIsAsWebUserRequest,
} from './reducer'

import { updateLoggedUser } from '../../user/reducer'

function* getUsers() {
  try {
    const response = yield call(api.get, apiEndPoints.webUsers.root)
    const webUsers = response.data

    yield put(getUsersSuccess(webUsers))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os usuários')
    yield put(getUsersFailure())
  }
}

function* createUser({ payload }) {
  try {
    const response = yield call(api.post, apiEndPoints.webUsers.root, payload)
    const createdWebUser = response.data

    toast.success('Usuário cadastrado com sucesso')

    // -------------------------------

    yield put(createUserSuccess(createdWebUser))
    yield put(closeFormEdit())
  } catch (error) {
    handleErrors(error, 'Não foi possível criar o usuário')
    yield put(errorOnSave())
  }
}

function* updateUser({ payload }) {
  try {
    const loggedUser = store.getState().user.profile.web_user

    const { formValues, webUser } = payload
    const response = yield call(
      api.put,
      apiEndPoints.webUsers.onMember(webUser.id),
      formValues
    )
    const updatedWebUser = response.data

    toast.success('Usuário atualizado com sucesso')

    // -------------------------------

    yield put(updateUserSuccess(updatedWebUser))

    if (loggedUser.weu_id === webUser.id)
      yield put(updateLoggedUser(merge(loggedUser, updatedWebUser)))

    yield put(closeFormEdit())
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o usuário')
    yield put(errorOnSave())
  }
}

function* removeUser({ payload: webUser }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Remover Usuário',
      <>
        <div>Tem certeza que deseja remover o usuário {webUser.weu_nome} ?</div>
        <div>- {webUser.company.emp_razao_social}</div>
      </>
    )

    confirmationDialog.close()

    if (!confirm) {
      yield put(removeUserCancel())
      return
    }

    // --------------------------------

    yield call(api.delete, apiEndPoints.webUsers.onMember(webUser.id))
    yield put(removeUserSuccess(webUser))

    toast.success('Usuário removido com sucesso')
  } catch (error) {
    yield put(removeUserCancel())
    handleErrors(error, 'Não foi possível remover o usuário')
  }
}

function* sendInvite({ payload: webUser }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Enviar Convite',
      `Enviar convite para ${webUser.weu_email} ?`
    )

    confirmationDialog.close()

    if (!confirm) {
      yield put(sendInviteCancel())
      return
    }

    // --------------------------------

    const response = yield call(api.post, apiEndPoints.invites.root, {
      weu_id: webUser.id,
    })
    const recipient = response.data.weu_id

    yield put(sendInviteSuccess(recipient))
    toast.success('Convite enviado com sucesso')
  } catch (error) {
    yield put(sendInviteCancel())
    handleErrors(error, 'Não foi possível enviar o convite')
  }
}

function* logIsAsWebUser({ payload: webUser }) {
  try {
    const response = yield call(
      api.post,
      apiEndPoints.miscellaneous.changeUser,
      { weu_id: webUser.id }
    )

    const token = response.data.auth_token
    const profile = response.data.profile

    // Access profile
    const profiles = profile.web_user.profiles
    const rights = yield call(getRights, profiles)

    configAuthorization(token)
    saveProfileTheme(profile.web_company.wee_tipo)

    yield put(signInSuccess({ token, rights }))
    yield put(setUser(profile))

    /** Go to company type default path, ex: transportadora/pedidos */
    history.push(
      PATHS.defaultPath(profile.web_company.wee_tipo, profile.web_user.weu_tipo)
    )
  } catch (error) {
    handleErrors(error, 'Não foi possível completar o acesso')
  }
}

// --------------------------------

function* handleSave({ payload }) {
  const isUpdate = payload.webUser
  if (isUpdate) yield put(updateUserRequest(payload))
  else yield put(createUserRequest(payload.formValues))
}

export default all([
  takeLatest(getUsersRequest.type, getUsers),
  takeLatest(handleSaveUser.type, handleSave),
  takeLatest(createUserRequest.type, createUser),
  takeLatest(updateUserRequest.type, updateUser),
  takeLatest(removeUserRequest.type, removeUser),
  takeLatest(sendInviteRequest.type, sendInvite),
  takeLatest(logIsAsWebUserRequest.type, logIsAsWebUser),
])
