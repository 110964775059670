import React, { useState, useEffect } from 'react'
import Client from '../../services/eventEmitter'

import { StyledDialog, Container, StyledButton } from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const client = Client

const events = {
  open: '@portalDelta/CONFIRMATIONDIALOG/open',
  close: '@portalDelta/CONFIRMATIONDIALOG/close',
  confirm: '@portalDelta/CONFIRMATIONDIALOG/confirm',
  cancel: '@portalDelta/cancelATIONDIALOG/cancel',
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

export const confirmationDialog = {
  async open(title, message, { onlyMessage = false } = {}) {
    client.emit(events.open, { title, message, onlyMessage })

    return new Promise((resolve) => {
      client.once(events.confirm, () => resolve(true))
      client.once(events.cancel, () => resolve(false))
    })
  },
  close() {
    client.emit(events.close)
  },
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ConfirmationDialog() {
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    message: '',
    onlyMessage: false,
  })

  function handleOpen(options) {
    setDialog({
      open: true,
      title: options.title,
      message: options.message,
      onlyMessage: options.onlyMessage,
    })
  }

  function handleClose() {
    setDialog((previousDialog) => ({ ...previousDialog, open: false }))
  }

  function handleConfirm() {
    client.emit(events.confirm)
  }

  function handleCancel() {
    client.emit(events.cancel)
  }

  useEffect(() => {
    client.on(events.open, handleOpen)
    client.on(events.close, handleClose)

    return () => {
      client.removeEventListener(events.open, handleOpen)
      client.removeEventListener(events.close, handleClose)
    }
  }, [])

  return (
    <StyledDialog
      open={dialog.open}
      title={dialog.title}
      actions={
        dialog.onlyMessage ? (
          <StyledButton
            className="cancelButton"
            type="button"
            onClick={handleCancel}
          >
            Fechar
          </StyledButton>
        ) : (
          <>
            <StyledButton
              className="confirmButton"
              type="button"
              onClick={handleConfirm}
            >
              Sim
            </StyledButton>
            <StyledButton
              className="cancelButton"
              type="button"
              onClick={handleCancel}
            >
              Não
            </StyledButton>
          </>
        )
      }
    >
      <Container className="border-none">
        <div>{dialog.message}</div>
      </Container>
    </StyledDialog>
  )
}

export default ConfirmationDialog
