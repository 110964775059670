import { all, takeLatest, call, put } from 'redux-saga/effects'
import api from '../../../../services/api'
import handleErrors from '../../../../services/handleErrors'
import apiEndPoints from '../../../../consts/apiEndPoints'
import {
  getLastVehiclesSuccess,
  getLastVehiclesFailure,
  getLastVehiclesRequest,
} from './reducer'

function* getLastVehicles() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.lastVehicles
    )

    yield put(getLastVehiclesSuccess(response.data))
  } catch (error) {
    yield put(getLastVehiclesFailure())
    handleErrors(error, 'Não foi possível buscar as placas')
  }
}

export default all([takeLatest(getLastVehiclesRequest.type, getLastVehicles)])
