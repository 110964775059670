import styled from 'styled-components'

import MuiDivider from '@material-ui/core/Divider'
import Dialog from 'components/Dialog'

import {
  GroupContainer,
  GroupHeader,
  GroupBody,
} from 'components/Table/TableGroup'

export const StyledGroupContainer = styled(GroupContainer)`
  flex-direction: column;
  width: 100%;

  .multiple-unity-disclaimer {
    margin: 0.75rem 0;
    font-size: 0.875rem;
    font-weight: 900;
    text-align: center;
  }
`

export const StyledGroupHeader = styled(GroupHeader)`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;

  .group__title {
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .MuiChip-label {
      padding: 0 9px;
    }
  }

  > div b {
    margin-right: 5px;
  }
`

export const StyledGroupBody = styled(GroupBody)`
  padding: 3px 0;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const Actions = styled.div`
  display: flex;

  .MuiButton-container-root:not(:disabled) {
    height: 30px;
    border-radius: 5px;

    background-color: ${({ theme }) => theme.palette.success.dark};

    .MuiButton-label {
      font-weight: normal;
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  .MuiButton-container-root.edit {
    background-color: ${({ theme }) => theme.palette.info.dark};
    &:hover {
      background-color: ${({ theme }) => theme.palette.info.main};
    }
  }

  .MuiButton-container-root.cancel {
    background-color: ${({ theme }) => theme.palette.error.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }

  .MuiButton-container-root {
    margin-right: 10px;
  }
`

export const Divider = styled(MuiDivider)`
  margin: 5px 0;
`

export const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    padding: 10px 12px;
    form {
      border: none;
    }
  }
`
