import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'

export const Container = styled.div`
  position: fixed;
  z-index: 2;

  top: 50%;
  left: 50vw;

  > div {
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;

    > .loading-text {
      margin-top: 10px;
      line-height: 150%;
      color: ${colors.color500};
    }
  }

  @media ${medias.md} {
    left: 40vw;
  }
`
