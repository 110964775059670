import { all, takeLatest, call, put } from 'redux-saga/effects'
import api from '../../../../services/api'
import handleErrors from '../../../../services/handleErrors'
import apiEndPoints from '../../../../consts/apiEndPoints'
import {
  getLastDriversSuccess,
  getLastDriversFailure,
  getLastDriversRequest,
} from './reducer'

function* getLastDrivers() {
  try {
    const response = yield call(api.get, apiEndPoints.miscellaneous.lastDrivers)

    yield put(getLastDriversSuccess(response.data))
  } catch (error) {
    yield put(getLastDriversFailure())
    handleErrors(error, 'Não foi possível buscar os motoristas')
  }
}

export default all([takeLatest(getLastDriversRequest.type, getLastDrivers)])
