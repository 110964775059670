import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getFormatsRequest } from 'store/modules/users/orders/products/reducer'

import { FormCombobox } from '../../CoForm'

export function ComboboxFormats(props) {
  const dispatch = useDispatch()
  const formats = useSelector(
    (state) => state.users.orders.products.productFormats
  )

  useEffect(() => {
    dispatch(getFormatsRequest())
  }, [dispatch])

  const options = formats.map((f) => ({
    value: f.id,
    label: f.descr,
  }))

  return <FormCombobox options={options} {...props} />
}

export default ComboboxFormats
