import React from 'react'
import Route from './Route'
import { PATHS } from 'consts/paths'

import CustomersHomePage from '../pages/Users/Customers/Home'
import CustomersSchedulePage from '../pages/Users/Customers/Schedule'
import CustomerBudgetPage from '../pages/Users/Customers/Budget'
import CustomerReleasesPage from '../pages/Users/Customers/Releases'
import QueryShipmentsPage from '../pages/Users/Queries/Shipments'
import QueryTradeReceivablesPage from '../pages/Users/Queries/TradeReceivables'
import QueryInvoicesPage from '../pages/Users/Queries/Invoices'
import QueryInventoryPage from '../pages/Users/Queries/Inventory'
import QueryIssuedOrdersPage from '../pages/Users/Queries/IssuedOrders'
import QueryBilledOrdersPage from '../pages/Users/Queries/BilledOrders'
import QueryWalletOrdersPage from '../pages/Users/Queries/WalletOrders'

import RIGHTS from '__access_profiles__'

export function CustomerRoutes() {
  return (
    <>
      <Route
        path={PATHS.user.home}
        component={CustomersHomePage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.schedule}
        rightId={RIGHTS.incluiAgendamento.id}
        component={CustomersSchedulePage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.orders()}
        rightId={RIGHTS.incluiOrcamento.id}
        component={CustomerBudgetPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.releases}
        rightId={RIGHTS.consultaLancamentos.id}
        component={CustomerReleasesPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.shipments}
        rightId={RIGHTS.consultaCarregamentos.id}
        component={QueryShipmentsPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.tradeReceivables}
        component={QueryTradeReceivablesPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.invoices}
        component={QueryInvoicesPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.inventory}
        rightId={RIGHTS.relEstoque.id}
        component={QueryInventoryPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.issuedOrders}
        component={QueryIssuedOrdersPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.billedOrders}
        rightId={RIGHTS.relPedidosFaturados.id}
        component={QueryBilledOrdersPage}
        isPrivate
        exact
      />
      <Route
        path={PATHS.user.queries.walletOrders}
        rightId={RIGHTS.relPedidosEmCarteira.id}
        component={QueryWalletOrdersPage}
        isPrivate
        exact
      />
    </>
  )
}

export default CustomerRoutes
