import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../theme'

import {
  Container,
  HeaderComponent,
  ContentComponent,
  FooterComponent,
} from './styles'
import Loader from 'react-spinners/ScaleLoader'

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

function SideBarLoader({ loadingText, ...props }) {
  return (
    <Container className="loaderContainer" {...props}>
      <div>
        <Loader color={colors.color500} />
        <span>{loadingText}</span>
      </div>
    </Container>
  )
}

SideBarLoader.propTypes = {
  loadingText: TranslationStringType,
}

function SideBar(props) {
  return (
    <Container className={props.className}>
      {props.loading ? (
        <SideBarLoader loadingText={props.loadingText} />
      ) : (
        <>
          {props.HeaderComponent && (
            <HeaderComponent className="Header">
              {props.HeaderComponent}
            </HeaderComponent>
          )}
          {props.ContentComponent && (
            <ContentComponent className="Content">
              {props.ContentComponent}
            </ContentComponent>
          )}
          {props.FooterComponent && (
            <FooterComponent className="SideBar-FooterComponent">
              {props.FooterComponent}
            </FooterComponent>
          )}
        </>
      )}
    </Container>
  )
}

const ComponentType = PropTypes.element

SideBar.propTypes = {
  loading: PropTypes.bool,
  loadingText: TranslationStringType,

  HeaderComponent: ComponentType,
  ContentComponent: ComponentType.isRequired,
  FooterComponent: ComponentType,
}

SideBar.defaultProps = {
  loading: false,
  loadingText: '',
}

export { SideBar }
export default SideBar
