import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { persistor } from 'store/index'
import { environments } from 'consts/environments'
import * as medias from 'consts/medias'
import { signOutRequest } from 'store/modules/auth/reducer'
import Client from 'services/eventEmitter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const updateWindowkeyCode = 116

const client = Client

const events = {
  signout: '@portalDelta/SIGNOUTCHECKER/signout',
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function SignOutChecker() {
  const [lastKeyCode, setLastKeyCode] = useState(null)
  const dispatch = useDispatch()
  const sm = useMediaQuery(medias.sm)

  useEffect(() => {
    const handleSignOut = () => dispatch(signOutRequest())
    client.on(events.signout, handleSignOut)

    const handlePausePersist = async (e) => {
      e.preventDefault()

      if (!sm && environments.PRODUCTION && lastKeyCode !== updateWindowkeyCode)
        // Clear persist memory
        await persistor.purge()

      delete e['returnValue']
    }

    window.addEventListener('beforeunload', handlePausePersist)

    return () => {
      client.removeEventListener(events.signout, handleSignOut)
      window.removeEventListener('beforeunload', handlePausePersist)
    }
  }, [dispatch, lastKeyCode, sm])

  useEffect(() => {
    const handleKeyDown = (e) =>
      e.keyCode === updateWindowkeyCode && setLastKeyCode(e.keyCode)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return null
}

export { SignOutChecker, events }
export default SignOutChecker
