import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { merge } from 'ramda'

import ctPointLabels from 'chartist-plugin-pointlabels'
import Chartist from 'react-chartist'

import formatter from 'services/formatter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const createOptions = ({ decimalCases = 2 } = {}) => ({
  height: 300,
  showArea: true,
  fullWidth: true,
  chartPadding: {
    top: 40,
    left: 20,
    right: 60,
  },
  plugins: [
    ctPointLabels({
      textAnchor: 'left',
      labelInterpolationFnc: (v) =>
        formatter(Number(v)).toDecimal({ decimalCases }),
    }),
  ],
})

// --------------- 𝕊𝕥𝕪𝕝𝕖𝕕 ---------------

const Container = styled.div`
  width: 100%;

  svg {
    g.ct-series,
    g.ct-series-a {
      path.ct-area {
        fill: ${(props) => props.color};
      }
      line,
      path.ct-line {
        stroke: ${(props) => props.color};
      }
    }
  }
`

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function Graph({ type, color, data, options, decimalCases, ...props }) {
  return (
    <Container color={color} {...props}>
      <Chartist
        className="ct-chart"
        type={type}
        data={data}
        options={merge(createOptions({ decimalCases }), options)}
      />
    </Container>
  )
}

Graph.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.exact({
    labels: PropTypes.arrayOf(PropTypes.any),
    series: PropTypes.arrayOf(PropTypes.array),
  }).isRequired,
  options: PropTypes.object,
}

Graph.defaultProps = {
  type: 'Line',
  options: {},
}

export default Graph
