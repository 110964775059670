import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { addDays, formatISO, startOfMonth } from 'date-fns'
import { setFilters } from 'store/modules/users/orders/orders/reducer'
import { toast } from 'react-toastify'

import { Grid } from '@material-ui/core'
import { Search, Delete } from '@material-ui/icons'

import StyledFilterForm from 'components/StyledFilterForm'
import FilterTitle from 'components/FilterTitle'
import { FormDatePicker, FormTextField } from 'components/CoForm'
import Button from 'components/Button'
import SideComponent from 'components/SideBar'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
const initialValues = {
  ped_data_emissao: {
    initial: formatISO(startOfMonth(new Date())),
    final: formatISO(new Date()),
  },
  cod_pedido: '',
  dsc_abreviado: '',
}

// ╦ ╦╔╦╗╦╦  ╔═╗
// ║ ║ ║ ║║  ╚═╗
// ╚═╝ ╩ ╩╩═╝╚═╝
const isValidFinalDate = (final, initial) => new Date(final) > new Date(initial)

const minDate = addDays(new Date(), -365)

function FilterIssued() {
  // ╦ ╦╔═╗╔═╗╦╔═╔═╗
  // ╠═╣║ ║║ ║╠╩╗╚═╗
  // ╩ ╩╚═╝╚═╝╩ ╩╚═╝
  const formRef = useRef(null)
  const dispatch = useDispatch()

  // ╦ ╦╔═╗╔╗╔╔╦╗╦  ╔═╗╦═╗╔═╗
  // ╠═╣╠═╣║║║ ║║║  ║╣ ╠╦╝╚═╗
  // ╩ ╩╩ ╩╝╚╝═╩╝╩═╝╚═╝╩╚═╚═╝
  const handleUpdateForm = (filters) => {
    if (
      filters &&
      !isValidFinalDate(
        filters.ped_data_emissao.final,
        filters.ped_data_emissao.initial
      )
    ) {
      toast.error('A data final deve ser superior à data inicial')
      return
    }

    dispatch(
      setFilters(
        filters
          ? {
              ...filters,
              dsc_abreviado: filters.dsc_abreviado.toUpperCase(),
            }
          : null
      )
    )
  }

  const handleClearForm = () => {
    formRef.current.setValues(initialValues)
    handleUpdateForm(null)
  }

  return (
    <SideComponent
      ContentComponent={
        <StyledFilterForm
          formRef={formRef}
          initialData={initialValues}
          onSubmit={handleUpdateForm}
        >
          <FilterTitle>Pesquisar</FilterTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="ped_data_emissao.initial"
                label="Data Inicial"
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="ped_data_emissao.final"
                label="Data Final"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                name="cod_pedido"
                label="Número do orçamento"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField fullWidth name="dsc_abreviado" label="Produto" />
            </Grid>
          </Grid>

          <div className="buttons">
            <Button type="submit" label="Buscar" startIcon={<Search />} />
            <Button
              type="button"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClearForm}
            />
          </div>
        </StyledFilterForm>
      }
    />
  )
}

export default FilterIssued
