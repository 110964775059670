import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  shipments: [],
  count: 0,

  filters: [],
  selectedFilters: [],
}

const shipments = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    shipmentsRequest(state) {
      state.loading = true
    },
    shipmentsSuccess(state, { payload }) {
      state.shipments = payload.shipments
      state.count = payload.count
      state.loading = false
    },
    shipmentsFailure(state) {
      state.loading = false
    },
  },
})

export const { shipmentsRequest, shipmentsSuccess, shipmentsFailure } =
  shipments.actions
export default shipments.reducer
