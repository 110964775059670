import React from 'react'
import { inviteStatus } from 'consts/types'
import TextField from 'components/TextField'
import ComboboxBase from '../Base'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboboxInviteStatus(props) {
  return (
    <ComboboxBase
      name="invite_status"
      options={[
        { label: 'TODOS', value: '' },
        { label: 'ENVIADOS', value: inviteStatus.INVITED },
        { label: 'NÃO ENVIADOS', value: inviteStatus.NOT_INVITED },
      ]}
      renderInput={(provided) => (
        <TextField label="Convites" variant="outlined" {...provided} />
      )}
      disableClearable
      {...props}
    />
  )
}

export default ComboboxInviteStatus
