import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { test, sort } from 'ramda'
import { TextField } from '..'
import { Search } from '../Icons'
import { Container, Title, Divider, Rows, Row, InputArea } from './styles'
import CheckBox from '../Checkbox'

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const createFilter = ({ filterBy, row } = {}) => ({
  id: [filterBy, row.label].join('-'),
  value: row.label,
  filterBy,
  row,
})

const sortByValue = sort((a, b) => b.value - a.value)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function Ranking({ rowClassName, rows, ...props }) {
  const inputRef = useRef(null)
  const [activeRows, setActiveRows] = useState(rows)

  useEffect(() => {
    setActiveRows(rows)
  }, [rows])

  const handleChangeSearch = (e) => {
    const term = e.target.value
    const rgxp = new RegExp(term, 'i')
    setActiveRows(() => rows.filter((row) => test(rgxp, row.label)))
  }

  const handleClick = (row, e) => {
    if (inputRef.current) inputRef.current.value = ''
    props.onFiltering(createFilter({ filterBy: props.filterBy, row }), e)
  }

  const renderRows = () => {
    const sortedRows = sortByValue(activeRows)

    if (sortedRows.length === 0) return null
    else
      return sortedRows.map((row, rowIndex) => {
        const onClickRow = (e) => handleClick(row, e)
        const checked = row.active != null ? row.active : false

        return (
          <Row key={rowIndex} title={row.label} onClick={onClickRow}>
            <div>
              {props.showSelection && (
                <CheckBox value={checked} checked={checked} />
              )}
              <span className="row-label">{row.label}</span>
            </div>
            {row.value && <div className="value">{row.value}</div>}
          </Row>
        )
      })
  }

  return (
    <Container>
      <Title>
        <span>{props.title}</span>
        <Divider />
      </Title>
      {props.showSearch && (
        <InputArea>
          <TextField
            inputRef={inputRef}
            onChange={handleChangeSearch}
            placeholder="Pesquisar..."
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
        </InputArea>
      )}
      <Rows
        className={clsx(rowClassName, {
          'no-overflow': !props.overflow,
        })}
      >
        {renderRows()}
      </Rows>
    </Container>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

Ranking.propTypes = {
  filterBy: PropTypes.string.isRequired,
  rowClassName: PropTypes.string,
  showSearch: PropTypes.bool,
  overflow: PropTypes.bool,
  showSelection: PropTypes.bool,
  onFiltering: PropTypes.func,
  title: TranslationStringType,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

Ranking.defaultProps = {
  rows: [],
  showSearch: true,
  showSelection: false,
  overflow: true,
}

export default Ranking
