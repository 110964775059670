import { all, put, call, takeLatest } from 'redux-saga/effects'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getTradeReceivablesRequest,
  getTradeReceivablesSuccess,
  getTradeReceivablesFailure,
} from './reducer'

function* getTradeReceivables({ payload }) {
  try {
    const params = {
      page: payload.page,
      per_page: payload.perPage,
      // cod_representante: payload.representative,
      // cod_cliente: payload.customer,
      query: payload.query,
      dinicio: payload.issuedAt,
      dfinal: payload.issuedUntil,
      tipo: payload.type,
    }

    const response = yield call(
      api.get,
      apiEndPoints.queries.tradeReceivables.root,
      { params }
    )

    yield put(
      getTradeReceivablesSuccess({
        tradeReceivables: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as duplicatas!')
    yield put(getTradeReceivablesFailure())
  }
}

export default all([
  takeLatest(getTradeReceivablesRequest.type, getTradeReceivables),
])
