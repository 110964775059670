import styled from 'styled-components'
import { colors } from 'theme'
import { Box } from '@material-ui/core'
import Button from 'components/Button'

export const BulletinContainer = styled.div`
  .MuiStepper-root {
    overflow: auto;
  }

  .html__content {
    min-height: 450px;

    padding: 10px 0;
  }

  .html__content.isMultipleBulletins {
    border-top: 1px solid ${colors.color100};
  }

  .attachment__list > div {
    border-top: 1px solid ${colors.color100};
    padding: 10px 0;

    .MuiChip-root {
      margin-right: 5px;
    }
  }

  .attachment__list > span {
    font-size: 12px;
    color: ${colors.color400};
  }

  .loading__text {
    height: 20px;
  }
`

export const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.common.white};
  background-color: ${colors.color300};
  &:hover {
    background-color: ${colors.color400};
  }
`

export const Actions = styled(Box)`
  width: 100%;
  display: flex;
`
