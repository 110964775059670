import styled from 'styled-components'

export const Container = styled.div`
  // ref: https://codepen.io/abdelrhmansaid/pen/OJRNOpQ

  cursor: pointer;
  position: relative;
  background-color: #fff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  // Extend
  &.extended {
    border-radius: 5px;
    width: auto;
  }

  // Tooltip
  .tooltip {
    z-index: 2;
    position: absolute;
    top: 3px;
    left: 0;
    background-color: #fff;
    color: #fff;
    /* width: 150px; */
    text-align: center;
    white-space: nowrap;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  // Arrow
  .tooltip:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    background-color: #fff;
    top: 10px;
    left: 0%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  // Hover
  &:hover {
    // Tooltip
    .tooltip {
      left: 45px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    // Icon
    .tooltip,
    .MuiSvgIcon-root {
      text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    }
  }

  // Customs
  &[disabled] {
    cursor: initial;
  }

  &:hover,
  &.active {
    &,
    .tooltip,
    .tooltip:before {
      background-color: ${(props) => props.backgroundColor};
      color: ${(props) => props.color};
    }
  }
`
