import styled from 'styled-components'

import Button from '../Button'
import Paper from '../Paper'
import Dialog from '../Dialog'

export const StyledDialog = styled(Dialog)``

export const Container = styled(Paper)`
  padding: 20px 0;
  > div {
    font-size: 16px;
    line-height: 150%;
  }

  li {
    margin: 5px 16px;
    list-style: initial;
  }
`

export const StyledButton = styled(Button)`
  width: 100px;
  margin-left: 10px;
  padding: 6px;
  border-radius: 4px;

  > span.MuiButton-label {
    color: white;
  }

  &.confirmButton {
    background: ${(props) => props.theme.palette.success.main};

    &:hover {
      background: ${(props) => props.theme.palette.success.light};
    }
  }

  &.cancelButton {
    background: ${(props) => props.theme.palette.error.main};

    &:hover {
      background: ${(props) => props.theme.palette.error.light};
    }
  }
`
