import styled from 'styled-components'
import { lighten } from 'polished'

import BaseButton from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/AddCircleOutline'

import * as medias from '../../../consts/medias'
import theme, { colors } from '../../../theme'
import Paper from '../../../components/Paper'
import { TableRow as BaseTableRow } from '../../../components/Table'

export const Container = styled(Paper)`
  border: none;
  padding: 10px 16px;
  min-height: 77vh;

  .MuiDivider-root {
    margin: 0.5rem 0;
  }
`

export const QueryButtonRow = styled.div`
  gap: 0.5rem;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media ${medias.sm} {
    flex-direction: column;
    align-items: center;
  }
`

export const QueryButton = styled.div`
  padding: 6px 12px;
  cursor: pointer;

  background: ${colors.white};
  border: 1px solid ${colors.color100};
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 220px;
  height: 260px;

  &:hover {
    background: ${colors.main};
  }

  @media ${medias.sm} {
    padding: 15px 5px;
  }
`

export const QueryIcon = styled.div`
  .MuiSvgIcon-root {
    font-size: 4.5rem;
    color: ${theme.palette.primary.dark};
  }
`

export const QueryInfo = styled.div`
  text-align: center;

  > span {
    font-size: 15px;
    line-height: 13px;
  }
  > p {
    font-size: 11px;
    line-height: 13px;
    color: ${colors.color500};
  }
`

// REPORTS

export const Controls = styled.div`
  display: flex;
  gap: 1rem;
`

export const Button = styled(BaseButton)`
  &.Excel {
    background-color: #1d6f42;

    &:hover {
      background-color: ${lighten(0.1, '#1D6F42')};
    }
  }
`

export const Messages = styled.div`
  margin-top: 1rem;
  padding: 1rem;

  p {
    font-weight: bold;
  }
`

export const TableRow = styled(BaseTableRow)`
  .MuiTableCell-root {
    background-color: #e7ebed;
  }

  &.highlightText .MuiTableCell-root {
    font-weight: bold;
  }

  &.large .MuiTableCell-root {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const HighlightText = styled.span`
  font-weight: bold;
`

export const DetailIcon = styled(AddIcon)`
  transition: transform 200ms ease;

  &.open {
    transform: rotate(45deg);
  }
`
