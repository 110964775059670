import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createOrderRequest } from 'store/modules/users/orders/orders/reducer'
import { Yup } from 'services/yup'

import { Typography, Grid } from '@material-ui/core'

import Button from 'components/Button'
import { Form, FormTextField, FormRadioGroup } from 'components/CoForm'
import {
  ComboboxCarriers,
  ComboboxDeliveryAddresses,
} from 'components/Combobox'
import { Container } from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const TRANSPORTAION_TYPES = [
  { value: '0', label: 'Transportadora determinada pelo cliente' },
  { value: '1', label: 'Retirado pelo cliente' },
  { value: '2', label: 'Sem Transportadora' },
]

const withdrawnByCustomer = TRANSPORTAION_TYPES[0].value

const formInitialData = {
  delivery_address: { id: 0 },
  ped_seupedido: '',
  ped_tipo_transp: withdrawnByCustomer,
  carrier: { id: null },
  ped_obs_transp: '',
  ped_obs: '',
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const schema = Yup.object().shape({
  carrier: Yup.object().when('ped_tipo_transp', (deliveryType, schema) => {
    if (deliveryType === TRANSPORTAION_TYPES[0].value) {
      return schema.shape({
        id: Yup.string().required('Escolha uma transportadora.').nullable(),
      })
    } else {
      return schema.shape({ id: Yup.string().nullable() })
    }
  }),
})

export function OrderForm() {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.users.orders.orders.loading)
  const orderParams = useSelector(
    (state) => state.users.orders.orders.order.params
  )

  const formRef = useRef(null)
  const [transportType, setTransportType] = useState(withdrawnByCustomer)

  useEffect(() => {
    if (orderParams) {
      formRef.current.setData({ ...orderParams })
    }
  }, [orderParams])

  const isNotWithdrawnByCustomer = (transportType) =>
    transportType !== withdrawnByCustomer

  const handleTransportTypeChange = (newTransportType) => {
    setTransportType(newTransportType)

    if (isNotWithdrawnByCustomer(newTransportType))
      formRef.current.setFieldValue('carrier.id', null)
  }

  const handleSubmit = (values) => {
    const params = { ...values }
    params.ped_orcto = 1
    dispatch(createOrderRequest(params))
  }

  return (
    <Container>
      <Typography variant="h4">Finalizar Orçamento</Typography>
      <Form
        formRef={formRef}
        onSubmit={handleSubmit}
        initialData={formInitialData}
        schema={schema}
      >
        <Grid container spacing={1} style={{ marginTop: '1rem' }}>
          <Grid item xs={12}>
            <ComboboxDeliveryAddresses
              name="delivery_address.id"
              label="Entrega"
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              label="Seu Pedido"
              name="ped_seupedido"
              inputProps={{
                inputMode: 'numeric',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRadioGroup
              direction="column"
              name="ped_tipo_transp"
              label="Transportadora"
              options={TRANSPORTAION_TYPES}
              onAfterChange={handleTransportTypeChange}
            />
          </Grid>
          <Grid item xs={12}>
            <ComboboxCarriers
              showAllCheck
              label="Transportadora"
              name="carrier.id"
              disabled={isNotWithdrawnByCustomer(transportType)}
              search=" "
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="ped_obs_transp"
              label="Observações do Transporte"
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="ped_obs"
              label="Observações do Pedido"
              multiline
              minRows={5}
              placeholder="Observações do pedido (max. 150 caracteres)"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            size="large"
            fullWidth
            type="submit"
            label="Enviar Orçamento"
            className="success font-white"
            loading={loading}
            style={{ marginTop: '1rem' }}
          />
        </Grid>
      </Form>
    </Container>
  )
}

export default OrderForm
