import React, { useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { merge, equals } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'

import { cpf } from 'consts/regexp'
import theme, { colors } from 'theme'
import { notEmpty, fetchVehiclebyPlate, copyObject } from 'services/utils'
import formatter from 'services/formatter'
import { Yup } from 'services/yup'
import { toMask, unmask } from 'services/masks'

import {
  finishCollectRequest,
  updateCollectRequest,
} from 'store/modules/users/schedules/reducer'

import { FormTextField, FormRow } from 'components/CoForm'
import { RadioGroup } from 'components/RadioGroup'
import { DatePickerCollect } from 'components/DatePicker/CollectDate'
import {
  ComboboxCarriers,
  ComboboxLastVehicles,
  ComboboxTruckTypes,
  ComboboxLastDrivers,
} from 'components/Combobox'

import {
  FinishForm,
  Margin,
  StyledButton as SendButton,
  SendIcon,
} from '../styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const transportTypes = {
  CUSTOMER: '0',
  CARRIER: '1',
}

const transportTypesOptions = [
  { value: transportTypes.CUSTOMER, label: 'Retirado pelo cliente' },
  { value: transportTypes.CARRIER, label: 'Transportadora' },
]

const initialData = {
  vpa_placa: '',
  id_tipo_caminhao: '',
  vpa_motorista: '',
  vpa_cod_motorista: '',
  vpa_dagenda: '',
  capacity: 0,
  available: 0,
  tare: 0,
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const schema = Yup.object().shape({
  vpa_placa: Yup.string().required('Informe a placa do veiculo').nullable(),
  id_tipo_caminhao: Yup.string()
    .required('Informe o tipo do veiculo')
    .nullable(),
  vpa_motorista: Yup.string()
    .required('Informe o nome do motorista')
    .nullable(),
  vpa_cod_motorista: Yup.string()
    .required('Informe o CPF do motorista')
    .nullable(),
  vpa_dagenda: Yup.string().required('Informe a data de previsão').nullable(),
  // available: Yup.number().min(1, 'Capacidade do veículo excedida.'),
})

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function WeightField(props) {
  return (
    <FormTextField
      {...props}
      large
      disabled
      formatValue={(v) => formatter(v).toDecimal()}
    />
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormSchedule({ isEditing }) {
  const dispatch = useDispatch()

  const vehicles = useSelector((state) => state.comuns.truckTypes.options)
  const activeScheduleWeight = useSelector(
    (state) => state.users.schedules.active.weight
  )
  const activeScheduleParams = useSelector(
    (state) => state.users.schedules.active.scheduleParams
  )

  const formRef = useRef(null)
  const availableFieldRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [transportType, setTransportType] = useState(transportTypes.CUSTOMER)

  const handleCollectWeightFieldColor = (available) => {
    const collectWithOvercapacity = available < 1

    formRef.current.setFieldError(
      'available',
      collectWithOvercapacity ? 'Capacidade do veículo excedida.' : null
    )

    availableFieldRef.current.style.color = collectWithOvercapacity
      ? theme.palette.error.main
      : colors.color500
  }

  const calcAvailableWeight = (capacity, tare, weight) =>
    Number(capacity - tare - weight)

  const handleFetchVehicleMinimumWeight = useCallback(
    async (plate, selectedVehicle = null) => {
      if (plate) {
        setLoading(true)

        const vehicle = await fetchVehiclebyPlate(plate)
        const withTruck = Boolean(selectedVehicle)

        if (vehicle) {
          const tare = vehicle.brd_peso_entrada
          const capacity = withTruck
            ? selectedVehicle.cam_pbt
            : vehicle.vehicle_pbt

          const available = calcAvailableWeight(
            capacity,
            tare,
            activeScheduleWeight
          )

          const updatedValues = {
            id_tipo_caminhao: withTruck
              ? selectedVehicle.id_tipo_caminhao
              : vehicle.id_tipo_caminhao,
            available,
            tare,
            capacity,
          }

          formRef.current.setData(updatedValues)

          handleCollectWeightFieldColor(available)
        } else formRef.current.setFieldValue('tare', initialData.tare)

        setLoading(false)
      }
    },
    [activeScheduleWeight]
  )

  useEffect(() => {
    if (notEmpty(vehicles)) {
      const { vpa_placa: plate, id_tipo_caminhao: vehicleId } =
        formRef.current.values
      const vehicle = vehicles.find((c) => c.id_tipo_caminhao === vehicleId)

      handleFetchVehicleMinimumWeight(plate, vehicle)
    }
  }, [activeScheduleWeight, vehicles, handleFetchVehicleMinimumWeight])

  const isWithdrawnByTheCustomer = equals(transportTypes.CUSTOMER)

  function handleSubmit(values) {
    const draft = copyObject(values)
    draft.vpa_cod_motorista = unmask(draft.vpa_cod_motorista)
    draft.vpa_dagenda = formatter(draft.vpa_dagenda).toSimpleDate()

    if (isEditing) dispatch(updateCollectRequest(draft))
    else dispatch(finishCollectRequest(draft))
  }

  function handleChangeTransportType(_, transportType) {
    setTransportType(transportType)

    if (isWithdrawnByTheCustomer(transportType))
      formRef.current.setFieldValue('cod_empresa', null)
  }

  function handleAfterChangeLastVehicles(_, vehicle) {
    const plate = vehicle && vehicle.brd_placa
    handleFetchVehicleMinimumWeight(plate)
  }

  function handleAfterChangeTruckTypes(_, truckType) {
    const capacity = Number(truckType ? truckType.cam_pbt : 0)
    const tare = formRef.current.getFieldValue('tare')

    const availableWeight = calcAvailableWeight(
      capacity,
      tare,
      activeScheduleWeight
    )

    formRef.current.setData({
      capacity: capacity,
      available: availableWeight,
    })

    handleCollectWeightFieldColor(availableWeight)
  }

  function handleChangeDriver(_, driver) {
    formRef.current.setFieldValue(
      'vpa_cod_motorista',
      driver ? driver.id : null
    )
  }

  return (
    <FinishForm
      formRef={formRef}
      initialData={merge(initialData, activeScheduleParams)}
      onSubmit={handleSubmit}
      schema={schema}
    >
      <h4>{isEditing ? 'Editar Agendamento' : 'Finalizar Agendamento'}</h4>

      <RadioGroup
        name="transport_type"
        value={transportType}
        onChange={handleChangeTransportType}
        options={transportTypesOptions}
      />
      <ComboboxCarriers
        search=" "
        label="Transportadora"
        name="cod_empresa"
        disabled={isWithdrawnByTheCustomer(transportType) || isEditing}
        showAllCheck={!isWithdrawnByTheCustomer(transportType)}
      />
      <FormRow>
        <ComboboxLastVehicles
          label="Placa"
          name="vpa_placa"
          onAfterChange={handleAfterChangeLastVehicles}
        />
        <ComboboxTruckTypes
          label="Tipo do Caminhão"
          name="id_tipo_caminhao"
          onAfterChange={handleAfterChangeTruckTypes}
          loading={loading}
        />
      </FormRow>
      <FormRow>
        <ComboboxLastDrivers
          label="Motorista"
          name="vpa_motorista"
          onAfterChange={handleChangeDriver}
        />
        <FormTextField
          className="FormRow-document-number"
          label="CPF do Motorista"
          name="vpa_cod_motorista"
          textMaskProps={toMask(cpf.array)}
        />
      </FormRow>
      <DatePickerCollect
        label="Data Prevista para o Agendamento"
        name="vpa_dagenda"
        type="schedule"
        disabled={isEditing}
      />
      <Margin />
      <WeightField label="Capacidade Total do Veículo" name="capacity" />
      <WeightField label="Tara Estimada" name="tare" />
      <WeightField
        label="Peso total do Agendamento"
        name="total"
        value={activeScheduleWeight}
      />
      <WeightField
        label="Disponível para Carregamento"
        name="available"
        inputRef={availableFieldRef}
      />
      <Margin />
      <SendButton
        type="submit"
        label={isEditing ? 'Salvar Alterações' : 'Enviar Agendamento'}
        endIcon={<SendIcon />}
      />
    </FinishForm>
  )
}

FormSchedule.propTypes = {
  isEditing: PropTypes.bool,
}

FormSchedule.defaultProps = {
  isEditing: false,
}

export default FormSchedule
