import React from 'react'
import Ranking2 from 'components/Ranking2'
import SearchInput from 'components/SearchInput'
import { StyledSideComponent } from './styles'
import { extractLens } from 'services/utils'

const createRanking = (path, data) => {
  const getValue = (item) =>
    data.filter((i) => extractLens(path, i) === item).length

  return [...new Set(data.map((item) => extractLens(path, item)))].map(
    (value) => ({
      id: value,
      label: value,
      value: getValue(value),
    })
  )
}

function FilterReleases({ data, onFilter, ...props }) {
  const brands = createRanking('dsc_marca', data)
  const formats = createRanking('dsc_tamanho_produtos', data)
  const lines = createRanking('line.dsc_linha_produtos', data)

  const handleFilters = (filterBy, filters) => {
    onFilter({
      [filterBy]: filters,
    })
  }

  const handleSearch = (search) => {
    handleFilters('dsc_produto', [search])
  }

  return (
    <StyledSideComponent
      {...props}
      HeaderComponent={
        <SearchInput
          fullWidth
          label="Código/Descrição"
          placeholder="Buscar lançamento..."
          onSearch={handleSearch}
        />
      }
      ContentComponent={
        <div className="Ranking-List">
          <Ranking2
            showSelection
            showSearch={false}
            filterBy="dsc_marca"
            title="Marca"
            rows={brands}
            onSelect={handleFilters}
          />
          <Ranking2
            showSelection
            showSearch={false}
            filterBy="dsc_tamanho_produtos"
            title="Tamanho"
            rows={formats}
            onSelect={handleFilters}
          />
          <Ranking2
            showSelection
            showSearch={false}
            filterBy="line.dsc_linha_produtos"
            title="Linha"
            rows={lines}
            onSelect={handleFilters}
          />
        </div>
      }
      FooterComponent={null}
    />
  )
}

export default FilterReleases
