import styled, { css } from 'styled-components'
import { StyledTextField } from '../TextField/styles'
import { colors } from '../../theme'

const rowSize = 23

const customRowStyle = (props) => {
  let style = ''
  if (props.children?.length <= 1) {
    style += css`
      max-height: none;
      overflow: initial;
    `
  }

  return style
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  > span {
    color: ${colors.color500};
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
  }
`

export const Divider = styled.div`
  margin-top: 5px;
  margin-bottom: 6px;
  border: 1px solid ${colors.color500};
  background-color: ${colors.color500};
`

export const Rows = styled.div`
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 1px;
  }

  max-height: ${() => `calc(${rowSize}px * 5.5)`};
  overflow: auto;

  &.no-overflow {
    max-height: none;
    overflow: initial;
  }

  ${customRowStyle}
`

export const Row = styled.div`
  cursor: pointer;
  height: ${rowSize}px;
  background-color: rgba(207, 216, 220, 0.2);
  flex: 1 1 auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 12px;
  line-height: 16px;

  > div {
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 3px;
  }

  .row-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 3px;
  }

  > div.value {
    padding: 3px 8px 5px 13px;
    background-color: ${colors.color100};
    font-weight: bold;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  transition: background-color 100ms ease-in;

  &:hover {
    background-color: ${colors.color100};
  }
`

export const InputArea = styled.div`
  margin-bottom: 8px;
  width: 100%;

  ${StyledTextField} {
    width: 100%;

    input {
      padding: 4px 16px;
      height: 20px;
      border-left: 1px solid ${colors.color100};

      &:focus ~ .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.color100};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.main};
    }
  }
`
