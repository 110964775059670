import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'ramda'

const initialState = {
  products: [],
  brands: [],
  formats: [],
  productFormats: [],
  lines: [],
  unitOfMeasures: [],
  loading: false,
}

const extractFormats = (products) =>
  [...new Set(products.map((p) => p.dsc_tamanho_produtos))].map((format) => ({
    id: format,
    label: format,
  }))

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getProductsRequest(state) {
      state.loading = true
    },
    getProductsSuccess(state, { payload: products }) {
      state.loading = false
      state.products = products.map((product) => ({
        ...product,
        dsc_produto: [
          product.cod_produto.slice(0, -2),
          product.dsc_produto,
        ].join(' '),
      }))

      if (isEmpty([...state.formats])) state.formats = extractFormats(products)
    },
    getProductsFailure(state) {
      state.loading = false
    },
    updateProduct(state, { payload: product }) {
      state.products = state.products.map((p) =>
        p.cod_produto === product.cod_produto
          ? { ...p, qtde_orc: product.quantity }
          : p
      )
    },

    getBrandsRequest() {},
    getBrandsSuccess(state, { payload }) {
      state.brands = payload
    },
    getBrandsfailure() {},

    getLinesRequest() {},
    getLinesSuccess(state, { payload }) {
      state.lines = payload
    },
    getLinesFailure() {},

    getFormatsRequest() {},
    getFormatSuccess(state, { payload }) {
      state.productFormats = payload
    },

    getUnitOfMeasuresRequest() {},
    getUnitOfMeasuresSuccess(state, { payload }) {
      state.unitOfMeasures = payload
    },
  },
})

export const {
  getProductsRequest,
  getProductsFailure,
  getProductsSuccess,
  getBrandsRequest,
  getBrandsSuccess,
  getBrandsfailure,
  getLinesFailure,
  getLinesRequest,
  getLinesSuccess,
  getFormatsRequest,
  getFormatSuccess,
  getUnitOfMeasuresRequest,
  getUnitOfMeasuresSuccess,
  updateProduct,
} = products.actions

export default products.reducer
