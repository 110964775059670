import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

export function Icon(props) {
  return <Tooltip title={props.tooltip}>{props.children}</Tooltip>
}

Icon.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.element.isRequired,
}

export default Icon
