import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { inviteStatus } from 'consts/types'
import { isCustomer, copyObject } from 'services/utils'
import { toMask, phoneMask, cnpjCpfMask, unmask } from 'services/masks'
import {
  handleSaveUser,
  closeFormEdit,
} from 'store/modules/admin/users/reducer'
import { Divider } from '@material-ui/core'
import { Form, FormTextField, FormCheckbox } from 'components/CoForm'
import { Dialog } from 'components/Dialog'
import {
  Container,
  StyledPanel,
  FormWrapper,
  StyledButton,
  Avatar,
} from './styles'

import { TabNavigation, Tabs } from 'components/TabNavigation'
import ProfileTransferList from 'components/User/ProfileTransferList'
export function UserFormEdit({ loggedUser }) {
  // █░█ █▀█ █▀█ █▄▀ █▀
  // █▀█ █▄█ █▄█ █░█ ▄█

  const formRef = useRef(null)
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.admin.users.webUser)
  const show = useSelector((state) => state.admin.users.formEditShow)

  useEffect(() => {
    if (show) {
      if (!!currentUser) {
        formRef.current.setValues({
          ...currentUser,
          weu_ativo: currentUser.weu_ativo === 'active',
        })
      } else {
        formRef.current.setValues({
          weu_avatar: null,
          weu_nome: '',
          weu_cpf: '',
          weu_email: '',
          weu_telefone: '',
          weu_ativo: true,
        })
      }
    }
  }, [show, currentUser])

  // █▀▄▀█ █▀▀ ▀█▀ ▄▀█ █▀▄ ▄▀█ ▀█▀ ▄▀█
  // █░▀░█ ██▄ ░█░ █▀█ █▄▀ █▀█ ░█░ █▀█

  const isEditing = !!currentUser

  const isSelfEditing =
    isEditing && currentUser.id === loggedUser.web_user.weu_id

  const isMasterAdminEditing =
    isEditing && !isSelfEditing && loggedUser.isMasterAdmin

  const isNotInvited =
    isEditing && currentUser.invite_status === inviteStatus.NOT_INVITED

  const enableProfileAccess = loggedUser
    ? loggedUser.isCustomer
    : isCustomer(currentUser?.wee_tipo)

  const disabledEmailField =
    isEditing && (!isNotInvited || isMasterAdminEditing || isSelfEditing)

  const disableProfileTransferList = isEditing && !loggedUser.isAdminUser

  // █▀▀ █░█ █▄░█ █▀▀ ▀█▀ █ █▀█ █▄░█ █▀
  // █▀░ █▄█ █░▀█ █▄▄ ░█░ █ █▄█ █░▀█ ▄█

  const handleSave = () => {
    const draft = copyObject(formRef.current.values)
    draft.weu_cpf = unmask(draft.weu_cpf)
    draft.id_perfil = draft.perfis_usuario?.map((p) => p.value).join(',')

    delete draft.perfis_usuario
    delete draft.perfis_disponiveis

    if (isSelfEditing) {
      delete draft.id_perfil
    }

    const userParams = {
      formValues: draft,
      webUser: currentUser,
    }

    dispatch(handleSaveUser(userParams))
  }

  const handleClose = () => dispatch(closeFormEdit())

  const getTitle = () => {
    const editingText = isSelfEditing ? 'Atualizar Perfil' : 'Atualizar Usuário'

    return isEditing ? editingText : 'Cadastrar Usuário'
  }

  return (
    <Form formRef={formRef}>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={show}
        title={getTitle()}
        actions={
          <>
            <StyledButton
              className="success light regular"
              label="Salvar"
              variant="contained"
              onClick={handleSave}
            />
            <StyledButton
              className="error light regular"
              label="Cancelar"
              variant="contained"
              onClick={handleClose}
            />
          </>
        }
      >
        <TabNavigation>
          <Container>
            {isEditing && (
              <Avatar
                name="weu_avatar"
                label="Clique para alterar seu avatar"
                alt={currentUser?.weu_nome}
                disabled
              />
            )}
            {enableProfileAccess && (
              <>
                <Tabs
                  className="unstyled"
                  tabs={[
                    { value: 0, label: 'Usuário' },
                    { value: 1, label: 'Perfis de Acesso' },
                  ]}
                />
                <Divider style={{ marginBottom: '1.5rem' }} />
              </>
            )}
            <StyledPanel index={0} cover>
              <FormWrapper>
                <FormTextField
                  name="weu_nome"
                  label="Nome"
                  type="text"
                  size="small"
                  margin="normal"
                  variant="standard"
                  disabled={isMasterAdminEditing}
                />
                <FormTextField
                  name="weu_cpf"
                  label="CPF/CNPJ"
                  type="text"
                  size="small"
                  variant="standard"
                  margin="normal"
                  textMaskProps={toMask(cnpjCpfMask)}
                  disabled={isMasterAdminEditing}
                />
                <FormTextField
                  name="weu_email"
                  label="E-mail"
                  type="email"
                  size="small"
                  margin="normal"
                  variant="standard"
                  disabled={disabledEmailField}
                />
                <FormTextField
                  name="weu_telefone"
                  label="Telefone"
                  type="tel"
                  size="small"
                  variant="standard"
                  margin="normal"
                  textMaskProps={toMask(phoneMask)}
                  disabled={isMasterAdminEditing}
                />
                {isEditing && !isSelfEditing && (
                  <div>
                    <FormCheckbox name="weu_ativo" label="Ativo" />
                  </div>
                )}
              </FormWrapper>
            </StyledPanel>

            {/** ==== PROFILE ACCESS ==== */}
            <StyledPanel index={1} cover>
              <FormWrapper className="list">
                <ProfileTransferList
                  disabled={disableProfileTransferList}
                  userProfiles={currentUser?.profiles?.map((p) => p.id_perfil)}
                />
              </FormWrapper>
            </StyledPanel>
          </Container>
        </TabNavigation>
      </Dialog>
    </Form>
  )
}

UserFormEdit.propTypes = {
  loggedUser: PropTypes.object,
}

export default UserFormEdit
