import React, { useState } from 'react'
import clsx from 'clsx'
import { Print } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import formatter from 'services/formatter'
import handleErrors from 'services/handleErrors'
import { convertJsonToXlsx } from 'utils/convert-json-to-xlsx'

import { ReactComponent as ExcelIcon } from 'assets/images/icons/microsoft-excel.svg'
import { Content, Paper } from 'components/index'

import * as SC from '../styles'
import SideBar from './Filter'
import InventoryTable from './Table'
import ProductDataSheet from './ProductDataSheet'

const UNAVAILABLE_STOCK_LIMIT = 1000

export default function InventoryPage() {
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(null)

  const initializeReport = async (filters) => {
    setLoading(true)

    try {
      const response = await api.post(apiEndPoints.queries.inventory, {
        filtros: filters,
      })

      const products = response.data.map((product) => ({
        cod_prod: product.cod_produto,
        abreviado: product.dsc_abreviado,
        saldo_disponivel: formatter(product.sdo_maior_lote).toDecimal(),
        tamanho: product.dsc_tamanho_produtos,
        cod_barras: product.it_cbarra,
        ncm: product.it_nbm,
        linha: product.dsc_linha_produtos,
        marca: product.dsc_marca,
        classif: product.prd_referencia,
        unidade: product.dsc_un_medidas,
        ind_uso: product.prd_ind_uso,
        var_ton: product.prd_var_ton,

        m2_caixa: formatter(product.prd_m2_caixa).toDecimal(),
        cx_pallet: product.prd_cx_pallet,
        m2_pallet: formatter(product.prd_m2_pallet).toDecimal(),
        peso_liq: formatter(product.it_peso_liq).toDecimal(),
        peso_caixa: formatter(product.peso_caixa).toDecimal(),
        peso_bruto: formatter(product.it_peso_bru).toDecimal(),
        peso_pallet: formatter(product.peso_pallet).toDecimal(),

        descrição: product.dsc_item,
        link_site_produto: product.prd_link_produto,
        cod_produto_cli: product.cod_produto_cli,
        indisponivel:
          parseFloat(product.sdo_maior_lote) < UNAVAILABLE_STOCK_LIMIT ? 1 : 0,
      }))

      setProducts(products)
    } catch (error) {
      handleErrors(error, 'Não foi possível gerar o relatório.')
    }

    setLoading(false)
  }

  const clearReport = () => {
    setProducts([])
  }

  const handleExport = () => {
    convertJsonToXlsx(products, `consulta-estoque-${new Date().getTime()}`)
  }

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <Content
        title="Consultar Estoque"
        className={clsx({ printable: current === null })}
        showReturn
        sideBarStart
        loading={loading}
        SideComponent={
          <SideBar onSubmit={initializeReport} onClear={clearReport} />
        }
        Controls={
          <SC.Controls>
            <SC.Button
              disableElevation
              size="medium"
              variant="contained"
              color="primary"
              className="Excel"
              startIcon={<ExcelIcon />}
              onClick={handleExport}
            >
              Exportar
            </SC.Button>
            <SC.Button
              disableElevation
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<Print />}
              onClick={handlePrint}
            >
              Imprimir
            </SC.Button>
          </SC.Controls>
        }
      >
        <Paper className="border-none">
          <InventoryTable data={products} onShowDataSheet={setCurrent} />
        </Paper>

        <SC.Messages>
          <Typography color="primary" gutterBottom>
            - Produtos indisponíveis favor consultar representante.
          </Typography>
          <Typography color="primary">
            - Saldo atual até presente momento. Sujeito a alterações.
          </Typography>
        </SC.Messages>
      </Content>

      {current && (
        <ProductDataSheet
          current={current}
          open={!!current}
          onClose={() => setCurrent(null)}
        />
      )}
    </>
  )
}
