import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { view, lensPath, split } from 'ramda'
import { IconButton } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'

import formatter from '../../services/formatter'

import { TableRow, TableCell } from './styles'

const formatCell = (cellValue, { cellFormat }) => {
  if (!cellFormat) return cellValue

  switch (cellFormat) {
    case 'decimal':
      return cellValue
        ? Number(cellValue) === 0
          ? '0,00'
          : formatter(Number(cellValue)).toDecimal()
        : ''

    case 'date':
      return cellValue && formatter(cellValue).toSimpleDate()
    case 'time':
      return (
        cellValue &&
        formatter(cellValue).toTimeDate({
          hour: '2-digit',
          minute: '2-digit',
        })
      )
    case 'document':
      return cellValue && formatter(cellValue).toCNPJorCPF()

    default:
      return cellValue
  }
}

const findCellValue = (path, rowData) => {
  if (!path) return ''

  const fieldLens = lensPath(split('.', path))
  return view(fieldLens, rowData)
}

export function RenderTableRow({
  isFooterRow,
  isGroupRow,
  rowData,
  columns,
  cellStyle,
  rowStyle,
  detailPanel,
  detailPanelIcon,
}) {
  const [showDetail, setShowDetail] = useState(false)

  const getRowStyle = (provided) => {
    if (rowStyle) return rowStyle(provided)
    else return {}
  }

  const getCellStyle = (column, rowData, columnIndex) => {
    if (typeof cellStyle === 'function') {
      const styles = cellStyle({
        rowData,
        isGroupRow,
        isFooterRow,
        isGroupedRow: !isGroupRow && !isFooterRow,
        columnName: column.field,
        columnIndex,
      })
      return styles || null
    } else return null
  }

  const handleShowDetail = () => {
    setShowDetail(!showDetail)
  }

  const isDetailed = columns !== undefined && detailPanel !== undefined

  return (
    <>
      <TableRow
        style={getRowStyle({ isGroupRow, rowData })}
        className={clsx({
          groupRow: isGroupRow,
          withError: rowData.withError,
        })}
      >
        {isDetailed && (
          <TableCell role="action" key="detail-panel-cell">
            <IconButton size="small" onClick={handleShowDetail}>
              {detailPanelIcon ? (
                detailPanelIcon({
                  open: showDetail,
                  fontSize: 'small',
                  color: 'primary',
                })
              ) : (
                <ExpandMoreRounded fontSize="small" color="primary" />
              )}
            </IconButton>
          </TableCell>
        )}
        {columns?.map((column, columnIndex) => {
          const cellValue = findCellValue(column.field, rowData)
          const formattedCell = formatCell(cellValue, column)

          return (
            <TableCell
              key={columnIndex}
              style={getCellStyle(column, rowData, columnIndex)}
              align={column.align}
              role={column.role}
              colSpan={column.colSpan}
            >
              {typeof cellValue === 'function'
                ? cellValue(rowData)
                : typeof column.renderCell === 'function' && !isFooterRow
                ? column.renderCell({
                    cellValue,
                    formattedCell,
                    rowData,
                    column,
                    isGroupRow,
                  })
                : formattedCell}
            </TableCell>
          )
        })}
      </TableRow>
      {isDetailed && detailPanel({ row: rowData, show: showDetail })}
    </>
  )
}

RenderTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  isFooterRow: PropTypes.bool,
  isGroupRow: PropTypes.bool,
  rowData: PropTypes.object,
  cellStyle: PropTypes.func,
  detailPanel: PropTypes.oneOfType([PropTypes.func]),
  detailPanelIcon: PropTypes.oneOfType([PropTypes.func]),
}

export default RenderTableRow
