import styled from 'styled-components'
import Scroll from 'components/InfiniteScroll'

export const ScrollContainer = styled(Scroll)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > div {
    margin: 12px;
  }

  .InfiniteScroll-LoaderContainer {
    // used to keep the loader always below the listing, when it has a flex-wrap: wrap
    /* min-width: calc(100vw - 100px); */
  }
`
