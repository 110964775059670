import { formatter } from './formatter'

export const getItemId = (item) => [
  item.weu_id,
  item.cod_pedido,
  item.id_cia,
  item.id_itens,
  item.id_agendamento,
]

export const getRowId = (item) => [
  item.weu_id,
  item.cod_pedido,
  item.id_cia,
  item.cod_produto,
  item.id_agendamento,
]

export const isEmptyOrderItem = (item) => Number(item.peso_bru) === 0

export const cleateScheduleTableData = (orders) => {
  const tableData = orders.flatMap((order) =>
    order.itens.map((orderItem) => {
      let tableItem = { ...orderItem }
      tableItem.id = getItemId(orderItem)

      tableItem.representative = order.representative.dsc_empresa
      tableItem.cod_pedido = order.cod_pedido
      tableItem.ped_local_entrega = order.ped_local_entrega
      tableItem.ped_tipo_frete = order.ped_tipo_frete
      tableItem.ped_data_emissao = formatter(
        order.ped_data_emissao
      ).toSimpleDate()
      tableItem.issued_at = `Emitido em ${tableItem.ped_data_emissao}`

      tableItem.rowId = getRowId(orderItem)
      tableItem.selected = false
      tableItem.withError = false
      return tableItem
    })
  )
  return tableData
}
