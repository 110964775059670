import styled from 'styled-components'
import InputLabel from '@material-ui/core/InputLabel'
import { colors } from 'theme'

export const Container = styled.div`
  margin: 0;
  label {
    margin: 0;

    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    &.Mui-disabled {
      pointer-events: none;
    }

    input {
      display: none;
    }
  }
`

export const StyledInputLabel = styled(InputLabel)`
  &.Mui-focused {
  }

  .label-text {
    margin: 5px auto;
    text-align: center;

    font-size: 12px;
    color: ${colors.color300};
  }
`
