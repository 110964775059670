import createSagaMiddleware from 'redux-saga'

import persistReducers from './persistReducer'
import rootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'

import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import persistStore from 'redux-persist/es/persistStore'

const configureAdminStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware]

  const store = createStore(
    persistReducers(rootReducer),
    applyMiddleware(...middlewares)
  )
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}

export default configureAdminStore
