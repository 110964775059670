import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import * as medias from '../../consts/medias'
import { colors } from 'theme'
import { Container } from './styles'

export function ToolButton({ active, extended, Icon, ...props }) {
  const sm = useMediaQuery(medias.sm)

  return (
    <Container
      {...props}
      className={clsx({ active, extended }, props.className)}
    >
      {!sm && props.tooltip && <div className="tooltip">{props.tooltip}</div>}
      <Icon fontSize="small" />
    </Container>
  )
}

ToolButton.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  tooltip: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  active: PropTypes.bool,
  extended: PropTypes.bool,
}

ToolButton.defaultProps = {
  active: false,
  extended: false,
  color: '#FFF',
  backgroundColor: colors.color500,
}

export default ToolButton
