import { all, takeLatest, call, put } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getDashboardFailure,
  getDashboardRequest,
  getDashboardSuccess,
} from './reducer'

function* getCarrierDashboard() {
  try {
    const response = yield call(api.get, apiEndPoints.user.carrierDashboard)
    yield put(getDashboardSuccess(response.data))
  } catch (error) {
    yield put(getDashboardFailure())
    handleErrors(error, 'Não foi possível buscar os dados da transportadora')
  }
}

export default all([takeLatest(getDashboardRequest.type, getCarrierDashboard)])
