// --> LIBRARIES
import React from 'react'
import PropTypes from 'prop-types'
// --> COMPONENTS
import { Container, StyledInputLabel } from './styles'
import { Avatar } from '@material-ui/core'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const read = (file) => {
  if (!file) return null

  const readedFile = new Promise((response) => {
    const fileReader = new FileReader()

    fileReader.addEventListener('load', () => {
      response({
        file: fileReader.result,
        fileName: file.name,
        size: file.size,
        type: file.type,
      })
    })

    fileReader.readAsDataURL(file)
  })

  return readedFile
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function ImageInput({
  className,
  label,
  name,
  value,
  onChange,
  disabled,
  ...props
}) {
  const file = value ?? {}

  async function handleChange(e) {
    const readedFile = await read(e.target.files[0])
    if (readedFile) onChange({ target: { name, value: { ...readedFile } } })
  }

  return (
    <Container>
      <StyledInputLabel disabled={disabled} htmlFor={name}>
        <Avatar className={className} alt={props.alt} src={file.file} />
        <input
          type="file"
          id={name}
          accept="image/*"
          onChange={handleChange}
          data-file={file.file}
          disabled={disabled}
        />
        {!disabled && <div className="label-text">{label}</div>}
      </StyledInputLabel>
    </Container>
  )
}

ImageInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

ImageInput.defaultProps = {
  disabled: false,
  value: {},
}
