import React, { useRef } from 'react'
import { addDays, formatISO, startOfMonth } from 'date-fns'
import { merge } from 'ramda'

import formatter from 'services/formatter'
import { copyObject } from 'services/utils'
import Yup from 'services/yup'

import { Grid } from '@material-ui/core'
import { Search, Delete } from '@material-ui/icons'

import SideBar from 'components/SideBar'
import Button from 'components/Button'
import FilterTitle from 'components/FilterTitle'
import StyledFilterForm from 'components/StyledFilterForm'

import { FormCheckbox, FormDatePicker, FormTextField } from 'components/CoForm'
import ComboboxBrands from 'components/Combobox/Brands'
import ComboboxProductRefs from 'components/Combobox/ProductRefs'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const initialData = {
  data_inicial: formatISO(startOfMonth(new Date())),
  data_final: formatISO(new Date()),
  cod_cia: 0,
  ped_seupedido: '',
  classif: 'T',
  gera_fatur: true,
}

// ╦ ╦╔╦╗╦╦  ╔═╗
// ║ ║ ║ ║║  ╚═╗
// ╚═╝ ╩ ╩╩═╝╚═╝
const schema = Yup.object().shape({
  data_inicial: Yup.string().required('Informe a data inicial').nullable(),
  data_final: Yup.string().required('Informe a data final').nullable(),
})

const minDate = addDays(new Date(), -365)

export default function Filter({ onSubmit, onClear, ...props }) {
  // ╦ ╦╔═╗╔═╗╦╔═╔═╗
  // ╠═╣║ ║║ ║╠╩╗╚═╗
  // ╩ ╩╚═╝╚═╝╩ ╩╚═╝
  const formRef = useRef(null)

  // ╦ ╦╔═╗╔╗╔╔╦╗╦  ╔═╗╦═╗╔═╗
  // ╠═╣╠═╣║║║ ║║║  ║╣ ╠╦╝╚═╗
  // ╩ ╩╩ ╩╝╚╝═╩╝╩═╝╚═╝╩╚═╚═╝
  const handleSubmit = (filteredValues) => {
    const draft = copyObject(filteredValues)

    draft.data_inicial = formatter(draft.data_inicial).toSimpleDate()
    draft.data_final = formatter(draft.data_final).toSimpleDate()

    draft.ped_seupedido = draft.ped_seupedido?.trim()
    draft.gera_fatur = Number(draft.gera_fatur)

    const params = merge({ ...initialData }, draft)
    onSubmit(params)
  }

  const handleClear = () => {
    formRef.current.setValues({ ...initialData })
    onClear()
  }

  return (
    <SideBar
      {...props}
      ContentComponent={
        <StyledFilterForm
          initialData={{ ...initialData }}
          formRef={formRef}
          schema={schema}
          onSubmit={handleSubmit}
        >
          <FilterTitle>Pesquisar</FilterTitle>

          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="data_inicial"
                label="Data Inicial"
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="data_final"
                label="Data Final"
              />
            </Grid>
            <Grid item xs={12}>
              <ComboboxBrands fullWidth name="cod_cia" label="Marca" />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                fullWidth
                name="ped_seupedido"
                label="Seu Pedido"
              />
            </Grid>
            <Grid item xs={12}>
              <ComboboxProductRefs fullWidth name="classif" label="Classif." />
            </Grid>
            <Grid item xs={12}>
              <FormCheckbox name="gera_fatur" label="Gera Fatur." />
            </Grid>
          </Grid>

          <div className="buttons">
            <Button type="submit" label="Buscar" startIcon={<Search />} />
            <Button
              type="button"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClear}
            />
          </div>
        </StyledFilterForm>
      }
    />
  )
}
