import * as XLSX from 'xlsx'

const EXCEL_EXTENSION = '.xlsx'

export function convertJsonToXlsx(data, filename = 'DataSheet') {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, filename + EXCEL_EXTENSION)
}
