import formatter from 'services/formatter'
import { today } from 'services/utils'

export const getItemId = (item) => [
  item.weu_id,
  item.cod_pedido,
  item.id_cia,
  item.id_itens,
  item.id_coleta,
]

export const getProductId = (item) => [
  item.weu_id,
  item.cod_pedido,
  item.id_cia,
  item.cod_produto,
  item.id_coleta,
]

export const isEmptyOrderItem = (item) => Number(item.peso_bru) === 0

export const createCollectTableData = (orders) => {
  const tableData = orders.map((order) => {
    return order.itens.map((item) => {
      let orderItem = { ...item }
      const rowId = getProductId(item)

      orderItem.id = getItemId(item)
      orderItem.id_product = rowId
      orderItem.rowId = rowId
      orderItem.representative = order.representative.dsc_empresa
      orderItem.ped_local_entrega = order.ped_local_entrega
      orderItem.ped_tipo_frete = order.ped_tipo_frete
      orderItem.selected = false
      orderItem.withError = false

      return orderItem
    })
  })

  return tableData.flat()
}

export function createCollectItemForPrint(ci) {
  let collectItem = {}

  collectItem.id_coleta = ci.id_coleta
  collectItem.id_cia = ci.id_cia
  collectItem.cia_descricao_carreg = ci.cia_descricao_carreg

  collectItem.customer = {}
  collectItem.customer.emp_razao_social = ci.emp_razao_social
  collectItem.customer.emp_endereco = `${ci.emp_cidade}/${ci.dsc_uf}`

  collectItem.cod_cliente = ci.cod_cliente
  collectItem.cod_pedido = ci.cod_pedido
  collectItem.ped_seupedido = ci.order.ped_seupedido
  collectItem.cod_produto = ci.cod_produto
  collectItem.dsc_produto = ci.dsc_abreviado
  collectItem.vpt_peso_bruto = ci.vpt_peso_bruto
  collectItem.vpt_qtd = ci.vpt_qtd
  collectItem.vpt_data_prev_entrega = ci.vpt_data_prev_entrega
  collectItem.ped_tipo_frete = ci.order.ped_tipo_frete
  collectItem.ped_representante = ci.representative.dsc_empresa

  return collectItem
}

const filterByGroupCollectId = (mainCollect, collect) => {
  if (mainCollect.id_coleta_agrupa) {
    return (
      mainCollect.id_coleta === collect.id_coleta ||
      mainCollect.id_coleta_agrupa === collect.id_coleta_agrupa
    )
  }

  return mainCollect.id_coleta === collect.id_coleta
}

export function createCollectForPrint(mainCollect, collects) {
  let collect = {}

  let collectItems = collects.filter((collect) =>
    filterByGroupCollectId(mainCollect, collect)
  )

  const collectItem = collectItems[0]

  collect.id = mainCollect.id_coleta_agrupa || mainCollect.id_coleta
  // collect.schedules = collectItem.schedules
  collect.items = collectItems.map(createCollectItemForPrint)
  collect.isGrouped = collect.items.some(
    (ci) => ci.id_coleta !== mainCollect.id_coleta
  )

  collect.printDate = today()
  collect.status = collectItem.status

  collect.driver = {}
  collect.driver.id = collectItem.vpt_cod_motorista
  collect.driver.name = collectItem.vpt_motorista

  collect.truck = {}
  collect.truck.plate = collectItem.vpt_placa
  collect.truck.dsc_caminhao = collectItem.truck_type.dsc_tipo_caminhao
  collect.truck.dsc_tipo_caminhao = collectItem.truck_type.dsc_tipo_caminhao

  collect.date = formatter(collectItem.vpt_data_emissao).toDate()
  collect.lastUpdateDate = collectItem.vpt_dinsert
    ? formatter(collectItem.vpt_dinsert).toDate()
    : null

  collect.collectDate = formatter(collectItem.vpt_dcoleta).toSimpleDate()
  collect.obs = collectItem.vpt_obs

  return collect
}
