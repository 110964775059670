import React from 'react'
import { useLocale } from '../hooks'
import { IntlProvider } from 'react-intl'
import flatten from 'flat'
import messages from './messages'

function Provider({ children }) {
  const { idLang } = useLocale()
  const flattenedLocaleMessages = flatten(messages[idLang])

  return (
    <IntlProvider
      locale={idLang}
      textComponent={React.Fragment}
      messages={flattenedLocaleMessages}
    >
      {children}
    </IntlProvider>
  )
}

Provider.propTypes = {}

export default Provider
