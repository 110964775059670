import React from 'react'
import DatePickerBase from '../DatePickerBase'
import TextField from '../TextField'

import { formatISO, parseISO } from 'date-fns'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function DatePicker(props) {
  return <DatePickerBase TextFieldComponent={TextField} {...props} />
}

// --------------- ℂ𝕠𝕟𝕥𝕣𝕠𝕝𝕝𝕖𝕕 𝔽𝕠𝕣𝕞 𝕍𝕖𝕣𝕤𝕚𝕠𝕟 ---------------

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

export function FormDatePicker({ name, value, onChange, ...props }) {
  const handleChange = (newDate) => {
    onChange({
      target: {
        name,
        value: isValidDate(newDate) ? formatISO(newDate) : null,
      },
    })
  }

  const parsedValue = value ? parseISO(value) : null

  return (
    <DatePickerBase
      onChange={handleChange}
      TextFieldComponent={TextField}
      value={parsedValue}
      {...props}
    />
  )
}

export default DatePicker
