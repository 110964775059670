import { createSlice } from '@reduxjs/toolkit'
import { sum } from 'ramda'
import { precisionRound } from 'services/utils'

const initialState = {
  loading: false,
  issueds: [],
  errors: [],
  active: {
    schedule: [],
    scheduleParams: {},
    weight: 0,
  },
  selectedWeight: 0,
  count: 0,
}

const createScheduleParams = (schedule = {}, isEditing = false) => {
  return schedule.vpa_dagenda
    ? {
        cod_empresa: schedule.carrier?.id,
        id_agendamento: schedule.id_agendamento,
        id_tipo_caminhao: schedule.id_tipo_caminhao,
        vpa_dagenda: schedule.vpa_dagenda,
        vpa_placa: schedule.vpa_placa,
        vpa_motorista: schedule.vpa_motorista,
        vpa_cod_motorista: schedule.vpa_cod_motorista,
        isEditing,
      }
    : initialState.active.scheduleParams
}

const schedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    // get active collect
    getCollectRequest(state) {
      state.loading = true
    },
    getCollectSuccess(state, { payload: schedule }) {
      state.active.schedule = schedule

      const schedules = schedule.flatMap((collectItem) => collectItem.itens)
      const scheduleItem = schedules[0]

      if (scheduleItem) {
        state.active.scheduleParams = {
          cod_empresa: scheduleItem.cod_empresa,
          id_agendamento: scheduleItem.id_agendamento,
          id_tipo_caminhao: scheduleItem.id_tipo_caminhao,
          vpa_dagenda: scheduleItem.vpa_dagenda,
          vpa_placa: scheduleItem.vpa_placa,
          vpa_motorista: scheduleItem.vpa_motorista,
          vpa_cod_motorista: scheduleItem.vpa_cod_motorista,
          isEditing: false,
        }
      }

      state.active.weight = sum(schedules.map((item) => item.vpa_peso_bruto))

      state.errors = []
      state.loading = false
    },
    getCollectFailure(state) {
      state.loading = false
    },

    // create collection in progress
    createCollectRequest(state) {
      state.loading = true
    },
    createCollectSuccess(state) {
      state.selectedWeight = initialState.selectedWeight
      state.loading = false
    },
    createCollectFailure(state) {
      state.loading = false
    },

    // finish collection in progress
    finishCollectRequest(state) {
      state.loading = true
    },
    finishCollectSuccess(state) {
      state.active.weight = initialState.active.weight
      state.active.scheduleParams = initialState.active.scheduleParams
      state.active.schedule = initialState.active.schedule
      state.loading = false
    },
    finishCollectFailure(state, { payload }) {
      state.loading = false
      if (payload) state.errors = payload
    },
    finishCollectCancel(state) {
      state.loading = false
    },

    // update collect
    updateCollectRequest(state) {
      state.loading = true
    },
    updateCollectSuccess(state) {
      state.loading = false
      state.active.scheduleParams = initialState.active.scheduleParams
    },
    updateCollectFailure(state) {
      state.loading = false
    },

    // remove
    removeCollectItemsRequest() {},

    // update
    updateCollectItemRequest(state) {
      state.loading = true
    },

    updateCollectItemFailure(state) {
      state.loading = false
    },

    // handle weight
    updateSelectedWeight(state, { payload }) {
      if (!payload) state.selectedWeight = 0
      else {
        const weight = state.selectedWeight
        const newWeight = weight + payload.added - payload.removed
        state.selectedWeight = precisionRound(newWeight, 1)
      }
    },

    // issueds
    issuedsRequest(state) {
      state.loading = true
    },
    issuedsSuccess(state, { payload }) {
      state.issueds = payload
      state.loading = false
    },
    issuedsFailure(state) {
      state.loading = false
    },

    // cancel collect
    cancelCollectRequest(state) {
      state.loading = true
    },
    cancelCollectSuccess(state) {
      state.loading = false
    },
    cancelCollectFailure(state) {
      state.loading = false
    },

    // reopen collect
    reOpenCollectRequest(state) {
      state.loading = true
    },

    reOpenCollectFailure(state) {
      state.loading = false
    },
    reOpenCollectSuccess(state, { payload }) {
      state.loading = false
      state.active.scheduleParams = payload
    },

    // edit collect
    displayForEditCollect(state, { payload: scheduleItem }) {
      state.active.scheduleParams = createScheduleParams(scheduleItem, true)
      state.active.weight = scheduleItem.collectWeight
    },
    cancelEditCollect(state) {
      state.active.scheduleParams = initialState.active.scheduleParams
    },

    // collect count
    collectCountRequest() {},
    collectCountSuccess(state, { payload }) {
      state.count = payload
    },
  },
})

export const {
  getCollectRequest,
  getCollectSuccess,
  getCollectFailure,
  createCollectRequest,
  createCollectSuccess,
  createCollectFailure,
  finishCollectRequest,
  finishCollectSuccess,
  finishCollectFailure,
  finishCollectCancel,
  updateSelectedWeight,
  removeCollectItemsRequest,
  issuedsRequest,
  issuedsSuccess,
  issuedsFailure,
  updateCollectItemRequest,
  updateCollectItemFailure,
  cancelCollectFailure,
  cancelCollectRequest,
  cancelCollectSuccess,
  reOpenCollectRequest,
  reOpenCollectFailure,
  reOpenCollectSuccess,
  updateCollectFailure,
  updateCollectRequest,
  updateCollectSuccess,
  displayForEditCollect,
  cancelEditCollect,
  collectCountRequest,
  collectCountSuccess,
} = schedulesSlice.actions

/** SELECTORS */
export const collectWeightSelector = (state) =>
  state.users.schedules.active.weight

export const collectSelectedWeightSelector = (state) =>
  state.users.schedules.selectedWeight

export default schedulesSlice.reducer
