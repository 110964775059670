import React from 'react'
import { FormCombobox } from 'components/CoForm'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩
export const refs = [
  { id: 'T', descr: 'Todas' },
  { id: 'A', descr: 'A' },
  { id: 'B', descr: 'B' },
  { id: 'C', descr: 'C' },
]

export function ComboboxProductRefs(props) {
  const options = refs.map((r) => ({
    value: r.id,
    label: r.descr,
  }))

  return <FormCombobox options={options} {...props} />
}

export default ComboboxProductRefs
