import styled, { css } from 'styled-components'
import { colors } from 'theme'
import * as medias from 'consts/medias'
import { IconButton } from '@material-ui/core'
import { Button } from '..'

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.white};
  z-index: 2;

  padding: 1rem;
  display: flex;
  align-items: center;

  border: 1px solid ${colors.color100};
  position: sticky;
  top: 0;

  @media ${medias.sm} {
    position: fixed;
    top: initial;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

export const ContainerWeights = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 380px;
`

export const Divider = styled.div`
  width: 60px;
  height: 0;

  border: 1px solid ${colors.color100};
  background-color: ${colors.color100};
  transform: rotate(90deg);
`

export const ContainerButton = styled.div`
  flex: 1;
`

const buttonStyles = css`
  transition: opacity 100ms ease-in;

  &:not(:disabled) {
    animation: shake 300ms backwards;
    background-color: ${colors.color500};
    .MuiButton-label,
    .MuiIconButton-label {
      color: ${colors.white};
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &:hover {
    opacity: 0.8;
  }
`

export const StyledButton = styled(Button)`
  ${buttonStyles}
  min-height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
`

export const StyledIconButton = styled(IconButton)`
  border: 1px solid ${colors.color200};
  ${buttonStyles};
`

export const WeightText = styled.div`
  margin: 3px 0;

  display: flex;
  justify-content: space-between;

  color: ${colors.color800};
  font-weight: bold;
  font-size: 12px;

  &.fill {
    color: ${colors.color500};
  }

  @media ${medias.sm} {
    font-size: 10px;
  }
`
