import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  releases: [],
  formats: [],
  marks: [],
  loading: false,
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

const releases = createSlice({
  name: 'releases',
  initialState,
  reducers: {
    // Releases
    getReleasesRequest(state) {
      state.loading = true
    },
    getReleasesSuccess(state, { payload }) {
      state.releases = payload.map((product) => ({
        ...product,
        dsc_linha_produtos: product.line.dsc_linha_produtos,
        dsc_produto: [
          product.cod_produto.slice(0, -2),
          product.dsc_item_ingles,
        ].join(' '),
      }))
      state.loading = false
    },

    getReleasesFailure(state) {
      state.loading = false
    },

    setAddSampleRequest() {},
  },
})

export const {
  getReleasesRequest,
  getReleasesFailure,
  getReleasesSuccess,
  setAddSampleRequest,
} = releases.actions

export default releases.reducer
