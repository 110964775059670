import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { isEmail } from 'services/utils'
import eventEmitter from 'services/eventEmitter'

import { toast } from 'react-toastify'
import { FormCombobox, useCurrentForm } from 'components/CoForm'

import { makeStyles } from '@material-ui/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import formatter from 'services/formatter'

import { companyString } from 'consts/types'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const companyVariants = {
  carrier: companyString.TRANSPORTADORA,
  customer: companyString.CLIENTE,
  representative: companyString.REPRESENTANTE,
}

const events = {
  fetchUserCompanies: '@portalDelta/COMBOBOXUSERCOMPANIES/fetchUserCompanies',
}

export const comboBoxUserCompanies = {
  fetchUserCompanies(email) {
    eventEmitter.emit(events.fetchUserCompanies, email)
  },
}

const useStyles = makeStyles((theme) => ({
  ToggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      backgroundColor: '#A89A57',
      color: theme.palette.common.white,
    },
  },
}))

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboBoxUserCompanies({ name, ...props }) {
  const [userCompanies, setUserCompanies] = useState([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles(props)
  const { setFieldValue, getFieldValue } = useCurrentForm()

  const webUserId = getFieldValue('weu_id')
  const selectedCompany = getFieldValue(name)

  /** FormCombobox options */
  const options = useMemo(() => {
    const mappedCompanies = new Map(
      userCompanies.map((company) => [company.cod_empresa, company])
    ).values()

    const userCompaniesUniquedByDocument = [...mappedCompanies]

    return userCompaniesUniquedByDocument.map((company) => {
      const formattedDocument = formatter(company.cod_empresa).toCNPJorCPF()

      return {
        value: company.cod_empresa,
        label: `${company.emp_razao_social} (${formattedDocument})`,
      }
    })
  }, [userCompanies])

  const selectedCompanyVariants = userCompanies.filter(
    (uc) => uc.cod_empresa === selectedCompany
  )

  const showCompanyVariantToogler = selectedCompanyVariants.length > 1

  useEffect(() => {
    async function fetchUserCompanies(email) {
      if (isEmail(email)) {
        try {
          setLoading(true)

          const response = await api.get(apiEndPoints.userCompanies, {
            params: { weu_email: email },
          })

          const userCompanies = response.data
          setUserCompanies(userCompanies)

          if (userCompanies.length < 1) {
            toast.info('Não existem usuários cadastrados para esse e-mail')
            return
          }

          setFieldValue(name, userCompanies[0].cod_empresa)
          setFieldValue('weu_id', userCompanies[0].weu_id)
        } catch (error) {
          handleErrors(error, 'Não foi possível buscar as empresas')
          setUserCompanies([])
        } finally {
          setLoading(false)
        }
      }
    }

    eventEmitter.on(events.fetchUserCompanies, fetchUserCompanies)

    return () => {
      eventEmitter.removeEventListener(
        events.fetchUserCompanies,
        fetchUserCompanies
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleTypeChange(e, newValue) {
    if (newValue !== null) {
      setFieldValue('weu_id', newValue)
    }
  }

  function handleCompanyChange(e, option) {
    const variant = userCompanies.find((uc) => uc.cod_empresa === option.value)
    setFieldValue('weu_id', variant?.weu_id)
  }

  return (
    <>
      <FormCombobox
        label="Escolha a empresa"
        variant="standard"
        options={options}
        loading={loading}
        disabled={loading}
        name={name}
        onAfterChange={handleCompanyChange}
        {...props}
      />

      {showCompanyVariantToogler && (
        <ToggleButtonGroup
          color="primary"
          value={webUserId}
          onChange={handleTypeChange}
          exclusive
        >
          {selectedCompanyVariants.map((company) => (
            <ToggleButton
              size="small"
              key={`company-variant-${company.weu_id}`}
              classes={{ selected: classes.ToggleButton }}
              value={company.weu_id}
            >
              <span>{companyVariants[company.wee_tipo]}</span>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    </>
  )
}

ComboBoxUserCompanies.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ComboBoxUserCompanies
