import React, { useRef, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import { groupWithCompany, groupWithCustomer, notEmpty } from 'services/utils'
import { handleErrors } from 'services/handleErrors'
import { getItemId, createCollectForPrint } from 'services/collects'
import {
  getCollectRequest,
  removeCollectItemsRequest,
  cancelCollectPrint,
  finishCollectRequest,
} from 'store/modules/users/collections/reducer'
import { useMediaQuery } from '@material-ui/core'
import { Trash } from 'components/Icons'
import { Content, ErrorMessage } from 'components/index'
import {
  Container,
  Header,
  StyledCheckBox,
  StyledButton,
  CollectList,
} from './styles'
import FormEdit from './Form'
import TableCollect from 'components/Transportadora/TableCollect'
import CollectPrint from 'components/Transportadora/CollectPrint'
import CollectSchedules from 'components/Transportadora/CollectSchedules'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function CollectPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.users.collections.loading)
  const collectItems = useSelector(
    (state) => state.users.collections.active.collect
  )
  const collectForPrint = useSelector(
    (state) => state.users.collections.active.collectForPrint
  )
  const collectItemsWithError = useSelector(
    (state) => state.users.collections.errors
  )

  const [pendingSchedules, setPendingSchedules] = useState([])
  const [scheduleParams, setScheduleParams] = useState(null)
  const tablesRef = useRef({})

  useEffect(() => {
    const fetchAvailableSchedules = async () => {
      try {
        const response = await api.get(apiEndPoints.user.shippingOrders.root)
        const schedules = response.data
        setPendingSchedules(schedules)
      } catch (error) {
        handleErrors(error, 'Não foi possível buscar os Agendamentos')
      }
    }

    dispatch(getCollectRequest())
    fetchAvailableSchedules()
  }, [dispatch])

  // responsible effect for selecting the items with error in the collection
  useEffect(() => {
    const collectIds = collectItemsWithError
      .map(getItemId)
      .map((id) => String(id))

    Object.values(tablesRef.current).forEach((provided) => {
      provided.selectBy(
        (tableItem) => collectIds.includes(String(tableItem.id)),
        true,
        { withError: true }
      )
    })
  }, [collectItemsWithError])

  function handleFinish(values) {
    let params = { ...values }
    params.schedules = params.schedules || []

    dispatch(finishCollectRequest(params))
    setScheduleParams(null)
  }

  function handleSubmit(values) {
    const withPendingSchedules = notEmpty(pendingSchedules)

    if (withPendingSchedules) setScheduleParams(values)
    else handleFinish(values)
  }

  function removeSelectedItems() {
    let selected = []
    Object.values(tablesRef.current).forEach((provided) => {
      selected.push(provided.getSelectedItems())
    })

    dispatch(removeCollectItemsRequest(selected.flat()))
  }

  function selectAll(_, checked) {
    Object.values(tablesRef.current).forEach((methods) => {
      methods.selectAll(checked)
    })
  }

  function handleClose() {
    dispatch(cancelCollectPrint())
  }

  function Actions() {
    return (
      <Header>
        <StyledCheckBox onChange={selectAll} label="Selecionar Todos" />
        <ErrorMessage
          show={notEmpty(collectItemsWithError)}
          message="Alguns itens não possuem saldo suficiente"
        />
        <StyledButton
          className="red"
          label={sm ? 'Excluir' : 'Excluir Selecionados'}
          endIcon={<Trash />}
          onClick={removeSelectedItems}
        />
      </Header>
    )
  }

  const companies = groupWithCompany(collectItems)
  const withCollectItems = notEmpty(collectItems)

  return (
    <Content
      title="Coleta em Andamento"
      loading={loading}
      Controls={withCollectItems && sm ? <Actions /> : null}
    >
      {withCollectItems ? (
        <Container>
          <CollectList>
            {!sm && <Actions />}

            {companies.map((orders, companyIndex) => (
              <section key={`company-section-${companyIndex}`}>
                <h3 className="collect-list-title">
                  {orders[0]?.billing_company.cia_descricao_carreg}
                </h3>

                {groupWithCustomer(orders).map((data, index) => {
                  const customerId = data[0].cod_cliente

                  return (
                    <TableCollect
                      key={`customer-table-collect-${index}`}
                      data={data}
                      getRef={(provided) =>
                        (tablesRef.current[customerId] = provided)
                      }
                    />
                  )
                })}
              </section>
            ))}
          </CollectList>
          <FormEdit onSubmit={handleSubmit} />
        </Container>
      ) : (
        <Container>
          <span className="collect-empty-text">Não possui nenhum item</span>
        </Container>
      )}
      {scheduleParams && (
        <CollectSchedules
          open={Boolean(scheduleParams)}
          scheduleParams={scheduleParams}
          schedules={pendingSchedules}
          onClose={() => setScheduleParams(null)}
          onConfirm={handleFinish}
        />
      )}
      {collectForPrint && (
        <CollectPrint
          collect={createCollectForPrint(collectForPrint[0], collectForPrint)}
          open={Boolean(collectForPrint)}
          onClose={handleClose}
        />
      )}
    </Content>
  )
}
