import styled from 'styled-components'
import { colors } from 'theme'
import { Paper } from '@material-ui/core'

export const ComboContainer = styled.div`
  min-width: 200px;
`

export const SmallDeviseContent = styled.div``

export const StyledBulletinCard = styled(Paper)`
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 10px;
  transition: background-color 200ms ease;

  > div {
    padding: 10px;
  }

  .StyledBulletinCard-subject {
    color: ${colors.color500};
    text-decoration: underline;
    font-weight: bold;
  }

  .StyledBulletinCard-content {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background-color: ${colors.main};
  }
`
