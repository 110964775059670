import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import theme from 'theme'

import { isWithinInterval } from 'date-fns'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import formatter from 'services/formatter'
import apiEndPoints from 'consts/apiEndPoints'
import { usePagination } from 'hooks/index'
import {
  getOrdersRequest,
  initializeOrder,
} from 'store/modules/users/orders/orders/reducer'

import { Box, Typography } from '@material-ui/core'
import {
  CancelOutlined as Cancel,
  CheckCircleOutline as Approve,
  MenuOpen as Open,
  Visibility as View,
} from '@material-ui/icons'

import { confirmationDialog } from 'components/ConfirmationDialog'
import { useTabContext } from 'components/TabNavigation'
import Table from 'components/Table'
import Button from 'components/Button'
import TablePagination from 'components/TablePagination'
import GroupHeader from 'components/GroupHeader'

import BaseDialog from 'components/Dialog'
import ComboboxOrderCancelReasons from 'components/Combobox/OrderCancelReasons'
import { StyledButton as ConfirmationButton } from 'components/ConfirmationDialog/styles'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'
import FilterIssued from './Filter'
import IssuedOrderReport from './Report'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

const ORDER_STATUS = {
  CANCELADO: 1,
  AG_VALDIACAO: 2,
  VALIDADO: 3,
  APROVADO: 4,
}

const filterItemByKey = (item, key, value) => {
  switch (key) {
    // Filtragem por periodo
    case 'ped_data_emissao':
      if (value.initial && value.final) {
        return isWithinInterval(new Date(item.ped_data_emissao), {
          start: new Date(value.initial),
          end: new Date(value.final),
        })
      } else return true

    // Filtragem pelo codido do pedido
    case 'cod_pedido':
      return item.cod_pedido.includes(value)

    // Filtragem por produto
    case 'dsc_abreviado':
      return item.dsc_abreviado.includes(value)

    default:
      return true
  }
}

export function IssuedOrders() {
  const { changeTab } = useTabContext()

  const [current, setCurrent] = useState(null)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showOrderReport, setShowOrderReport] = useState(false)

  const dispatch = useDispatch()
  const count = useSelector((state) => state.users.orders.orders.count)
  const orders = useSelector((state) => state.users.orders.orders.issuedOrders)

  const filters = useSelector((state) => state.users.orders.orders.filters)

  const orderItems = useMemo(() => {
    let items = orders.flatMap((order) =>
      order.items.map((orderItem) => ({
        ...orderItem,
        orderId: order.id,
        cod_pedido: order.cod_pedido,
        ped_data_emissao: order.ped_data_emissao,
        payment_condition: order.payment_condition.descr,
        representative: order.representative?.emp_razao_social?.toUpperCase(),
        dsc_abreviado: `${orderItem.product.cod_produto} - ${orderItem.product.dsc_item_ingles}`,
        status: order.status,
      }))
    )

    // Lógica filtragem items
    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        items = items.filter((item) => filterItemByKey(item, key, value))
      })
    }
    return items
  }, [orders, filters])

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    usePagination()

  const fetchOrders = () => {
    dispatch(getOrdersRequest())
  }

  useEffect(() => {
    fetchOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleCancel = (orderItem) => {
    const order = orders.find(
      (order) => order.cod_pedido === orderItem.cod_pedido
    )
    setCurrent(order)
    setShowCancelModal(true)
  }

  const handleCloseCancel = () => {
    setCurrent(null)
    setShowCancelModal(false)
  }

  const handleShowOrderView = (orderItem) => {
    const order = orders.find(
      (order) => order.cod_pedido === orderItem.cod_pedido
    )
    setCurrent(order)
    setShowOrderReport(true)
  }

  const handleCloseOrderView = () => {
    setCurrent(null)
    setShowOrderReport(false)
  }

  const reopen = async ({ orderId }) => {
    try {
      const confirm = await confirmationDialog.open(
        'Reabrir',
        `Confirma a reabertura do orçamento ${orderId[0]} ?`
      )

      confirmationDialog.close()

      if (confirm) {
        const response = await api.post(
          `${apiEndPoints.user.orders.onMember(orderId)}/reopen`
        )

        changeTab(null, 1)
        dispatch(initializeOrder(response.data))
      }
    } catch (error) {
      handleErrors(error, 'Não foi possível reabrir o orçamento')
    }
  }

  const approve = async ({ orderId }) => {
    try {
      const confirm = await confirmationDialog.open(
        'Aprovar Orçamento',
        `Confirma a aprovação do orçamento ${orderId[0]} ?`
      )

      confirmationDialog.close()

      if (confirm) {
        await api.post(`${apiEndPoints.user.orders.onMember(orderId)}/approve`)

        // Reload
        fetchOrders()
      }
    } catch (error) {
      handleErrors(error, 'Não foi possível aprovar o orçamento')
    }
  }

  const cancel = async ({ orderId, cancelReason }) => {
    try {
      await api.post(`${apiEndPoints.user.orders.onMember(orderId)}/cancel`, {
        ctt_campo: cancelReason.ctt_campo,
        dsc_conteudo: cancelReason.dsc_conteudo,
      })

      // Reload
      fetchOrders()
      handleCloseCancel()
    } catch (error) {
      handleErrors(error, 'Não foi possível cancelar o orçamento')
    }
  }

  const getChipStatusColor = (orderItem) => {
    switch (orderItem.status.id) {
      case 1:
        return theme.palette.error.main
      case 2:
        return theme.palette.warning.main
      case 3:
        return theme.palette.info.main
      case 4:
        return theme.palette.success.main
      default:
        break
    }
  }

  return (
    <>
      <Box
        style={{
          padding: '0.5rem',
          background: '#fff',
        }}
      >
        <Table
          stripped={false}
          groupBy="cod_pedido"
          columns={[
            {
              field: 'dsc_abreviado',
              title: 'ABREVIADO',
            },
            {
              field: 'product.line.dsc_linha_produtos',
              title: 'LINHA',
            },
            {
              field: 'product.dsc_tamanho_produtos',
              title: 'TAMANHO',
            },
            {
              field: 'product.prd_referencia',
              title: 'REF.',
            },
            {
              field: 'itped_qtd',
              title: 'QUANTIDADE',
              align: 'right',
              cellFormat: 'decimal',
            },
            {
              field: 'itped_prunit',
              title: 'VALOR',
              align: 'right',
              cellFormat: 'decimal',
            },
            {
              field: 'total',
              title: 'TOTAL',
              align: 'right',
              cellFormat: 'decimal',
            },
            {
              field: 'peso_bru',
              title: 'PESO',
              align: 'right',
              cellFormat: 'decimal',
            },
            {
              field: 'no_pallets',
              title: 'PALETES',
              align: 'right',
            },
            {
              field: 'no_boxes',
              title: 'CAIXAS',
              align: 'right',
            },
          ]}
          data={orderItems}
          components={{
            Group: (props) => {
              const orderItem = props.rowData
              const statusId = orderItem.status?.id

              const disableReopen = [
                ORDER_STATUS.CANCELADO,
                ORDER_STATUS.VALIDADO,
                ORDER_STATUS.APROVADO,
              ].includes(statusId)
              const disableApprove = [
                ORDER_STATUS.CANCELADO,
                ORDER_STATUS.AG_VALDIACAO,
                ORDER_STATUS.APROVADO,
              ].includes(statusId)
              const disableCancel = [
                ORDER_STATUS.CANCELADO,
                ORDER_STATUS.APROVADO,
              ].includes(statusId)
              const disableView = [ORDER_STATUS.CANCELADO].includes(statusId)

              return (
                <GroupHeader
                  size={props.columns.length}
                  title={orderItem.cod_pedido}
                  primary={{
                    label: 'EMISSÃO:',
                    text: formatter(orderItem.ped_data_emissao).toSimpleDate(),
                  }}
                  secondary={{
                    label: 'COND. PAGAMENTO:',
                    text: orderItem.payment_condition,
                  }}
                  specs={[orderItem.representative]}
                  status={{
                    label: orderItem.status?.descr,
                    color: getChipStatusColor(orderItem),
                  }}
                >
                  <UserRight id={RIGHTS.alteraOrcamento.id}>
                    <Button
                      className="info"
                      label="Reabrir"
                      startIcon={<Open />}
                      onClick={() => reopen(orderItem)}
                      disabled={disableReopen}
                    />
                  </UserRight>
                  <UserRight id={RIGHTS.aprovaOrcamento.id}>
                    <Button
                      className="success"
                      label="Aprovar"
                      startIcon={<Approve />}
                      onClick={() => approve(orderItem)}
                      disabled={disableApprove}
                    />
                  </UserRight>
                  <Button
                    className="error"
                    label="Cancelar"
                    onClick={() => handleCancel(orderItem)}
                    startIcon={<Cancel />}
                    disabled={disableCancel}
                  />
                  <Button
                    size="large"
                    className="dark"
                    label="Visualizar"
                    disabled={disableView}
                    startIcon={<View />}
                    onClick={() => handleShowOrderView(orderItem)}
                  />
                </GroupHeader>
              )
            },
          }}
        />
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Box>
      {current && (
        <CancelOrderModal
          current={current}
          open={showCancelModal}
          onClose={handleCloseCancel}
          onAccept={cancel}
        />
      )}
      {current && (
        <IssuedOrderReport
          current={current}
          open={showOrderReport}
          onClose={handleCloseOrderView}
        />
      )}
    </>
  )
}

function CancelOrderModal({ current, onClose, onAccept, ...props }) {
  const [cancelReason, setCancelReason] = useState(null)

  return (
    <BaseDialog
      title="Cancelar Orçamento"
      actions={
        <>
          <ConfirmationButton
            className="large success light regular"
            label="Sim"
            onClick={() =>
              onAccept({
                orderId: current.id,
                cancelReason,
              })
            }
            disabled={!cancelReason}
          />
          <ConfirmationButton
            className="large error light regular"
            label="Não"
            onClick={onClose}
          />
        </>
      }
      onClose={onClose}
      {...props}
    >
      <Box marginTop="1rem">
        <Typography variant="subtitle1">
          Confirma o cancelamento do orçamento {current.id[0]} ?
        </Typography>
        <Box marginTop="1.5rem">
          <ComboboxOrderCancelReasons
            value={cancelReason?.value}
            onChange={(_, newCancelReason) => setCancelReason(newCancelReason)}
          />
        </Box>
      </Box>
    </BaseDialog>
  )
}

export { FilterIssued }
