import { RIGHTS } from '.'

export const purchasesRights = [
  RIGHTS.incluiOrcamento,
  RIGHTS.alteraOrcamento,
  RIGHTS.aprovaOrcamento,
  RIGHTS.consultaNotasFiscais,
  RIGHTS.alteraSenha,
  RIGHTS.consultaAvisos,
  RIGHTS.consultaLancamentos,
  RIGHTS.leituraTermo,
]

export default purchasesRights
