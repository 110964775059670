import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { sort } from 'ramda'
import { TextField } from '..'
import { Search } from '../Icons'
import { Container, Title, Divider, Rows, Row, InputArea } from './styles'
import CheckBox from '../Checkbox'

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const sortByValue = sort((a, b) => b.value - a.value)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function Ranking2({
  rowClassName,
  filterBy,
  rows,
  onSelect,
  disabled,
  ...props
}) {
  const [search, setSearch] = useState('')
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (disabled) {
      setSelectedRows([])
      onSelect(filterBy, [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled])

  const handleChangeSearch = (e) => {
    const newSearch = e.target.value
    setSearch(newSearch)
  }

  const handleClick = (row, selected) => {
    let newSelectedRows = [...selectedRows]

    if (selected) {
      newSelectedRows = newSelectedRows.filter((id) => id !== row.id)
    } else {
      newSelectedRows = newSelectedRows.concat(row.id)
    }

    setSelectedRows(newSelectedRows)
    onSelect(filterBy, newSelectedRows)
  }

  const renderRows = () => {
    const filteredRows =
      search.length > 0
        ? rows.filter((row) =>
            row.label.toLowerCase().includes(search.toLowerCase())
          )
        : rows

    const sortedRows = sortByValue(filteredRows)

    if (sortedRows.length === 0) return null
    else
      return sortedRows.map((row, rowIndex) => {
        const checked = selectedRows.includes(row.id)

        return (
          <Row
            disabled={disabled}
            key={rowIndex}
            title={row.label}
            onClick={() => handleClick(row, checked)}
          >
            <div>
              {props.showSelection && (
                <CheckBox
                  disabled={disabled}
                  value={checked}
                  checked={checked}
                />
              )}
              <span className="row-label">{row.label}</span>
            </div>
            {row.value && <div className="value">{row.value}</div>}
          </Row>
        )
      })
  }

  return (
    <Container>
      <Title>
        <span>{props.title}</span>
        <Divider />
      </Title>
      {props.showSearch && (
        <InputArea>
          <TextField
            onChange={handleChangeSearch}
            placeholder="Pesquisar..."
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <Search />,
            }}
          />
        </InputArea>
      )}
      <Rows
        className={clsx(rowClassName, {
          'no-overflow': !props.overflow,
        })}
      >
        {renderRows()}
      </Rows>
    </Container>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

Ranking2.propTypes = {
  filterBy: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  rowClassName: PropTypes.string,
  showSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  overflow: PropTypes.bool,
  showSelection: PropTypes.bool,
  title: TranslationStringType,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
}

Ranking2.defaultProps = {
  rows: [],
  showSearch: true,
  showSelection: false,
  overflow: true,
}

export default Ranking2
