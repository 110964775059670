import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  drivers: [],
}

const lastDriversSlicer = createSlice({
  name: 'lastDrivers',
  initialState,
  reducers: {
    getLastDriversRequest(state) {
      state.loading = true
    },
    getLastDriversSuccess(state, { payload }) {
      state.loading = false
      state.drivers = payload
    },
    getLastDriversFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getLastDriversFailure,
  getLastDriversRequest,
  getLastDriversSuccess,
} = lastDriversSlicer.actions

export default lastDriversSlicer.reducer
