import React from 'react'
import PropTypes from 'prop-types'

import { Container, CardHeader, CardIcon, CardBody } from './styles'

export function Card({ children, title, Icon, ...props }) {
  return (
    <Container {...props}>
      <CardIcon>{Icon && <Icon />}</CardIcon>
      <CardHeader>
        <div className="title">{title}</div>
      </CardHeader>
      <CardBody>{children}</CardBody>
    </Container>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

export default Card
