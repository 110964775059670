import { Tooltip } from '@material-ui/core'

const Edit = (props) => (
  <Tooltip title="Editar">
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 9.77332V13.3333C13.3334 13.6869 13.1929 14.0261 12.9429 14.2761C12.6928 14.5262 12.3537 14.6667 12 14.6667H2.66671C2.31309 14.6667 1.97395 14.5262 1.7239 14.2761C1.47385 14.0261 1.33337 13.6869 1.33337 13.3333V3.99999C1.33337 3.64637 1.47385 3.30723 1.7239 3.05718C1.97395 2.80713 2.31309 2.66666 2.66671 2.66666H6.22671"
        stroke="#455A64"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 1.33334L14.6667 4.00001L8.00004 10.6667H5.33337V8.00001L12 1.33334Z"
        stroke="#455A64"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Tooltip>
)

export { Edit }
export default Edit
