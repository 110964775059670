import { useState, useCallback } from 'react'
import { slice } from 'ramda'

export function useInfiniteScroll({ step = 50, nextDelay = 200 } = {}) {
  const [scrollLimiter, setScrollLimiter] = useState(step)

  const calculateCurrentRecords = (records) => {
    const calculatedRecords =
      records.length < step ? records : slice(0, scrollLimiter, records)
    return calculatedRecords
  }

  const onNext = () => {
    setTimeout(() => {
      setScrollLimiter(scrollLimiter + step)
    }, nextDelay)
  }

  const reset = useCallback(() => {
    setScrollLimiter(step)
  }, [step])

  return {
    scrollLimiter,
    calculateCurrentRecords,
    onNext,
    reset,
  }
}

export default useInfiniteScroll
