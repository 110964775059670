import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getBrandsRequest } from 'store/modules/users/orders/products/reducer'

import { FormCombobox } from '../../CoForm'

export function ComboboxBrands(props) {
  const dispatch = useDispatch()
  const brands = useSelector((state) => state.users.orders.products.brands)

  useEffect(() => {
    dispatch(getBrandsRequest())
  }, [dispatch])

  const options = brands.map((b) => ({
    value: b.id,
    label: b.descr,
  }))

  return <FormCombobox options={options} {...props} />
}

export default ComboboxBrands
