import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  tradeReceivables: [],
  count: 0,
  filters: [],
  selectedFilters: [],
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

const tradeReceivables = createSlice({
  name: 'tradeReceivables',
  initialState,
  reducers: {
    // tradeReceivables
    getTradeReceivablesRequest(state) {
      state.loading = true
    },

    getTradeReceivablesSuccess(state, { payload }) {
      state.tradeReceivables = payload.tradeReceivables
      state.count = payload.count
      state.loading = false
    },

    getTradeReceivablesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getTradeReceivablesRequest,
  getTradeReceivablesSuccess,
  getTradeReceivablesFailure,
} = tradeReceivables.actions

export default tradeReceivables.reducer
