const Truck = () => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25 17H40.375V48.875H27.625"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51 53.125C53.3472 53.125 55.25 51.2222 55.25 48.875C55.25 46.5278 53.3472 44.625 51 44.625C48.6528 44.625 46.75 46.5278 46.75 48.875C46.75 51.2222 48.6528 53.125 51 53.125Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M17 53.125C19.3472 53.125 21.25 51.2222 21.25 48.875C21.25 46.5278 19.3472 44.625 17 44.625C14.6528 44.625 12.75 46.5278 12.75 48.875C12.75 51.2222 14.6528 53.125 17 53.125Z"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M40.375 48.875V25.5H53.125L61.625 36.125V48.875H55.25"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 25.5H27.625"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 34H23.375"
      stroke="black"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
)

export { Truck }
export default Truck
