import React from 'react'
import { userStatus } from 'consts/types'
import TextField from 'components/TextField'
import ComboboxBase from '../Base'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboboxUserStatus(props) {
  return (
    <ComboboxBase
      name="status"
      options={[
        { label: 'TODOS', value: '' },
        { label: 'ATIVOS', value: userStatus.ACTIVE },
        { label: 'INATIVOS', value: userStatus.INACTIVE },
      ]}
      renderInput={(provided) => (
        <TextField label="Status" variant="outlined" {...provided} />
      )}
      disableClearable
      {...props}
    />
  )
}

export default ComboboxUserStatus
