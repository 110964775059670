import React from 'react'
import PropTypes from 'prop-types'

function B64Image({ src: data, ...props }) {
  return <img alt="b64" src={`data:image/jpeg;base64,${data}`} {...props} />
}

B64Image.propTypes = {
  src: PropTypes.string.isRequired,
}

export { B64Image }
export default B64Image
