import { useEffect } from 'react'
import { useProfile } from 'hooks'
// import carrierIcon from "../../assets/images/favicons/carrier-favicon.ico";

/** Used to dynamically change browser window information: title, favicon, etc */
export function PortalAdapter() {
  const profile = useProfile()
  const defaultTitle = 'Delta - Portal'

  useEffect(() => {
    const config = {
      [profile.isCarrier]: {
        title: 'Transportadoras',
        favicon: null,
      },
      [profile.isCustomer]: {
        title: 'Clientes',
        favicon: null,
      },
      [profile.isMasterAdmin]: {
        title: 'Admin',
        favicon: null,
      },
    }

    const getFaviconEl = () => document.getElementById('favicon')

    const portalAdapter = () => {
      const favicon = getFaviconEl()
      const current = config[true]

      if (current) {
        if (current.favicon) favicon.href = current.favicon
        document.title = [defaultTitle, current.title].join(' | ')
      }
    }

    portalAdapter()
  }, [profile])

  return null
}

export default PortalAdapter
