import { RIGHTS } from '.'

export const inventoryRights = [
  RIGHTS.relEstoque,
  RIGHTS.consultaAvisos,
  RIGHTS.consultaLancamentos,
  RIGHTS.alteraSenha,
  RIGHTS.leituraTermo,
]

export default inventoryRights
