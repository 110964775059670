import { useSelector } from 'react-redux'
import { DEFAULT_LOCALE, LOCALE_IDS } from '../_i18n'

function useLocale() {
  const languageId = useSelector((state) => state.user.locale)
  if (!languageId) return DEFAULT_LOCALE

  return {
    id: languageId,
    idLang: LOCALE_IDS[languageId],
  }
}

export { useLocale }
export default useLocale
