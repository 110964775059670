import { equals } from 'ramda'
const environment = equals(process.env.NODE_ENV)

export const environments = {
  PRODUCTION: environment('production'),
  DEVELOPMENT: environment('development'),
  TEST: environment('test'),
}

export default environments
