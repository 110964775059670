import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bulletins: [],
  count: 0,
  unreadedBulletins: 0,
  loading: false,
}

const bulletins = createSlice({
  name: 'bulletins',
  initialState,
  reducers: {
    getBulletinsRequest(state) {
      state.loading = true
    },
    getBulletinsSuccess(state, { payload }) {
      state.bulletins = payload.bulletins
      state.count = payload.count
      state.loading = false
    },
    getBulletinsFailure(state) {
      state.loading = false
    },

    readBulletinRequest() {},

    unreadedBulletinsRequest() {},
    unreadedBulletinsSuccess(state, { payload }) {
      state.unreadedBulletins = payload
    },
  },
})

export const {
  getBulletinsRequest,
  getBulletinsSuccess,
  getBulletinsFailure,
  readBulletinRequest,
  unreadedBulletinsRequest,
  unreadedBulletinsSuccess,
} = bulletins.actions
export default bulletins.reducer
