import React from 'react'
// import PropTypes from 'prop-types'

import MuiChip from './styles'
import { Clear } from '../Icons'

function Chip(props) {
  return <MuiChip title={props.label} deleteIcon={<Clear />} {...props} />
}

Chip.propTypes = {}

export { Chip }
export default Chip
