import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  customers: [],
  deliveryAddresses: [],
}

const customers = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomersRequest(state) {
      state.loading = true
    },
    getCustomersSuccess(state, { payload }) {
      state.loading = false
      state.customers = payload
    },
    getCustomersFailure(state) {
      state.loading = false
    },

    // DELIVERY ADDRESSES
    getDeliveryAddressesRequest(state) {
      state.loading = true
    },
    getDeliveryAddressesSuccess(state, { payload }) {
      state.loading = false
      state.deliveryAddresses = payload
    },
    getDeliveryAddressesFailure(state) {
      state.loading = false
    },
  },
})

export const {
  getCustomersFailure,
  getCustomersRequest,
  getCustomersSuccess,
  getDeliveryAddressesFailure,
  getDeliveryAddressesRequest,
  getDeliveryAddressesSuccess,
} = customers.actions

export default customers.reducer
