import React from 'react'
import PropTypes from 'prop-types'
import { StyledChip } from './styles'

export function ChipStatus({ background, ...props }) {
  return <StyledChip background={background} {...props} />
}

ChipStatus.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  background: PropTypes.string,
}

export default ChipStatus
