import styled, { css } from 'styled-components'
import { colors } from '../../theme'

import {
  TableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableFooter as MuiTableFooter,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from '@material-ui/core'

import CheckBox from '../Checkbox'

export const SpinnerArea = styled.div`
  z-index: 2;
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;

  > .loaderText {
    margin-top: 10px;
    color: ${colors.color500};
  }
`

export const TableCell = styled(MuiTableCell)`
  padding: 3px 24px 3px 16px;
  min-height: 19px;

  border-bottom: none;

  color: ${colors.color500};
  font-size: 11px;
  line-height: 13px;

  &[role='button']:not(.MuiTableCell-head),
  &[role='status'] {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    &:last-child {
      padding-right: 12px;
    }
  }

  &[role='button'] {
    img {
      cursor: pointer;
      &:hover {
        filter: grayscale(50%);
      }
    }
    svg {
      cursor: pointer;
      opacity: 1;
      transition: opacity 200ms ease-in-out;
      font-size: 1.25rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &[role='status'] > div {
    margin: auto;
  }

  @media print {
    padding: 3px 6px;

    &[role='button'],
    &[role='action'] {
      display: none;
    }
  }
`

export const TableRow = styled(MuiTableRow)`
  &.groupRow {
    ${TableCell} {
      font-weight: bold;
      font-size: 12px;
      color: ${colors.color700};
      white-space: nowrap;
    }
  }

  &.largeRow {
    height: 40px;
    ${TableCell} {
      padding-top: 15px;
      font-size: 14px;
      color: ${colors.color700};
    }
  }

  &.groupHeader {
    ${TableCell} {
      background-color: ${colors.color100};
    }
  }

  &.withError {
    background-color: ${colors.notification};
    ${TableCell} {
      color: ${colors.color900};
    }
  }
  &.groupRowBorderered {
    border-bottom: 2px solid ${colors.color200};
  }
`

export const Container = styled(TableContainer)`
  position: relative;

  &.loading {
    pointer-events: none;
    user-select: none;
    ${TableRow} {
      opacity: 0.4;
    }
  }
`

export const Table = styled(MuiTable)`
  z-index: 1;

  ${(props) =>
    props.size === 'medium' &&
    css`
      ${TableCell} {
        padding: 6px 24px 5px 16px;

        &[role='button'],
        &[role='status'] {
          padding-left: 3px;
          padding-right: 3px;
          max-width: fit-content;
        }

        @media print {
          padding: 3px 12px 2.5px 8px;
        }
      }
    `}
`

const customRowStyle = css`
  background-color: rgba(207, 216, 220, 0.5);
  ${TableCell} {
    font-weight: bold;
  }
`

export const TableHead = styled(MuiTableHead)`
  ${customRowStyle}

  ${TableCell} {
    font-size: 11px;
    line-height: 11px;
    white-space: nowrap;
  }

  .tableCustomHeader {
    background-color: ${colors.white};
  }
`

export const TableBody = styled(MuiTableBody)`
  &.TableBody-stripped {
    ${TableRow} {
      &:not(.withError):nth-child(even) {
        background-color: ${colors.backgroundTable};
      }
      @media print {
        &:not(.withError):nth-child(even) {
          background-color: ${colors.white};
        }
      }
    }
  }
`

export const TableFooter = styled(MuiTableFooter)`
  ${customRowStyle}
`

export const StyledCheckBox = styled(CheckBox)`
  padding: 0;
  @media print {
    display: none;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`
