import { RIGHTS } from '.'

export const commercialRights = [
  RIGHTS.relPedidosEmCarteira,
  RIGHTS.relPedidosFaturados,
  RIGHTS.reImprimePedidos,
  RIGHTS.consultaNotasFiscais,
  RIGHTS.alteraPreferencias,
  RIGHTS.alteraSenha,
  RIGHTS.consultaAvisos,
  RIGHTS.consultaLancamentos,
  RIGHTS.leituraTermo,
]

export default commercialRights
