import styled from 'styled-components'

import MuiDivider from '@material-ui/core/Divider'
import ChipStatus from 'components/ChipStatus'
import Dialog from 'components/Dialog'

import {
  GroupContainer,
  GroupHeader,
  GroupBody,
} from 'components/Table/TableGroup'

export const StyledChipStatus = styled(ChipStatus)`
  width: 180px;
  height: 30px;

  color: ${(props) => props.theme.palette.getContrastText(props.background)};

  .MuiChip-label {
    font-size: 11px;
  }
`

export const StyledGroupContainer = styled(GroupContainer)`
  > div {
    width: 100%;
  }
`

export const StyledGroupHeader = styled(GroupHeader)`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;

  .group__title {
    font-size: 18px;
    line-height: 150%;
  }

  > div b {
    margin-right: 5px;
  }
`

export const StyledGroupBody = styled(GroupBody)`
  padding: 3px 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
    }
  }
`

export const Actions = styled.div`
  display: flex;

  .MuiButton-container-root:not(:disabled) {
    height: 30px;
    border-radius: 5px;

    background-color: ${({ theme }) => theme.palette.success.dark};

    .MuiButton-label {
      font-weight: normal;
      color: ${({ theme }) => theme.palette.common.white};
    }
  }

  .MuiButton-container-root.edit {
    background-color: ${({ theme }) => theme.palette.info.dark};
    &:hover {
      background-color: ${({ theme }) => theme.palette.info.main};
    }
  }

  .MuiButton-container-root.cancel {
    background-color: ${({ theme }) => theme.palette.error.main};
    &:hover {
      background-color: ${({ theme }) => theme.palette.error.dark};
    }
  }

  .MuiButton-container-root {
    margin-right: 10px;
  }
`

export const Divider = styled(MuiDivider)`
  margin: 5px 0;
`

export const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root,
  .MuiDialogActions-root {
    padding: 0;
  }

  .MuiDialogContent-root {
    padding: 10px 12px;
    form {
      width: 100%;
      max-width: none;
      border: none;
    }
  }
`
