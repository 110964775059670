import styled from 'styled-components'
import * as medias from 'consts/medias'

export const Container = styled.div`
  position: relative;

  @media ${medias.sm} {
    margin-bottom: 80px;
  }

  .infinite-scroll-component > div:first-child {
    border-top: none;
  }
`
