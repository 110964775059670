import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'components/Icons'
import {
  IconButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import { StyledListItem } from './styles'
export function ListItem(props) {
  const { avatar, text, actions, ...listItemProps } = props
  const withActions = actions && actions.length > 0

  return (
    <StyledListItem {...listItemProps}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText primary={text.primary} secondary={text.secondary} />
      {withActions && (
        <ListItemSecondaryAction>
          {actions.map((action, i) => (
            <IconButton
              onClick={action.onClick}
              key={`list-item-secondary-action-${i}`}
            >
              <Icon tooltip={action.tooltip}>{action.icon}</Icon>
            </IconButton>
          ))}
        </ListItemSecondaryAction>
      )}
    </StyledListItem>
  )
}

ListItem.propTypes = {
  text: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    secondary: PropTypes.string,
  }).isRequired,

  avatar: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),

  actions: PropTypes.arrayOf(
    PropTypes.exact({
      tooltip: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
    })
  ),
}

export default ListItem
