import React from 'react'
import PropTypes from 'prop-types'
import formatter from 'services/formatter'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Checkbox from 'components/Checkbox'
import { ListItem, SizeInfo } from '../styles'
import { IconButton } from '@material-ui/core'
import { Edit } from 'components/Icons/Edit'

export default function OrderItem({
  orderItem,
  checked,
  showRepresentative,
  onChangeGroup,
  onChangeItem,
  onEditItem,
  ...props
}) {
  return (
    <React.Fragment {...props}>
      {/* ==== REPRESENTATIVE ==== */}
      {showRepresentative && (
        <ListItem selected>
          <Checkbox
            value={checked}
            checked={checked}
            onChange={(e) => onChangeGroup(e, orderItem.cod_representante)}
          />
          <Box flex={1} display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="body1">
                {orderItem.rep_nome} ({orderItem.rep_sigla})
              </Typography>
              <Typography color="primary" variant="body2">
                {formatter(orderItem.cod_representante).toCNPJorCPF()}
              </Typography>
            </Box>

            <SizeInfo className="bordered">
              <Typography variant="body2">Paletes</Typography>
              <Typography variant="body2">Caixas</Typography>
              <Typography variant="body2">m²</Typography>
              <Typography variant="body2">Peso</Typography>
            </SizeInfo>
          </Box>
        </ListItem>
      )}

      {/* ==== PRODUCT ==== */}
      <ListItem className="Product">
        <Checkbox
          value={checked}
          checked={checked}
          onChange={(e) => onChangeItem(e, String(orderItem.id))}
        />
        <img
          src={orderItem.product.prd_link_img_produto}
          alt={orderItem.cod_produto}
        />
        <Box
          flex={1}
          display="flex"
          justifyContent="space-between"
          marginLeft="20px"
          className="ListItem-productInfo"
        >
          <Box>
            <Typography variant="body1">
              {`${orderItem.cod_produto} - ${orderItem.product.dsc_item_ingles}`}
            </Typography>
            <Typography color="primary" variant="body2">
              {`${orderItem.product?.line.dsc_linha_produtos} - ${orderItem.product.dsc_tamanho_produtos}`}
            </Typography>
          </Box>

          <Box display="flex" gridGap="1rem">
            <Box>
              <IconButton onClick={() => onEditItem(orderItem)} color="primary">
                <Edit fontSize="small" />
              </IconButton>
            </Box>

            <SizeInfo>
              <Typography variant="body2">{orderItem.no_pallets}</Typography>
              <Typography variant="body2">{orderItem.no_boxes}</Typography>
              <Typography variant="body2">
                {formatter(orderItem.itped_qtd).toDecimal()}
              </Typography>
              <Typography variant="body2">
                {formatter(orderItem.peso_bru).toDecimal()}
              </Typography>
            </SizeInfo>
          </Box>
        </Box>
      </ListItem>
    </React.Fragment>
  )
}

OrderItem.propTypes = {
  orderItem: PropTypes.object,
  checked: PropTypes.bool,
  showRepresentative: PropTypes.bool,
  onChangeGroup: PropTypes.func,
  onChangeItem: PropTypes.func,
  onEditItem: PropTypes.func,
}
