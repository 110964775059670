import { combineReducers } from 'redux'

import dashboard from './dashboard/reducer'

import orders from './orders/reducer'

import collections from './collections/reducer'
import ordersForCollect from './ordersForCollect/reducer'

import schedules from './schedules/reducer'
import queries from './queries/reducer'

import releases from './releases/reducer'

export default combineReducers({
  dashboard,
  orders,
  ordersForCollect,
  collections,
  schedules,
  queries,
  releases,
})
