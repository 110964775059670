import styled, { css } from 'styled-components'
import { colors } from 'theme'

export const CARD_SIZE = 56

export const Container = styled.div`
  position: relative;

  background-color: ${colors.backgroundTable};
  border-radius: 4px;
  padding: 9px;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`

export const CardHeader = styled.div`
  margin-left: ${CARD_SIZE}px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div.title {
    color: rgba(96, 125, 139, 0.6);

    font-size: 14px;
    line-height: 16px;

    margin-left: auto;
    text-align: right;
  }
`

export const CardIcon = styled.div`
  position: absolute;
  top: -12px;

  background-color: ${colors.color100};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: ${CARD_SIZE}px;
  min-width: ${CARD_SIZE}px;

  svg.MuiSvgIcon-root,
  svg {
    color: ${colors.color500};
    font-size: 2.7rem;
  }
`

export const CardBody = styled.div`
  margin-top: 6px;
`
