import React, { useRef } from 'react'
import { addDays } from 'date-fns'
import { Grid } from '@material-ui/core'
import { Search, Delete } from '@material-ui/icons'
import SideBar from 'components/SideBar'
import Button from 'components/Button'
import {
  FormDatePicker,
  FormRadioGroup,
  FormTextField,
} from 'components/CoForm'

import RIGHTS from '__access_profiles__'
import { useAvaiableRight } from 'components/UserRight'
import FilterTitle from 'components/FilterTitle'
import StyledFilterForm from 'components/StyledFilterForm'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function Filter({ onFilter, initialValues, ...props }) {
  const consultaTitulosAVencer = useAvaiableRight(
    RIGHTS.consultaTitulosAVencer.id
  )
  const consultaTitulosVencidos = useAvaiableRight(
    RIGHTS.consultaTitulosVencidos.id
  )

  const formRef = useRef(null)
  const minDate = addDays(new Date(), -365)

  const handleClearForm = () => {
    formRef.current.setValues(initialValues)
    onFilter(initialValues)
  }

  return (
    <SideBar
      {...props}
      ContentComponent={
        <StyledFilterForm
          formRef={formRef}
          onSubmit={(values) => onFilter({ ...values })}
          initialData={initialValues}
        >
          <FilterTitle>Pesquisar</FilterTitle>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormTextField fullWidth name="query" label="Número da nota" />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="issuedAt"
                label="Data Inicial"
                minDate={minDate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDatePicker
                disableToolbar
                variant="inline"
                name="issuedUntil"
                label="Data Final"
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                label="Status de Vencimento"
                name="type"
                options={[
                  {
                    label: 'Emissão',
                    value: 'E',
                    disabled: !consultaTitulosVencidos,
                  },
                  {
                    label: 'Vencimento',
                    value: 'V',
                    disabled: !consultaTitulosAVencer,
                  },
                ]}
              />
            </Grid>
          </Grid>

          <div className="buttons">
            <Button type="submit" label="Buscar" startIcon={<Search />} />
            <Button
              type="button"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClearForm}
            />
          </div>
        </StyledFilterForm>
      }
    />
  )
}

export default Filter
