import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { StyledButton, Progress } from './styles'

export function Button({ children, loading, loadingText, label, ...props }) {
  const content = label ?? children

  return (
    <StyledButton
      {...props}
      className={clsx('MuiButton-container-root', props.className, {
        loading,
      })}
    >
      <div className="MuiButton-content">
        {loading && <Progress color="inherit" size={25} />}
        {content}
      </div>
    </StyledButton>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

Button.propTypes = {
  label: TranslationStringType,
  loadingText: TranslationStringType,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  variant: 'text',
  loadingText: null,
  loading: false,
}

export default Button
