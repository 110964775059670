import { all, takeLatest, call, put } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'

import api from 'services/api'
import handleErrors from 'services/handleErrors'

import { shipmentsRequest, shipmentsSuccess, shipmentsFailure } from './reducer'

function* fetchShipments({ payload }) {
  try {
    const queryParameters = {
      params: payload,
    }

    const response = yield call(
      api.get,
      apiEndPoints.queries.shipments.root,
      queryParameters
    )

    yield put(
      shipmentsSuccess({
        shipments: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    yield put(shipmentsFailure())
    handleErrors(error, 'Não foi possível buscar os carregamentos')
  }
}

export default all([takeLatest(shipmentsRequest.type, fetchShipments)])
