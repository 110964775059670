import { call, put, all, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import {
  getReleasesRequest,
  getReleasesSuccess,
  getReleasesFailure,
  setAddSampleRequest,
} from './reducer'

function* getReleases() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.productReleases
    )
    yield put(getReleasesSuccess(response.data))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar os lançamentos!')
    yield put(getReleasesFailure())
  }
}

function* setAddSample({ payload }) {
  try {
    const requestBody = {
      cod_produto: payload.cod_produto,
    }

    const response = yield call(
      api.post,
      apiEndPoints.interestProducts.root,
      requestBody
    )
    toast.success(response.data.message)
  } catch (error) {
    handleErrors(error, 'Não foi possível concluir a sua solicitação!')
  }
}

export default all([
  takeLatest(getReleasesRequest.type, getReleases),
  takeLatest(setAddSampleRequest.type, setAddSample),
])
