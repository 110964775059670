import React, { useRef, useEffect } from 'react'
import { map, prop, sum } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'
import { useInfiniteScroll } from 'hooks'
import { groupWithCarrier } from 'services/utils'
import { getScheduleOrdersRequest } from 'store/modules/users/ordersForCollect/reducer'
import {
  createCollectRequest,
  updateSelectedWeight,
} from 'store/modules/users/schedules/reducer'
import Scroll from 'components/InfiniteScroll'
import Status from 'components/ScheduleStatus'
import TableCustomerOrders from 'components/Transportadora/TableOrder'
import { Container } from './styles'
import formatter from 'services/formatter'

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const sumWeight = (list) => sum(map(prop('peso_bru'), list))

const getSelectedItems = (tables) => {
  let selectedItems = []

  tables.forEach((table) => {
    selectedItems.push(table.getSelectedItems())
  })

  return selectedItems.flat()
}

const removeTablesSelection = (tables) =>
  tables.forEach((table) => table.selectAll(false))

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function OrdersForSchedulePage() {
  const dispatch = useDispatch()
  const tablesRef = useRef([])
  const orders = useSelector((state) => state.users.ordersForCollect.orders)

  const { calculateCurrentRecords, onNext, reset } = useInfiniteScroll({
    step: 20,
  })

  useEffect(() => {
    dispatch(getScheduleOrdersRequest())
  }, [dispatch])

  useEffect(() => {
    // effect responsible for resetting the states that depend on the requests, after their eventual filtering
    removeTablesSelection(tablesRef.current)
    dispatch(updateSelectedWeight())
    reset()
  }, [dispatch, reset, orders])

  const handleAddItemsInCollect = () =>
    dispatch(createCollectRequest(getSelectedItems(tablesRef.current)))

  const handleUpdateWeight = (updatedItens) => {
    const added = sumWeight(updatedItens.filter((item) => item.selected))
    const removed = sumWeight(updatedItens.filter((item) => !item.selected))

    dispatch(
      updateSelectedWeight({
        added,
        removed,
      })
    )
  }

  const records = groupWithCarrier(orders)
  const currentRecords = calculateCurrentRecords(records)
  const hasMore = currentRecords.length < records.length

  const getHeaderData = (summary) => {
    return {
      title: summary.trp_nome,
      helperText: null,
      bodyData: [formatter(summary.cod_transportadora).toCNPJorCPF()],
    }
  }

  return (
    <Container>
      <Status onPressAction={handleAddItemsInCollect} />
      <Scroll
        dataLength={currentRecords.length}
        hasMore={hasMore}
        scrollableTarget="orders-for-schedule-container"
        onNext={onNext}
      >
        {currentRecords.map((data, index) => (
          <TableCustomerOrders
            key={index}
            data={[...data]}
            onUpdate={handleUpdateWeight}
            getRef={(table) => (tablesRef.current[index] = table)}
            getHeaderData={getHeaderData}
          />
        ))}
      </Scroll>
    </Container>
  )
}
