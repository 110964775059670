import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'
import { Tabs, Tab } from '@material-ui/core'

export const StyledTab = styled(Tab)`
  background-color: ${colors.color100};
  color: ${colors.color600};

  text-transform: none;
  font-size: 13px;
  line-height: 16px;

  &.Mui-selected {
    font-weight: bold;
    background-color: ${colors.color200};
  }
`

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${colors.color200};
  }
  &.unstyled ${StyledTab} {
    background-color: inherit;
  }
`

export const StyledTabPanel = styled.div``

export const Container = styled.div`
  height: calc(100vh - 229px);
  overflow: auto;

  @media ${medias.sm} {
    height: auto;
  }
`
