import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, useMediaQuery } from '@material-ui/core'
import { colors } from 'theme'
import * as medias from 'consts/medias'

import { useSortData, usePagination, useProfile } from 'hooks/index'
import { isNotInvited, isActive, applyFilters, notEmpty } from 'services/utils'
import { applyScopeInvite, applyScope } from 'services/scope'

import {
  getUsersRequest,
  removeUserRequest,
  showFormEdit,
  sendInviteRequest,
  logIsAsWebUserRequest,
} from 'store/modules/admin/users/reducer'

import { Box, Tooltip } from '@material-ui/core'
import { Table, Content, ChipStatus, TablePagination } from 'components/index'
import { ComboboxUserStatus, ComboboxInviteStatus } from 'components/Combobox'
import { Mail, Edit, Delete, Add, Signout } from 'components/Icons'
import { StyledAvatar } from './styles'

import { LoginButton, Paper } from '../Companies/styles'

import Filter from './Filter'
import { company } from 'consts/types'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const initialSort = 'company.emp_razao_social'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function ActiveChipStatus({ isActive }) {
  const color = isActive ? colors.success : colors.error

  return (
    <ChipStatus
      size="small"
      label={isActive ? 'Ativo' : 'Inativo'}
      background={color}
    />
  )
}

function AccessEmailButton({ companyType, email, ...props }) {
  const showAccessButton = Object.values(company).includes(companyType)

  return (
    <Box display="flex" alignItems="center">
      <span>{email}</span>

      {showAccessButton && (
        <Tooltip title={`Acessar como ${email}`}>
          <LoginButton component={Signout} paddingLeft={1} {...props} />
        </Tooltip>
      )}
    </Box>
  )
}

function SendInviteButton({ inviteStatus, ...props }) {
  const title = isNotInvited(inviteStatus)
    ? 'Enviar Convite'
    : 'Reenviar Convite'

  return (
    <Mail className={inviteStatus} fontSize="small" title={title} {...props} />
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function AdminUsersPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const profile = useProfile()
  const loading = useSelector((state) => state.admin.users.loading)
  const webUsers = useSelector((state) => state.admin.users.webUsers)

  const [status, setStatus] = useState('')
  const [inviteStatus, setInviteStatus] = useState('')
  const [selectedFilters, setSelectedFilters] = useState([])

  useEffect(() => {
    dispatch(getUsersRequest())
  }, [dispatch])

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: initialSort,
  })

  const {
    onChangePage,
    onChangeRowsPerPage,
    calculateNewPaginatorData,
    rowsPerPage,
    page,
  } = usePagination()

  const isAdmin = profile.isMasterAdmin

  // --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

  const handleMailLogin = (webUser) => dispatch(logIsAsWebUserRequest(webUser))

  const handleSendInvite = (user) => dispatch(sendInviteRequest(user))

  const handleDelete = (webUser) => dispatch(removeUserRequest(webUser))

  const handleDisplayUserForm = (webUser) =>
    dispatch(showFormEdit(webUser.id ? webUser : null))

  const handleChangeScope = (scopeSetter) => (e) => {
    const newScopeValue = e.target.value
    scopeSetter(newScopeValue)
    setSelectedFilters([])
  }

  function renderUserEmail({ cellValue, rowData: webUser }) {
    if (!isAdmin) return cellValue
    return (
      <AccessEmailButton
        email={cellValue}
        companyType={webUser.wee_tipo}
        onClick={() => handleMailLogin(webUser)}
      />
    )
  }

  function renderSendInvite({ rowData: webUser }) {
    if (!webUser) return null
    return (
      <SendInviteButton
        inviteStatus={webUser.invite_status}
        onClick={() => handleSendInvite(webUser)}
      />
    )
  }

  function renderEdit({ rowData: webUser }) {
    return <Edit onClick={() => handleDisplayUserForm(webUser)} />
  }

  function renderDelete({ rowData: webUser }) {
    if (isAdmin) return null
    const disabled = profile.web_user.weu_id === webUser.id

    return (
      <Delete
        fontSize="small"
        disabled={disabled}
        onClick={() => handleDelete(webUser)}
      />
    )
  }

  const applyScopes = (collection) =>
    applyScope(
      status,
      (user) => user.weu_ativo,
      applyScopeInvite(inviteStatus, (user) => user.invite_status, collection)
    )

  const filteredWebUsers = applyScopes(applyFilters(selectedFilters, webUsers))

  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(filteredWebUsers)
  )

  const showNewUserButton = !isAdmin

  return (
    <Content
      title="Usuários"
      loading={loading}
      Controls={
        <>
          <ComboboxUserStatus
            value={status}
            onChange={handleChangeScope(setStatus)}
          />
          <ComboboxInviteStatus
            value={inviteStatus}
            onChange={handleChangeScope(setInviteStatus)}
          />
          {!sm && showNewUserButton && (
            <Button
              color="primary"
              variant="contained"
              disabled={isAdmin}
              onClick={handleDisplayUserForm}
              endIcon={<Add />}
            >
              Adicionar
            </Button>
          )}
        </>
      }
      SideComponent={
        <Filter
          data={filteredWebUsers}
          loading={loading}
          onFiltering={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      }
    >
      <Paper>
        {notEmpty(webUsers) ? (
          <>
            <Table
              size="medium"
              currentSort={currentSort}
              onSortChange={onSortChange}
              data={currentPageRecords}
              columns={[
                {
                  renderCell: ({ rowData: webUser }) => (
                    <StyledAvatar
                      alt={webUser.weu_nome}
                      src={webUser.weu_avatar}
                    />
                  ),
                },
                { title: 'USUÁRIO', field: 'weu_nome' },
                {
                  title: 'EMPRESA',
                  field: 'company.emp_razao_social',
                },
                {
                  title: 'EMAIL',
                  field: 'weu_email',
                  renderCell: renderUserEmail,
                },
                {
                  title: 'CPF/CNPJ',
                  field: 'weu_cpf',
                  cellFormat: 'document',
                },
                { title: 'TELEFONE', field: 'weu_telefone' },
                {
                  role: 'button',
                  renderCell: renderSendInvite,
                },
                { role: 'button', renderCell: renderEdit },
                { role: 'button', renderCell: renderDelete },

                {
                  title: 'STATUS',
                  field: 'weu_ativo',
                  role: 'status',
                  renderCell: ({ cellValue: status }) => (
                    <ActiveChipStatus isActive={isActive(status)} />
                  ),
                },
              ]}
            />
            <TablePagination
              count={filteredWebUsers.length}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
            />
          </>
        ) : (
          <span className="empty-text">
            Não possui nenhum usuário cadastrado, clique em Adicionar para
            cadastrar um novo usuário.
          </span>
        )}
      </Paper>
    </Content>
  )
}

export default AdminUsersPage
