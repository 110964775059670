import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Table from '../../Table'

export function OrderDeliveyDateConfirmationDialog({ orders }) {
  return (
    <Box>
      <Typography color="error">
        Existem pedidos com a data de entrega maior que a data da coleta, deseja
        continuar ?
      </Typography>
      <Box marginTop={2}>
        <Table
          size="medium"
          data={orders}
          columns={[
            { title: 'PEDIDO', field: 'cod_pedido' },
            { title: 'PRODUTO', field: 'cod_produto' },
            { title: 'CLIENTE', field: 'emp_razao_social' },
            {
              title: 'ENTREGA',
              field: 'vpt_data_prev_entrega',
              cellFormat: 'date',
            },
            {
              title: '#PALLETS',
              field: 'vpt_pallet',
              align: 'right',
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default OrderDeliveyDateConfirmationDialog
