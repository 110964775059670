export const company = {
  ECOSIS: 'developer',
  ADMIN: 'admin',
  // USER: "user",
  CLIENTE: 'customer',
  TRANSPORTADORA: 'carrier',
  REPRESENTANTE: 'representative',
}

export const companyString = {
  ECOSIS: 'Desenvolvedor',
  ADMIN: 'Administrador',
  // USER: "Usuário",
  CLIENTE: 'Cliente',
  TRANSPORTADORA: 'Transportadora',
  REPRESENTANTE: 'Representante',
}

export const inviteStatus = {
  ALL: 'all',
  INVITED: 'invited',
  NOT_INVITED: 'not_invited',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
}

export const userStatus = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export const order = {
  BUDGET: 'B',
}
