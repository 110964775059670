import styled from 'styled-components'
import { colors } from 'theme'

export const Container = styled.div`
  margin-left: 0.5rem;
  padding: 1rem;
  position: sticky;
  top: 0;

  background-color: ${colors.white};
  border: 1px solid ${colors.color100};
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  min-width: 350px;

  .MuiTypography-h4 {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  // RADIO
  .MuiFormGroup-root[role='radiogroup'] {
    flex-direction: column;
  }
`
