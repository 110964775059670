import styled from 'styled-components'

export const MobileContainer = styled.div``

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;

  > div {
    min-width: 200px;
  }
`

export const LimitedText = styled.div`
  max-width: ${(props) => props.limit * 10}px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
