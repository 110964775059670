import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'
import * as medias from 'consts/medias'
import { StyledTabs, StyledTab, StyledTabPanel, Container } from './styles'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const allyProps = (index) => ({
  id: `navigate-tab-${index}`,
  'aria-controls': `navigate-tabpanel-${index}`,
})

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

export const TabContext = createContext({})

export const useTabContext = () => {
  const ctx = useContext(TabContext)
  return ctx
}

export function TabNavigation({ children, initialTab = 0 }) {
  const [activeTab, setActiveTab] = useState(initialTab)
  const changeTab = (_, newTab) => setActiveTab(newTab)

  return (
    <TabContext.Provider
      value={{
        activeTab,
        changeTab,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}

export function Tabs({ tabs, ...props }) {
  const ctx = useContext(TabContext)
  const sm = useMediaQuery(medias.sm)

  return (
    <StyledTabs
      orientation="horizontal"
      variant="scrollable"
      value={ctx.activeTab}
      onChange={ctx.changeTab}
      {...props}
    >
      {tabs.map((props, tabIndex) => (
        <StyledTab
          key={tabIndex}
          {...props}
          {...allyProps(tabIndex)}
          label={props.icon ? (sm ? null : props.label) : props.label}
        />
      ))}
    </StyledTabs>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
}

export function TabPanel({
  children,
  value,
  index,
  className,
  containerId,
  cover,
  ...props
}) {
  const ctx = useContext(TabContext)
  const show = ctx.activeTab === index

  if (cover || show)
    return (
      <StyledTabPanel
        role="tabpanel"
        hidden={!show}
        id={`navigate-tabpanel-${index}`}
        aria-labelledby={`navigate-tab-${index}`}
        {...props}
      >
        <Container className={className} id={containerId}>
          {children}
        </Container>
      </StyledTabPanel>
    )
  else return null
}

TabPanel.propTypes = {
  index: PropTypes.number.isRequired,
  cover: PropTypes.bool,
}

TabPanel.defaultProps = {
  cover: false,
}

export default Tabs
