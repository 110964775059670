import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { merge, equals } from 'ramda'
import { extractLens, notEmpty } from 'services/utils'

import Table from 'components/Table'
import { Container } from './styles'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

// const check = (fn, args) => (_, checked) => fn(args, checked)

const withGroup = (group, groupBy) => (item) => {
  const glens = extractLens(groupBy)
  const groupValue = glens(group)
  const itemValue = glens(item)
  return equals(groupValue, itemValue)
}

const createMethods = (data, update, isAvailable, groupBy) => {
  let methods = {}

  methods.getItems = () => data
  methods.getAvailableItems = () => data.filter(isAvailable)
  methods.getUnavailableItems = () => data.filter((item) => !isAvailable(item))
  methods.getSelectedItems = () => data.filter((i) => i.selected)
  methods.getUnSelectedItems = () => data.filter((i) => !i.selected)
  methods.getItemsWithGroup = (group) => data.filter(withGroup(group, groupBy))

  methods.getTableChecked = () => {
    const tableAvailableItems = methods.getAvailableItems()
    return !tableAvailableItems.some((i) => !i.selected)
  }
  methods.getGroupChecked = (group) => {
    const items = methods.getItemsWithGroup(group)
    const availableItems = items.filter(isAvailable)
    const groupChecked = availableItems.find((i) => !i.selected) === undefined
    return groupChecked
  }

  methods.getShowGroupSelector = (group) => {
    const countAvailable = group
      ? methods.getItemsWithGroup(group).filter(isAvailable).length
      : methods.getAvailableItems().length
    return countAvailable !== 0
  }

  methods.filterBy = (cb) => data.filter(cb)

  methods.selectAll = (checked) => update(() => true, checked)
  methods.selectBy = (conditionToUpdate, checked, payload) =>
    update(conditionToUpdate, checked, payload)
  methods.select = (arr, checked) =>
    methods.selectBy(
      (item) => [...arr].map((i) => i.rowId).includes(item.rowId),
      checked
    )

  return methods
}

const cellStyle =
  (groupBy) =>
  ({ isGroupedRow, columnName }) =>
    columnName === groupBy
      ? {
          whiteSpace: 'noWrap',
          paddingLeft: isGroupedRow ? 50 : 16,
        }
      : {}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function MultiSelectionTable({
  data,
  getRef,
  onUpdate,
  onRefresh,
  isAvailable,
  components,
  columns,
  groupBy,
  TableProps,
  ...props
}) {
  const [tableData, setTableData] = useState([])
  useEffect(
    () =>
      setTableData((previousData) =>
        typeof onRefresh === 'function'
          ? onRefresh(data, previousData)
          : [...data]
      ),
    [onRefresh, data]
  )

  function updateTableData(condition, checked, payload = {}) {
    const itemChanged = (item) =>
      item.selected !== checked && condition(item) && isAvailable(item)

    const updateItem = (item) => merge(item, { selected: checked, ...payload })

    setTableData((previousData) =>
      previousData.map((item) => (itemChanged(item) ? updateItem(item) : item))
    )

    if (onUpdate) {
      let updatedItems = []
      ;[...tableData].forEach((item) => {
        if (itemChanged(item)) updatedItems.push(updateItem(item))
      })

      if (notEmpty(updatedItems)) onUpdate(updatedItems)
    }
  }

  const onSelectTable = (_, checked) => updateTableData(() => true, checked)

  /**
   *
   * @param {Required<{ id: Array<string|number>}>} group
   * @returns
   */
  const onSelectGroup = (group) => {
    return (event, checked) => {
      updateTableData(withGroup(group, groupBy), checked)
    }
  }

  /**
   *
   * @param {Required<{ id: Array<string|number>}>} args
   * @returns
   */
  const onSelectRow = (row) => {
    return (event, checked) => {
      updateTableData((item) => equals(item.rowId, row.rowId), checked)
    }
  }

  const methods = createMethods(
    tableData,
    updateTableData,
    isAvailable,
    groupBy
  )

  return (
    <Container ref={() => (getRef ? getRef(methods) : null)} {...props}>
      <Table
        data={tableData}
        groupBy={groupBy}
        cellStyle={cellStyle(groupBy)}
        columns={columns({
          ...methods,
          onSelectRow,
          onSelectGroup,
          isAvailable,
        })}
        components={components({
          ...methods,
          onSelectTable,
          onSelectGroup,
          onSelectRow,
          count: tableData.length,
          tableData,
        })}
        {...TableProps}
      />
    </Container>
  )
}

MultiSelectionTable.propTypes = {
  data: PropTypes.array.isRequired,
  getRef: PropTypes.func,
  onUpdate: PropTypes.func,
  onRefresh: PropTypes.func,
  isAvailable: PropTypes.func.isRequired,
  components: PropTypes.func,
  columns: PropTypes.func.isRequired,
  groupBy: PropTypes.string,
  TableProps: PropTypes.object,
}

export default MultiSelectionTable
