import styled from 'styled-components'
import {
  TableContainer as MuiTableContainer,
  Typography,
} from '@material-ui/core'

import theme, { colors } from 'theme'
import * as medias from 'consts/medias'
import Button from 'components/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0;

  section {
    margin: 10px 0;
    padding: 15px 0;
    b {
      margin-right: 5px;
    }
    .MuiTypography-body1 {
      font-size: 12px;
    }
    .MuiTypography-body2 {
      font-size: 12px;
    }
  }

  @media print {
    section {
      .MuiTypography-body1 {
        font-size: 11px;
      }
      .MuiTypography-body2 {
        font-size: 9px;
      }
    }
  }
`

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  .Header-barcode {
    order: 1;
  }

  .Header-content {
    order: 2;

    margin: 0 15px;
    flex: 1;
  }

  .Header-logo {
    order: 3;

    max-width: 150px;
    img {
      width: 100%;
    }
  }

  .MuiTypography-root {
    display: flex;
    align-items: center;
  }

  .MuiChip-root {
    width: auto;
  }

  @media only screen and (${medias.md}) {
    flex-wrap: wrap;
    justify-content: flex-start;

    .Header-barcode {
      order: 1;
    }

    .Header-content {
      order: 3;
    }

    .Header-logo {
      order: 2;
      max-width: 120px;
    }
  }
`

export const Row = styled.div`
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5px;
`

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  b {
    color: ${colors.color500};
    font-size: 12px;
    margin-bottom: 3px;
  }
  span {
    font-size: 11px;
  }
`

export const StyledButton = styled(Button)``

/////////////////////////////////////////////////

export const Cards = styled.section`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${theme.palette.divider};
  padding: 10px 0;

  @media only screen and (${medias.md}) {
    flex-direction: column;
  }
`

export const StyledCard = styled.div`
  flex: 1;
  padding: 10px;
  margin: 5px;

  display: flex;
  align-items: center;

  @media print {
    padding: 5px;
  }
`

export const StyledCardIcon = styled.div`
  margin-right: 10px;
  .MuiSvgIcon-root {
    color: ${colors.color500};
  }
`

export const StyleCardBody = styled.div`
  flex: 1;
  .MuiSvgIcon-root {
    color: ${colors.color500};
  }
`

/////////////////////////////////////////////////

export const TableSContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .multiple-unity-disclaimer {
    margin: 0.75rem 0;
    font-size: 0.975rem;
    font-weight: 900;
    text-align: center;
  }
`

export const MultipleUnityDisclaimer = styled(Typography)`
  margin: 0.75rem 0;
  font-size: 0.975rem;
  font-weight: 900;
  text-align: center;
  color: red;
`

export const TableContainer = styled(MuiTableContainer)`
  .unity-name {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
`

export const SummaryContainer = styled.div`
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;

  div {
    text-align: right;

    p,
    h6 {
      color: ${colors.color500};
    }

    h6 {
      font-weight: 700;
    }
  }
`
