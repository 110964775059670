import styled from 'styled-components'
import { colors } from 'theme'

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  height: max-content;
  width: 250px;
  min-height: 300px;
  background: ${colors.white};
  border: 2px solid ${colors.white};
  border-radius: 15px;

  // Sample
  &.sample {
    min-height: auto;
    border: 2px solid ${colors.main};
  }
`

export const ProductContainer = styled.div`
  position: relative;
  flex: 3;
  display: flex;
  flex-direction: column;
  text-align: center;

  .product {
    padding: 10px 0 15px;
    color: ${(props) => props.theme.palette.common.black};
    .desc {
      font-weight: 700;
      font-size: 15px;
    }
    .brand,
    .format {
      color: ${colors.color500};
      font-size: 11px;
    }
  }
`

export const Banner = styled.img`
  border-radius: 15px 15px 0 0;
  height: 140px;
  width: auto;

  &.clickable {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      transition: opacity 200ms ease;
    }
  }
`

export const Wrapper = styled.div`
  position: relative;

  flex: 1;
  display: flex;
  margin-bottom: 3px;
  margin-left: 3px;

  border-top: 1px solid ${colors.main};
  /* border-bottom: 1px solid ${colors.main}; */
  padding: 8px 2px 4px;
`

export const Control = styled.div`
  margin-right: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-size: 9px;
    color: ${colors.color500};
  }

  &:last-child {
    margin-right: 0;
  }

  .read-only {
    user-select: none;
    pointer-events: none;
  }
`

export const ActionsContainer = styled.div`
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
`
