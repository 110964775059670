import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profile: { web_user: {}, web_company: {}, company: {} },
  locale: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      state.profile = payload
    },
    changeLocale(state, { payload }) {
      state.locale = payload
    },
    updateLoggedUser(state, { payload }) {
      state.profile.web_user = payload
    },
  },
})

export const { setUser, changeLocale, updateLoggedUser } = userSlice.actions

export default userSlice.reducer
