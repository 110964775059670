import React from 'react'
import { Content } from 'components/index'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function CustomerHomePage() {
  return <Content title="Bem vindo, Cliente" />
}

export default CustomerHomePage
