import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatISO, startOfMonth } from 'date-fns'

import RIGHTS from '__access_profiles__'

import apiEndPoints from 'consts/apiEndPoints'
import handleErrors from 'services/handleErrors'
import api from 'services/api'
import formatter from 'services/formatter'

import { usePagination } from 'hooks'
import { useAvaiableRight } from 'components/UserRight'
import { getTradeReceivablesRequest } from 'store/modules/users/queries/tradeReceivables/reducer'

import { Content, Table, TablePagination, Paper } from 'components/index'
import Filter from './Filter'
import Logo from 'components/Logos'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const initialFilterValues = {
  query: '',
  type: 'E',
  issuedAt: formatISO(startOfMonth(new Date())),
  issuedUntil: formatISO(new Date()),
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function TradeReceivablesPage() {
  const dispatch = useDispatch()
  const consultaBoletos = useAvaiableRight(RIGHTS.consultaBoletos.id)

  const tradeReceivables = useSelector(
    (state) => state.users.queries.tradeReceivables.tradeReceivables
  )
  const count = useSelector(
    (state) => state.users.queries.tradeReceivables.count
  )
  const loading = useSelector(
    (state) => state.users.queries.tradeReceivables.loading
  )

  const [selectedFilters, setSelectedFilters] = useState({
    ...initialFilterValues,
  })

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    usePagination({
      initialRowsPerpage: 30,
    })

  // const { currentSort, onSortChange } = useSortData({
  //   initialField: 'cr_data_vencto',
  //   initialOrder: 'asc',
  // })

  useEffect(() => {
    const { query, type } = selectedFilters

    const issuedAt = formatter(selectedFilters.issuedAt).toSimpleDate()
    const issuedUntil = formatter(selectedFilters.issuedUntil).toSimpleDate()

    dispatch(
      getTradeReceivablesRequest({
        page,
        perPage: rowsPerPage,
        issuedAt,
        issuedUntil,
        query,
        type,
      })
    )
  }, [dispatch, page, rowsPerPage, selectedFilters])

  const handleClickBankSlip = (rowData) => async (_e) => {
    try {
      const response = await api.get(
        apiEndPoints.queries.bankSlip.onMember(rowData.id),
        {
          params: { output: 'pdf' },
          responseType: 'blob',
        }
      )

      const file = new Blob([response.data], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (error) {
      handleErrors(error, `Não foi possível imprimir o boleto selecionado.`)

      return null
    }
  }

  return (
    <Content
      showReturn
      sideBarStart
      title="Consultar Duplicatas"
      loading={loading}
      SideComponent={
        <Filter
          initialValues={initialFilterValues}
          onFilter={setSelectedFilters}
        />
      }
    >
      <Paper className="border-none">
        <Table
          data={tradeReceivables}
          size="medium"
          columns={[
            { title: 'DUPLICATA', field: 'cr_num_duplicata' },
            { title: 'SÉRIE', field: 'cr_serie' },
            { title: 'ORDEM', field: 'cr_ordem' },
            {
              title: 'EMISSÃO',
              field: 'cr_data_emissao',
              cellFormat: 'date',
            },
            {
              title: 'VENCIMENTO',
              field: 'cr_data_vencto',
              cellFormat: 'date',
            },
            { title: 'PORTADOR', field: 'bank_bearer.descr' },
            { title: 'REPRESENTANTE', field: 'representative' },
            {
              title: 'VALOR',
              field: 'cr_valor',
              cellFormat: 'decimal',
            },
            {
              role: 'button',
              renderCell: ({ rowData }) => (
                <Logo
                  disabled={!consultaBoletos}
                  bank={rowData.bank_bearer.id}
                  onClick={handleClickBankSlip(rowData)}
                />
              ),
            },
          ]}
          groupHeader={[{ start: 9, label: 'EMITIR BOLETO' }]}
        />
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </Content>
  )
}

export default TradeReceivablesPage
