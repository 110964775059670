import { all, takeLatest, call, put } from 'redux-saga/effects'
import api from '../../../../services/api'
import handleErrors from '../../../../services/handleErrors'
import apiEndPoints from '../../../../consts/apiEndPoints'
import {
  getPossibleDatesRequest,
  getPossibleDatesFailure,
  getPossibleDatesSuccess,
} from './reducer'

function* getPossibleDates({ payload }) {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.possibleDates,
      { params: { type: payload.type } }
    )

    yield put(getPossibleDatesSuccess(response.data))
  } catch (error) {
    yield put(getPossibleDatesFailure())
    handleErrors(error, 'Não foi possível buscar as datas disponíveis')
  }
}

export default all([takeLatest(getPossibleDatesRequest.type, getPossibleDates)])
