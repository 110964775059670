import { RIGHTS } from '.'

export const logisticsRights = [
  RIGHTS.incluiAgendamento,
  RIGHTS.alteraAgendamento,
  RIGHTS.consultaCarregamentos,
  RIGHTS.consultaNotasFiscais,
  RIGHTS.alteraSenha,
  RIGHTS.consultaAvisos,
  RIGHTS.consultaLancamentos,
  RIGHTS.leituraTermo,
]

export default logisticsRights
