import React from 'react'
import { useSelector } from 'react-redux'
import { TabNavigation, Tabs, useTabContext } from 'components/TabNavigation'
import { Content } from 'components/index'
import OrdersForSchedule from '../../Schedules/Orders'
import FilterOrdersForSchedule from '../../Schedules/Orders/Filter'
import Schedule from '../../Schedules/Schedule'
import IssuedSchedules from '../../Schedules/IssuedSchedules'
import { Container, TabsContainer, StyledTabPanel } from './styles'

export function CustomerScheduleContent() {
  const { activeTab } = useTabContext()

  const ordersLoading = useSelector(
    (state) => state.users.ordersForCollect.loading
  )
  const schedulesLoading = useSelector((state) => state.users.schedules.loading)

  const loading = ordersLoading || schedulesLoading
  const showSideComponent = !loading && activeTab === 0

  return (
    <Content
      loading={loading}
      SideComponent={showSideComponent ? <SideComponent /> : null}
    >
      <Container>
        <TabsContainer>
          <Tabs
            tabs={[
              { label: 'Pedidos' },
              { label: 'Agendamento' },
              { label: 'Emitidos' },
            ]}
          />
        </TabsContainer>
        <StyledTabPanel index={0}>
          <OrdersForSchedule />
        </StyledTabPanel>
        <StyledTabPanel className="cover" index={1}>
          <Schedule />
        </StyledTabPanel>
        <StyledTabPanel className="cover" index={2}>
          <IssuedSchedules />
        </StyledTabPanel>
      </Container>
    </Content>
  )
}

function SideComponent({ activeTab, ...props }) {
  const selectedFilters = useSelector(
    (state) => state.users.ordersForCollect.selectedFilters
  )
  return (
    <FilterOrdersForSchedule {...props} selectedFilters={selectedFilters} />
  )
}

export default function CustomerSchedulePage(props) {
  return (
    <TabNavigation>
      <CustomerScheduleContent {...props} />
    </TabNavigation>
  )
}
