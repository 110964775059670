import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { colors } from 'theme'
import fallbackImage from 'assets/images/broken-image-fallback.png'

import formatter from 'services/formatter'

import { Box, Tooltip, Typography } from '@material-ui/core'
import { Star, StarBorder, Add, ShoppingCart } from '@material-ui/icons'
import TextField from '../TextField'
import ToolButton from '../ToolButton'
import {
  Container,
  ProductContainer,
  Wrapper,
  ActionsContainer,
  Banner,
  Control,
} from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const calculateProductQuantity = (
  field,
  value,
  boxesPerPallet,
  metersPerBox
) => {
  const values = {}

  switch (field) {
    case 'pallets':
      values.pallets = value
      values.boxes = value * boxesPerPallet
      break
    case 'boxes':
      values.boxes = value
      values.pallets = value / boxesPerPallet
      break
    default:
      return null
  }

  values.quantity = values.pallets * parseFloat(boxesPerPallet * metersPerBox)
  values.formatted_quantity = formatter(values.quantity).toDecimal()
  values.pallets = Math.floor(values.pallets)
  return values
}

const formInitialData = {
  pallets: 0,
  boxes: 0,
  unitaryPrice: '0',
  quantity: '0',
  formatted_quantity: '0',
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function Product(props) {
  const { isSample, product, onAddSample, onAddItem, onClickBanner } = props

  const [formValues, setFormValues] = useState({ ...formInitialData })
  const [selectedSample, setselectedSample] = useState(
    product.vpi_data_interesse !== null
  )

  const budgeted = parseFloat(product.qtde_orc) > 0
  const showControls = !isSample // && !budgeted;

  useEffect(() => {
    const handleInitialFormData = () => {
      if (isSample) return
      setFormValues(
        calculateProductQuantity(
          'pallets',
          1,
          product.ctp_caixa_pallet,
          product.prd_m2_caixa
        )
      )
    }
    handleInitialFormData()
  }, [product, isSample])

  const getInputProps = (name) => {
    const handleCalculateQuantity = (field, fieldValue) =>
      calculateProductQuantity(
        field,
        fieldValue,
        product.ctp_caixa_pallet,
        product.prd_m2_caixa
      )

    const handleChange = (e) => {
      const name = e.target.name
      const value = e.target.value
      const values = handleCalculateQuantity(name, value)
      if (values) setFormValues(values)
    }

    return {
      name,
      value: formValues[name],
      onChange: handleChange,
      variant: 'standard',
    }
  }

  const handleAddProduct = () => {
    const payload = {
      product,
      pallets: formValues.pallets,
      quantity: formValues.quantity,
      boxes: formValues.boxes,
    }
    onAddItem(payload)
  }

  const handleSendSample = () => {
    if (!selectedSample) {
      setselectedSample(true)
      onAddSample(product)
    }
  }

  const formatText = () => {
    const text = [product.dsc_tamanho_produtos, product.dsc_linha_produtos]

    if (product.ctp_caixa_pallet) {
      text.push(parseInt(product.ctp_caixa_pallet))
    }

    return text.join(' - ')
  }

  return (
    <Container
      className={clsx({
        sample: isSample,
      })}
    >
      <ProductContainer>
        <Banner
          className={clsx('product-banner', {
            clickable: !!onClickBanner,
          })}
          src={product.prd_link_img_produto || fallbackImage}
          onClick={() => onClickBanner && onClickBanner(product)}
          role="button"
        />
        <div className="product">
          <Typography className="desc">{product.dsc_produto}</Typography>
          <Typography className="brand">{product.dsc_marca}</Typography>
          <Typography className="format">{formatText()}</Typography>
        </div>
      </ProductContainer>
      {showControls && (
        <Wrapper>
          <Control style={{ flex: 1 }}>
            <TextField
              {...getInputProps('pallets')}
              label="Paletes"
              type="number"
              disabled={budgeted}
              inputProps={{
                min: 1,
              }}
            />
          </Control>
          <Control style={{ flex: 1 }}>
            <TextField
              {...getInputProps('boxes')}
              label="Caixas"
              type="number"
              disabled={product.cli_nao_pallet_aberto || budgeted}
              inputProps={{
                min: 1,
              }}
            />
          </Control>
          <Control style={{ flex: 2, overflowX: 'auto' }}>
            <TextField
              {...getInputProps('formatted_quantity')}
              label="Quantidade"
              disabled
            />
          </Control>
        </Wrapper>
      )}
      <ActionsContainer>
        {isSample && (
          <ToolButton
            onClick={handleSendSample}
            tooltip={
              selectedSample ? 'Amostra Solicitada' : 'Solicitar Amostra'
            }
            active={selectedSample}
            Icon={selectedSample ? Star : StarBorder}
            backgroundColor={colors.notification}
          />
        )}
        {budgeted ? (
          <ToolButton
            disabled
            active
            extended
            backgroundColor={colors.color500}
            Icon={() => (
              <Box display="flex" alignItems="center">
                <Tooltip
                  arrow
                  title="Produto já incluido - Fazer a alteração ou exclusão pela aba orçamento."
                >
                  <ShoppingCart fontSize="small" style={{ marginRight: 10 }} />
                </Tooltip>
                <span>
                  {[formatter(product.qtde_orc).toDecimal(), 'm2'].join('')}
                </span>
              </Box>
            )}
          />
        ) : (
          showControls && (
            <ToolButton
              active
              tooltip="Incluir no Orçamento"
              backgroundColor={colors.success}
              onClick={handleAddProduct}
              Icon={Add}
            />
          )
        )}
      </ActionsContainer>
    </Container>
  )
}

Product.propTypes = {
  product: PropTypes.object,
  onAddItem: PropTypes.func,
  onAddSample: PropTypes.func,
  onClickBanner: PropTypes.func,
  isSample: PropTypes.bool,
  loading: PropTypes.bool,
}

export default Product
