import styled from 'styled-components'
import { colors } from '../../theme'
import { KeyboardDatePicker } from '@material-ui/pickers'

export default styled(KeyboardDatePicker)`
  .MuiFormLabel-root {
    /* font-size: 12px; */
    color: ${colors.color500};
  }

  .MuiFormControl-root {
  }

  .MuiButtonBase-root {
    padding: 3px;
    border-radius: 3px;
  }
`
